<template>
  <s-dialog
    :visible="dialogShow"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="closeHandle"
  >
    <template slot="title">
      <span v-html="props.orderItem?.title"></span>
    </template>
    <div
      v-if="props.orderItem"
      class="unpaid-dialog__wrap"
    >
      <template v-if="isCompliance">
        <div class="unpaid-dialog__wrap-nospecial">
          <div class="unpaid-dialog__wrap-countdown">
            {{ props.orderItem?.countdownTips }}
          </div>
          <div
            class="unpaid-dialog__wrap-countdown"
            v-html="getCountDownTips"
          >
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="unpaid-dialog__wrap-countdown"
          v-html="getCountDownTips"
        >
        </div>
      </template>
      
      <div class="unpaid-dialog__wrap-goods">
        <div class="unpaid-dialog__goods-img-wrap">
          <img
            v-for="(src, index) in getGoodImageList"
            :key="`${src}_${index}`"
            :src="transformImg({ img: src })"
            class="unpaid-dialog__goods-img"
          />
        </div>

        <div
          v-if="getGoodImageList.length === 1"
          class="unpaid-dialog__goods-info"
        >
          <p class="unpaid-dialog__goods-info-title">
            {{ props.orderItem?.goodInfo.title }}
          </p>
          <p
            class="unpaid-dialog__goods-info-price"
            v-html="props.orderItem?.goodInfo.currencyPayAmount"
          >
          </p>
        </div>
      </div>
      <div
        v-if="props.orderItem?.reviewScore"
        class="unpaid-dialog__wrap-review"
      >
        <div
          
          class="unpaid-dialog__review-title"
          @click="showReviewTitlePopover"
        >
          <span>{{ props.orderItem?.reviewText }}</span>
          <s-popover
            v-model="showReviewTitle"
            class="unpaid-dialog__popover"
            placemen="bottom-end"
            trigger-type="user"
            :prop-style="{
              'z-index': 999999,
              'font-weight': 700,
              'max-width': '6.08rem'
            }"
            :content="props.orderItem?.extraText"
          >
            <i
              v-if="getGoodImageList.length > 1"
              slot="reference"
              class="unpaid-dialog__review-title-icon suiiconfont sui_icon_info_14px_2"
            >
            </i>
          </s-popover>
        </div>
      
        <!-- 评分 -->
        <div
          class="star-box"
        >
          <div class="star-num">
            {{ props.orderItem?.reviewScore }}
          </div>
          <div
            class="star-html"
            v-html="ratingStarHTML"
          ></div>
        </div>

        <div class="unpaid-dialog__review-span">
          <span
            v-for="(item) in props.orderItem?.goodReviewText"
            :key="item"
          >
            {{ item }}
          </span>
        </div>
      </div>
    </div>

    <template
      slot="footer"
    >
      <div
        class="unpaid-dialog__footer"
        @click="payNowHandle"
      >
        <AdaptText
          :text="props.orderItem?.clickButton.content || ''"
          :maxrem="8.0533"
          :source="[10, 14]"
        />
      </div>
    </template>
  </s-dialog>
</template>
<script setup>
// 未支付弹窗(旧)
import { ref, watch, computed, onMounted, getCurrentInstance } from 'vue'
import { CountDown, transformImg } from '@shein/common-function'
import AdaptText from 'public/src/pages/components/AdaptText.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getBuriedData } from 'public/src/pages/user_index/service/utils.js'
import { useMapMutations } from '../../../hooks/useVuex.js'
import { formatUrlQuery } from '../../../service/utils.js'
const { langPath } = gbCommonInfo


const { setStoreState } = useMapMutations(['setStoreState'])

const instance = getCurrentInstance()
const routerInstance = instance?.proxy?.$router

const countTimer = ref(new CountDown())
const dialogShow = ref(false)
const showReviewTitle = ref(false)
const isJumpToOtherPage = ref(false)

const props = defineProps({
  orderItem: { type: Object, default: () => {} }
})

onMounted(() => {
  /* 未支付订单倒计时 */
  const { expireCountdown } = props.orderItem || {}
  if (expireCountdown) {
    countTimer.value.start({
      seconds: expireCountdown,
      endFunc: () => {
      // 倒计时结束
      }
    })
  }
})

/* computed */
// 是否合规
const isCompliance = computed(() => !!props.orderItem?.countdownTips)
const getGoodImageList = computed(() => props.orderItem?.goodInfo?.goodImageList || [])
const getCountDownTips = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  const timeDom = `<span>${H}</span> : <span>${M}</span> : <span>${S}</span>`
  return isCompliance.value ? timeDom
    : `${props.orderItem?.countdownTips} ${timeDom}`
})
const ratingStarHTML = computed(() => window.GB_SHEIN_counting_star_suiicon(
  props.orderItem?.reviewScore, 15, 2
))

/* methods */
const showReviewTitlePopover = () => {
  if (showReviewTitle.value || props.orderItem.goodInfo?.goodImageList.length <= 1) return
  showReviewTitle.value = true
  // 3s后自动关闭
  setTimeout(() => {
    showReviewTitle.value = false
  }, 3000)
}

const payNowHandle = () => {
  dialogShow.value = false
  isJumpToOtherPage.value = true
  const { jumpUrl = '', jumpParams = {} } = props.orderItem?.clickButton?.urlObject
  const { billno, page_from, from_type, show_selected_pay_method } = jumpParams
  const { order_id, scene } = getBuriedData(props.orderItem?.buriedObject, 2)
  const urlQuery = formatUrlQuery({
    goBack: 1,
    show_error_guide_payment: show_selected_pay_method,
    from_type,
    page_from
  })
  daEventCenter.triggerNotice({
    daId: '1-25-11-1003',
    extraData: {
      order_id,
      scene
    }
  })
  routerInstance.push(`${langPath}${jumpUrl}/${billno}?${urlQuery}`)
}

const closeHandle = () => {
  if (!isJumpToOtherPage.value) {
    setStoreState({ dialogName: 'next-renew' })
  }
  daEventCenter.triggerNotice({
    daId: '1-25-11-1005',
    extraData: getBuriedData(props.orderItem?.buriedObject, 2)
  })
  dialogShow.value = false
}

/* watch */
watch(() => props.orderItem, (val) => {
  if (!val) return
  dialogShow.value = true
  setStoreState({ dialogName: 'unpaid' })
  const { order_id, scene } = getBuriedData(props.orderItem?.buriedObject, 1)
  daEventCenter.triggerNotice({
    daId: '1-25-11-1004',
    extraData: {
      order_id,
      scene
    }
  })
}, { immediate: true })

</script>


<style lang="less">
.unpaid-dialog {
  &__wrap-nospecial {
    margin-bottom: 0.1067rem;
    display: flex;
    justify-content: center;
  }
  &__wrap-countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .1067rem 0;
    color: @sui_color_discount;
    font-weight: 700;
    font-size: 12px;
    line-height: .3733rem;
    span {
      background-color: @sui_color_discount;
      font-weight: 400;
      color: #FFFFFF;
      width: 0.4267rem;
      height: 0.4267rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: .0533rem;
      &:first-child {
        margin-left: .1067rem;
      }
    }
  }
  &__wrap-goods {
    padding: .16rem;
    margin-bottom: 10px;
    background-color: #F6F6F6;
    display: flex;
  }
  &__goods-img-wrap {
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__goods-img {
    width: 1.4933rem;
    height: 1.4933rem;
    margin-right: .1067rem;
    object-fit: cover;
  }
  &__goods-info {
    margin-left: .1067rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    max-width: calc(100% - 1.6rem);
  }
  &__goods-info-title {
    font-size: 12px;
    color: #000;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  &__goods-info-price {
    font-size: 14px;
    font-weight: 700;
    color: #222;
  }
  &__wrap-review {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__review-title {
    text-align: center;
    span {
      color: #666666;
      font-weight: 400;
    }
  }
  &__review-title-icon {
    color: #959595;
    margin-left: .1067rem;
  }
  &__review-span {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    span {
      padding: .1067rem;
      margin: 0 .1067rem .1067rem 0;
      background-color: #F6F6F6;
    }
  }
  &__footer {
    width: 100%;
    padding: 9px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    background-color: #000000;
  }
}
// 左侧星级块
.star-box {
  display: flex;
  align-items: center;
  margin: .1067rem 0;
}
.star-num {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  color: #222;
  margin-right: .16rem;
}
.unpaid-dialog__wrap-review [class*=icon_star] {
  display: inline-block;
  font-size: 20px !important;
  color: #facf19;
}
</style>
