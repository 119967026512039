import { getCurrentInstance, computed } from 'vue'

export const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$store
}

export const useMapState = (stateKeys, moduleName = 'userIndex') => {
  const store = useStore()
  return Object.fromEntries(
    stateKeys.map(key => [
      key,
      computed(() => store.state[moduleName][key])
    ])
  )
}

export const useMapMutations = (mutationKeys, moduleName = 'userIndex') => {
  const store = useStore()
  return Object.fromEntries(
    mutationKeys.map(mutation => [
      mutation,
      value => store.commit(`${moduleName}/${mutation}`, value)
    ])
  )
}
