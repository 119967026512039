var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"new-user-benefits__coupon-item",style:(_setup.itemStyles),on:{"click":function($event){$event.stopPropagation();return _setup.onCouponItemClick.apply(null, arguments)}}},[_c('div',{staticClass:"new-user-benefits__coupon-left",style:(_setup.itemLeftStyles)},[(_setup.couponRules.length)?_vm._l((_setup.couponRules),function(rule,index){return _c('div',{key:index,staticClass:"new-user-benefits__coupon-rule",style:({
          maxWidth: `${90 / _setup.couponRules.length}%`
        })},[_c(_setup.SAdapterText,{staticClass:"new-user-benefits__coupon-discount",class:[_vm.couponStyle.discountFontSizeClass],style:({
            lineHeight: `${_vm.couponStyle.discountLineHeightRem}rem`,
          }),attrs:{"min-size":12,"text":_setup.getCouponDiscountText(rule, _vm.couponData, _vm.language, _setup.GB_cssRight)}}),_vm._v(" "),(_setup.getCouponApplyRuleTips(rule, _vm.couponData, _vm.language))?_c(_setup.SAdapterText,{staticClass:"new-user-benefits__coupon-rule-tips",style:({
            lineHeight: `${_vm.couponStyle.ruleTipsLineHeightRem}rem`,
          }),attrs:{"min-size":9,"text":_setup.getCouponApplyRuleTips(rule, _vm.couponData, _vm.language)}}):_vm._e()],1)}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"new-user-benefits__coupon-right"},[_c('div',{staticClass:"new-user-benefits__coupon-use-btn"},[_c(_setup.SAdapterText,{staticClass:"new-user-benefits__coupon-use-text",attrs:{"min-size":9,"text":_vm.language.SHEIN_KEY_PWA_27132}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }