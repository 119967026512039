import { getLocalStorage, setLocalStorage } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

const { langPath } = gbCommonInfo
const redDotKey = `${langPath.replace(/\/\//, '')}_RED_DOT_TIME`
export const getRedDotStatus = (type) => {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'getRedDotStatus' })
  const status = getLocalStorage(`${redDotKey}_${memberId}_${type}`) || {}
  return status?.[type]

}

export const setRedDotStatus = ({ type, expire }) => {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'setRedDotStatus' })
  setLocalStorage({ key: `${redDotKey}_${memberId}_${type}`, value: true, expire })
}
