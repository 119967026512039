<template>
  <div :class="['page__login_confrim', isDialog ? 'risk-pwd-dialog' : '', IS_RW ? 'rwm-risk-pwd' : '']">
    <s-alert
      type="error"
      :show-icon="true"
    >
      <p
        tabindex="0"
        v-html="isNewReveal && scene == 'login_success' ? language.SHEIN_KEY_PWA_17815 : language.SHEIN_KEY_PWA_17710"
      ></p>
      <!-- 用于防止浏览器自动填充 -->
      <input
        class="mshe-hide"
        type="password"
      />
    </s-alert>
    <div class="risk-process">
      <div class="process-item ">
        <span
          v-if="bcIndex == 0"
          :class="['ball_num', bcIndex == 0 ? 'active' : '']"
        >1</span>
        <i
          v-else
          class="iconfont icon-normal-checked"
        ></i>
        <span
          v-show="bcIndex == 0"
          class="process-title"
        >{{ language.SHEIN_KEY_PWA_17713 }}</span>
      </div><!--
						--><span :class="['process-line', bcIndex == 0 ? 'short-line' : '']"></span><!--
						--><div class="process-item">
							<span
v-if="bcIndex != 2"
:class="['ball_num', bcIndex == 1 ? 'active' : '']"
>2</span>
							<i
v-else
class="iconfont icon-normal-checked"
></i>
							<span
v-show="bcIndex == 1"
class="process-title"
>{{ language.SHEIN_KEY_PWA_15287 }}</span>
      </div><!--
						--><span :class="['process-line', bcIndex == 1 ? 'short-line' : '']"></span><!--
						--><div class="process-item">
							<span :class="['ball_num', bcIndex == 2 ? 'active' : '']">3</span>
							<span
v-show="bcIndex == 2"
class="process-title"
>{{ language.SHEIN_KEY_PWA_17714 }}</span>
						</div>
    </div>

    <div
      v-show="bcIndex == 0"
      class="process-tab info-tab"
    >
      <div
        v-if="emailCap"
        class="info-item info-email"
      >
        <div
          class="item-title"
          tabindex="0"
        >
          {{ language.SHEIN_KEY_PWA_15288 }}
        </div>
        <div
          class="item-val"
          tabindex="0"
        >
          {{ showEmail }}
        </div>
      </div>

      <div
        v-else
        class="info-item info-phone"
      >
        <div
          class="item-title"
          tabindex="0"
        >
          {{ language.SHEIN_KEY_PWA_17715 }}
        </div>
        <div
          v-if="phoneList[phoneIndex]"
          class="item-val"
        >
          <span tabindex="0">{{ phoneList[phoneIndex].telephone }}</span>
          <span
            v-enterkey
            class="select-phone-btn"
            tabindex="0"
            role="button"
            @click="showPhoneList = true"
          >{{ language.SHEIN_KEY_PWA_17752 }}<i class="iconfont icon-Rectanglex"></i></span>
        </div>
      </div>

      <div class="info-item info-code">
        <div
          class="item-title"
          tabindex="0"
        >
          {{ language.SHEIN_KEY_PWA_17724 }}
        </div>
        <input
          v-model.trim="sendCodeVal"
          type="text"
          class="item-val code-val"
          onkeyup="this.value=this.value.replace(/\s+/g,'')"
          @blur="sendverifyFun"
        />
        <s-button
          v-show="emailCap"
          class="send-code-btn"
          :type="['primary', 'H48PX']"
          :disabled="sended"
          @click.prevent="sendSms(true)"
        >
          {{ sendtips }}
        </s-button>
        <s-button
          v-show="!emailCap"
          class="send-code-btn"
          :type="['primary', 'H48PX']"
          :disabled="telSended"
          @click.prevent="sendSms()"
        >
          {{ telSendtips }}
        </s-button>
      </div>
      <p
        v-show="resendTipErr"
        class="normal-info"
        tabindex="0"
        v-html="resendTipErr"
      ></p>

      <div class="very-code-btn">
        <s-button
          v-show="!goVerifing"
          :type="['primary', 'H88PX']"
          :disabled="!sendCodeVal"
          @click="goVerifyCode"
        >
          {{ language.SHEIN_KEY_PWA_15326 }}
        </s-button>
        <s-button
          v-show="goVerifing"
          :type="['primary', 'H88PX']"
          :disabled="true"
        >
          <s-loading :show="goVerifing" />
        </s-button>
      </div>
    </div>

    <!-- 新密码 -->
    <div
      v-show="bcIndex == 1"
      class="process-tab info-tab"
    >
      <div class="info-item info-email">
        <div
          class="item-title"
          tabindex="0"
        >
          {{ language.SHEIN_KEY_PWA_15208 }}
        </div>
        <input
          v-model.trim="sendnewpassVal"
          :type="inputPwType ? 'password' : 'text'"
          class="item-val code-val"
          autocomplete="off"
          @focus="foucsCheckexg"
          @keyup="delayCheckexg('sendnewpassVal')"
        />
        <i
          :class="['pwtype-icon', 'iconfont', inputPwType ? 'icon-pass' : 'icon-see-pass']"
          @click="inputPwType = ! inputPwType"
        ></i>
      </div>

      <div
        v-show="!safety.hideAll"
        class="normal-info"
      >
        <!--<p>{{language.SHEIN_KEY_PWA_15785}}</p>-->
        <div
          :class="{'normal-red' :safety.showAll}"
          tabindex="0"
        >
          <p :class="{'tips-greenp':safety.showFir}">
            {{ language.SHEIN_KEY_PWA_16140 }}
          </p>
          <p :class="{'tips-greenp':safety.showSec}">
            {{ language.SHEIN_KEY_PWA_16141 }}
          </p>
          <p :class="{'tips-greenp':safety.showThi}">
            {{ language.SHEIN_KEY_PWA_16142 }}
          </p>
        </div>
      </div>

      <div class="info-item info-email">
        <div
          class="item-title"
          tabindex="0"
        >
          {{ language.SHEIN_KEY_PWA_15181 }}
        </div>
        <input
          v-model.trim="sendconpassVal"
          :type="inputPwType ? 'password' : 'text'"
          class="item-val code-val"
          onkeyup="this.value=this.value.replace(/\s+/g,'')"
          autocomplete="off"
        />
        <i
          :class="['pwtype-icon', 'iconfont', inputPwType ? 'icon-pass' : 'icon-see-pass']"
          @click="inputPwType = ! inputPwType"
        ></i>
      </div>

      <p
        v-show="sendconPassTxtErr"
        class="normal-info"
        tabindex="0"
        v-html="sendconPassTxtErr"
      ></p>

      <div class="very-code-btn">
        <s-button
          v-show="!sendcoding"
          :type="['primary', 'H88PX']"
          @click.prevent="sendcodePassword"
        >
          {{ language.SHEIN_KEY_PWA_16462 }}
        </s-button>
        <s-button
          v-show="sendcoding"
          :type="['primary', 'H88PX']"
          :disabled="true"
        >
          <s-loading :show="sendcoding" />
        </s-button>
      </div>
    </div>

    <div
      v-show="bcIndex == 2"
      class="process-tab info-tab success-tab"
    >
      <i
        v-if="WEB_CLIENT == 'shein'"
        class="iconfont icon-success"
      ></i>
      <i
        v-else
        class="suiiconfont sui_img_fb_success_75px"
      ></i>
      <h3 tabindex="0">
        {{ language.SHEIN_KEY_PWA_17718 }}
      </h3>
      <p tabindex="0">
        {{ language.SHEIN_KEY_PWA_17753 }}
      </p>
      <div class="very-code-btn">
        <s-button
          :type="['primary', 'H88PX']"
          @click="successConfirm"
        >
          {{ language.SHEIN_KEY_PWA_15631 }}
        </s-button>
      </div>
    </div>
    <div
      v-if="!isDialog"
      class="pwd-contact sign-out-btn"
      @click="signOutClick"
    >
      <a
        href="javascript:;"
        class="pwd-contact-signout"
      >
        {{ language.SHEIN_KEY_PWA_15616 }}
      </a>
    </div>
    <div
      v-show="bcIndex != 2"
      class="pwd-contact"
      @click="onlineHelp"
    >
      <a
        href="javascript:;"
        class="j-contact-email"
        v-html="language.SHEIN_KEY_PWA_17754"
      ></a>
    </div>
    <div
      v-show="bcIndex == 0 && riskType == 'choose_number_channel_captcha' && ((emailCap && phoneList.length) || (!emailCap && email))"
      class="switch-cap"
    >
      <div>
        <span class="cap-line"></span>
        <span
          v-if="emailCap"
          class="cap-tip"
        >{{ language.SHEIN_KEY_PWA_17717 }}</span>
        <span
          v-else
          class="cap-tip"
        >{{ language.SHEIN_KEY_PWA_17716 }}</span>
        <span class="cap-line"></span>
      </div>
      <i
        :class="['iconfont', emailCap ? 'icon-shouji' : 'icon-youxiang']"
        @click="switchCaptcha"
      ></i>
    </div>

    <!-- 电话选择弹窗 -->
    <div class="phone-list-dialog">
      <s-drawer
        :direction="'btt'"
        :visible.sync="showPhoneList"
        aside-class="mche-ship-drawer-aside"
      >
        <div class="shipping-drawer-header">
          <s-title-nav :title="language.SHEIN_KEY_PWA_17751" />
        </div>
        <div
          class="list-box"
          style="overflow-y: hidden;padding-bottom: 0"
        >
          <div class="">
            <ul class="all-list">
              <template v-for="(item, index) in phoneList">
                <li
                  :key="index"
                  :class="[phoneIndex == index ? 'active' : '']"
                  @click.stop="choosePhone(index)"
                >
                  <div class="mshe-flexbetween">
                    {{ item.telephone }}<i
                      v-show="phoneIndex == index"
                      class="iconfont icon-normal-checked"
                    ></i>
                  </div>
                </li>
              </template>
            </ul>
            <button
              class="mshe-btn-black mshe-btn-block"
              @click="showPhoneList = false"
            >
              Cancel
            </button>
          </div>
        </div>
      </s-drawer>
    </div>
  </div>
</template>
<script>
/* globals _gb_passwordSafetyExp */
const { host, langPath, IS_RW } = gbCommonInfo
import { InitFtoken } from 'public/src/pages/common/ftoken/index'
import { getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp/index'
import { Button, Alert, TitleNav as STitleNav,  } from '@shein/sui-mobile'
import { robotLinkCommon } from 'public/src/pages/common/utils/index.js'

const { WEB_CLIENT } = gbCommonInfo

export default {
  name: 'LoginConfirmVue',
  components: { SButton: Button, SAlert: Alert, STitleNav },
  props: {
    pageType: { type: Number, default: 0 },
    isDialog: { type: Boolean, default: false }
  },
  data: function () {
    return {
      WEB_CLIENT,
      loginFormEmail: '',
      showType: '', // 1：无邮箱：不展示验证码&修改密码 2：显示验证码&修改密码  3：仅显示验证码
      keepEmail: '',
      returnregs: false,
      needRevisePass: false,
      // sended: false,
      // sendtips: login_lang.SHEIN_KEY_PWA_14897,
      oldpassVal: '',
      newpassVal: '',
      conpassVal: '',
      oldpassErrorTxt: '',
      newpassErrorTxt: '',
      conpassErrorTxt: '',
      sendnewpassVal: '',
      sendconpassVal: '',
      sendnewpassTxtErr: '',
      sendconPassTxtErr: '',
      resendTipErr: '',
      // sendCodeVal: '',
      sendCodestatus: false,
      sendcoding: false,
      sendcodingold: false,
      setTimeId: '',
      setTimePhoneId: '',
      isReveal: getQueryString({ key: 'isReveal' }) == 1,
      hasInitSa: false,
      safety: {
        showFir: 0,
        showSec: 0,
        showThi: 0,
        showAll: 0,
        hideAll: 0
      },

      bcIndex: 0,

      scene: 'view_privacy',
      riskStr: 1,

      email: '',
      sendCodeVal: '',
      riskType: '',
      pwdType: '',

      phoneList: [],
      phoneIndex: 0,
      telStr: '',

      riskInfo: {},
      newPwd: '',
      newPwdVis: false,
      conPwd: '',
      conPwdVis: false,

      sended: false,
      sendtips: '',
      telSended: false,
      telSendtips: '',

      goVerifing: false,
      emailCap: true,	
      tip: {
        show: false,
        txt: ''
      },
      showPhoneList: false,
      goSendPwd: false,
      language: {},
      IS_RW,
      inputPwType: true,
      isNewReveal: false,
      loginCode: {}
    }
  },
  computed: {
    showEmail () {
      let showEmail = this.email
      if (showEmail) {
        // 获取邮箱后缀
        const suffixArr = showEmail.match(/(?=@).+/)
        let name = showEmail.match(/.*(?=@)/)
        if (suffixArr && suffixArr.length && suffixArr[0]) {
          const suffixStr = suffixArr[0]
          name = name[0] || showEmail
          const _f = name.substr(0, 2)
          const _l = name.substr(2).replace(/./g, '*')
          name = _f + _l
          showEmail = name + suffixStr
        }
      }
      return showEmail
    }
  },
  created () {
    if (this.isDialog) {
      schttp({ url: '/api/user/center/riskLang/get' }).then((res) => {
        if (res && res.code == 0) {
          this.updateLang(res.info.language)
        }
      })
    }
  },

  mounted: function () {
    InitFtoken(() => {
      if (!this.isDialog && gbCommonInfo.user && gbCommonInfo.user.member_id) {
        this.setScene()
        this.checkInfo()
      }
    }, { notSendFtoken: true })
  },
  methods: {
    updateLang (language) {
      Vue.set(this, 'language', language || {})
      this.sendtips = this.language.SHEIN_KEY_PWA_15323
      this.telSendtips = this.language.SHEIN_KEY_PWA_15323

      const loginCode = {
        '400502': language.SHEIN_KEY_PWA_15183,
        '400505': language.SHEIN_KEY_PWA_15258,
        '400506': language.SHEIN_KEY_PWA_14898,
        '400527': language.SHEIN_KEY_PWA_15786,
        '400528': language.SHEIN_KEY_PWA_16069,
        '400525': language.SHEIN_KEY_PWA_16043,
        '400534': language.SHEIN_KEY_PWA_16043,
        '400535': language.SHEIN_KEY_PWA_15786,
        '400530': language.SHEIN_KEY_PWA_16108,
        '400531': language.SHEIN_KEY_PWA_16108,
        '400524': language.SHEIN_KEY_PWA_17712,
        '9005': language.SHEIN_KEY_PWA_17719,
        '9002': language.SHEIN_KEY_PWA_17720,
        '9007': language.SHEIN_KEY_PWA_17712,
        'default': language.SHEIN_KEY_PWA_15235
      }
      this.loginCode = loginCode
    },

    dialogUpdateInfo ({ scene, info, cb, force = false } = {}) {
      // 场景切换时，重新初始化
      if (this.isDialog && this.scene != scene) {
        this.bcIndex = 0
      }
      if (force) {
        this.bcIndex = 0
        this.sendCodeVal = ''
        this.sendnewpassVal = ''
        this.sendconpassVal = ''
      }
      this.scene = scene
      const riskStr = this.updateRiskStr(info)
      // 泄露名单展示，爆破黑名单只在登录成功后展示
      if ([2, 3].includes(riskStr)) {
        this.setType()

        sa('send', { 
          activity_name: 'expose_popup_blacklist_pwreset',
          activity_param: {
            page_from: this.scene
          }
        })
      }
      cb && cb(riskStr)
    },

    setScene () {
      // 这里需要传入scene
      let scene = 'view_privacy'
      if (!this.isDialog) {
        scene = getQueryString({ key: 'scene' }) || scene
      }

      // const pathList = location.pathname.split('/')
      // if (pathList.length && pathList[0] === '') {
      //   if (pathList[1] == 'user') {
      //     if (pathList[2] == 'edit_password') {
      //       scene = 'change_pwd'
      //     }	
      //   }
      // }
      this.scene = scene
    },

    updateRiskStr (info) {
      let riskStr = 1
      Vue.set(this, 'riskInfo', info)
      if (info && info.extend_info && info.extend_info.actions && info.extend_info.actions.length) {
        // 泄露名单
        info.extend_info.actions.some(item => item.name == 'change_pwd_leak') && (riskStr = 3)
        // 爆破黑名单
        info.extend_info.actions.some(item => item.name == 'change_pwd_blasting') && (riskStr = 2)
      }

      // 新泄露名单
      if ( 
        info && info.risk_reason && 
			info.risk_reason.risk_module_detail && 
			info.risk_reason.risk_module_detail.risk_type &&
			info.risk_reason.risk_module_detail.risk_type.length && 
			info.risk_reason.risk_module_detail.risk_type.includes(444)
      ) {
        this.isNewReveal = true
      }

      this.riskStr = riskStr
      return riskStr
    },

    checkInfo () {
      const data = { scene: this.scene, ftoken: gbCommonInfo.ftoken }
      schttp({ method: 'POST', url: '/api/user/center/checkRisk/create', data }).then((res) => {
        this.updateLang(res.language)
        // 1：非风险用户，2：风险用户（爆破黑名单），3：风险用户（泄露名单）
        let riskStr = 1

        if (res && res.code == 0 && res.info) {
          riskStr = this.updateRiskStr(res.info)
          // 泄露名单展示，爆破黑名单只在登录成功后展示
          if ([2, 3].includes(riskStr)) {
            this.setType()
          }
        }
        if (riskStr == 1 && !this.isDialog) {
          // _gb_app_.$router.push(langPath || '/')
          location.href = langPath || '/'
        }
        this.initSaPageInfo()
      }).catch(() => {
        if (!this.isDialog) {
          // _gb_app_.$router.push(langPath || '/')
          location.href = langPath || '/'
        }
      })
    },

    setType () {
      const { extend_info } = this.riskInfo
      if (extend_info && extend_info.actions) {
        extend_info.actions.forEach(item => {
          const { name, param } = item

          // 短信验证
          if (name == 'choose_number_captcha') {
            this.riskType = name
            Vue.set(this, 'phoneList', param.telephone_list || [])
            this.emailCap = false
            this.sendSms()

            // 邮箱验证
          } else if (name == 'email_captcha' && param.email) {
            this.riskType = name
            this.email = param.email
            this.sendSms(true)

            // 手机/邮箱验证，优先展示邮箱验证
          } else if (name == 'choose_number_channel_captcha') {
            this.riskType = name
            this.email = param.email
            Vue.set(this, 'phoneList', param.telephone_list || [])

            if (!this.email && this.phoneList.length) {
              this.emailCap = false
            }
            this.sendSms(true)
							
            // 爆破黑名单，强制限制访问个人信息
          } else if (name == 'change_pwd_blasting') {
            this.pwdType = name

            // 泄露名单，允许访问个人信息
          } else if (name == 'change_pwd_leak') {
            this.pwdType = name
          }
        })
      }
    },

    initEvent: function () {
      // 改成服务端判断
      this.safety.showAll = 0
      this.safety.showFir = 0
      this.safety.showSec = 0
      this.sendtips = this.language.SHEIN_KEY_PWA_14897
      this.sended = false
    },
    checkexg: function (pwd) {
      var self = this
      // var pwdexp = /^(?=.*[0-9])(?=.*[a-zA-Z])/
      self[pwd] = self[pwd].replace(/\s+/g, '')

      if (self[pwd].length >= 8) {
        self.safety.showFir = 1
      } else {
        self.safety.showFir = 0
      }
      if (/[a-zA-Z]{1,}/.test(self[pwd])) {
        self.safety.showSec = 1
      } else {
        self.safety.showSec = 0
      }
      if (/[0-9]{1,}/.test(self[pwd])) {
        self.safety.showThi = 1
      } else {
        self.safety.showThi = 0
      }
      if (!_gb_passwordSafetyExp.test(self[pwd])) {
        self.safety.showAll = 1
      }
      var timer = setInterval(function () {
        clearInterval(timer)
        if (self.safety.showFir == 1 && self.safety.showSec == 1 && self.safety.showThi == 1) {
          self.safety.hideAll = 1

          sa('send', {
            activity_name: 'expose_verification_check_error',
            activity_param: {
              verification_popup_type: self.getVerificationPopupType(),
              front_event: self.pageType
            }
          })
        } else {
          self.safety.hideAll = 0
        }
      }, 200)
    },
    foucsCheckexg: function () {
      this.safety.showAll = 1
    },
    delayCheckexg: function (pwdval) {
      var self = this
      self.checkexg(pwdval)
    },
    sendverifyFun: function () {
      if (this.sendCodeVal !== '') {
        this.resendTipErr = ''
      }
    },
    sendcodePassword: function () {
      if (this.sendnewpassVal === '' || !_gb_passwordSafetyExp.test(this.sendnewpassVal)) {
        this.safety.hideAll = 0

        return false
      } else if (this.sendnewpassVal !== this.sendconpassVal) {
        this.sendconPassTxtErr = this.language.SHEIN_KEY_PWA_15183

        return false
      }

      this.sendcoding = true
      this.sendconPassTxtErr = ''

      var data = {
        risk_id: this.riskInfo.risk_id,
        content: this.sendCodeVal,
        password: this.sendnewpassVal,
        password_confirm: this.sendconpassVal,
        scene: this.scene
      }
      schttp({ method: 'POST', url: '/api/user/center/riskPwdChange/update', data }).then((res) => {
        this.sendcoding  = false
        const { code,  info, tips } = res || {}
        if (code == 0 && info?.result == 1 || code == 401101) {
          this.successHandle()
          return
        }

        this.sendconPassTxtErr = this.loginCode[code] || tips || this.loginCode['default']
      })

      sa('send', { 'activity_name': 'click_submit' })
    },
    onlineHelp: function () {
      sa('send', {
        'activity_name': 'click_customer_service'
      }, { beacon: 1 })

      location.href = robotLinkCommon()
    },
    signOutClick: function () {
      sa('send', {
        activity_name: 'click_verification_sign_out',
        activity_param: {
          verification_popup_type: this.getVerificationPopupType()
        }
      })

      location.href = host + langPath + '/user/logout'
    },

    getVerificationPopupType: function () {
      if (this.pageType == 11) {
        return this.showType == 3 ? 0 : this.showType == 2 ? 1 : 2
      } else {
        return 3
      }
    },

    countdown (isEmail, time) {
      let s = 0
      let txt = ''

      isEmail && clearInterval(this.setTimeId)
      !isEmail && clearInterval(this.setTimePhoneId)

      const _timer = setInterval(() => {
        if (time <= 0) {
          if (isEmail) {
            this.sended = false
            this.sendtips = this.language.SHEIN_KEY_PWA_14897
          } else {
            this.telSended = false
            this.telSendtips = this.language.SHEIN_KEY_PWA_14897
          }

          // this.sendCodestatus = false
          isEmail && clearInterval(this.setTimeId)
          !isEmail && clearInterval(this.setTimePhoneId)
          return
        }
        time--
        // this.sendCodestatus = true

        let h = 0
        let timer = time
        if (time > 3600) {
          h = parseInt(time / 3500)
          timer = time % 3600
        }

        if (timer > 60) {
          s = timer % 60
          txt = parseInt(timer / 60) + ' : ' + (s ? (s < 10 ? ('0' + s) : s) : '00')
        } else {
          txt = timer + 's'
        }

        h && (txt = h + ' : ' + txt)

        if (isEmail) {
          this.sended = true
          this.sendtips = txt 
        } else {
          this.telSended = true 
          this.telSendtips = txt 
        }
      }, 1000)

      if (isEmail) {
        this.setTimeId = _timer
      } else {
        this.setTimePhoneId = _timer
      }
    },

    switchCaptcha () {
      this.emailCap = !this.emailCap
      this.sendSms(this.emailCap)

      sa('send', { 'activity_name': 'click_switch_method' })
    },
    // 发送到邮件验证码
    sendSms (isEmail) {
      if (isEmail && this.sended) return
      if (!isEmail && this.telSended) return
      let channel = ''
      let target = ''
      let target_key = ''
      let message_type = ''
      const scene = this.scene
      if (isEmail) {
        channel = 'mmp_email'
        target = this.email
      } else {
        channel = 'phone_msg'
        target_key = this.phoneList[this.phoneIndex].billno
        target = this.phoneList[this.phoneIndex].telephone
      }

      scene == 'login_success' && (message_type = 'login_success_confirm')
      scene == 'change_pwd' && (message_type = 'change_pwd_confirm')
      scene == 'choose_wallet' && (message_type = 'choose_wallet_confirm')
      scene == 'view_privacy' && (message_type = 'view_privacy_confirm')
      scene == 'view_wallet_balance' && (message_type = 'view_wallet_confirm')
      scene == 'view_shipping_addr' && (message_type = 'view_shipping_addr_confirm')
      scene == 'place_order' && (message_type = 'place_order_confirm')

      isEmail && (this.sended = true)
      !isEmail && (this.telSended = true)
      this.resendTipErr = ''
      const params = {
        scene,
        risk_id: this.riskInfo.risk_id,
        channel,
        target_key,
        target,
        message_type
      }
      schttp({ method: 'POST', url: '/api/user/center/sendRiskCode/get', data: params, }).then((res) => {
        if (res && res.code == 0 && res.info) {
          const { countdown_second, sended } = res.info
          if (sended == 1) {
            this.$toast(this.language.SHEIN_KEY_PWA_17775)

            this.countdown(isEmail, 120)
          } else {
            this.countdown(isEmail, countdown_second || 120)
            this.resendTipErr = this.language.SHEIN_KEY_PWA_17712 
          }
        } else {
          if (isEmail) {
            this.sended = false
            this.sendtips = this.language.SHEIN_KEY_PWA_16372
          } else {
            this.telSended = false
            this.telSendtips = this.language.SHEIN_KEY_PWA_16372
          }

          this.resendTipErr = this.loginCode[res.code] || this.loginCode['default']
        }
      }).catch(() => {
        isEmail && (this.sended = false)
        !isEmail && (this.telSended = false)

        if (isEmail) {
          this.sended = false
          this.sendtips = this.language.SHEIN_KEY_PWA_16372
        } else {
          this.telSended = false
          this.telSendtips = this.language.SHEIN_KEY_PWA_16372
        }
        this.resendTipErr = this.loginCode['default']
      })
    },
    choosePhone (index) {
      this.phoneIndex = index
      this.showPhoneList = false
    },

    successHandle () {
      this.bcIndex = 2
      this.$emit('verify-suc-callback', { scene: this.scene })

      // 重置状态
      this.sended = false
      this.telSended = false
      this.sendtips = this.language.SHEIN_KEY_PWA_15323
      this.telSendtips = this.language.SHEIN_KEY_PWA_15323

      try {
        localStorage.removeItem('user_need_edit_pwd')
      } catch (e) { /* empty */ }
    },
    goVerifyCode () {
      if (this.sendCodeVal.trim() == '') {
        this.resendTipErr = this.language.SHEIN_KEY_PWA_14895
        return false
      } else {
        this.resendTipErr = ''
      }

      if (this.goVerifing) return 
      this.goVerifing = true
      const params = {
        risk_id: this.riskInfo.risk_id,
        content: this.sendCodeVal
      }
      schttp({ method: 'POST', url: '/api/user/center/verifyRiskCode/update', data: params }).then((res) => {
        if (res && (res.code == 0 || res.code == 401101)) {
          this.bcIndex = 1
        } else if (res.code == 9006) {
          // 风控单已闭环，可直接跳到成功
          this.successHandle()
        } else {
          this.resendTipErr = this.loginCode[res.code] || this.loginCode['default']
        }
        this.goVerifing = false
      }).catch(() => {
        this.goVerifing = false
      })
      sa('send', { 'activity_name': 'click_next' })
    },
    closeDirect () {
      const url = langPath || '/'
      location.href = url
    },
    successConfirm () {
      // _gb_app_.$router.push(langPath || '/')
      if (this.isDialog) {
        // 下单页的弹窗隐藏
        this.$emit('confirm-callback')
      } else {
        let url = langPath || '/'
        if (getQueryString({ key: 'redirection' })) {
          url = getQueryString({ key: 'redirection' })
        }
        location.href = url
      }
    },
    initSaPageInfo () {
      if (this.isDialog) return
      // 页面信息
      var SaPageInfo = {
        page_id: 210,
        page_name: 'page_blacklist_pwreset',
        page_param: {
          page_from: this.scene
        },
        start_time: new Date().getTime()
      }

      // 进入页面
      sa('set', 'setPageData', SaPageInfo)
      sa('send', 'pageEnter', {
        start_time: new Date().getTime()
      })

    }

  }
}
</script>

<style lang="less">
/* stylelint-disable selector-max-specificity,selector-max-type */
.page__login_confrim {
  background: #fff;
	.S-alert {
    .S-alert__icon {
      color: @sui_color_unusual;
    }
  }
	.risk-process {
		line-height: 1.17333rem;
		display: flex;
    justify-content: center;
    align-items: center;
		border-bottom: 1px solid #E5E5E5;
    padding: 13px 0;
		.process-item {
			display: flex;
      align-items: center;
		}

		.process-line {
			width: 1.0666rem;
			height: 0.04rem;
			background: #E5E5E5;
			display: inline-block;
			margin: 0 0.10666rem;
			vertical-align: middle;
		}
    .process-title {
      line-height: 18px;
      .padding-l(4px);
    }
		.short-line {
			width: 0.2666rem;
		}
		.ball_num, .icon-normal-checked {
			width: 18px;
			height: 18px;
			line-height: 18px;
			background: #ccc;
			color: #fff;
			border-radius: 50%;
			overflow: hidden;
			text-align: center;
			font-size: 12px;
			vertical-align: middle;
		}
		.icon-normal-checked {
			background: @sui_color_main;
		}
		.ball_num.active {
			background: @sui_color_main;
		}
	}
	.info-tab {
		color: #666666;
		font-size: 0.32rem;

		.info-item {
			position: relative;
			border-bottom: 1px solid #E5E5E5;
			padding: 0.21333rem 0;
			margin-top: 0.2666rem;
			.margin-l(0.32rem);

			.item-title {
				height: 0.49333rem;
				line-height: 0.49333rem;
				margin-bottom: 0.02666rem;
			}

			.item-val {
				width: 100%;
				height: 0.49333rem;
				line-height: 0.49333rem;
				font-size: 0.37333rem;
				border: none;
			}
			.select-phone-btn {
				color: #999999;
				font-size: 0.32rem;
				.margin-r(0.32rem);
				.fr();
			}
			.send-code-btn {
				position: absolute;
				bottom: 0.4rem;
				.right(0.32rem);
			}
			.item-title-blur {
				line-height: 1.5333rem;
			}
			.pwtype-icon {
				position: absolute;
				bottom: 0.21333rem;
				.right(0.32rem);
				color: #999;
				font-size: 0.21333rem;
				line-height: 0.48rem;
			}
		}
		.info-code {
			.code-val {
				color: #222;
			}
		}
		.very-code-btn {
			margin: 0.666rem 0.32rem 0.42666rem;
      button {
        width: 100%;
        text-transform: uppercase;
      }
			.la-sm {
				display: inline;
			}
		}
		.normal-info {
			font-size: 0.32rem;
			color: @sui_color_unusual;
			line-height: 0.4rem;
			margin-top: 0.21333rem;
			margin-bottom: 0.1333rem;
			.margin-l(0.32rem);
		}
	}
	.pwd-contact {
		font-size: 0.32rem;
		line-height: 0.4rem;
		color: #999999;
		text-align: center;
		a {
			text-decoration: none;
      font {
        // stylelint-disable-next-line
        color: @sui_color_link !important;
      }
		}
	}
	.switch-cap {
		margin: 0 0.2rem;
		margin-top: 1.0666rem;
		margin-bottom: 1.2rem;
		line-height: 0.45333rem;
		color: #999999;
		text-align: center;
		.cap-line {
			display: inline-block;
			width: 0.24rem;
			height: 1px;
			background: #999;
			vertical-align: middle;
		}
		.cap-tip {
			display: inline-block;
			vertical-align: middle;
		}
		[class*="iconfont"] {
			color: #222;
			font-size: 44px;
			line-height: 44px;
			margin-top: 0.32rem;
			display: inline-block;
		}
	}
	.success-tab {
		text-align: center;
    padding-top: 24px;
		.icon-success {
			font-size: 2rem;
		}
    .suiiconfont {
      line-height: 1;
      & when (@IS_RW) {
        color: @sui_color_main;
      }
    }
		h3 {
			color: #333;
			font-size: 0.48rem;
			line-height: 0.58666rem;
			margin: 12px 0;
		}
		p {
			font-size: 0.32rem;
			line-height: 0.4rem;
			color: #666;
		}
		button {
			margin-top: 0.16666rem;
		}
	}
	.phone-list-dialog {
    /* stylelint-disable-next-line selector-class-pattern */
		.mche-ship-drawer-aside {
			height: auto;
		}
		.list-box {
			background: #F6F6F6;
			ul {
				background: #fff;
				li {
					font-size: 0.37333rem;
					height: 1.09333rem;
					line-height: 1.09333rem;
					margin: 0 0.32rem;
				}
				.active {
					font-weight: bold;
					color: #222;

				}
			}
			button {
				height: 1.28rem;
				line-height: 1.28rem;
				font-weight: normal;
				background: #fff;
				color: #222;
				font-size: 0.37333rem;
				margin-top: 0.266rem;
			}
		}
	}
	.sign-out-btn {
		margin: 16px 0 24px 0;
		a {
			color: #222;
			font-size: 14px;
			font-weight: bold;
		}
	}

  &.risk-pwd-dialog {
    .risk-process {
      line-height: 1.0666rem;
    }
  }
  &.rwm-risk-pwd {
    .ball_num.active {
      background: #ff696e;
    }
  }
}

</style>
