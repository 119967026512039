function wait(time = 0) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}
class Scene {
  constructor({ name = '', holder = null, trigger = null, timeout = 10000, cb = null }) {
    this.name = name
    this.holder = holder
    this.trigger = trigger
    this.timeout = timeout
    this.cb = cb
  }
}
class AsyncStreamCenter {
  // #holders = new Map();
  // scenes = {}
  scenes = new Map()

  init({ scene, timeout = 2000, cb = null }) {
    console.log('[AsyncStreamCenter] Init scene: ', scene)
    const { holder, trigger } = this.useHolder()

    this.setScene(new Scene({
      name: scene,
      holder,
      trigger,
      timeout,
      cb
    }))
  }

  setScene(sceneItem) {
    const sceneName = sceneItem.name
    this.scenes.set(sceneName, sceneItem)
  }

  getScene(sceneName) {
    return this.scenes.get(sceneName)
  }

  useHolder() {
    let holder, trigger

    holder = new Promise(resolve => {
      trigger = resolve
    })

    return {
      holder,
      trigger
    }
  }

  register(asyncFn, { scene = '' } = {} ) {
    const currentScene = this.getScene(scene)
    if (!currentScene) return

    const { holder, timeout } = currentScene

    return new Promise(resolve => {
      const waiting = wait(timeout)
      Promise.race([holder, waiting]).then(() => {
        resolve(asyncFn())
      })
    })
  }

  trigger({ scene }) {
    // console.log('[AsyncStreamCenter] Before trigger scene: ', scene)
    const currentScene = this.getScene(scene)
    if (!currentScene || !currentScene.trigger) return
    // console.log('[AsyncStreamCenter] Trigger scene: ', scene)
    currentScene.trigger()
  }
}

const asyncStreamCenter = new AsyncStreamCenter()

export default asyncStreamCenter
