<template>
  <div
    v-cloak
    class="super-extra-wrap fsp-element"
    @click="goToRightDetail"
  >
    <div class="super-extra">
      <div :class="['super-extra__layer', { 'super-extra__layerAr': GB_cssRight }]">
        <div class="super-extra__header">
          <div class="super-extra__header-left">
            <img
              :class="['logo', superExtraStatus === 'IS_SUPER_EXTRA_EXPIRED' ? '' : 'add-pr']"
              :src="`${PUBLIC_CDN}/pwa_dist/images/user/extra/shein-saver-ea9b36164c.png`"
            />
            <div
              v-if="superExtraStatus === 'IS_SUPER_EXTRA_EXPIRED_RECENTLY' && !!cardInfo?.statusTip"
              class="ending-soon"
            >
              <div class="ending-soon__text">
                {{ cardInfo.statusTip }}
              </div>
            </div>
            <div
              v-else-if="!!cardInfo?.statusTip"
              class="status"
            >
              {{ cardInfo.statusTip }}
            </div>
          </div>

          <div class="super-extra__header-right">
            <div
              v-if="!!cardInfo.bobbleTip"
              class="special-offer"
            >
              <div
                class="special-offer__text"
                v-html="cardInfo.bobbleTip"
              ></div>
              <img
                class="special-offer__arrow"
                :src="`${PUBLIC_CDN}/pwa_dist/images/user/prime/renew_discount_bg_tips-e70005d314.png`"
              />
            </div>
            <div class="operation-box">
              <div
                v-if="showButtonMoreRightIcon"
                class="operation-text"
              >
                <span>{{ cardInfo.buttonTip }}</span>
                <i class="suiiconfont sui_icon_more_right_16px_2"></i>
              </div>
              <div
                v-else
                class="operation-btn"
              >
                {{ cardInfo.buttonTip }}
              </div>
            </div>
          </div>
        </div>
        <div :class="['super-extra__content']">
          <div class="super-extra__content-left">
            <span
              class="coupon-desc"
              v-html="cardInfo.richText"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { langPath, PUBLIC_CDN, GB_cssRight, SiteUID } = gbCommonInfo

export default {
  name: 'SuperExtraDetail',
  props: {
    cardInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ['USER_CENTER_CONTEXT'],
  data() {
    return {
      langPath,
      PUBLIC_CDN,
      GB_cssRight,
      SiteUID,
    }
  },
  computed: {
    // 是临期会员
    isExpiredRecently() {
      return this.superExtraStatus === 'IS_SUPER_EXTRA_EXPIRED_RECENTLY'
    },
    // 超省卡会员状态
    superExtraStatus() {
      const { status, isPaid } = this.cardInfo || {}
      if (isPaid) return 'IS_SUPER_EXTRA'
      // 会员状态:1-临期，2-过期
      if (status === '1') return 'IS_SUPER_EXTRA_EXPIRED_RECENTLY'
      if (status === '2') return 'IS_SUPER_EXTRA_EXPIRED'
      return 'NOT_SUPER_EXTRA'
    },
    showButtonMoreRightIcon() {
      // 1为普通按钮，2为带箭头样式
      return this.cardInfo?.buttonStyle === 2
    }
  },

  mounted() {
    this.reportOnSuperSavePanelShow()
    this.handleDaEvent()
  },

  methods: {
    reportOnSuperSavePanelShow() {
      const reportParams = this.getReportParams()
      daEventCenter.triggerNotice({
        daId: '1-25-12-32',
        extraData: reportParams,
      })
    },
    getReportParams() {
      const { buriedObject } = this.cardInfo || {}
      const item = (buriedObject || []).find(item => item.buriedKey === 'saver')
      return item?.defaultParams || {}
    },
    // 去超省卡权益详情页
    goToRightDetail() {
      const reportParams = this.getReportParams()
      daEventCenter.triggerNotice({
        daId: '1-25-12-33',
        extraData: reportParams,
      })

      const { pushLoginRoute } = this.USER_CENTER_CONTEXT || {}

      pushLoginRoute?.({
        target: '/user/sheinsaver?productsource=me',
        bi: 'saver',
      })
      // const isLogin = isLoginFn()
      // if (isLogin) {
      //   this.$router.push(`${langPath}/user/sheinsaver?productsource=me`)
      // } else {
      //   SHEIN_LOGIN.show({
      //     show: true,
      //     bi: 'saver',
      //     cb: () => {
      //       this.$router.push(`${langPath}/user/sheinsaver?productsource=me`)
      //     }
      //   })
      // }
    },

    handleDaParams() {
      let superExtraStatus = ''

      if (this.superExtraStatus === 'IS_SUPER_EXTRA') {
        superExtraStatus = 1
      } else if (this.superExtraStatus === 'IS_SUPER_EXTRA_EXPIRED') {
        superExtraStatus = 2
      } else {
        superExtraStatus = 0
      }

      return {
        superExtraStatus,
      }
    },

    // 处理埋点
    handleDaEvent() {
      const { buriedObject } = this.cardInfo || {}
      const item = (buriedObject || []).find(item => item.buriedKey === 'saver_reach')
      // 非超省卡用户满足可购资格时上报
      if (item) {
        daEventCenter.triggerNotice({
          daId: '1-25-12-31',
          extraData: item.defaultParams,
        })
      }
    },
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-nested-pattern, declaration-no-important, selector-max-specificity, selector-max-type, declaration-property-value-blacklist  */
.super-extra-wrap {
  background: #fff;
  margin: 0.2133rem 0.32rem 0 0.32rem;
}
.super-extra {
  border: 0.0267rem solid rgba(255, 202, 214, 0.8);
  border-radius: 0.16rem;

  background: linear-gradient(270deg, #ffedf1 0%, #fcf2ff 100%);

  &__layer {
    position: relative;
    background-image: url('/pwa_dist/images/user/extra/left-layer-63d85c3d3a.png'),
      url('https://img.ltwebstatic.com/images3_ccc/2024/09/04/e3/17254177090226b64bfd33a16bf4ce1dfab0e85407.png');
    background-repeat: no-repeat;
    background-position: left top, right bottom;
    background-size: contain, contain;
  }

  &__layerAr {
    position: relative;
    background-image: url('/pwa_dist/images/user/extra/ar-left-layer-3a525e7679.png'),
      url('/pwa_dist/images/user/extra/ar-right-layer-85c1100e0a.png');
    background-repeat: no-repeat;
    background-position: left top, right bottom;
    background-size: contain, contain;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-right: 0.1067rem;

    &-left {
      position: relative;
      display: inline-flex;
      height: 0.6667rem;
      padding: 0 0 0 0.2133rem;
      align-items: center;
      flex-shrink: 0;
      border-radius: 0.16rem 0rem 0.32rem 0rem;
      background: linear-gradient(
        89deg,
        rgba(255, 43, 119, 0.08) 25.9%,
        rgba(204, 49, 198, 0.08) 68.09%,
        rgba(252, 86, 255, 0.08) 100%
      );

      .logo {
        width: 2.4rem;
        height: 0.5067rem;
      }

      .add-pr {
        margin-right: 0.2133rem;
      }

      .status {
        display: flex;
        padding: 0.0533rem 0.16rem;
        align-items: center;
        border-radius: 0.56rem;
        line-height: normal;
        border: 0.0267rem solid rgba(128, 135, 193, 0.5);
        background: #ecf1fd;
        color: #5d6492;
        font-weight: 400;
        font-size: 0.2667rem;
        transform: scale(0.8);
      }
      .ending-soon {
        position: absolute;
        border-radius: 0.312rem;
        border: 1px solid rgba(51, 51, 51, 0.3);
        background-color: rgba(19, 18, 71, 0.05);
        right: -0.2rem;
        transform: translateX(100%);
        top: 0.182rem;
        display: block;
        align-items: center;
        padding: 0 0.156rem;
        font-size: 0.26rem;
        font-weight: 400;
      }
      .ending-soon__text {
        background-image: linear-gradient(
          90deg,
          rgba(248, 40, 84, 0.8) 3.8%,
          rgba(248, 11, 203, 0.8) 49.39%,
          rgba(223, 29, 255, 0.8) 96.2%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &-right {
      margin-right: 0.1067rem;
      position: relative;
      margin-top: 0.1067rem;
      color: #240b44;
      line-height: normal;
      font-style: normal;

      .operation-btn {
        display: inline-flex;
        padding: 0.0533rem 0.2133rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.4267rem;
        border: 0.0267rem solid #240b44;
        font-size: 0.32rem;
        font-family: 'SF UI Display';
        font-weight: 600;
      }
      .operation-text {
        display: flex;
        font-weight: 400;
        align-items: center;
      }
      .special-offer {
        position: absolute;
        top: -0.3733rem;
        right: 0;
        z-index: 99;
        border-radius: 0.0533rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, #f93776 0%, #d52bff 100%);
        .special-offer__text {
          white-space: nowrap;
          color: #fff;
          font-family: 'SF UI Display';
          font-size: 9px;
          font-style: normal;
          font-weight: 700;
          padding: 0.0267rem 0.1067rem;
        }
        .special-offer__arrow {
          width: 0.16rem !important;
          height: 0.1067rem !important;
          position: absolute;
          bottom: -0.1067rem;
          left: 75%;
        }
      }
    }
  }

  &__content {
    font-size: 0.32rem;
    font-weight: 400;
    color: #240b44;
    padding: 0.25rem 0.2133rem 0.25rem 0.2667rem;
    display: flex;
    position: relative;
    &-left {
      .highlight {
        color: #f82854;
        font-weight: 600;
      }
      .special-height {
        color: #240b44;
        text-align: center;
        font-family: SF UI Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  & .remove-pd {
    padding-top: 0.13rem;
    padding-bottom: 0.13rem;
  }
}
</style>
