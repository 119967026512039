<script lang="jsx">
import { htmlDecode } from '@shein/common-function'
const { language: gbLanguage, IS_RW } = gbCommonInfo
import OrderPannel from './OrderPannel/index.vue'

const myOrders = [{
  id: 'unpaid',
  statusType: 1,
  title: gbLanguage.SHEIN_KEY_PWA_15309,
  icon: 'sui_icon_me_unpaid_24px',
  dataEventId: '1-16-1-17',
  scence: 'orders',
  usLabel: 'Unpaid', // 埋点需要us语言
}, {
  id: 'processing',
  statusType: 2,
  title: gbLanguage.SHEIN_KEY_PWA_15215,
  icon: 'sui_icon_me_processing_24px',
  dataEventId: '1-16-1-17',
  scence: 'orders',
  usLabel: 'Processing',
}, {
  id: 'shipped',
  statusType: 3,
  title: gbLanguage.SHEIN_KEY_PWA_15213,
  icon: 'sui_icon_me_shipped_24px',
  dataEventId: '1-16-1-17',
  scence: 'orders',
  usLabel: 'Shipped',
}, {
  id: 'review',
  statusType: 5,
  title: gbLanguage.SHEIN_KEY_PWA_15162,
  icon: 'sui_icon_me_review_24px',
  dataEventId: '1-16-1-11',
  link: '/user/review_center',
  scence: 'reviews',
  usLabel: 'Review',
}, {
  id: 'returns',
  statusType: 4,
  title: gbLanguage.SHEIN_KEY_PWA_15626,
  icon: 'sui_icon_me_returns_24px',
  dataEventId: '1-16-1-17',
  scence: 'orders',
  usLabel: 'Returns',
}]

export default {
  name: 'MyOrderSection',
  functional: true,
  components: {
    OrderPannel,
  },
  props: {
    isOpenReviewEntry: {
      type: Boolean,
      default: false
    },
    orderCount: {
      type: Object,
      default () {
        return {}
      }
    },
    unpaidOrder: {
      type: Array,
      default: () => []
    },
    unReviewOrder: {
      type: Object,
      default () {
        return {}
      }
    },
    unpaidOrderAbt: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  render(h, { props, listeners }) {
    const { isOpenReviewEntry, orderCount, unpaidOrder, unpaidOrderAbt, unReviewOrder } = props

    const hasOrderInfo = unpaidOrder?.length || (unReviewOrder?.unReviewInfoResult?.code == 0 && unReviewOrder?.unReviewInfoResult?.info?.data?.length && unReviewOrder?.unReviewPointsResult?.code == 0)

    const { handleRouterPush } = listeners
    // const handleRouterPush = (item) => listeners.handleRouterPush(item.router, '', item.scence)
    const handleMyOrdersEntry = (item) => {
      if (item.link) {
        handleRouterPush(item.link, '', item.scence)
      } else {
        handleRouterPush('/user/orders/list', item.statusType, item.scence)
      }
    }

    const renderSectionTitle = () => {
      return (
        <div class="module-content-title">
          <h3
            tabindex="0"
            class={[IS_RW ? 'font' : '']}
          >
            { gbLanguage.SHEIN_KEY_PWA_15172 }
          </h3>
          <span
            v-enterkey
            tabindex="0"
            role="button"
            vOn:click={() => handleRouterPush('/user/orders/list', 0, 'orders')}
          >
            { gbLanguage.SHEIN_KEY_PWA_15040 }
            <i class="suiiconfont sui_icon_more_right_12px_2"></i>
          </span>
        </div>
      )
    }

    const renderSectionListItem = item => {
      return (
        <div
          key={item.id}
          v-enterkey
          tabindex="0"
          role="button"
          class={[
            'me-li',
            'waves-btn',
            'waves-btn-auto',
            'waves-effect',
            'waves-classic',
            isOpenReviewEntry ? 'me-li-item' : '',
            `me-li-item-${item.id}`
          ]}
          da-event-click={item.dataEventId || ''}
          data-param={orderCount[item.id]}
          data-text={item.title}
          data-label={item.usLabel}
          vOn:click={() => handleMyOrdersEntry(item)}
        >
          <div class="order-num-container">
            <i
              class={[
                'suiiconfont',
                'me-li-icon',
                item.icon
              ]}
            ></i>
            {
              orderCount[item.id] ? (
                <em
                  class="order-num"
                >{orderCount[item.id] > 99 ? '99+' : orderCount[item.id]}</em>
              ) : null
            }
          </div>
          <p class="me-li__title">
            {htmlDecode({ text: item.title })}
          </p>
        </div>
      )
    }

    const renderSectionList = () => {
      const isShow = item => item.id == 'review' ? isOpenReviewEntry : true
      return (
        <div class="me-list">
          {myOrders.filter(isShow).map(item => renderSectionListItem(item))}
        </div>
      )
    }

    return (
      <li class="module-content my-entry-block more-service-section">
        {renderSectionTitle()}
        {renderSectionList()}
        {hasOrderInfo && <OrderPannel
          ref="unPaidOrder"
          unpaid-order={unpaidOrder}
          unpaid-order-abt={unpaidOrderAbt}
          un-review-order={unReviewOrder}
        />}
      </li>
    )
  }
}
</script>

<style lang="less">
.more-service-section {
  overflow: hidden;
}
</style>
