<script lang="jsx">
/**
 * @file 个人中心接融合之前的容器组件
 */

import { mapState, mapMutations } from 'vuex'
import Analysis from './analysis'
import UserRightsPanel from 'public/src/pages/user/child_pages/dashboard/components/UserRightsPanel/ModuleIndex.vue'
import UserAssetsBottom from 'public/src/pages/user/child_pages/dashboard/components/UserAssetsBottom/ModuleIndex.vue'
import NewsPannel from 'public/src/pages/user/child_pages/dashboard/components/NewsPannelTest.vue'
import NewUserBenefits from 'public/src/pages/user/child_pages/dashboard/components/NewUserBenefits/ModuleIndex.vue'
import schttp from 'public/src/services/schttp'
import { abtservice } from 'public/src/services/abt'
import { getUserAbtData, abtUserAnalysis } from '@shein-aidc/basis-abt-router'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import {
  getQueryString,
  htmlDecode,
  getLocalStorage,
  setLocalStorage,
  firstUpperCase
} from '@shein/common-function'
import dashboardServcie from './service/index.js'
import { fetchFooterRcmdListCccConfig } from 'public/src/pages/user/child_pages/dashboard/service/fetcher'
import { FooterRcmdListResolveStatus } from 'public/src/pages/user/child_pages/dashboard/service/constants'
import asyncStreamCenter from 'public/src/pages/user/service/asyncStreamCenter'
import dayjs from 'dayjs'
import { markPoint } from 'public/src/services/mark/index.js'
import { Alert, Button, NetworkErrorAlert, Loading as SLoading } from '@shein/sui-mobile'
import ClientOnly from 'vue-client-only'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { triggerLowPriorityTask } from 'public/src/pages/user/child_pages/dashboard/service/utils'
;[Alert, Button].forEach(c => Vue.use(c))
daEventCenter.addSubscriber({ modulecode: '2-2' })
daEventCenter.addSubscriber({ modulecode: '1-5-1' })
daEventCenter.addSubscriber({ modulecode: '1-16-1' })
daEventCenter.addSubscriber({ modulecode: '1-25-2' })

/* Components */
import RewardMsgNotice from './components/RewardMsgNoticeNew.vue'
import CommonNotice from '../../../common/commonNotice/index.vue'
import MyOrderSection from './components/MyOrderSection.vue'
import MoreServicesSection from './components/MoreServicesSection.vue'
import MoreServicesSectionNew from './components/MoreServicesSectionNew.vue'
import UserVipTag from './components/UserVipTag.vue'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { getOrderStatusText } from 'public/src/pages/user/child_pages/orders/utils'
import ShoppingGuide from 'public/src/pages/common/oneClickPay/components/shopping_guide/ShoppingGuide.vue'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
// import FooterSection from 'public/src/pages/user/child_pages/dashboard/components/FooterSection.vue'
// 业务监控
import Monitor from 'public/src/pages/common/business-monitor/store'
import { setPageName, showTopTrendCard } from 'public/src/pages/product_app/store/modules/trend_landing/topTrend.utils.js'

const {
  language: gbLanguage,
  IS_RW,
  langPath,
  SiteUID,
  GB_cssRight,
  WEB_CLIENT,
  lang,
  currency,
  TWO_HANDLE_URL_SETTING
} = gbCommonInfo
// 阻止顶部brnach展示
window.JOURNEY_BRANCH = window.JOURNEY_BRANCH || {}
window.JOURNEY_BRANCH.pageConfig = window.JOURNEY_BRANCH.pageConfig || {}

const SaPageInfo = {
  page_id: 115,
  page_name: 'page_me',
  page_param: {},
  start_time: Date.now()
}

const riskPathWhiteList = ['/user/setting', '/user/gift_card', '/user/membership']
let routeFrom = {}

const analysisModule = new Analysis()

const isExposed = []
function isExposeEvt(arr) {
  let sharEarn = arr.find(item => item.id == 'share&earn')
  let reference = arr.find(item => item.id == 'My Reference')
  let freetrial = arr.find(item => item.id == 'freetrial')
  let bonusDay = arr.find(item => item.id == 'Bonus Day')
  let dayTrial = arr.find(item => item.id == '14_Day_trial')
  // let following = arr.find(item => item.id == 'following')

  if (sharEarn && !isExposed.includes('sharEarn') && sharEarn.show) {
    isExposed.push('sharEarn')
    daEventCenter.triggerNotice({ daId: '1-16-1-13' })
  }
  if (reference && !isExposed.includes('reference') && reference.show) {
    isExposed.push('reference')
    daEventCenter.triggerNotice({ daId: '1-16-1-14' })
  }
  if (freetrial && !isExposed.includes('freetrial') && freetrial.show) {
    isExposed.push('freetrial')
    daEventCenter.triggerNotice({ daId: '1-16-1-15' })
  }
  if (bonusDay && !isExposed.includes('bonusDay') && bonusDay.show) {
    isExposed.push('bonusDay')
    daEventCenter.triggerNotice({ daId: '1-16-1-18' })
  }
  if (dayTrial && !isExposed.includes('dayTrial') && dayTrial.show) {
    isExposed.push('dayTrial')
    daEventCenter.triggerNotice({ daId: '1-16-1-34' })
  }
}

function shouldUpdateWishlist(needUpdateData) {
  return (
    routeFrom.name != 'page_goods_detail' ||
    (routeFrom.name == 'page_goods_detail' && needUpdateData)
  )
}

asyncStreamCenter.init({ scene: 'dashboard' })

export default {
  name: 'UserIndex',
  components: {
    SLoading,
    UserVipTag,
    MoreServicesSection,
    MoreServicesSectionNew,
    RewardMsgNotice,
    CommonNotice,
    UserRightsPanel,
    UserAssetsBottom,
    NewsPannel,
    NewUserBenefits,
    FooterRcmdList: () => import(/* @vite-ignore */'./components/FooterRcmdList.vue'),
    FooterSection: () =>
      asyncStreamCenter.register(
        () =>
          import(
            /* webpackChunkName: "dashboard-async-section" */ /* @vite-ignore */ './components/FooterSection.vue'
          ),
        { scene: 'dashboard' }
      ),
    riskconfirm: () =>
      asyncStreamCenter.register(
        () =>
          import(
            /* webpackChunkName: "dashboard-async-section" */ /* @vite-ignore */ './risk_confirm.vue'
          ),
        { scene: 'dashboard' }
      ),
    AppCcc: () =>
      asyncStreamCenter.register(
        () =>
          import(
            /* webpackChunkName: "dashboard-async-section" */ /* @vite-ignore */ 'public/src/pages/components/ccc/Index.vue'
          ),
        { scene: 'dashboard' }
      ),
    RiskDialog: () =>
      asyncStreamCenter.register(
        () =>
          import(
            /* webpackChunkName: "dashboard-async-section" */ /* @vite-ignore */ 'public/src/pages/user/child_pages/dashboard/components/RiskDialog.vue'
          ),
        { scene: 'dashboard' }
      )
  },
  data: function () {
    return dashboardServcie.getInitData()
  },

  async beforeRouteEnter(to, from, next) {
    routeFrom = from
    next(vm => {
      vm.beforeRouteEnterInit(to, from)
    })
  },
  beforeRouteLeave(to, from, next) {
    // 延时避免组件重复渲染影响其他页面的跳转时间
    window.setTimeout(() => {
      this.assignState({
        isRouterLeaveFlag: true
      })
    }, 200)
    window.JOURNEY_BRANCH.pageConfig.hideScroll = false
    window.SaPageInfo = SaPageInfo
    if (to.path === '/user/orders/list') {
      to.meta.orderListPrefetch = this.prefetchOrdersListPageData()
    }
    next()
  },
  computed: {
    ...mapState('root', [
      'userInfo',
      'accountInformation',
      'activityInfo',
      'userCenterLanguage',
      'couponOrPointsData',
      'statusType'
    ]),
    ...mapState('dashboard', [
      'wishlistCount',
      'goodsTabActive',
      'scrollPos',
      'isRouterLeaveFlag',
    ]),
    ...mapState('wishlist', ['needUpdateData']),
    appCccConfig() {
      const { cateLinks = {}, content = [], pageType = '' } = this.advertise
      return {
        cateLinks,
        content,
        context: {
          WEB_CLIENT,
          SiteUID,
          lang
        },
        sceneData: {
          pageFrom: 'pageMe',
          pageType,
          sceneName: pageType
        }
      }
    },
    username() {
      const userEmail = UserInfoManager.getSync({ key: 'pwa_user_email', actionType: 'USER/DASHBOARD' })
      return (
        this.userInfo.nickname ||
        userEmail ||
        this.userInfo.login_phone ||
        ''
      )
    },
    giftcardShow() {
      return WEB_CLIENT != 'romwe'
    },
    account_info: function () {
      return this.accountInformation.account_info || {}
    },
    assets() {
      const arr = [
        {
          id: 'coupon',
          title: gbLanguage.SHEIN_KEY_PWA_16129,
          icon: 'sui_icon_me_coupon_24px',
          router: '/user/coupon',
          show: true,
          showVal: this.isLogin,
          val: this.couponNum === undefined || this.isRisk ? '***' : this.couponNum,
          scence: 'coupons'
        },
        {
          id: 'points',
          title: gbLanguage.SHEIN_KEY_PWA_16455,
          icon: 'sui_icon_me_points_24px',
          router: '/user/points',
          show: true,
          val: this.totalPoints === undefined || this.isRisk ? '***' : this.totalPoints,
          showVal: this.isLogin,
          scence: 'points'
        },
        {
          id: 'wallet',
          title: gbLanguage.SHEIN_KEY_PWA_15109,
          icon: 'sui_icon_me_wallet_24px',
          router: '/user/wallet',
          show: true,
          showVal: this.isLogin && this.wallets() !== '',
          val: this.wallets(),
          scence: 'wallet'
        },
        {
          id: 'gift_card',
          title: gbLanguage.SHEIN_KEY_PWA_15846,
          icon: 'sui_icon_me_gift_24px',
          router: '/user/gift_card',
          show: true,
          scence: 'giftcard'
        }
      ]

      return arr
    },
    isShowSurveyPoints() {
      const userId = gbCommonInfo.user?.member_id || ''
      const hadClick = window.localStorage.getItem(`uid_${userId}`)
      return hadClick ? new Date().getTime() - Number(hadClick) > 24 * 3600 * 1000 : true
    },
    isShowFollowUnReadDot() {
      return (
        +this.followedStoreHaveNew === 1 &&
        !this.isFollowClickWithinThreeDays &&
        !this.isFollowClick
      )
    },
    showdownloadGuidPoints() {
      return this.abtShowdownloadGuid
    },
    newsData({ news }) {
      return {
        list: news?.cccConfig?.list || [],
        moreLang: news?.moreLang || '',
        isNewStrategy: news?.cccConfig?.isNewStrategy,
        strategy: news?.cccConfig?.strategy
      }
    },
  },
  async created() {
    window.vBus && window.vBus.$emit('hideBranch')
    window.JOURNEY_BRANCH.pageConfig.hideScroll = true
  },
  async mounted() {
    document.querySelector('.c-skeleton')?.classList.add('mshe-hide')
    if (routeFrom.name != 'page_goods_detail') {
      // 商品详情页返回不刷新收藏数据
      this.assignState({ scrollPos: 0 })
      this.assignWSState({ needUpdateData: false })
    }

    window.appEventCenter?.$on('clickFooterUserIndexEntry', this.onClickFooterUserIndexEntry)

    setTimeout(() => {
      if (this.scrollPos && this.wishlistCount) {
        $(window).scrollTop(this.scrollPos)
        this.assignState({ scrollPos: 0 })
      }
      requestIdleCallback(() => {
        window._gb_app_.$on('init-userIndex-userData', data => {
          this.routerUrl = this.$route.path
          this.loginSucRedirection(data)
          // TODO refresh abt
        })
        this.clear301cache() // 清除停服期间部分被301重定向的缓存链接
      })
      this.prefetchOrdersListResource()
    }, 100)
  },
  methods: {
    ...mapMutations('root', [
      'changeAccountInfo',
      'setActivityInfo',
      'assignRootState',
      'changeCouponOrPointsData'
    ]),
    ...mapMutations('dashboard', ['assignState', 'updateDialogName']),
    ...mapMutations('wishlist', ['assignWSState']),
    prefetchOrdersListResource () {
      requestIdleCallback(() => {
        prefetchResource.listen({
          el: this.$el,
          prefetchList: [
            {
              chunkName: 'orders_list',
              relType: 'prefetch'
            },
          ],
          delay: 2000, // 默认两秒
        })
      })
    },
    async getUnionOrderListPageData () {
      const res = await schttp({
        method: 'POST',
        url: '/api/orders/base/unionOrderListPageData/get',
        data: {
          status_type: this.statusType
        },
        schttp: { needLogin: true }
      })
      return res
    },
    prefetchOrdersListPageData () {
      if (!this.isLogin) {
        return
      }
      const prefetch = Promise.all([this.getUnionOrderListPageData(), getOrderStatusText()])
      return prefetch
    },
    // 三天内是否点击过 follow
    checkFollowHasClicked() {
      const userId = gbCommonInfo.user?.member_id || ''
      const startTime = window.localStorage.getItem(`CLICK_FOLLOW_BUTTON_TIME_${userId}`)
      if (startTime) {
        const endTime = dayjs().valueOf()
        this.isFollowClickWithinThreeDays = this.isWithinThreeDays(startTime, endTime)
      }
    },
    // follow红点展示时间是否在三天范围内
    isWithinThreeDays(startTime, endTime) {
      const oneDayMilliseconds = 24 * 60 * 60 * 1000
      const differenceDays = Math.abs((endTime - startTime) / oneDayMilliseconds)
      return differenceDays <= 3
    },
    // 点击follow菜单
    handleFollowEvt() {
      if (!this.isFollowClick) {
        this.isFollowClick = true
        let startTime = dayjs().valueOf()
        const userId = gbCommonInfo.user?.member_id || ''
        window.localStorage.setItem(`CLICK_FOLLOW_BUTTON_TIME_${userId}`, startTime)
      }
    },
    async onClickFooterUserIndexEntry() {
      if (IS_RW) return
      if (!this.$route.path.match(/\/user\/index$/)) return
      if (!this.enableBottomTabRefresh) return
      if (this.refreshingPage) return
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      this.refreshingPage = true
      await Promise.all([
        this.fetchMainData().then(mainData => {
          this.handleMainData(mainData)
        }),
        this.initFooterRcmdListCccConfig({
          resetResolveStatus: false,
        })
      ])
      this.refreshingPage = false
    },
    // 关注的店铺里 商品是否有上新
    async getFollowStoreRefreshStatus() {
      const userId = gbCommonInfo.user?.member_id || ''

      if (!this.isLogin) return

      try {
        // 关注店铺是否有上新标识本地缓存一小时
        const cacheKey = `STORE_HAS_NEW_${userId}`
        const cacheData = localStorage.getItem(cacheKey)
        if (cacheData) {
          const { value, expires } = JSON.parse(cacheData)
          if (dayjs().valueOf() <= expires) {
            this.followedStoreHaveNew = value
            return
          }
        }
        const { code, data, msg } = await schttp({
          url: '/api/user/user_index/refresh/get',
          method: 'GET',
        })
        if (code === 0) {
          if (data.hasRefresh || data.has_promotion) {
            this.followedStoreHaveNew = 1
          } else {
            this.followedStoreHaveNew = 0
          }
          localStorage.setItem(
            cacheKey,
            JSON.stringify({
              value: this.followedStoreHaveNew,
              expires: dayjs().add(60, 'minute').valueOf()
            })
          )
        } else {
          this.followedStoreHaveNew = 0
          console.error(msg)
        }
      } catch (err) {
        this.followedStoreHaveNew = 0
        console.error(err)
      }
    },
    async fetchMainData() {
      this.isMainDataReady = false
      const mainData = await dashboardServcie.useMainData({
        cache: false,
        isRisk: this.isRisk,
        isLogin: this.isLogin,
        routeFrom,
        isShouldUpdateWishlist: shouldUpdateWishlist()
      })
      return mainData
    },
    getUserCenterNoticeTips({ notice = {} }) {
      const hideCccNotice = getLocalStorage('hideCccNotice')
      if (hideCccNotice) return
      this.showUserCenterNoticeText = notice.content || ''
      this.showUserCenterNotice = !!notice.content
      if (this.showUserCenterNotice) {
        daEventCenter.triggerNotice({
          daId: '1-25-2-4'
        })
        // 新增埋点
        daEventCenter.triggerNotice({
          daId: '1-25-2-13'
        })
      }
    },
    async handleMainData(mainData) {
      const {
        moreServices,
        isShowOrderResellEntrance,
        isShowCouponOrPointsNotice,
        isShowFollowing,
        vipData,
        userExpireInfo,
        showUserCenterNoticeText,
        accountInfo,
        accountLanguage,
        moreServiceConfig,
      } = mainData

     
      // SHEIN VIP
      if (vipData) {
        this.isOldEsVip = vipData.isOldEsVip
        this.vipSiteFrom = vipData.site_from
        this.vipLevel = vipData.level || null
        SaPageInfo.page_param.vip_level = this.vipLevel
        SaPageInfo.page_param.is_logined =  this.isLogin ? 1 : 0
        sa('set', 'setPageData', SaPageInfo)
      }

      // coupon or ponints tip
      const { isReqData } = userExpireInfo
      if (isReqData) {
        const { pointsOrCouponInfo, isClickCoupon, isClickPoints } = userExpireInfo
        this.changeCouponOrPointsData({ ...pointsOrCouponInfo, isClickCoupon, isClickPoints })
      } else {
        const { couponOrPointsData, isClickCoupon, isClickPoints } = userExpireInfo
        this.changeCouponOrPointsData({ ...couponOrPointsData, isClickCoupon, isClickPoints })
      }
      this.isMainDataReady = true

      if (!isShowCouponOrPointsNotice) {
        const member_id = gbCommonInfo?.user?.member_id || ''
        if (member_id)
          setLocalStorage({
            key: `${member_id}_${SiteUID}_${lang}_couponOrPointsData`,
            value: JSON.stringify({}),
            expire: 60 * 5 * 1000
          })
      }

      // CCC Notice
      this.getUserCenterNoticeTips(mainData)
      this.showUserCenterNotice = !!showUserCenterNoticeText
      this.showUserCenterNoticeText = showUserCenterNoticeText

      // Account Info
      this.changeAccountInfo({ language_account: accountLanguage, account_info: accountInfo })
 
      triggerLowPriorityTask(() => {
        const hasOrderInfo = this.unpaidOrder?.length || (this.unReviewOrder?.unReviewInfoResult?.code == 0 && this.unReviewOrder?.unReviewInfoResult?.info?.data?.length && this.unReviewOrder?.unReviewPointsResult?.code == 0)
        if(!hasOrderInfo) {
          this.updateDialogName('next-renew')
        }
        isExposeEvt(moreServices)
        if (isShowOrderResellEntrance) {
          daEventCenter.triggerNotice({
            daId: '1-16-1-24'
          })
        }
        if (this.isLogin) {
          const orderstatus_num = `${
            this.userCenterLanguage.SHEIN_KEY_PWA_15309
          }_${this.orderCount.unpaid},${
            this.userCenterLanguage.SHEIN_KEY_PWA_15215
          }_${this.orderCount.processing},${
            this.userCenterLanguage.SHEIN_KEY_PWA_15213
          }_${this.orderCount.shipped},${
            this.userCenterLanguage.SHEIN_KEY_PWA_15626
          }_${this.orderCount.returns}`
          daEventCenter.triggerNotice({
            daId: '1-25-2-15',
            extraData: {
              orderstatus_num: orderstatus_num
            }
          })
        }
        if (this.showUserCenterNotice) {
          daEventCenter.triggerNotice({
            daId: '1-25-2-4'
          })
          // 新增埋点
          daEventCenter.triggerNotice({
            daId: '1-25-2-13'
          })
        }
        if (Object.keys(this.accountInformation?.language_account).length) {
          this.exposeFillingPersonalProfile()
        }
        // 展示了follow入口，才会有上新相关的逻辑
        if (isShowFollowing && !IS_RW) {
          this.checkFollowHasClicked()
          this.getFollowStoreRefreshStatus().then(() => {
            daEventCenter.triggerNotice({
              daId: '1-16-1-30',
              extraData: {
                is_red: this.isShowFollowUnReadDot ? 1 : 0
              }
            })
          })
        }
        this.exposeMyAssets()
      })
      triggerLowPriorityTask(() => {
        this.getAdvertise()
      })

      // 业务监控埋点
      const hasData = !!moreServices?.length || !!moreServiceConfig?.length
      Monitor.metricPageSuccess({ 
        page: 'user-index',
        status: hasData ? '1' : '0',
      })
    },
    async getAdvertise() {
      if (IS_RW) {
        return
      }
      const { UserProfileAd } = await getUserAbtData()
      const abtBranch = UserProfileAd?.param?.UserProfileAd || ''
      if (abtBranch === 'OFF') {
        return
      }
      const res = await schttp({
        method: 'POST',
        url: '/api/ccc/sceneConfig/get',
        data: {
          pageType: 'advertPersonalCenter',
          abtBranch
        }
      })
      this.advertise = res?.data || {}
    },
    clear301cache() {
      try {
        if (getQueryString({ key: 'countdown' }) > 0) {
          let _urlArr = [
            location.origin + langPath,
            location.origin + langPath + '/category',
            location.origin + langPath + '/user/message',
            location.origin + langPath + '/cart',
            location.origin + langPath + '/user/orders/list'
          ]
          if (document.referrer) _urlArr.push(document.referrer)
          _urlArr.forEach(url => {
            fetch(url, {
              method: 'GET',
              headers: { pragma: 'no-cache', 'cache-control': 'no-cache' }
            }).then(() => console.log(url + ' REQ SUCCESS'))
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async beforeRouteEnterInit(to, from) {
      this.routeInfo = { to, from }
      this.assignState({
        isRouterLeaveFlag: false
      })
      // user = gbCommonInfo.user || {}
      this.isLogin = isLogin()
      SaPageInfo.start_time = Date.now()
      SaPageInfo.page_param = {
        vip_level: null,
        is_logined: this.isLogin ? 1 : 0,
      }
      window.SaPageInfo = SaPageInfo
      window.PageGroup = this.goodsTabActive == 'save' ? '收藏商品列表-个人中心' : '最近浏览'
      sa('set', 'setPageData', SaPageInfo)

      const abtestVal = abtUserAnalysis({ posKeys: 'Reviewentry' }).sa
      SaPageInfo.page_param.abtest = abtestVal

      if (this.isLogin) this.clearAbtInfo()
      this.initFooterRcmdListCccConfig()
      if (this.$route.meta.prefetch) {
        markPoint({ eventName: 'waitPageData', measureFrom: 'routeChangeEnd' })
        const data = await this.$route.meta.prefetch
        markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
        this.handleMainData(data)
        markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
        this.$nextTick(() => {
          markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })
        })
      } else {
        markPoint({ eventName: 'apiFetchMainData', tag: 'begin' })
        const data = await this.fetchMainData()
        markPoint({ eventName: 'fetchMainData', tag: 'end' })
        this.handleMainData(data)
        markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
        this.$nextTick(() => {
          markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })
        })
      }
      this.trendFlowPageNum = 1
      this.trendFlowData = []
      this.firstTrendFlowPending = true
      this.fetchTrendFlowData()

      // PV埋点
      window.SaPageInfo = SaPageInfo
      appEventCenter.$emit('pageOnload')

      triggerLowPriorityTask(() => {
        if (shouldUpdateWishlist(this.needUpdateData)) {
          this.assignState({
            wishlist: {
              data: [],
              page: 1,
              noMoreData: false
            },
            scrollPos: 0
          })
          this.assignWSState({ needUpdateData: false })
        }
      })
      triggerLowPriorityTask(() => {
        asyncStreamCenter.trigger({ scene: 'dashboard' })
        asyncStreamCenter.trigger({ scene: 'user-container' })
      })
    },
    // 请求趋势内容体数据
    async fetchTrendFlowData({ unuseCartSlotInfoNums } = {}) {
      try {
        setPageName('user_index')
        const { pagemefeedtrendword, pagemefeedtrendcard } = this.trendFlowAbt
        // 还剩2个内容体时，请求剩余数据
        if (pagemefeedtrendword === 0 || unuseCartSlotInfoNums > 2 || unuseCartSlotInfoNums === 0) return
        const startIndex = this.trendFlowData.length ? this.trendFlowData[this.trendFlowData.length - 1].index : 0
        const getTrendCardFlow = showTopTrendCard(true) && pagemefeedtrendcard > 0
        const res = await schttp({
          url: '/api/user/trend/flow/get',
          method: 'GET',
          params: { 
            getTrendCardFlow,
            fdPageNum: this.trendFlowPageNum
          }
        })
        const { trendWordFlow = {}, trendCardFlow = {}, sheinClubInfo } = res || {}
        this.sheinClubInfo = sheinClubInfo
        const validTrendCardData = trendCardFlow.data?.products?.length
        const trendFlowData = trendWordFlow.data?.map((n, i) => {
          const slotIndex = (startIndex + i) * 20 + (pagemefeedtrendword - 1)
          // 趋势频道内容体与趋势词内容体共用同一套插坑逻辑，在趋势词内容体插坑中仅展示一次
          const isTrendCardSlot = validTrendCardData && getTrendCardFlow && (pagemefeedtrendcard - 1) === i
          const data = isTrendCardSlot ? trendCardFlow.data : n
          const type = isTrendCardSlot ? 'TopTrendCard' : 'TrendWordCard'
          return ({ slotIndex, data, type, index: startIndex + i + 1 })
        })
        const newTrendFlowData = this.trendFlowData.concat(trendFlowData)
        // console.log('newTrendFlowData: ', unuseCartSlotInfoNums, newTrendFlowData)
        this.trendFlowData = newTrendFlowData
        this.trendFlowPageNum ++
      } finally {
        this.firstTrendFlowPending = false
      }
    },
    async initFooterRcmdListCccConfig({
      resetResolveStatus = true,
    } = {}) {
      if (resetResolveStatus) {
        this.footerRcmdListResolveStatus = FooterRcmdListResolveStatus.PENDING
      }
      const cccConfig = await fetchFooterRcmdListCccConfig()
      if (this.footerRcmdCccConfig) {
        this.footerRcmdCccConfig = null
      }
      return new Promise((resolve) => {
        setTimeout(() => {
          this.footerRcmdCccConfig = cccConfig
          this.footerRcmdListResolveStatus = !!cccConfig
            ? FooterRcmdListResolveStatus.HAS_RCMD_LIST
            : FooterRcmdListResolveStatus.NO_RCMD_LIST
          resolve()
        }, 20)
      })
    },
    routerPush(target, orderStatusType, scence = '', jumpIgnoreLogin = false, noReportEvent = false) {
      if (this.isRisk && !riskPathWhiteList.some(val => target.indexOf(val) > -1)) {
        this.$refs.riskDialog?.riskDrawerUpdate()
        return
      }

      this.routerUrl = langPath + target
      if (!noReportEvent) {
        this.sendFn(target)
      }
      if (!['', null, undefined].includes(orderStatusType)) {
        this.assignRootState({ statusType: orderStatusType })
      }
      if (this.isLogin && this.surveyNum > 0 && this.isShowSurveyPoints) {
        const userId = gbCommonInfo.user?.member_id || ''
        window.localStorage.setItem(`uid_${userId}`, new Date().getTime())
      }

      if (target === '/affiliate') {
        setTimeout(() => {
          window.location.href = langPath + target
        }, 1e3)
        return
      }

      if (
        this.isLogin ||
        jumpIgnoreLogin || [
          '/user/setting',
          '/user/support',
          '/sheinx',
          '/friendship_coupon',
          '/product/article/1212',
          '/user/gift_card',
        ].includes(target)
      ) {
        if (target.includes('/exchange')) {
          // ME页面的二手入口的地址，改为配置在中间层的阿波罗，需要支持到站点维度配置，本次会新增法国
          this.getApolloConfig()
        } else {
          // 当http开头时，代表是链接跳转
          if(target?.startsWith('http')) {
            return location.href = target
          }
          let searchParamsStr = '' 
          if (['/store/following'].includes(target)) {
            const searchParams = new URLSearchParams({
              page_from: 'page_me',
              entrance: '-',
            })
            const hasQuery = target.includes('?')
            searchParamsStr = (hasQuery ? '&' : '?') + searchParams.toString()
          }
          this.$router.push(langPath + target + searchParamsStr)
        }
      } else {
        SHEIN_LOGIN.show({
          show: true,
          from: 'other',
          ga: 'account',
          bi: scence || 'other',
          cb: this.loginSucRedirection
        })
      }

      // follow 事件处理
      if (['/store/following'].includes(target) && this.isShowFollowUnReadDot) {
        this.handleFollowEvt()
      }
    },
    // 获取阿波罗配置信息
    async getApolloConfig() {
      try {
        const res = await schttp({
          method: 'POST',
          url: '/api/config/apolloConfig/query',
          data: { apolloKeys: 'TWO_HANDLE_URL_SETTING' }
        })
        if (res.code == 0) {
          this.apolloSiteUrl = res.info?.TWO_HANDLE_URL_SETTING
          let isSite = false
          if (this.apolloSiteUrl) {
            isSite = Object.keys(this.apolloSiteUrl).includes(SiteUID)
          } else {
            isSite = false
          }

          if (isSite && !IS_RW) {
            // ME页面的二手入口的地址，改为配置在中间层的阿波罗，需要支持到站点维度配置，本次会新增法国
            window.location.href = this.apolloSiteUrl[SiteUID] || TWO_HANDLE_URL_SETTING
          } else {
            window.location.href = this.routerUrl
          }
        }
      } catch (e) {
        window.location.href = this.routerUrl
      }
    },
    async loginSucRedirection(opts) {
      this.updateDialogName('default')
      const _this = this
      let _isDanger = false
      
      if (opts && opts.info && opts.info.member) {
        gbCommonInfo.user = { ...opts.info.member }
        gbCommonInfo.user.email = '1'
        const user = opts.info.member
        const userEmail = await UserInfoManager.get({ key: 'pwa_user_email', actionType: 'USER/DASHBOARD' })
        gbCommonInfo.user_name =
          user && user.nickname ? user.nickname : userEmail || ''
        this.isLogin = true

        if (this.routerUrl.indexOf('/user/index') > -1) {
          // 返回me页面才初始化
          // this.loginInit()
          this.fetchMainData().then(mainData => {
            this.handleMainData(mainData)
            appEventCenter.$emit('initUserInfo')
            setTimeout(() => {
              this.initFooterRcmdListCccConfig()
            }, 100)
          })
        } else {
          appEventCenter.$emit('initUserInfo')
        }

        appEventCenter.$emit('checkBlackListUser', function (isDanger) {
          _isDanger = isDanger
          if (_isDanger) return
          if (_this.routerUrl.indexOf('/exchange') > -1) {
            window.location.href = _this.routerUrl
          } else {
            _this.$router.push(_this.routerUrl)
          }
        })
      }
    },
    gotoAccountSetting() {
      if (this.isRisk) {
        this.$refs.riskDialog?.riskDrawerUpdate()
        return
      }
      this.$router.push({ path: `${langPath}/user/account_setting` })
      triggerLowPriorityTask(() => {
        sa(
          'send',
          {
            activity_name: 'click_personal_avatar_nickname',
            activity_param: ''
          },
          { beacon: 1 }
        )
      })
    },
    exposeFillingPersonalProfile: function () {
      if (this.account_info.sex == '0' || this.account_info.birthday == '0') {
        if (typeof analysisModule.saObj !== 'undefined') {
          analysisModule.saObj.viewTips()
        }
      }
    },
    /* use in reward notice */
    clickBanner() {
      if (this.isRisk) {
        this.$refs.riskDialog?.riskDrawerUpdate()
        return
      }

      if (typeof analysisModule.saObj !== 'undefined') {
        analysisModule.saObj.clickTips()
      }
      this.$router.push({ path: `${langPath}/user/account_setting` })
    },
    gotoLogin: function () {
      markPoint('toNextPageClick', 'public')
      this.routerUrl = langPath + '/user/index'
      SHEIN_LOGIN.show({
        show: true,
        from: 'login',
        bi: 'login',
        ga: 'login',
        cb: this.loginSucRedirection
      })
    },
    clearAbtInfo() {
      abtservice.abtMap.delete('WishLoadmore')
    },
    openLevel() {
      if (this.isRisk) {
        this.$refs.riskDialog?.riskDrawerUpdate()
        return
      }
      this.$router.push(`${langPath}/user/${!IS_RW ? 'sheinvip' : 'romwevip'}`)

      triggerLowPriorityTask(() => {
        daEventCenter.triggerNotice({
          daId: !IS_RW ? '1-25-2-16' : '1-25-2-17'
        })
      })
    },

    sendFn(page) {
      if (typeof analysisModule.saObj !== 'undefined') {
        const { currency_code, amount_with_sep = 0 } = this.balances.find(balance => balance.currency_code === currency) || {}
        switch (page) {
          case '/user/orders/list':
            analysisModule.saObj.clickOrders()
            break
          case '/user/coupon':
            analysisModule.saObj.clickMyCoupons({ coupon_num: this.couponNum || 0 })
            break
          case '/user/gift_card':
            analysisModule.saObj.clickGiftcard()
            break
          case '/user/addressbook':
            analysisModule.saObj.clickMyAddress()
            break
          case '/user/wallet':
            analysisModule.saObj.clickMyWallet({ wallet_num: amount_with_sep != 0 ? `${currency_code}_${amount_with_sep}` : null })
            break
          case '/user/setting':
            analysisModule.saObj.clickSettings()
            break
          case '/user/points':
            analysisModule.saObj.clickPoints({ points_num: this.totalPoints || 0 })
            break
          default:
            break
        }
      }
    },
    wallets() {
      let wallet = 0
      if (this.balances.length > 0) {
        const { currency_code, amount_with_sep, amount } =
          this.balances.find(balance => balance.currency_code === currency) || {}
        wallet =
          currency_code && amount > 0
            ? `<span>${amount_with_sep || 0}</span><sub>${currency_code}</sub>`
            : 0
      }
      return wallet
    },
    close_cccNotice() {
      this.showUserCenterNotice = false
      daEventCenter.triggerNotice({
        daId: '1-25-2-5'
      })
      // 新增埋点
      daEventCenter.triggerNotice({
        daId: '1-25-2-14'
      })
      setLocalStorage({
        key: 'hideCccNotice',
        value: '1',
        expire: 24 * 60 * 60 * 1000
      })
    },
    expose_cccNotice() {
      daEventCenter.triggerNotice({
        daId: '1-25-2-12'
      })
    },
    exposeMyAssets() {
      const { currency_code, amount_with_sep = 0 } = this.balances.find(balance => balance.currency_code === currency) || {}
      daEventCenter.triggerNotice({
        daId: '1-25-2-25',
        extraData: {
          my_assets_list: [
            {
              'item_type': 'my_coupons',
              'coupon_num': this.couponNum || 0
            },
            {
              'item_type': 'shein_points',
              'points_num': this.totalPoints || 0
            },
            {
              'item_type': 'my_wallet',
              'wallet_num': amount_with_sep != 0 ? `${currency_code}_${amount_with_sep}` : null
            },
            {
              'item_type': 'gift_card'
            }
          ]
        }
      })
    },
  },
  render() {
    const renderRewardMsgNotice = () => {
      const isNoticeOpen =
        this.activityInfo.couponActOpen == 1 || this.activityInfo.pointActOpen == 1
      const isHasABT = this.activityInfo.abtResult
      if (!isNoticeOpen || !isHasABT || !this.showUserCenterNotice) return null

      return (
        <RewardMsgNotice
          activity-info={this.activityInfo}
          vOn:clickBanner={this.clickBanner}
        />
      )
    }

    const renderCommonNotice = () => {
      if (this.showUserCenterNotice) {
        return (
          <div class="common-notice-wrap">
            <CommonNotice
              message={this.showUserCenterNoticeText}
              oncloseAlert={this.close_cccNotice}
              onshowDetailEvt={this.expose_cccNotice}
            />
          </div>
        )
      }
      return null
    }

    /* Header */
    const renderHeader = () => {
      return (
        <div
          class={`index-me-header mshe-z-header
            ${
              this.blackFiveConfig?.img
                ? IS_RW
                  ? 'index-me-header_rwbg'
                  : 'index-me-header_sheinbg'
                : ''
            }`}
        >
          <div class="header-right">
            <div
              v-enterkey
              role="button"
              tabindex="0"
              aria-label="settings"
              class="waves-btn waves-btn-auto waves-effect waves-classic"
              DA-type="syncClick"
              vOn:click={() => this.routerPush('/user/setting')}
            >
              <i class="me-header-li suiiconfont sui_icon_nav_setting_24px"></i>
            </div>
          </div>
        </div>
      )
    }

    /* Nickname */
    const renderNickname = () => {
      if (this.isLogin) {
        const isShowVip = !!this.vipLevel
        return (
          <div
            class={`aside-login mshe-flex ${
              this.blackFiveConfig?.img && !IS_RW ? 'aside-login_sheinbg' : ''
            }`}
          >
            <div class="upload-msg">
              <div
                class={['user-name', IS_RW ? 'font' : '']}
                vOn:click={this.gotoAccountSetting}
              >
                {this.blackFiveConfig?.fetched && (
                  <span
                    role="link"
                    tabindex="0"
                    class="name"
                    ref="userName"
                  >
                    {this.account_info.nickname || this.username}
                  </span>
                )}
                
                {isShowVip && (
                  <UserVipTag
                    is-old-es-vip={this.isOldEsVip}
                    site-from={this.vipSiteFrom}
                    level={this.vipLevel}
                    vOn:openVip={this.openLevel}
                  />
                )}
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div
            class="aside-login j-aside-login mshe-flex"
            DA-type="syncClick"
            DA-sa-name="signin_register"
            vOn:click={this.gotoLogin}
          >
            <div class="upload-msg">
              <p class={['user-name', IS_RW ? 'font' : '']}>
                {this.blackFiveConfig?.fetched && (
                  <span
                    tabindex="0"
                    role="link"
                    class="not-login-title"
                  >
                    {firstUpperCase(htmlDecode({ text: gbLanguage.SHEIN_KEY_PWA_15872 })) + ' >'}
                  </span>
                )}
              </p>
            </div>
          </div>
        )
      }
    }

    /* Main Services */
    const renderMainServices = () => {
      const renderServiceItemIcon = item => {
        if (item.showVal) {
          return (
            <i
              class={[
                'me-li-icon',
                GB_cssRight ? 'assets-wallet-ar' : '',
                item.id == 'wallet' && item.val ? 'assets-wallet' : 'assets-val',
                IS_RW ? 'font-bold' : ''
              ]}
              domPropsInnerHTML={item.val}
            ></i>
          )
        } else {
          return <i class={['suiiconfont', 'me-li-icon', item.icon]}></i>
        }
      }
      const renderServiceItem = item => {
        if (!item.show) return null
        return (
          <div
            key={item.id}
            v-enterkey
            class={`me-li waves-btn waves-btn-auto waves-effect waves-classic me-li-item-${item.id}`}
            tabindex="0"
            role="button"
            da-event-click={item.dataEventId}
            data-sa-param={item.id == 'points' ? JSON.stringify({ points: this.totalPoints }) : ''}
            vOn:click={() => this.routerPush(item.router, '', item.scence)}
          >
            {renderServiceItemIcon(item)}
            <p class="me-li__title">{htmlDecode({ text: item.title })}</p>
          </div>
        )
      }
      return (
        <div class={['me-list', 'fsp-element', this.giftcardShow ? '' : 'me-list-3']}>
          {this.assets.map(item => renderServiceItem(item))}
        </div>
      )
    }

    /* Main Section */
    const renderMainSection = () => {
      const showNewsPannel = !IS_RW && this.newsData.list.length > 0
      return (
        <li
          class={`index-me-center j-index-me-center ${
            this.blackFiveConfig?.img && IS_RW ? 'index-me-center_rwbg' : ''
          }`}
        >
          {renderNickname()}
          {this.refreshingPage ? <s-loading show size="medium" /> : null}
          <div class="pannel-wrap">
            <UserRightsPanel
              rawPrimeData={this.primeData}
              userInfo={this.userInfo}
              rawSuperSaveData={this.superSaveData}
              userRightsFusion={this.userRightsFusion}
              isRouterLeave={this.isRouterLeaveFlag}
            />
            { showNewsPannel ? <NewsPannel {...{ props: this.newsData }} routeInfo={this.routeInfo} /> : null }
          </div>
          {renderMainServices()}
          {/* 用户资产下方模块 */}
          <UserAssetsBottom
            is-login={this.isLogin}
            language={({
              ...this.userCenterLanguage,
              ...this.language,
            })}
            ready-to-init={this.isMainDataReady}
            expire-game-money={this.expireGameMoney}
            new-user-benefits={this.newUserBenefits}
            coupon-or-points-data={this.couponOrPointsData}
            personal-reward={this.personalReward}
            router-push={this.routerPush}
          />
        </li>
      )
    }

    const moreServicesSectionNewSection = (length) => {
      if (!length) {
        return (
          <MoreServicesSection
            is-risk={this.isRisk}
            is-login={this.isLogin}
            is-show-survey-points={this.isShowSurveyPoints}
            is-open-review-entry={this.isOpenReviewEntry}
            is-open-free-trial-entry={this.isOpenFreeTrialEntry}
            is-show-follow-un-read-dot={this.isShowFollowUnReadDot}
            more-services={this.moreServices}
            vOn:openRiskDrawer={() => this.$refs.riskDialog?.riskDrawerUpdate()}
            vOn:handleRouterPush={this.routerPush}
          />
        )
      }
      return (
        <MoreServicesSectionNew
          is-risk={this.isRisk}
          is-login={this.isLogin}
          more-services={this.moreServiceConfig}
          is-show-follow-un-read-dot={this.isShowFollowUnReadDot}
          open-risk-drawer={() => this.$refs.riskDialog?.riskDrawerUpdate()}
          handle-router-push={this.routerPush}
        />
      )
    }

    return (
      <section
        style={{
          '--config-background-img': `url("${this.blackFiveConfig?.img}")`,
          '--config-color': this.blackFiveConfig?.color
        }}
        class={`c-index-me ${IS_RW ? '' : 'c-index-me_bg'}`}
      >

        <ClientOnly>
          <NetworkErrorAlert 
            description={this.userCenterLanguage.SHEIN_KEY_PWA_31743}
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 999,
            }}
          />
        </ClientOnly>

        <div>
          {IS_RW && [
            renderRewardMsgNotice(),
            renderCommonNotice(),
            <div class="c-index-me_bg">
              {this.blackFiveConfig?.fetched && renderHeader()}
              <div class="me-scroll-wrap">
                <ul>
                  {this.blackFiveConfig?.fetched && renderMainSection()}
                  <MyOrderSection
                    is-risk={this.isRisk}
                    is-login={this.isLogin}
                    is-open-review-entry={this.isOpenReviewEntry}
                    order-count={this.orderCount}
                    my-orders={this.orderCount}
                    unpaid-order={this.unpaidOrder}
                    un-review-order={this.unReviewOrder}
                    vOn:handleRouterPush={this.routerPush}
                  />
                  <MoreServicesSection
                    is-risk={this.isRisk}
                    is-login={this.isLogin}
                    is-show-survey-points={this.isShowSurveyPoints}
                    is-open-review-entry={this.isOpenReviewEntry}
                    is-open-free-trial-entry={this.isOpenFreeTrialEntry}
                    is-show-follow-un-read-dot={this.isShowFollowUnReadDot}
                    more-services={this.moreServices}
                    vOn:openRiskDrawer={() => this.$refs.riskDialog?.riskDrawerUpdate()}
                    vOn:handleRouterPush={this.routerPush}
                  />
                  <AppCcc
                    class="advertise"
                    {...this.appCccConfig}
                  />
                  <FooterSection is-login={this.isLogin} />
                </ul>
              </div>
            </div>
          ]}
          {!IS_RW && [
            renderHeader(),
            renderRewardMsgNotice(),
            <div class="me-scroll-wrap">
              <ul>
                {renderCommonNotice()}
                {renderMainSection()}
                <MyOrderSection
                  is-risk={this.isRisk}
                  is-login={this.isLogin}
                  is-open-review-entry={this.isOpenReviewEntry}
                  order-count={this.orderCount}
                  my-orders={this.orderCount}
                  unpaid-order={this.unpaidOrder}
                  un-review-order={this.unReviewOrder}
                  unpaid-order-abt={this.unpaidOrderAbt}
                  vOn:handleRouterPush={this.routerPush}
                />
                {this.visibleGuide && <ShoppingGuide vOn:callBackEvent={({ show }) => this.visibleGuide = show } />}
                {moreServicesSectionNewSection(this.moreServiceConfig?.iconsGroup?.icons?.length )}
                <AppCcc
                  class="advertise"
                  {...this.appCccConfig}
                />
                <FooterSection
                  is-login={this.isLogin}
                  footer-rcmd-ccc-config={this.footerRcmdCccConfig}
                  footer-rcmd-list-resolve-status={this.footerRcmdListResolveStatus}
                />
                {
                  !!this.footerRcmdCccConfig && !this.firstTrendFlowPending
                    ? (<FooterRcmdList
                      ccc-config={this.footerRcmdCccConfig}
                      handle-insert-cart-slot={this.fetchTrendFlowData}
                      cart-slot-info-list={this.trendFlowData}
                      language={this.language}
                      shein-club-info={this.sheinClubInfo}
                      abt-info={this.abtSource}
                    />)
                    : null
                }
              </ul>
            </div>
          ]}
        </div>
        <RiskDialog
          ref="riskDialog"
          vOn:changeUserRisk={v => (this.isRisk = v)}
        />
      </section>
    )
  }
}
</script>
<style lang="less">
// 模块内容过于复杂，出于安全考虑，部分内容禁用lint
@w: 375/100vw;
@w2: 750/100vw;
.c-index-me {
  background-color: #fff;
  padding-bottom: 50px;
  &_bg {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      background-image: var(--config-background-img);
      background-size: 100% auto;
      background-repeat: no-repeat;
      pointer-events: none;
      height: 5rem /* rw: 4.2rem */;
    }
  }

  &.tab-hidden {
    visibility: hidden;
    pointer-events: none;
  }

  .iScrollVerticalScrollbar {
    /* stylelint-disable-next-line declaration-no-important */
    width: 0.13rem !important;
    .iScrollIndicator {
      /* stylelint-disable-next-line declaration-no-important */
      background: rgba(0, 0, 0, 0.3) !important;
    }
  }

  .index-me-header {
    height: 1.17rem;
    padding: 0 0.1rem;
    background-color: #fff;
    .flexbox();
    .align-center();
    .space-between();
    &_sheinbg {
      background: transparent;
      position: relative;
      z-index: @zindex-hack;
    }
    &_rwbg {
      background: transparent;
      position: relative;
      z-index: @zindex-hack;
      color: #fff;
    }
    & when (@IS_RW) {
      background: linear-gradient(283deg, #fc5070 0%, #ca88f1 100%);
      backdrop-filter: blur(40px);
    }

    &.scrolling {
      border-bottom: 1px solid rgba(229, 229, 229, 1);

      & when (@IS_RW) {
        border: none;
        background: linear-gradient(90deg, rgba(255, 122, 126, 1) 0%, rgba(255, 153, 153, 1) 100%);
      }
    }

    .header-left,
    .header-right {
      .flexbox();
      .align-center();
      flex: 1;
      height: 1.17rem;

      & when (@IS_RW) {
        color: #fff;
      }
    }

    .header-right {
      .txt-r();
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
    .me-header-li {
      color: var(--config-color, #222);
      display: inline-block;
      .font-dpr(48px);
      height: 1.17rem;
      line-height: 1.17rem;
      width: 1.17rem;
      text-align: center;

      & when (@IS_RW) {
        color: #fff;
        color: var(--config-color, #fff);
      }
    }
  }



  .index-me-center {
    border-bottom: 20/75rem solid #f6f6f6;
    position: relative;
    z-index: @zindex-hack;

    & when (@IS_RW) {
      // background: linear-gradient(125deg,rgba(255,153,153,1) 0%,rgba(255,122,126,1) 100%);
      background: linear-gradient(283deg, #fc5070 0%, #ca88f1 100%);
      backdrop-filter: blur(40px);

      [class*='iconfont'] {
        color: #fff;
      }
    }

    &_rwbg {
      background: none;
      backdrop-filter: none;
    }

    .pannel-wrap {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 37%);
    }

    .aside-login {
      height: 88/75rem;
      padding: 22/75rem 24/75rem;
      white-space: nowrap;
      &_sheinbg {
        background: transparent;
      }
    }
  }

  .download-guid {
    background-color: #FFF;
    padding: 0 .32rem;
    border-bottom: 0.29rem solid #fff;
    &__inner {
      background-color: #fff1d5;
      display: flex;
      align-items: center;
      padding: 0.32rem 0;
    }
    &__content {
      padding: 0 0.32rem;
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      max-height: 0.77rem;
      line-height: 0.39rem;
    }

    &__button {
      padding: 0.12rem 0.35rem;
      background: #222222;
      color: #fff;
      font-size: 0.32rem;
      height: max-content;
    }

    &__close {
      padding: 0.12rem 0.32rem;
      height: max-content;
    }
  }

  .my-entry-block {
    min-height: 2.606667rem;
  }

  .module-content {
    border-bottom: 20/75rem solid #f6f6f6;
    background: #fff;
    padding: 0.32rem 0 0;
    position: relative;
    z-index: 1;

    .module-content-title {
      .flexbox();
      .space-between();
      padding: 0 0.32rem;

      h3 {
        .font-dpr(28px);
        font-weight: bold;
        color: #222222;
      }

      /* stylelint-disable */
      span {
        color: @sui_color_gray_dark3;
        .font-dpr(24px);

        [class*='iconfont'] {
          .flip();
        }
      }
      /* stylelint-enable */
    }

    .me-list {
      flex-wrap: wrap;

      & when (@IS_RW) {
        color: #222;
      }
    }
  }

  .me-list {
    .flexbox();
    flex-flow: row nowrap;
    padding: 0 8/75rem 24/75rem;
    background-color: #fff /* rw:ignore */;

    & when (@IS_RW) {
      color: #fff;
    }

    .me-li {
      position: relative;
      display: inline-block;
      width: 25%;
      text-align: center;
      .font-dpr(26px);
      word-break: break-word;
      padding: 24/75rem 8/75rem 0;

      /* stylelint-disable */
      .me-li-icon {
        display: block;
        .font-dpr(48px);
        margin-bottom: 0.1rem;
        height: 38/75rem;
        line-height: 38/75rem;

        &.assets-val {
          font-weight: bold;
          .font-dpr(28px);
          color: #222222;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          sub {
            bottom: 0;
            .font-dpr(20px);
          }

          & when (@IS_RW) {
            color: #ffffff;
          }
        }

        &.assets-wallet {
          .flexbox();
          .pack-center();
          color: #222;

          span {
            font-weight: 700;
            .font-dpr(28px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            direction: ltr;
          }

          sub {
            .font-dpr(20px);
            line-height: normal;
            font-weight: bold;
            position: relative;
            width: 0.7rem;
            white-space: nowrap;
          }

          & when (@IS_RW) {
            color: #ffffff;
          }
        }

        &.assets-wallet-ar {
          sub {
            .left(0);
          }
        }
      }
      /* stylelint-enable */

      &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        .font-dpr(24px);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    /* stylelint-disable */
    &.me-list-3 {
      .me-li {
        width: 33.33333%;
      }
    }
    /* stylelint-enable */

    .order-num {
      position: absolute;
      top: 0.1rem;
      min-width: 0.32rem;
      height: 0.4rem;
      padding: 0 0.1rem;
      box-sizing: border-box;
      .font-dpr(20px);
      display: inline-block;
      border: 1px solid #222;
      border-radius: 0.4rem;
      text-align: center;
      line-height: 0.4rem;
      background: #fff;
      color: #222;
    }

    .red-num {
      background: #ff411c;
      color: #fff;
      border: none;
    }

    .red-point {
      position: absolute;
      top: 0.1rem;
      .right(32%);
      height: 0.16rem;
      width: 0.16rem;
      display: inline-block;
      border: 1px solid @sui_color_guide;
      border-radius: 0.08rem;
      background: @sui_color_guide;
    }
  }

  /* stylelint-disable */
  .index-me-list {
    background: #fff;
    padding: 0.32rem 0 0.64rem;
    min-height: 370/37.5rem;
    &.followed-by-rcmdlist {
      display: flex;
      flex-direction: column;
      padding-top: 0.32rem;
      padding-bottom: 0;
      border-bottom: .26666667rem solid #f6f6f6;
      .wishlist-center-page {
        min-height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        .wishlist-center-empty {
          padding: 40/37.5rem 0 80/37.5rem;
        }
        .wish-list-comp {
          flex: 1;
        }
        .wishlist-center-page__view {
          height: 40/37.5rem;
          font-weight: normal;
        }
        .wish-list-comp__list {
          background-color: #fff !important;
        }
      }
      .recently-page {
        min-height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        &__empty {
          padding: 40/37.5rem 0 80/37.5rem;
        }
        .wish-list-comp__list {
          background-color: #fff !important;
        }
      }
    }

    .goodsl-wrap {
      background: #fff;
      min-height: 12rem;
      padding: 0 0.32rem;
      .flexbox();
      flex-flow: row wrap;
      font-size: 0;

      .c-goodsli .left-discount > div {
        width: auto;
      }

      .goodsl-item-wrap {
        .margin-r(0.32rem);
        display: inline-block;

        &:nth-child(2n) {
          .margin-r(0);
        }

        .sold-out {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          .flexbox();
          .align-center();
          .pack-center();
          text-align: center;
          font-weight: bold;
          color: #fff;
          .font-dpr(28px);
          background: rgba(0, 0, 0, 0.4);

          span {
            display: inline-block;
            line-height: 2;
            background: fade(#fff, 90%);
            color: #222;
            padding: 0 16px;
            min-width: 1.92rem;
          }
        }
      }

      .goodsli-price-discount {
        color: @sui_color_discount;
        .font-dpr(28px);

        &.single {
          color: #222;
        }
      }

      .c-goodsli .goodsli-price-original > del {
        .font-dpr(20px);
        color: #767676;
      }

      .c-goodsli {
        .font-dpr(20px);

        .save-del {
          position: absolute;
          .right(0);
          top: 0.89rem;
          transform: translateY(-100%);
          width: 0.85rem;
          height: 0.9rem;
          .padding-l(0.27rem);
          padding-bottom: 0.27rem;
          text-align: center;

          [class*='iconfont'] {
            .font-dpr(36px);
          }

          .unstock {
            color: #fff;
          }
        }

        .goodsl-promotion-wrap {
          display: table;

          > div {
            display: table-cell;
          }

          .goodsl-promotion {
            width: 100%;

            .goodsl-promotion-item {
              text-align: center;
              /*(border: 1px solid @color_red_dark;*/
              .font-dpr(24px);
              line-height: 0.5333rem;
              min-height: 0.5333rem;
              padding: 0 0.1067rem;
              display: inline-block;
              max-width: 4.52rem;
              word-break: keep-all;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              background: rgba(245, 110, 110, 0.04);
              border: 1px solid rgba(213, 51, 51, 0.5);
              color: #d53333;

              & when(@IS_SH) {
                background: rgba(245, 110, 110, 0.04);
                border: 1px solid rgba(213, 51, 51, 0.5);
                color: @color_red_dark;
              }

              & when(@IS_RW) {
                background: #ffefed;
                color: @color_brand;
              }
            }
          }
        }

        .goodsli-bottom-price {
          width: 85%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .j-goodsli-bottom {
          margin-bottom: 0;

          .goodsli-name {
            width: 85%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
          }
        }
      }

      .goodsli-price-discountTips {
        color: @sui_color_highlight;
        .font-dpr(20px);
        margin-bottom: 0.05rem;
        line-height: 2;
        max-width: 4.52rem;

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .goodsli-size-stockTips {
        color: @sui_color_highlight;
        .font-dpr(20px);
        margin-bottom: 0.05rem;
        line-height: 2;
        max-width: 85%;

        &__cut {
          span {
            margin-top: 0;
          }
        }

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .sold-out-text {
        opacity: 0.4;
        text-transform: none;

        .promo-icon {
          display: none;
        }
      }

      .goodsl-promotion-wrap.sold-out-promo {
        .goodsl-promotion .goodsl-promotion-item {
          display: none;
        }

        .icon-add-bag {
          color: #ccc;
        }
      }

      .goodsl-item-wrap {
        display: inline-block;
        margin-bottom: 0.64rem;
        overflow: hidden;
      }
    }

    .height-auto {
      min-height: auto;
    }

    .resent-view {
      &.mgds-goodsl {
        .margin-r(0);
      }
    }
  }
  /* stylelint-enable */

  .view-span {
    display: inline-block;
  }
}
.upload-msg {
  .flexbox();
  .align-center();
  width: 100%;

  .user-name {
    color: var(--config-color, @color_black_mirror);
    .font-dpr(40px);
    font-weight: bold;
    .flexbox();
    .align-center();
    width: 100%;

    .name {
      .text-overflow();
      display: inline-block;
      vertical-align: bottom;
    }

    &.is-student {
      span {
        .text-overflow();
      }

      /* stylelint-disable */
      .img_box {
        display: inline-block;
        position: relative;
        bottom: 0.038rem;

        img {
          .margin-l(24/@w);
          width: 30 / @w;
          height: 28 / @w;
        }
      }
      /* stylelint-enable */

    }

    .not-login-title {
      color: var(--config-color, #222);
      display: inline-block;
      font-weight: bold;
      .font-dpr(40px);

      & when (@IS_RW) {
        color: var(--config-color, #fff);
      }
    }
  }
}
</style>

<style lang="less" scoped>
body.ipx-fullscreen {
  .me-scroll-wrap {
    margin-bottom: 0.906rem;
  }
}

.mshe-flex {
  width: 100%;
  .flexbox();
}

.font {
  font-family: Adieu-Regular, Adieu;
  font-weight: 400;
}

.font-bold {
  font-family: Adieu-Bold, Adieu;
  font-weight: bold;
}

.my-wishlist-tab {
  border-bottom: none;
}

.c-index-me {
  .advertise {
    margin: 0.267rem 0;
  }
}
.common-notice-wrap {
  position: relative;
  z-index: @zindex-hack;
}

</style>
