<script lang="jsx">
import Vue from 'vue'
import RiskConfirm from './RiskConfirm.vue'
import { InitFtoken } from 'public/src/pages/common/ftoken/index'
import schttp from 'public/src/services/schttp'
export default {
  components: {
    RiskConfirm
  },
  data: function () {
    return {
      isRisk: false,
      showRiskDialog: false,
      riskInfo: {},
      riskTitle: '',
    }
  },
  mounted() {
    // 密码风险用户验证
    appEventCenter.$emit('checkBlackListUser')

    InitFtoken(() => {
      this.checkRisk()
    }, { notSendFtoken: true })
  },
  methods: {
    riskDrawerUpdate(){
      this.showRiskDialog = true
      //需等组件渲染后才能获取到$refs.riskConfirm
      this.$nextTick(() => {
        this.$refs.riskConfirm.dialogUpdateInfo({
          scene: 'view_privacy',
          info: this.riskInfo
        })
      })
    },
    async checkRisk(){
      //检查爆破黑名单
      if( gbCommonInfo.user.is_black_list_user == 1 || gbCommonInfo.user.is_new_reveal == 1 ){
        this.$emit('changeUserRisk', true)
        // this.isRisk = true
        try {
          const res = await schttp({
            method: 'POST',
            url: '/api/user/center/checkRisk/create',
            data: {
              ftoken: gbCommonInfo.ftoken,
              scene: 'view_privacy'
            },
          })
          if( res && res.code == 0 && res.info && res.info.extend_info ){
            Vue.set(this, 'riskInfo', res.info)
          } else {
            Vue.set(this, 'riskInfo', {})
            this.$emit('changeUserRisk', false)
            // this.isRisk = false
          }
          if( res && res.language ){
            this.riskTitle = res.language.SHEIN_KEY_PWA_17711
          }
        } catch (error) {
          Vue.set(this, 'riskInfo', {})
          // this.isRisk = false
          this.$emit('changeUserRisk', false)
        }
      }
    },
    loginModalSucCb(){
      location.reload()
    },
    riskConfirmCb(){
      this.showRiskDialog = false
    },
  },
  render () {
    const drawerListeners = {
      'update:visible': v => this.showRiskDialog = v
    }

    return (
      <SDrawer
        visible={this.showRiskDialog}
        on={drawerListeners}
        direction="btt"
        append-to-body={true}
      >
        <template slot="top">
          { this.riskTitle }
        </template>
        <div
          class="risk-content"
          style="padding-bottom: 1.07rem;"
        >
          <RiskConfirm
            ref="riskConfirm"
            page-type={11}
            is-dialog={true}
            vOn:verify-suc-callback={this.loginModalSucCb}
            vOn:confirm-callback={this.riskConfirmCb}
          />
        </div>
      </SDrawer>
    )
  }
}



</script>
