import asyncStreamCenter from 'public/src/pages/user/service/asyncStreamCenter'
import { CccLink, isInSpa } from 'public/src/pages/components/ccc/common/ccc-link'

export const triggerLowPriorityTask = fn => {
  asyncStreamCenter.register(
    () => {
      requestIdleCallback(() => {
        fn()
      })
    },
    { scene: 'dashboard' }
  )
}

export const getLocalStorage = (cacheKey, fallbackData) => {
  const cacheData = window.localStorage.getItem(cacheKey)
  if (!cacheData) return fallbackData
  try {
    return JSON.parse(cacheData)
  } catch {
    return fallbackData
  }
}

export const setLocalStorage = (cacheKey, data) => {
  window.localStorage.setItem(cacheKey, JSON.stringify(data))
}


export const openCccJumpUrl = (hrefInfo, router) => {
  const url = getCccJumpUrl(hrefInfo)
  if (url.indexOf('javascript:;') > -1) return
  if (!isInSpa(hrefInfo || {})) {
    window.open(url, '_blank')
    return
  }
  router.push(url)
}

export const getCccJumpUrl = (hrefInfo) => {
  const cccLink = new CccLink({})
  let url = cccLink.getLink({
    item: hrefInfo || {},
    cateLinks: {},
  })
  if (typeof window !== 'undefined') {
    url = cccLink.replacePwaUrl(url)
  }
  return url
}

export const formatUrlQuery = (queryObj = {}) =>
  Object.keys(queryObj).reduce((sum, key) => `${sum}${queryObj[key] ? `&${key}=${queryObj[key]}` : ''}`, '')


// 获取埋点数据
// type 1曝光 2 点击
export const getBuriedData = (buriedObject = [], type = 1) => {
  if (!(buriedObject && Array.isArray(buriedObject))) return
  return buriedObject?.find(item => +item.buriedType === +type)?.defaultParams || {}
}
