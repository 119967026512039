import asyncStreamCenter from 'public/src/pages/user/service/asyncStreamCenter'

export const triggerLowPriorityTask = fn => {
  asyncStreamCenter.register(
    () => {
      requestIdleCallback(() => {
        fn()
      })
    },
    { scene: 'dashboard' }
  )
}

export const getLocalStorage = (cacheKey, fallbackData) => {
  const cacheData = window.localStorage.getItem(cacheKey)
  if (!cacheData) return fallbackData
  try {
    return JSON.parse(cacheData)
  } catch {
    return fallbackData
  }
}

export const setLocalStorage = (cacheKey, data) => {
  window.localStorage.setItem(cacheKey, JSON.stringify(data))
}
