<template>
  <div class="product-swiper">
    <swiper-container
      ref="swiperEl" 
      class="product-swiper__container"
      init="false"
    >
      <swiper-slide
        v-for="item in props.orderItem?.newGoodInfo"
        :key="item.billNo"
        class="product-swiper__slide"
        :style="{width: '17vw'}"
      >
        <img
          :src="transformImg({ img: item.goodImage })"
          class="product-swiper__img-good"
        />
        <div
          v-if="item.stockTip"
          class="product-swiper__img-tip"
        >
          {{ item.stockTip }}
        </div>
      </swiper-slide>
      <swiper-slide
        v-for="item in 4"
        :key="item"
      />
    </swiper-container>
    <div class="product-swiper__active-border"></div>
    
    <transition
      name="fade"
      type="animation"
    >
      <div 
        v-if="activeSwiperItem.text"
        :key="activeSwiperItem.goodImage"
        class="product-swiper__active-item"
      >
        <div
          class="product-swiper__active-desc"
        >
          <div 
            class="product-swiper__active-text"
          >
            {{ activeSwiperItem.text }}
          </div>
          <img
            class="product-swiper__active-icon"
            :src="getActiveItemIcon(activeSwiperItem.type)"
          />
        </div>
      </div>
    </transition>

    <div class="product-swiper__footer">
      <div class="product-swiper__total">
        <div class="product-swiper__total-counts">
          {{ props.orderItem?.itemNumText }}
        </div>
        <div 
          class="product-swiper__total-price" 
          v-html="props.orderItem?.totalPriceText"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { transformImg } from '@shein/common-function'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
import { USER_INDEX_LOCAL_IMG } from '../../../consts.js'
typeof window !== 'undefined' && register()

const props = defineProps({
  orderItem: { type: Object, default: null }
})
const swiperEl = ref() // swiper容器 // 
const activeSwiperItem = ref(props.orderItem.newGoodInfo?.[0])

/* computed */
const getNewGoodInfo = computed(() => props.orderItem.newGoodInfo)

// 初始化轮播
const initSwiper = () => {
  const options = {
    modules: [Autoplay],
    speed: 500,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    allowTouchMove: false,
    spaceBetween: 4,
    slidesPerView: 'auto',
    on: {
      slideChangeTransitionStart: (event) => {
        const activeIndex = event?.realIndex
        activeSwiperItem.value = getNewGoodInfo.value?.[activeIndex]
        if(activeIndex == getNewGoodInfo.value?.length) {
          swiperEl.value.swiper.slideTo(0)
        }
      }
    }
  }
  Object.assign(swiperEl.value, options)
  swiperEl.value.initialize()
}

onMounted(() => {
  initSwiper()
}) 

const getActiveItemIcon = (type) => {
  switch (Number(type)) {
    case 1:
      return USER_INDEX_LOCAL_IMG.orderNewLow
    case 2:
      return USER_INDEX_LOCAL_IMG.orderTopList
    case 3: 
      return USER_INDEX_LOCAL_IMG.orderFire
    default:
      return USER_INDEX_LOCAL_IMG.orderNewLow
  }
}
</script>

<style lang="less" scoped>
// 设计稿用的 375px
@unit: 375/100vw;
.product-swiper {
  position: relative;
  border-radius: 2 / @unit;
  background: #F6F6F6;
  padding: 4 / @unit;

  &__container {
    height: 85/ @unit;
    overflow: hidden;
    display: flex;
    gap: 4 / @unit;
  }
  &__slide {
    position: relative;
  }
  &__img-good {
    width: 17vw;
    height: 85 / @unit;
    object-fit: cover;
  }
  &__img-tip {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 15 / @unit;
    line-height: 15 / @unit;
    text-align: center;
    width: 64 / @unit;
    box-sizing: border-box;
    padding: 0 2 / @unit;
    background: rgba(34, 34, 34, 0.60);
    color: #FFF;
    font-size: 9 / @unit;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__active-border{
    position: absolute;
    top: 4 / @unit;
    width: 17vw;
    height: 85 / @unit;
    border: 2 / @unit solid #FA6338;
    z-index: @zindex-hack;
  }
  &__active-item {
    position: absolute;
    width: 64/ @unit;
    height: 52/ @unit;
    border: 0.5px solid rgba(168, 97, 4, 0.20);
    background: #FFF7E8;
    box-sizing: border-box;
    padding: 4 / @unit;
    color: #A86104;
    font-size: 9px;
    font-weight: 700;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
  &__active-text {
    position: relative;
    z-index: 1;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  &__active-icon {
    position: absolute;
    bottom: 4 / @unit;
    right: 4 / @unit;
    width: 32 / @unit;
    height: 32 / @unit;
    background-size: cover;
  }
  &__total {
    padding: 14 / @unit 4 / @unit 4 / @unit 4 / @unit;
    color:#767676;
    text-align: right;
    font-size: 12 / @unit;
    font-weight: 400;
  }
  &__total-price {
    margin-top: 4 / @unit;
  }
  /deep/ .font-strong {
    color: var(---sui_color_discount, #000);
    font-size: 14px;
    font-weight: 700;
  }
}
.fade-leave-active {
  animation: fade 0.5s;
}
.fade-enter-active {
  animation: fade 0.5s reverse;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
