<template>
  <div
    v-show="defaultVisible"
    class="shopping-guide_container module-content_shoppingGuide"
    @click="moduleClick"
  >
    <div class="header">
      <img
        :src="imagesAll.activeCar"
        class="activeCar"
      />
      <div class="tips-container">
        <span class="title">{{ languageInfo.SHEIN_KEY_PWA_29464 }}</span>
        <div class="time-txt">
          <span>{{ languageInfo.SHEIN_KEY_PWA_16627 }}</span>
          <div
            class="time"
            v-html="timeInfo.countDownTxt"
          ></div>
        </div>
      </div>
      <span class="go-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.10103 5.55902L-0.000380927 10.3416L0.7053 11.0943L6.60962 5.55902L0.705301 0.0237164L-0.000380091 0.776443L5.10103 5.55902Z"
            fill="#222222"
          />
        </svg>
      </span>
    </div>
    <div class="content">
      <div class="content-left">
        <div
          v-for="(item, index) in productList"
          :key="index"
          class="item"
        >
          <div class="item-image">
            <img
              :src="item.goods_img"
              class="image"
            />
            <div class="tips">
              <Icon
                name="sui_icon_free_shipping_12px"
                size="12px"
                color="#fff"
              />
              <span class="Free">{{ languageInfo.SHEIN_KEY_PWA_25536 }}</span>
            </div>
          </div>
          <div class="item-tips">
            {{ item?.price }}
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="right-tips">
          <div class="right-freeContainer">
            <Icon
              name="sui_icon_free_shipping_12px"
              size="12px"
              color="#fff"
            />
            <span class="Free">{{ languageInfo.SHEIN_KEY_PWA_29330 }}</span>
          </div>

          <div class="triangle"></div>
        </div>
        <div class="btn">
          {{ languageInfo.SHEIN_KEY_PWA_21046 }}
        </div>
        <img
          :src="imagesAll.guideHand"
          alt=""
          :class="[`guideHand`, { 'guideHand-animation': isHandleEff }]"
        />
      </div>
    </div>
  </div>
</template>
<script>
const { PUBLIC_CDN, SiteUID, user, langPath, host } =
  gbCommonInfo || {}
import { Icon } from '@shein-aidc/icon-vue2'
import { ref, reactive, computed, onMounted, getCurrentInstance } from 'vue'
import { _useCountDown as useCountDown } from 'public/src/pages/pix_form/utils.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { checkQualificationForNoGoods } from 'public/src/pages/common/oneClickPay/fetcher.js'

daEventCenter.addSubscriber({ modulecode: '2-25' })

export default {
  name: 'ShoppingGuide',
  components: {
    Icon
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance()?.proxy || {}
    const imagesAll = ref({
      activeCar: `${PUBLIC_CDN}/pwa_dist/images/checkout/Group 1312320042@2x-152704014c.png`,
      guideHand: `${PUBLIC_CDN}/pwa_dist/images/checkout/hands@2x-239ddc0e62.png`,
      guideContainer: `${PUBLIC_CDN}/pwa_dist/images/checkout/guide_container_bg-836282db72.png`
    })
    const optionsDuide = reactive({
      productList: [],
      timeInfo: {},
      showComponent: false
    })
    const midSiteUid = ref(`${SiteUID}_${user?.member_id}`)
    const isHandleEff = ref(false)
    const defaultVisible = ref(false)
    const languageInfo = ref({})
    const timeInfo = reactive({
      expiredShow: false,
      countDownTxt: ''
    })

    onMounted(() => {
      // 预防会有ssr情况，在mounted重新赋值
      if (midSiteUid.value.indexOf('undefind') > -1) {
        midSiteUid.value = `${SiteUID}_${user?.member_id}`
      }
      sessionMidSite({ type: 'isHandleEffFn' })
    })

    const productList = computed(() => {
      return optionsDuide?.productList
    })

    // 倒计时
    const downTimer = ({ startTime = 0, endTime = 0, countDown = 0 }) => {
      const timeParams = {
        startTime,
        endTime,
        countDown
      }
      useCountDown(
        {
          ...timeParams,
          onCompleted() {
            timeInfo.expiredShow = true
            if (
              optionsDuide?.expiredEvent &&
              typeof optionsDuide.expiredEvent == 'function'
            ) {
              optionsDuide.expiredEvent()
            }
            callBackEventEmit(false)
          }
        },
        info => {
          const {
            hours_transform = '00',
            minutes_transform = '00',
            seconds_transform = '00'
          } = info || {}
          const styleIs =
            'background:#000;color:#FFF;padding-left:2px;padding-right:2px;padding-top:1px;padding-bottom:1px;line-height:1.2;border-radius:2px;font-size:10px;'
          let res = ''
          ;[hours_transform, minutes_transform, seconds_transform].forEach(
            (item, index) => {
              const sp = `<span style=${styleIs}>${item}</span> ${
                index < 2 ? ': ' : ''
              }`
              res += sp
            }
          )
          timeInfo.countDownTxt = res
        }
      )
    }

    // 模块点击
    const moduleClick = () => {
      daEventCenter.triggerNotice({
        daId: '2-25-15',
        extraData: {
          click_type: 'open',
          status: 'unfold'
        }
      })
      sessionMidSite({ type: 'changeSession' })
      if (
        optionsDuide?.moduleClick &&
        typeof optionsDuide.moduleClick == 'function'
      ) {
        return optionsDuide.moduleClick()
      }
      const src_module = 'one_click_pay',
            src_identifier = window?.SaPageInfo?.page_name,
            src_tab_page_id = `${window?.SaPageInfo?.page_name}${window?.SaPageInfo?.start_time}`
      // console.log('前往一键购落地页', vm.$routerPush)
      // vm.$routerPush(`${langPath}/pay/ocp?billno=${optionsDuide.billno}&activity_from=me`)
      window.location.href = `${langPath}/pay/ocp?billno=${optionsDuide.billno}&src_module=${src_module}&src_identifier=${src_identifier}&src_tab_page_id=${src_tab_page_id}`
    }

    // sessionStorage
    const sessionMidSite = ({ type = 'isHandleEffFn' }) => {
      const info = sessionStorage.getItem('MIDSiTEUID_GUIDE') || ''
      const is = info == midSiteUid.value

      const sessionMap = {
        isHandleEffFn: () => (isHandleEff.value = !is),
        changeSession: () => {
          if (!is) {
            sessionStorage.setItem('MIDSiTEUID_GUIDE', midSiteUid.value)
          }
        }
      }

      return sessionMap[type]()
    }

    // 获取服务数据
    const getOneClickPayInfo = async () => {
      const res = await checkQualificationForNoGoods({ type: 'me' })

      Object.assign(optionsDuide, {
        productList: (res?.productList || []).splice(0, 4),
        timeInfo: {
          startTime: res?.holdInfo?.startTime,
          endTime: res?.holdInfo?.endTime,
        },
        billno: res?.lastPayOrderBillno || '',
        showComponent: res?.showComponent,
        reason: res?.reason || ''
      })

      languageInfo.value = res?.language || {}

      // 上报一键购情况
      daEventCenter.triggerNotice({
        daId: '2-25-13',
        extraData: {
          scenes: 'one_click_pay',
          one_click_pay_status: optionsDuide?.showComponent ? 1 : 0,
          one_click_pay_reason: optionsDuide?.reason
        }
      })
      // 不支持一键购数据
      if (!optionsDuide?.showComponent) {
        callBackEventEmit(false)
      } else {
        callBackEventEmit(true)
        // 曝光一键购
        daEventCenter.triggerNotice({
          daId: '2-25-14',
          extraData: {
            src_module: 'one_click_pay',
            src_identifier: window?.SaPageInfo?.page_name,
            goods_to_list: optionsDuide.productList.map(m => m.goods_id).join(',')
          }
        })
      }
    }

    const callBackEventEmit = val => {
      defaultVisible.value = val
      emit('callBackEvent', {
        show: val
      })
    }

    const initPage = async () => {
      await getOneClickPayInfo()
      const { startTime, endTime, countDown } = optionsDuide?.timeInfo || {}
      downTimer({ startTime, endTime, countDown })
    }

    initPage()

    return {
      imagesAll,
      timeInfo,
      defaultVisible,
      isHandleEff,
      productList,
      languageInfo,

      moduleClick
    }
  }
}
</script>
<style lang="less" scoped>
@baseSize: 37.5rem;
.module-content_shoppingGuide {
  border-bottom: 20/75rem solid #f6f6f6;
  background: #fff;
}
.shopping-guide_container {
  // background: linear-gradient(180deg, #01985B 0%, #01985B 0%, #FFF 42.89%);
  // padding: 7/@baseSize 12/@baseSize 9/@baseSize;
  box-sizing: border-box;
  min-height: 142 / @baseSize;
  font-style: normal;
  line-height: normal;
  background: url('/pwa_dist/images/checkout/guide_container_bg-836282db72.png');
  background-size: cover;
  background-position: center;

  .header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 7 / @baseSize 12 / @baseSize 0;
    // background: linear-gradient(180deg, #01985B 0%, #01985B 0%, #FFF 100%);
    .activeCar {
      // width: 60 / @baseSize;
      // height: 35.5 / @baseSize;
      width: 60px;
      // height: 35.5px;
      flex-shrink: 0;
    }
    .tips-container {
      // margin-left: 8 / @baseSize;
      .title {
        font-size: 14px;
        font-weight: 700;
        // background-clip: text;
        // background-image: linear-gradient(90deg, #FFFBF5 1.32%, #FFF0C1 102.63%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14);
        // font-family: "SF Pro";
        color: #000;
        margin-top: 3 / @baseSize;
      }
      .time-txt {
        font-size: 12px;
        font-weight: 510;
        display: flex;
        color: #000000;
        font-size: 12px;
        margin-top: 5 / @baseSize;

        .time {
          margin-left: 7 / @baseSize;
        }
      }
    }
    .go-icon {
      position: absolute;
      right: 12 / @baseSize;
    }
  }
  .content {
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin-top: 15 / @baseSize;
    padding: 0 12 / @baseSize 9 / @baseSize;

    &-left {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      // gap: 4px;
      // overflow: hidden;
      .item {
        width: 59 / @baseSize;
        min-height: 74 / @baseSize;
        flex-shrink: 0;
        margin-right: 8 / @baseSize;
        .item-image {
          border-radius: 4 / @baseSize;
          background: #ccc;
          color: #fff;
          position: relative;
          .image {
            width: 59 / @baseSize;
            height: 58 / @baseSize;
            object-fit: cover;
            border-radius: 4 / @baseSize 4 / @baseSize 0 0;
          }
          .tips {
            background: rgba(27, 129, 87, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            // padding: 1 / @baseSize 0;
            position: absolute;
            bottom: 0;
            width: 100%;
            font-size: 10px;
            border-radius: 0 0 4 / @baseSize 4 / @baseSize;
            box-sizing: border-box;
            .Free {
              margin-left: 1 / @baseSize;
              color: #FFF;
              text-align: center;
              font-family: "SF Pro";
              font-size: 10px;
              font-style: normal;
              font-weight: 510;
              line-height: normal;
              margin-top: 1px;
            }
          }
        }
        .item-tips {
          text-align: center;
          line-height: 1.5;
          font-weight: 700;
        }
      }
    }
    &-right {
      position: relative;
      margin-top: 14 / @baseSize;
      margin-left: 4 / @baseSize;

        .triangle {
          position: absolute;
          right: 0 / @baseSize;
          bottom: -5.5 / @baseSize;
          border-left: 0 solid transparent;
          border-right: 6 / @baseSize solid transparent;
          border-bottom: 6 / @baseSize solid #16533a;
          transform: rotate(90deg);
        }
      }
      .btn {
        width: 76 / @baseSize;
        height: 30 / @baseSize;
        flex-shrink: 0;
        background: linear-gradient(94deg, #4f4f4f -1.66%, #000 58.79%);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .guideHand {
        position: absolute;
        bottom: -3 / @baseSize;
        right: -3 / @baseSize;
        // width: 31/@baseSize;
        // height: 32/@baseSize;
        width: 31px;
        // height: 32;
      }
      .guideHand-animation {
        animation: slidein 2s linear infinite
      }
    }
  }
    .right-tips {
        background: #1b8157;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        padding: 2 / @baseSize 5 / @baseSize;
        font-weight: 510;
        color: #fff;
        top: -10 / @baseSize;
        right: -5 / @baseSize;
        min-width: 76 / @baseSize;
        .right-freeContainer{
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
        .Free {
          margin-left: 1 / @baseSize;
          font-size: 8px;
        }
  }

  @keyframes slidein {
    0% {
      // bottom: -15/@baseSize;
      // right: -15/@baseSize;
      transform: translate(0, 0);
    }
    50% {
      // bottom: 2/@baseSize;
      // right: 0;
      transform: translate(-15 / @baseSize, -15 / @baseSize);
    }
    100% {
      // bottom: -15/@baseSize;
      // right: -15/@baseSize;
      transform: translate(0, 0);
    }
  }
</style>
