var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('s-dialog',{class:`unpaid-dialog ${_setup.isHide ? 'hide' : ''}`,style:({
    '--transform-height': _setup.transformHeight + 'px'
  }),attrs:{"visible":_setup.dialogShow,"append-to-body":"","close-on-click-modal":false}},[_c('div',{ref:"dialogBodyRef",staticClass:"unpaid-dialog__bd"},[_c('div',{staticClass:"unpaid-layout"},[_c('i',{staticClass:"unpaid-layout__close",style:({ backgroundImage: `url(${_setup.USER_INDEX_LOCAL_IMG.closeImage})` }),on:{"click":_setup.handleCloseDialog}}),_vm._v(" "),_c('div',{staticClass:"unpaid-layout__header",style:({ backgroundImage: `url(${_setup.getOrderIncentivePoint?.type === '6' ? _setup.USER_INDEX_LOCAL_IMG.priceBg : _setup.USER_INDEX_LOCAL_IMG.defaultBg})` })},[(_setup.getCouponLists?.length)?[_c('div',{staticClass:"unpaid-layout__coupons"},[_vm._l((_setup.getCouponLists),function(coupon,index){return _c('div',{key:index,staticClass:"unpaid-layout__coupon",style:({ 
                backgroundImage: `url(
                  ${['1', '2'].includes(coupon.type) ? _setup.USER_INDEX_LOCAL_IMG.couponNewOrange: _setup.USER_INDEX_LOCAL_IMG.couponNewGreen}
                )`,
                marginLeft: index == 1 ? '8px' : '0'
              })},[_c('div',{staticClass:"unpaid-layout__coupon-type",style:({ backgroundColor: ['1', '2'].includes(coupon.type) ? '#FF804D' : '#2DAE78' })},[_vm._v("\n                "+_vm._s(coupon.couponTitle)+"\n              ")]),_vm._v(" "),(coupon.couponFaceValue)?_c('div',{staticClass:"unpaid-layout__coupon-price"},[(coupon.couponFaceValue)?_c('div',{domProps:{"innerHTML":_vm._s(coupon.couponFaceValue)}}):_vm._e(),_vm._v(" "),(coupon.couponThresholdTip)?_c('div',{class:{
                    'unpaid-layout__coupon-text': true, 
                    'unpaid-layout__coupon-small-text': coupon.couponThresholdTip.length > 15
                  }},[_vm._v("\n                  "+_vm._s(coupon.couponThresholdTip)+"\n                ")]):_vm._e()]):_vm._e()])}),_vm._v(" "),(_setup.getCouponLists.length > 2)?_c('div',{staticClass:"unpaid-layout__coupon-more",style:({ backgroundImage: `url(${_setup.USER_INDEX_LOCAL_IMG.moreIcon})` })}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"unpaid-layout__coupon-tip",domProps:{"innerHTML":_vm._s(_setup.getOrderIncentivePoint.text)}})]:_vm._e(),_vm._v(" "),(_setup.getOrderIncentivePoint?.type === '5')?_c('div',[_c('div',{staticClass:"unpaid-layout__points-main"},[_c('img',{staticClass:"unpaid-layout__points-icon",attrs:{"src":_setup.getOrderIcon(_setup.getOrderIncentivePoint?.type)}}),_vm._v(" "),_c('div',{staticClass:"unpaid-layout__points-num"},[_vm._v("\n              "+_vm._s(_setup.getOrderIncentivePoint?.point)+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"unpaid-layout__points-tip",domProps:{"innerHTML":_vm._s(_setup.getOrderIncentivePoint?.text)}})]):_vm._e(),_vm._v(" "),(['1','3','6'].includes(_setup.getOrderIncentivePoint?.type))?_c('div',{staticClass:"unpaid-layout__timeout"},[_c('img',{staticClass:"unpaid-layout__timeout-icon",attrs:{"src":_setup.getOrderIcon(_setup.getOrderIncentivePoint?.type)}}),_vm._v(" "),_c('div',{staticClass:"unpaid-layout__timeout-text",domProps:{"innerHTML":_vm._s(_setup.getOrderIncentivePoint?.text)}})]):_vm._e()],2),_vm._v(" "),_c('img',{staticClass:"unpaid-layout__content-top",attrs:{"src":_setup.USER_INDEX_LOCAL_IMG.mainBgTop}}),_vm._v(" "),_c(_setup.UnpaidDialogNewContent,{attrs:{"order-item":_setup.props.orderItem},on:{"closeDialog":_setup.closeDialog}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }