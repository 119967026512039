<template>
  <s-dialog
    v-if="currentDialogShow"
    :visible="true"
    class="renew-dialog"
    append-to-body
  >
    <div class="renew-dialog__bd">
      <div class="renew-layout">
        <div :class="`renew-layout__hd ${isCoupons ? 'renew-layout__hd_coupon': ''}`">
          <div v-html="title"></div>
        </div>

        <div class="renew-layout__bd">
          <div class="renew-gift">
            <div class="renew-gift__1"></div>
            <div 
              class="renew-gift__2"
              :style="{ backgroundImage: `url(${props.renewPopUpInfo?.gift_url})` }"
            ></div>
          </div>
          <div 
            class="renew-main"
            :style="{ backgroundImage: `url(${RENEW_IMG?.bgTopImage})` }"
          >
            <div class="renew-main__hd">
              <div
                class="renew-main__hd-content"
                v-html="props.renewPopUpInfo?.save_money_content"
              ></div>
              <div 
                class="renew-main__hd-content" 
                v-html="props.renewPopUpInfo?.roi_content"
              >
              </div>
            </div>
            <div 
              :class="`renew-main__bd ${isCoupons ? 'renew-main__bd_coupon' : ''}`"
              :style="{ backgroundImage: `url(${isCoupons ? RENEW_IMG?.renewMainBgCoupon : RENEW_IMG?.renewMainBg})` }"
            >
              <div :class="isCoupons ? 'renew-main__bd-text' : getRenewMainBdClass(props.renewPopUpInfo.renew_content_line1)">
                <div v-html="props.renewPopUpInfo.renew_content_line1"></div>
              </div>
              <div :class="isCoupons ? getRenewMainBdClass(props.renewPopUpInfo.renew_content_line2) : 'renew-main__bd-text'">
                <div v-html="props.renewPopUpInfo.renew_content_line2"></div>
              </div>
            </div>
            <div
              :class="`renew-main__ft ${isCoupons ? 'renew-main__ft_coupon': ''}`"
              @click="goToPrimeEvt"
            >
              <div 
                v-if="isCoupons"
                class="renew-main__bubble"
                :style="{backgroundImage: `url(${props.renewPopUpInfo?.button_img_url})`}"
              ></div>
              <div v-html="props.renewPopUpInfo?.button_content"></div>
            </div>
          </div>
        </div>
          
        <div class="renew-layout__ft">
          <!-- 关闭按钮 -->
          <i 
            class="renew-dialog__close"
            :style="{ backgroundImage: `url(${RENEW_IMG.closeImage})` }"
            @click="closeDialog"
          >
          </i>
        </div>
      </div>
    </div>
  </s-dialog>
</template>

<script setup>
// 续费弹窗
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { template, CountDown } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager'
const { langPath } =  gbCommonInfo

const instance = getCurrentInstance()
const routerInstance = instance?.proxy?.$router

/* state */
const RENEW_IMG = ref({})
const title = ref('') // v-html 标题
const timer = ref(null)
const isShowDialog = ref(true)

const { PUBLIC_CDN, GB_cssRight } = gbCommonInfo
const prefix = PUBLIC_CDN + '/pwa_dist/images/user/renew_dialog'

const props = defineProps({
  renewPopUpInfo: { type: Object, default: () => {} },
  isShow: { type: Boolean, default: false },
})

const IMAGES = {
  giftImage: GB_cssRight ? 'renew-gift-ar-5bb73a10c9' : 'renew-gift-7467e30366',
  bgTopImage: GB_cssRight ? 'renew-bg-top-ar-5695e1c12d' : 'renew-bg-top-c937dd2dae',
  closeImage: 'renew-close-7c4ceb6101',
  renewMainBg: 'renew-main-bg-a13f51be49',
  renewMainBgCoupon: 'renew-main-bg-coupon-7d7635fbcc',
}

onMounted(() => {
  RENEW_IMG.value = getReNewImg()
})

/* computed */
const currentDialogShow = computed(() => isShowDialog.value)
const isCoupons = computed(() => props.renewPopUpInfo?.total_discount_price > 0)
const isExpired = computed(() => countdownSeconds.value <= 0)
const countdownSeconds = computed(() => props.renewPopUpInfo?.total_end_time || 0)




/* methods */
// 记录弹窗时间，以及清除缓存
const recordPopUpTime = () => {
  const { SiteUID } = gbCommonInfo
  let memberId = UserInfoManager.get({ key: 'memberId', actionType: 'recordPopUpTime' })
  const cacheKey = (SiteUID || '') + '-' + (memberId || '') + '-'
  const currentTime = new Date().getTime()
  // 临期key
  const nearExpiredKey = cacheKey + 'primeV2RenewDialog'
  // 逾期key
  const ExpiredKey = cacheKey + 'primeV2ExpiredRenewDialog'
  if(isExpired.value) {
    localStorage.setItem(ExpiredKey, currentTime)
    localStorage.removeItem(nearExpiredKey)
  } else {
    localStorage.setItem(nearExpiredKey, currentTime)
  }

}
const getRenewMainBdClass = (text) => {
  if(text.length > 18) {
    return 'renew-main__bd-text-bold'
  } else {
    return 'renew-main__bd-text-big'
  }
}

const countdown = () => {
  const mainTitle = props.renewPopUpInfo?.title_line1 || 'Your SHEIN CLUB is about to expire'
  const subTitle = props.renewPopUpInfo?.title_line2 || 'in {0} day(s)'
  const couponClass = isCoupons.value ? 'coupon-color' : ''
  if(isExpired.value) {
    const days = Math.ceil(countdownSeconds.value / 86400)
    const d = `<i class="num ${couponClass}">${days}</i>`
    title.value = `${template(d, mainTitle)}`
  } else {
    timer.value?.clear()
    timer.value = new CountDown({
      seconds: countdownSeconds.value - 1,
      dayMode: true,
      countFunc(time) {
        const days = parseInt(time.D)
        if(days >= 3) {
          // 大于3天展示xx天
          const d = `<i class="num ${couponClass}">${days}</i>`
          title.value = mainTitle + ` ${template(d, subTitle)}`
        } else {
          // 小于等于3天展示时分秒
          const d = `<i class="num ${couponClass}">${days}</i>`
          const h = `<span class="num ${couponClass}">${time.H}</span>`
          const m = `<span class="num ${couponClass}">${time.M}</span>`
          const s = `<span class="num ${couponClass}">${time.S}</span>`
          const countdownStr = `<span class="num-group">${h}:${m}:${s}</span>`
          title.value = mainTitle + `<br><div class="countdown">${template(d, subTitle) + countdownStr }</div>`

        }
      },
    })
    timer.value.start()
  }
}

const getReNewImg = () => {
  const RENEW_IMG = {}
  for (const [key, value] of Object.entries(IMAGES)) {
    RENEW_IMG[key] = `${prefix}/${value}.png`
  }
  return RENEW_IMG
}
const goToPrimeEvt = () => {
  let query = 'productsource=me_popup'
  if (!isExpired.value) {
    query += '&renew=1'
  }
  routerInstance.push(`${langPath}/user/prime?${query}`)
  closeEventCenterTrigger()
}

const closeEventCenterTrigger = () => {
  isShowDialog.value = false
  // 缺上报埋点 todo
}

const closeDialog = () => {
  closeEventCenterTrigger()
}

/* watch */
watch(
  currentDialogShow, 
  (val) => {
    if(val) {
      // 存储当前时间
      recordPopUpTime()
      // 开启定时器
      countdown()
    } 
  },
  {
    immediate: true, // 立即执行回调函数
  }
)
watch(() => props.isShow, (val) => { isShowDialog.value = val }, { immediate: true })

</script>
<style lang="less" scoped>
// 设计稿用的 375px
@unit: 375/100vw;
.renew-dialog {
  // 弹窗样式重置
  .S-dialog__top {
    display: none;
  }
  .S-dialog__body {
    padding: 0;
  }
  &__bd {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__close{
    position: absolute;
    bottom: -42 / @unit;
    left: 50%;
    right: 50%;
    margin-left: -10 / @unit;
    margin-right: -10 / @unit;
    width: 20 / @unit;
    height: 20 / @unit;
    background-size: cover;
  }
}
.renew-layout {
  /deep/ .num {
    text-align: center;
    display: inline-block;
    height: 20 / @unit;
    width: 20 / @unit;
    color: #C1724A;
    line-height: 20 / @unit;
    font-weight: 600;
    border-radius: 4 / @unit;
    background: #FFF;
    margin-left: 4 / @unit;
    margin-right: 4 / @unit;
    font-size: 12 / @unit;
  }
  /deep/ .coupon-color {
    color: #C73BFF;
  }
  /deep/.countdown {
    display: flex;
    font-size: 14 / @unit;
    font-weight: 600;
    align-items: center;
    flex-wrap: wrap;
  }
  /deep/ .num-group{
    direction: ltr /* rtl:ignore */; 
  }
  /deep/ .coupon-price{
    font-size: 18 / @unit; 
  }
  // 标题：倒计时区域
  &__hd {
    position: relative;
    width: 300 / @unit;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10 / @unit;
    background: linear-gradient(148deg, #DF7742 9.78%, #DB5B19 105.85%);
    padding-top: 10 / @unit;
    padding-left: 10 / @unit;
    padding-right: 10 / @unit;
    padding-bottom: 52 / @unit;
    color: #FFF;
    font-size: 16 / @unit;
    font-weight: 700;
    line-height: 24 / @unit;
  }
  &__hd_coupon {
    background: linear-gradient(99deg, #F7516A -5.17%, #9124FF 95.19%); 
  }
  &__bd {
    position: relative;
    margin-top: -44 / @unit;
    width: 335 / @unit;
    border-bottom-left-radius: 10 / @unit;
    border-bottom-right-radius: 10 / @unit;
  }
}
// 礼物区域
.renew-gift {
  position: absolute;
  right: 5 / @unit;
  top: 10 / @unit;
  &__1 {
    width: 168 / @unit;
    height: 122 / @unit;
    background-color: #FFD8AA;
    border-top-right-radius: 10 / @unit;
  }
  &__2 {
    position: absolute;
    top: -70 / @unit;
    right: -18 / @unit;
    width: 230.4 / @unit / 2;
    height: 232.8 / @unit / 2;
    background-size: cover;
  }
  &__3 {
    position: absolute;
    top: -64 / @unit;
    left: 30 / @unit;
    width: 144 / @unit;
    height: 142 / @unit;
    transform: rotate(15.893deg);
    background-size: cover;
  }
}

// 内容区域
.renew-main {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 14 / @unit;
  padding-left: 24 / @unit;
  padding-right: 24 / @unit;
  padding-bottom: 32 / @unit;
  box-sizing: border-box;
  z-index: 1;
  &::before{
    position: absolute;
    top: 50 / @unit;
    bottom: 0;
    left: 0px;
    right: 0px;
    z-index: -1;
    content: "";
    background: #FFEED9;
    border-bottom-left-radius: 10 / @unit;
    border-bottom-right-radius: 10 / @unit;
  }
  &__hd {
    padding-bottom: 33 / @unit;
  }
  &__hd-content {
    color: rgba(#873C00, 0.8) ;
    font-size: 14 / @unit;
    line-height: 24 / @unit;
  }
  &__bd {
    position: relative;
    display: flex;
    width: 251 / @unit;
    height: 106 / @unit;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: auto;
    padding: 16 / @unit 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    color: #873C00;
    font-weight: 860;
  }
  &__bd-text {
    font-size: 20 / @unit;
  }
  &__bd-text-bold {
    font-size: 22 / @unit;
  }
  &__bd-text-big {
    font-size: 26 / @unit;
  }
  &__bd_coupon {
    color: #F8526D;
  }
  &__ft {
    height: 48 / @unit;
    margin-top: 40 / @unit;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #873C00;
    font-size: 14px;
    font-weight: 700;
    border-radius: 34 / @unit;
    background: linear-gradient(108deg, #FFC3A5 12.63%, #EAA27E 46.69%);
    box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 0.34) inset, 0px 6px 10px 0px rgba(255, 255, 255, 0.52) inset, -4px -3px 9px 0px #DE9B79 inset;
  }
  &__ft_coupon {
    height: 48 / @unit;
    color: #FFF;
    font-size: 14 / @unit;
    font-weight: 800;
    border-radius: 50 / @unit;
    border: 2px solid #FFE2D3;
    background: linear-gradient(98deg, #FF5656 1.43%, #C73BFF 100%);
    box-shadow: 0px -5.5px 13.2px 0px rgba(231, 49, 99, 0.90) inset, 0px 4.4px 6.6px 0px rgba(117, 58, 23, 0.32);
  }
  &__bubble {
    position: absolute;
    top: 3 / @unit;
    width: 239 / @unit;
    height: 23 / @unit;
    flex-shrink: 0;
    border-radius: 24 / @unit;
    opacity: 0.4;
    background: linear-gradient(180deg, #FFF 0.2%, rgba(255, 255, 255, 0.00) 99.8%);
  }
}
</style>
