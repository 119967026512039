<template>
  <div
    v-if="remount"
    class="new-user-benefits__module"
  >
    <div
      class="new-user-benefits__module-content"
      :style="moduleStyles"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup name="NewUserCommonWrapper">
import { ref, onActivated, nextTick } from 'vue'
import { useCccxConfig } from 'public/src/pages/user/child_pages/dashboard/hooks/new-user-benefits'

const props = defineProps({
  cccxConfig: {
    type: Object,
    default: {},
  },
})

const remount = ref(true)
const { moduleStyles, jumpUrl } = useCccxConfig(props.cccxConfig)
const onModuleClick = () => {
  location.href = jumpUrl
}

onActivated(() => {
  remount.value = false
  nextTick(() => {
    remount.value = true
  })
})
</script>

<style lang="less" scoped>
.new-user-benefits {
  &__module {
    width: 351/37.5rem;
    margin: -4/37.5rem auto 0;
    padding-bottom: 8/37.5rem;
  }
  &__module-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
  }
}
</style>
