<script lang="jsx">
import { htmlDecode } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
const { language: gbLanguage, IS_RW, langPath, host } = gbCommonInfo

const triggerEffect = fn => {
  requestIdleCallback(() => {
    fn()
  })
}

export default {
  functional: true,
  props: {
    isRisk: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean,
      default: false
    },
    isShowSurveyPoints: {
      type: Boolean,
      default: false
    },
    isOpenReviewEntry: {
      type: Boolean,
      default: false
    },
    isOpenFreeTrialEntry: {
      type: Boolean,
      default: false
    },
    isShowFollowUnReadDot: {
      type: Boolean,
      default: false
    },
    moreServices: {
      type: Array,
      default() {
        return []
      }
    }
  },
  render(h, { props, listeners }) {
    const {
      isRisk,
      isLogin,
      isShowSurveyPoints,
      isOpenReviewEntry,
      isOpenFreeTrialEntry,
      isShowFollowUnReadDot,
      moreServices
    } = props

    // console.log('** Render moreServices')
    // console.log(moreServices)

    const redMinderList = item => {
      if (item.id == 'survey' && !!item.val && isLogin && isShowSurveyPoints) {
        return 1
      } else if (item.id == 'support' && !!item.val) {
        return 1
      } else if (item.id === 'following' && isShowFollowUnReadDot) {
        return 1
      } else {
        return 0
      }
    }

    const handleSectionItemClick = item => {
      if (item.id == 'freetrial') {
        handleGoTrialCenter()
      } else {
        listeners.handleRouterPush(item.router, '', item.scence)
      }
    }

    const handleGoTrialCenter = () => {
      if (isRisk) return listeners.openRiskDrawer()
      if (isOpenFreeTrialEntry)
        return (location.href = host + langPath + '/free-trial-center/index')

      if (isLogin) {
        location.href = host + langPath + '/free-trial-center/my-center'
      } else {
        window.SHEIN_LOGIN.show({
          show: true,
          reload: false,
          from: 'freeTrial',
          ga: 'freetrial',
          cb: () => {
            location.href = host + langPath + '/free-trial-center/my-center'
          }
        })
      }
      triggerEffect(() => {
      })
    }

    /* Section Title */
    const renderSectionTitle = () => {
      return (
        <div class="module-content-title">
          <h3
            tabindex="0"
            class={[IS_RW ? 'font' : '']}
          >
            {gbLanguage.SHEIN_KEY_PWA_17330}
          </h3>
        </div>
      )
    }

    /* Section List Item */
    const renderSectionListItem = item => {
      if (!item.show) return null
      const isShowSurveyBadget =
        item.id == 'survey' && item.val > 0 && isLogin && isShowSurveyPoints
      const isShowFollowingBadget = item.id == 'following' && isShowFollowUnReadDot
      const isShowCommonBadget = item.isShowBadget
      const isShowFreeBadget = item.id == '14_Day_trial'

      const isShowBadget = isShowSurveyBadget || isShowFollowingBadget || isShowCommonBadget

      return (
        <div
          key={item.id}
          v-enterkey
          class={[
            'me-li',
            'waves-btn',
            'waves-btn-auto',
            'waves-effect',
            'waves-classic',
            isOpenReviewEntry ? 'me-li-item' : '',
            `me-li-item-${item.id}`
          ]}
          tabindex="0"
          role="button"
          da-event-click={item.dataEventId}
          data-param={redMinderList(item)}
          vOn:click={() => handleSectionItemClick(item)}
        >
          <div class="order-num-container">
            <Icon
              name={item.icon}
              size="24px"
            />
            {isShowBadget && <em class="red-point"></em>}
            {isShowFreeBadget && <div class="free-badget">{gbLanguage.SHEIN_KEY_PWA_27305}</div>}
            <p class="me-li__title">{htmlDecode({ text: item.title })}</p>
          </div>
        </div>
      )
    }

    /* Section List */
    const renderSectionList = () => {
      return (
        <div class="me-list j-aside-menu fsp-element">
          {moreServices.map(item => renderSectionListItem(item))}
        </div>
      )
    }

    return (
      <li class="module-content my-entry-block">
        {renderSectionTitle()}
        {renderSectionList()}
      </li>
    )
  }
}
</script>
<style lang="less" scoped>
@unit: 375/100vw;
.free-badget {
  position: absolute;
  top: 2 / @unit;
  right: 12 / @unit;
  display: inline-block;
  padding: 2 / @unit 4 / @unit 3 / @unit 4 / @unit;
  border-radius: 6 / @unit 10 / @unit 10 / @unit 0;
  background-color: #fa6338;
  color: #fff;
  font-size: 10 / @unit;
  font-weight: 600;
}
</style>
