<template>
  <div 
    v-if="showElement == 'showCouponList'"
    class="page-wrap" 
  >
    <img 
      class="page-wrap__tip" 
      :src="tipImg"
    />
    <div 
      v-expose="{
        id: '1-25-2-1009',
        data: {
          coupon_id: totalList.map(item => item.id).toString()
        },
      }"
      class="coupon-container"
    >
      <div 
        v-for="(item, index) in totalList" 
        ref="couponContainerRef"
        :key="index"
        v-tap="{
          id: '1-25-2-1010',
          data: { coupon_id: item.id, position: index + 1 },
        }"
        class="card-wrap"
        :class="getCardWrapClass(item.counponType)"
        @click="handleClickCounpon(item, index)"
      >
        <!-- 标题logo -->
        <template 
          v-if="[1,2,33].includes(item.counponType)"
        >
          <template v-if="item.counponType == 1">
            <div class="card-wrap__logo_new-person">
              <div class="card-wrap__logo-title">
                <div>{{ userCenterLanguage?.SHEIN_KEY_PWA_27133 }}</div>
              </div>
              <img :src="newPersonImg" />
            </div>
          </template>
          <template v-else-if="item.counponType == 2">
            <img
              :src="lang == 'ar' ? sheinSaverImgAr : sheinSaverImg" 
              class="card-wrap__logo_shein-saver" 
            />
          </template>
          <template v-else>
            <img
              :src="lang == 'ar' ? sheinClubImgAr : sheinClubImg"
              class="card-wrap__logo_shein-club"
            />
          </template>
        </template>

        <div class="card">
          <div
            :data-s-lang="lang"
            class="card__content"
          >
            <div 
              class="card__coupon-price" 
              v-html="item.content"
            >
            </div>
            <div class="card__min-price">
              {{ item.tip }}
            </div>
          </div>
          <div
            :data-s-lang="lang"
            class="handle"
          >
            <div class="handle__content">
              <div class="handle__use">
                {{ userCenterLanguage?.SHEIN_KEY_PWA_27132 }}
              </div>
              <div 
                class="handle__cut-down-time"
                v-html="countDownTips(index, item.counponType)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div 
    v-else-if="showElement == 'showBanner'"
    v-expose="{
      id: '1-25-2-1007',
      data: { is_login: isLogin ? 1 : 0 },
    }"
    v-tap="{
      id: '1-25-2-1008',
      data: { is_login: isLogin ? 1 : 0 },
    }"
  >
    <AppCcc
      class="advertise"
      v-bind="appCccConfig"
    />
  </div>
</template>

<script>
import { template, CountDown } from '@shein/common-function'
import AddOnItem from 'public/src/pages/common/add-on/index.js'
const { PUBLIC_CDN, SiteUID, WEB_CLIENT, lang, langPath } = gbCommonInfo

export default {
  name: 'PersonalRewardSection',
  components: {
    AppCcc: () => import('public/src/pages/components/ccc/Index.vue'),
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data() {
    return {
      isHitPeople: false, // 是否命中人群
      showBanner: null, // 存在三种状态null, false, true
      totalList: [], // 券集合
      countDownObj: {
        0: {}, // 批量倒计时组件存在的hack
      },
      countDownFinished: {},
      advertise: {},
      sheinSaverImg: `${PUBLIC_CDN}/pwa_dist/images/user/coupons/shein_saver-5ca2888af1.png`,
      sheinSaverImgAr: `${PUBLIC_CDN}/pwa_dist/images/user/coupons/shein_saver-ar-646d896977.png`,
      sheinClubImg: `${PUBLIC_CDN}/pwa_dist/images/user/coupons/shein_club-2adaf47adb.png`,
      sheinClubImgAr: `${PUBLIC_CDN}/pwa_dist/images/user/coupons/shein_club-ar-247a0e5c4c.png`,
      newPersonImg: `${PUBLIC_CDN}/pwa_dist/images/user/coupons/new_person-b8a2a86b57.png`,
      tipImg: `${PUBLIC_CDN}/pwa_dist/images/user/coupons/tip-916ac58a81.png`,
      lang
    }
  },
  computed: {
    showElement() {
      return this.showBanner === null ? '' : this.showBanner ? 'showBanner' : 'showCouponList'
    },
    appCccConfig () {
      const { cateLinks = {}, content = [], pageType = '' } = this.advertise
      return {
        cateLinks,
        content,
        context: {
          WEB_CLIENT,
          SiteUID,
          lang
        },
        sceneData: {
          pageFrom: 'pageMe',
          pageType,
          sceneName: pageType
        },
      }
    },
  },
  watch: {
    source: {
      handler(value){
        if (value) {
          this.init(value)
        }
      },
      immediate: true
    }
  },
  methods: {
    init(value) {
      this.totalList = []
      const { couponListData = {}, rulesData = {}, language = {} } = value || {}
      this.userCenterLanguage = language
      this.isHitPeople = rulesData?.data?.length > 0 // 命中人群
      if(!this.isLogin) {
        if(this.isHitPeople) {
          this.showBanner = true
          this.getAdvertise()
        }
      } else {
        this.handleCouponList(couponListData)
      }
    },
    async handleCouponList (couponListData) {
      const { code, info } = couponListData
      if(code != 0) return

      if(!info?.coupon?.length) {
        return
      }

      // 新人券 56 超省卡 55 其余都是普通
      const newPersonList = []
      const sheinSaveList = []
      const normalList = []
      info.coupon.forEach(el => {
        if(el.real_type_id == 56) {
          newPersonList.push(el)
        } else if (el.real_type_id == 59) {
          sheinSaveList.push(el)
        } else {
          normalList.push(el)
        }
      })

      if(this.isHitPeople && newPersonList.length == 0) {
        this.showBanner = true
        this.getAdvertise()
        return
      }

      this.showBanner = false
      newPersonList.length && this.formateList(newPersonList, 1)
      sheinSaveList.length && this.formateList(sheinSaveList, 2)
      normalList.length && this.formateList(normalList, 3)
      // 初始化倒计时
      this.totalList.forEach((el, index) => {
        const nowTime = Date.parse(new Date()) / 1000
        const endTime = el.time
        const THREE_DAYS = 3 * 24 * 60 * 60
        if(endTime - nowTime < THREE_DAYS) { 
          this.countDownObj[index] = new CountDown()
          this.countDownObj[index].start({
            seconds: endTime - nowTime,
            dayMode: true,
            endFunc: () => {
              this.countDownFinished[index] = true
            },
          })
        }
      })
      this.totalList = this.totalList.splice(0, 3)

      daEventCenter.triggerNotice({
        daId: '1-25-2-1011',
        bindData: {
          scenes: 'show_coupons',
          coupon_id: this.totalList.map(el => el.id).toString()
        },
      })
    },
    handleClickCounpon(item, index) {
      if(this.countDownFinished[index]) {
        this.$toast(this.userCenterLanguage?.SHEIN_KEY_PWA_27183)
        return window.location.reload()
      }

      //付费会员33，超省卡2
      if ([2, 33].includes(item.counponType)) { // 付费会员和超省卡跳落地页
        const path = item.counponType === 33 ? '/user/prime' : '/user/sheinsaver'
        this.$router.push(`${langPath}${path}?productsource=myCouponMod`)
      } else {
        AddOnItem.open({
          props: {
            type: 'coupon',
            coupon: item.coupon,
            queryInfo: {
              addOnType: 2,
              sceneId: 152,
            },
            saInfo: {
              activity_from: 'me_coupon_add',
              state: 'me_coupon_add'
            }
          },
        })
      }
    },
    formateList(list, type) {
      list.some(item => {
        // 只要前三个
        if(this.totalList.length >= 3) {
          return true
        }
        let counponType = type
        if(type == 3) { // 普通类型区分 免邮券 apply_for 569 其余都是金额折扣券
          if(['5', '6', '9'].includes(item.apply_for)) {
            counponType = 31
          } else {
            counponType = item.real_type_id === '58' ? 33 : 32 //33付费会员入会券，32普通券
          }
        }
        const obj = {
          content: '',
          tip: '',
          time: '',
          counponType,
          coupon: item.coupon,
          id: item.id,
        }
        // 取最大优惠
        const maxItem = item.other_coupon_rule.reduce((prev, curr) => {
          return prev.min_order.price > curr.min_order.price ? prev : curr
        })


        obj.tip = template(maxItem.min_order.priceSymbol, counponType === 33 ? this.userCenterLanguage.SHEIN_KEY_PWA_28755 : counponType === 2 ? this.userCenterLanguage.SHEIN_KEY_PWA_28743 : this.userCenterLanguage.SHEIN_KEY_PWA_27134)
        if(counponType == 31) {
          obj.content = `${this.userCenterLanguage?.SHEIN_KEY_PWA_27139}`
        } else if(item.coupon_type_id == 1) {
          obj.content = template(maxItem.price.priceSymbol, [2, 33].includes(counponType) ? this.userCenterLanguage.SHEIN_KEY_PWA_28882 : this.userCenterLanguage?.SHEIN_KEY_PWA_27009)
        } else {
          obj.content = template(`${maxItem.price.price}%`,  [2, 33].includes(counponType) ? this.userCenterLanguage.SHEIN_KEY_PWA_28882 : this.userCenterLanguage?.SHEIN_KEY_PWA_27009)
        }

        obj.time = item.end_date
        this.totalList.push(obj)
        return false
      })
    },
    countDownTips (index, counponType) {
      const { D, H, M, S } = this.countDownObj[index]?.timeObj || {}
      if(D === undefined) {
        return ''
      }
      let str1 = D == '00' ? '' : `<div class="pd2">${D[1]}d</div>`
      let str2 = 
      `
      <div class="${ !([2, 33].includes(counponType)) ? 'white-bg' : '' } pd2">${H}</div> : <div class="${ !([2, 33].includes(counponType)) ? 'white-bg' : '' } pd2">${M}</div> : <div class="${ !([2, 33].includes(counponType)) ? 'white-bg' : '' } pd2">${S}</div>
      `
      return str1 + str2
    },
    getCardWrapClass(counponType) {
      // 1新人 2shinsaver 31免邮 32普通
      let class1 = this.totalList.length == 1 ? 'card-wrap_full' : ''
      let class2 = ''
      if (counponType == 1) {
        class2 = 'card-wrap_new-person'
      } else if (counponType == 2) {
        class2 = 'card-wrap_shein-saver'
      } else if (counponType == 31) {
        class2 = 'card-wrap_postage'
      } else if (counponType == 33) {
        class2 = 'card-wrap_shein-club'
      } else {
        class2 = 'card-wrap_normal'
      }
      return `${class1} ${class2}`
    },
    async getAdvertise() {
      const { cccBannerConfig } = this.source
      if(cccBannerConfig?.id) {
        this.advertise = cccBannerConfig
        daEventCenter.triggerNotice({
          daId: '1-25-2-1011',
          bindData: {
            scenes: 'new_user_banner',
          },
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.page-wrap{
  position: relative;
  font-family: Helvetica, Arial, sans-serif;
  background: #F6F6F6;
  padding-top: .16rem;
  &__tip{
    position: absolute;
    top: -0.2rem;
    left: 1.1rem;
    height: .2rem;
  }
}
.coupon-container{
  padding: .08rem 0 .02rem;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    display: none;
  }
}
.card {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 2px;
  background: var(--sui-color-promo-bg, #FFF6F3);
  overflow: hidden;
  &__content {
    flex: 1;
    text-align: center;
    box-sizing: border-box;
    padding: .4rem .21rem .16rem;
    min-width: 2.93rem;
  }
  &__coupon-price {
    font-size: .42rem;
    font-style: normal;
    font-weight: bolder;
    line-height: normal;
    text-transform: uppercase;
  }
  &__min-price {
    font-size: .32rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .handle {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: .18rem .42rem .18rem 0rem;
    &::before {
      content: "";
      display: block;
      width: .16rem;
      height: .16rem;
      position: absolute;
      top: -0.08rem;
      left: -0.05rem;
      background-color: #FA6338;
      border-radius: 50%;
    }
    &::after {
      content: "";
      display: block;
      width: .16rem;
      height: .16rem;
      position: absolute;
      bottom: -0.08rem;
      left: -0.05rem;
      background-color: #FA6338;
      border-radius: 50%;
    }
    &__content {
      height: 1.14rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-left: .53rem;
      border-left: .05rem dashed rgba(#FA6338, .3);
    }
    &__use {
      display: inline-block;
      padding: .05rem .32rem;
      border-radius: 16px;
      background: #FA6338;
      color: #FFFFFA;
      font-weight: 700;
    }
    &__cut-down-time {
      margin-top: .1rem;
      color: #FA6338;
      font-size: .32rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      /deep/ .white-bg {
        background-color: #FFFFFF;
      }
      /deep/ .pd2 {
        padding: .05rem;
      }
    }
  }
}
.card-wrap {
  position: relative;
  flex-shrink: 0;
  padding: .05rem;
  border-radius: .1rem;
  margin-right: .1rem;
  &:first-child {
    margin-left: .32rem;
  }
  &:last-child {
    margin-right: .32rem;
  }
  &_full {
    width: calc(100% - .64rem);
  }
  &_new-person {
    background: linear-gradient(90deg, #FEC36F 0.03%, #FD5E3E 99.91%);
    .card__coupon-price, .card__min-price, .handle__cut-down-time {
      color:#FA6338;
    }
    .handle__use {
      background: #FA6338;
    }
    .handle__content {
      border-left: .05rem dashed rgba(#FA6338, .3);
    }
    .handle {
      &::before {
        background: #FA6338;
      }
      &::after {
        background: #FA6338;
      }
    }
  }
  &_shein-saver {
    border-radius: .1067rem;
    background: linear-gradient(90deg, #F80BCB 38.98%, #700FED 81.12%);
    padding: .0533rem;
    // background: linear-gradient(90deg, #F80BCB 38.98%, #700FED 81.12%), linear-gradient(90deg, #FEC36F 0.03%, #FD5E3E 99.91%);
    .card__content {
      font-family: SF Pro;
      text-align: left;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAsCAMAAADFL88lAAAAM1BMVEUAAADqv/3MV/LWOtPPePv76vzen/7PcfrLUO3rxP3irf323fzUh/321vznMbXnsf3Ngv7PDl99AAAAEXRSTlMABxcYGQoXDxMWEQ8UEhQMC0tRGcsAAAHTSURBVEjHndXbcoMwDEXRQMA37JD//9rKsmShumlkNmkf1xzjzOTxooLK997v94I9jL2k0B6KwVnx4v3q5kTNrRiT06KATyg8nyvFYLgHPsHD1t54aPAWMyhp0gcBzQuZGkkRp848cFIGUZ/53kIBIT89cfCuYPBNvLtQvG2rXp94IiiiGWSulqkE3t0jM4etjcs++ZPEB5HzIE2sZoLOc+LMoydlAj2DMwtHz7nMopx5GtxWAUFMBELWMw83rECXEoO7URw9AaEUu7iQeA90VOoT9x29r+LgEQhPn3ik/YRnoQzg6PUSdKC4296iumHxcGIEsOiJKC5fwWFf+7gqxlRg4rFDLFrA0QMtxohgSQiqe5kCGxmRhEopdWI1Ddfy14lxn4CpgRgtXAzguG9caLlp1PAfg1DzENSi4S0iphbiIwMFvC78B6T0l5pEBdJE8pYvoJiOB4InYMGFljPr32HgMN6nwUOd2QBuOTtOebzQcs8hrPVTPcxJygPQds+By63R66AsRNMI5kQxxx5ONC/09Y/FiA9UOOSM79B7DxaWvewTTOYdF+/zmT1qAR/v0zWy2DN+D72KLWIUJwObaQCT2sgYpA48s/Ds7ZxY2vsE/gA7MzRC6fQQZAAAAABJRU5ErkJggg==);
      background-repeat: no-repeat;
      background-clip: padding-box;
      background-position: left center;
      background-size: auto 100%;
    }

    .card__content[data-s-lang="ar"] {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAsBAMAAAAA3yIkAAAAGFBMVEUAAADPaPbXONDntP7pvv701v3QcPjKZvirCtxHAAAACHRSTlMAGBcVBw4RDwEqgesAAAGeSURBVDjLhdNBboMwEAXQLnIBqJQ9LNgjRE4A9ADYzhoqhhuY6/ePp8gkHbsjkdXTn/lEfCgz8Pjj8BSmDqPB2zCM0w7XhBGYSpyOgxqidV0pC80OSM0KWmchb14hERhX6yfuCFuWIlfmxonEJ96LoijrHDTkPcH9CwfeXIFVVRGhVpoA4WDrLJzIAy6cWObg+BWgTIRaabhFumThOBGmgIy1E6UdPZB4jdRLh8AWLNA0HDZH310nrqqS0BjrqAO89lZPdJa+2+5cXZUpaEZASYx11BOt3TqBIhNwHBlGWQapnQhnA2x/ZQIai5kFxt7Kidjc97K6jb3VEzGdyLhbPXGeZ3GfsXfmxNc3qZ7IgVK7TSXCMbTdOXIiHuXEuLm9tNFOlC5CxeF53xwTX+vc1bfYQ8TMBZ/tnf6WNrZHYoT4dAijJs4XCLcU1DTqa7Q2wgctpCbKkRLJv4wajNZ6hmTdIy84LdEYwwaKrYV4pBKx+kzcAqOD5xXecONgRzj8Oxy4wRLtCgwvSGZ2DnTHSOJ7bV5+Wue8fz6fHPkDinLtjWrfhNwAAAAASUVORK5CYII=);
    }

    .card__coupon-price {
      font-size: .4267rem;
      font-weight: 900;
    }

    .card__min-price {
      font-size: .24rem;
    }
    .card__coupon-price, .card__min-price, .handle__cut-down-time {
      color: #F82854;
    }
    .card_new-person {
      background: #FFFFFF;
    }
    .handle__use {
      background: #F82854;
    }
    .handle__content {
      border-left: .05rem dashed rgba(#F82854, .3);
    }
    .handle {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAsBAMAAADcJlYTAAAAD1BMVEUAAAD4KFX5KVT6KVX8LFgBTy2nAAAABXRSTlMAFRwOBsdwc7MAAAE4SURBVDjLpdNRloMwCAXQ9JgFCHEBQ9oFhKELiFP3v6YBaxuscX6Gz94CT46Gf9UgUs9MWKScWUpJTuwhzFxCf/CimsKc+oMvDHUA6KPAGKGL9zDDFKCHUXRgCgxABxykxNXwiAOPcetD/ECWKUgJrECEUL3Z02mBinIefRRe813Q+tRvu3UMaXmAgXq++nsyPGvdR5mcyUYm1pddGAbXp5RLW/ciIiPFsdmr766RwxKXpbYoG7VfDuumDsXUbfNRxnBu5Q+rPYtPa6msdjmTvWwIeavqDM1m3KRdZlajta/Z12aX18yh2bUFxef2h1iBGrmguxfoW9EFJSrNos4ub0PE5L8cH4a0sTrEfHuHwY/GHxeUrHFyQ1sYQCDEnMTqbv92VyNFcreM7mpKkLHdMrqghFp5Z9dfXsA6QJUh54cAAAAASUVORK5CYII=);
      background-repeat:  no-repeat;
      background-position: right;
      background-size: contain;
      &::before {
        background: #700FED;
      }
      &::after {
        background: #700FED;
      }
    }

    .handle[data-s-lang="ar"] {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAsBAMAAADcJlYTAAAAD1BMVEUAAAD5KFX5KVT4KFb5LldctftlAAAABXRSTlMAFRwMBvVGETEAAAE3SURBVDjLnZJRcsMgDETtgQMgkQNUpAew6xyANL7/mSq2DZFq3I9q7B/eiF2tmP5fYdvqGVuJaPmD5RN2JzSuw4v3R2u8n1wctLH9J5opjC3d9MLLRCMYqQbK2stHGCjFxoTkAGcwzCnMHq4qtumAzNSqeoZY9FhpKclZgcpMon0K352clmajhIr2XV2eayaUsH5FnByKqc3ApRRjRs+BFXFDZTGTg6CTwJK1koFucap1ivte/WpPngVlXHoZoPjTNl455NIBdJu8jFkPf6zH1ANy4vR8uzNSQVUb5xWDlmclO3v27K1nxgy90FFfYejqj61VE5WXUWE7w4dCt9tkpuK+Qrw9yfYBvYzOhxWV/tYCjNrGT2+U+eIu7akJKyuEEW7fky7GqNgso01NOsJptKkR/2Iw8wXOuTbxzTF+5QAAAABJRU5ErkJggg==);
    }
  }

  &_shein-club {
    padding: .0533rem;
    border-radius: .1067rem;
    background: linear-gradient(90deg, #C96E3F 0%, #EAA27E 100%);

    .card__content {
      font-family: SF Pro;
      text-align: left;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAsCAMAAADFL88lAAAANlBMVEX/h4L/rFr/ulv/n2H/k3D/qVr/rFf/kmoAAAD/eJz/lmz/i3//oVr/d5j/bqz/qVf/gI7/Yr/kmBrgAAAAEnRSTlMwCwUUJRkQLAApHysiGSEeHxM7pYLqAAAB/0lEQVRIx53UUa7CIBRF0aq8akFtnf9kX48X2KkXMPEYv0xWNjR1eu5b8ia3a1nYd9fWdf2zXfbN2qnufD4LhBuAiIDz5e3pY14B8bwoE5BC5VkhfQYOPVmTO3IRCfQghhcrKLIEvhNdoMBvnpn+CksgfYDyRlfoj8yJ8TK4jMGOR2DZGMSbHHh4xjySHwvXjxMTCDj2psF7QiAe4PfA0L9CvNv02xXWE88jcBwIqGUwDw+wHWiJ4SowtK7wwxM4EAnsvMj0ObDnEahVT/NXeAP8Hhi6gfIAh+8ygZw4+meCNwSvFcxeHBSOQQKTOBXKixHPgcX7Dko08B7X6EA8B/oTpx1MChQYBfJv7cEbYC8x7Qu2e9CBVRgJ7BVqbS8HCoza4cTdwucAVCFeCfwFxLOHYl7u4wrnNqh1wMyFfOLIFfbv0MROoM28Ejh8JmMQjsCLL/Sg1g3kwBQ2/xoAScTrBwJCtsDU8FL27vLIM69yBnJkTWBq94mrXr1BB1L4MDEtQionUB/Oq+P6QI1CQImH1UDuj8AZsFWYE5dPLYkj8NjnHwngW9xCWELhsrbpi8cFGsiO4OuxTyKTJg9OUvcGa2MFJW4bWOH2Rf0opOR5L4/C1yuLmxRZ+rynH+TJgnOBZQXMpBmi0Mxrc6wJQqLBOc9xgKdXIf0cBtcF/wHe4EOCu5x4/AAAAABJRU5ErkJggg==);
      background-repeat: no-repeat;
      background-clip: padding-box;
      background-position: left center;
      background-size: auto 100%;
    }

    .card__content[data-s-lang="ar"] {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAsCAMAAADFL88lAAAAPFBMVEX/hoP/mlT/j3T/sVn/o17/qFv/oGEAAAD/eJz/mWb/rF3/lWn/vlT/i3//l2X/e5b/hYj/baz/b6v/Xr+hOP8mAAAAFHRSTlMwBiwNGREfACkkChYUJikaIh8lEqAq8zoAAAITSURBVEjHldTReoIwDIZhnA4prAJ6//e6po1+1DSr+/GA7eB9/iRzw2mWnCXfOT8ll5xREkK4pXwdM1SZcu73fd+Hk4qQmVNROBFfHhhaegYVAelIxbph08NUMYGmYuHchoOTAt4FbIksUbyOSEkBa9EsUcV+RUBJY4v/mpmKClrxQsO/QWLBnPrOmWNmQKcioN2it0TXK+D1XaQi3sdXEfDqzHwBHG8CUtELoBFbX+f+nS04C8cWq6sEQE9U8Npf4pg4KnYaVmJ7iUFn7omAKrLEH2ZuHbrNWTCFL8tyAMPHM1uQv+2ljEzDjkjDTsXXUQAdEbC5RAG1ImcBtB4gYtVwWVhiELAv3gFLMqiigiP/tUNEdCZuNEQUkIqZBOw0pOK5PXNfnFrgfDofG5qKMYrniYB4AqoIODJzvCUT0Ho7DakI+DZzDDHFqwh4bNib+Si+cYBVwZMHWrFV0DQ8m5m7FWmYPKehXzEWceqDGs7sVRSPod89BZmZM/uivfTUAImpaKdmaBHlZYriAdqCTVHIjY5kSr/J3jo4/Rjaltw2JafnBy+BiHN5qGg6rgnccuKxprxuG2DxqPgkTcd1HSHFJMoB0lCsWTgxIbVijoqQ6V29R6PhgfxGlBRxFDKjJGm7cA9AKiZx5jKYii5SpDbTT+IZkLyW+CQX+TzNdSk1yZg18QDrihpdJS1JQvSBk/wC9/1L94UxWH0AAAAASUVORK5CYII=);
    }

    .card__coupon-price {
      font-size: .4267rem;
      font-weight: 900;
    }

    .card__min-price {
      font-size: .24rem;
    }

    .card__coupon-price ,.handle__cut-down-time,.card__min-price {
      color: #873C00;
    }

    .handle__use {
      background: #873C00;
    }

    .handle__content {
      border-left: .05rem dashed rgba(#873C00, .3);
    }

    .handle {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAsAgMAAABTZqOzAAAADFBMVEUAAADKbj/Kb0DKcEEBk0dnAAAABHRSTlMAGBII14lsswAAARlJREFUKM+1kTFOw1AMhk3f1EpNzwE7SHAKFkd9sLAkCKlDj9BLvCCDxJZKmIUj5BI9QhkyMXdJ/8Z+Ui9QL09ffvv/bYUuXIthOCiqM3xQ3dY189rwBsioF8NetY2S+NFQ9bupmB0DkFkyTmAKp4wF1MQNMOdoFFMt5+NstofT2Sx63540OQbgtqMCmHOWeG8NZ8g5LT8Hes7G0e8hGnJzDxXSamcIeodUmnMY8Y7ZsAC+QnWcjeo8I3LakkJyRE5ddTR1RI7wL9HeUNEsK6wO9Hu4wfddVllqyFOfbSvm8p+u3FmiCD9T8Nw2MWpNvhXUBv/LcKJS2YZ+UWL5yogkGG32eZb+oNLix53pOsoSj+fSfeRPonGrI57FpJM1LAOPAAAAAElFTkSuQmCC);
      background-repeat:  no-repeat;
      background-position: right;
      background-size: contain;
      &::before {
        background: #e0936a;
      }
      &::after {
        background: #e0936a;
      }
    }

    .handle[data-s-lang="ar"] {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAsBAMAAADcJlYTAAAAD1BMVEUAAADKbj/KbkDKbkLIcj4m/Fg+AAAABXRSTlMAGxQNCOUpK/wAAAEZSURBVDjL1ZLhdcMwCISbugMYkgGEmgFkewHXzf4zBYExSE4GyP3xs753wPH4+hT9LKJ5yaLfyLYcRRjZ8SoCSIEZkQ8Q3N74MgDgmZHY2AelZ3T0g7FjpD4E1vWlr47Cahmpb2ep90lBhKpbyzDz1ghU2LK7bHZW5inigidlY2Sr/RA0KcwmVUEVWKrPDzb/7Sw5K4x41DIo8RTabuN4OIGpYTOHB1dpfRHBGPsNmSJDY1TH2hCilNnGhsaYdoZZgxv0FIzwbut0Wb86jYg6RuTw2we1WZChlu0CUsZ6MIBr7FjMVw9My1zafEjs1PvyhtdwgwJHY75PREoTtb5wL3UnMszFE2g+uYn/ZT0fWrUdOt0Lroa6dk89xkOSJmZ1MgAAAABJRU5ErkJggg==);
    }
  }

  &_postage {
    background: #198055;
    .card__coupon-price, .card__min-price, .handle__cut-down-time {
      color:#198055;
    }
    .card_new-person {
      background: #FFFFFF;
    }
    .handle__use {
      background: #198055;
    }
    .handle__content {
      border-left: .05rem dashed rgba(#198055, .3);
    }
    .handle {
      &::before {
        background: #198055;
      }
      &::after {
        background: #198055;
      }
    }
  }
  &_normal {
    background: #FA6338;
    .card__coupon-price,.card__min-price, .handle__cut-down-time {
      color:#FA6338;
    }
    .handle__use {
      background: #FA6338;
    }
    .handle__content {
      border-left: .05rem dashed rgba(#FA6338, .3);
    }
    .handle {
      &::before {
        background: #FA6338;
      }
      &::after {
        background: #FA6338;
      }
    }
  }

  &__logo_shein-saver,&__logo_shein-club {
    position: absolute;
    top: -0.1rem;
    left: 0;
    height: .64rem;
    background-size: contain;
  }
  &__logo_new-person {
    position: absolute;
    top: -0.1rem;
    left: 0;
    display: flex;
  }
  &__logo-title {
    padding-left: .16rem;
    padding-right: .05rem;
    border-radius: 6px 0px 0px 0px;
    background: linear-gradient(90deg, #FEC36F 0.03%, #FD5E3E 99.91%), #FA6338;
    color: #FFFFFA;
  }
}
.card {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 2px;
  background: var(--sui-color-promo-bg, #FFF6F3);
  overflow: hidden;
  &__content {
    flex: 1;
    text-align: center;
    box-sizing: border-box;
    padding: .4rem .21rem .16rem;
    min-width: 2.93rem;
  }
  &__coupon-price {
    font-size: .42rem;
    font-style: normal;
    font-weight: bolder;
    line-height: normal;
    text-transform: uppercase;
  }
  &__min-price {
    font-size: .32rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__handle {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: .18rem .42rem .18rem 0rem;
    &::before {
      content: "";
      display: block;
      width: .16rem;
      height: .16rem;
      position: absolute;
      top: -0.08rem;
      left: -0.05rem;
      background-color: #FA6338;
      border-radius: 50%;
    }
    &::after {
      content: "";
      display: block;
      width: .16rem;
      height: .16rem;
      position: absolute;
      bottom: -0.08rem;
      left: -0.05rem;
      background-color: #FA6338;
      border-radius: 50%;
    }
  }
}
</style>
