<script lang="jsx">
import ImgWraper from './ImgWraper.vue'
import { template, CountDown } from '@shein/common-function'
const { langPath, SiteUID, shein_suggested_sale_price } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  name: 'UnpaidOrder',
  components: { ImgWraper },
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },
    userCenterLanguage: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      countTimer: new CountDown(),
    }
  },
  computed: {
    hideSpecialPrice() {
      return !!shein_suggested_sale_price[SiteUID]
    },
    totalDesc () {
      const { orderGoodsList = [], currency_pay_amount = {}, total_discount_new = {} } = this.item
      let length = 0
      orderGoodsList.forEach(val => {
        if (!(val.is_prime_goods || val.is_xtra_goods)) {
          length += Number(val.product.quantity)
        }
      })
      return `${template(
        length,
        this.userCenterLanguage.SHEIN_KEY_PWA_26688
      )}: ${
        (+total_discount_new?.amount > 0 && !this.hideSpecialPrice)
          ? `<span>${currency_pay_amount?.amountWithSymbol || ''}</span>`
          : `<strong>${currency_pay_amount?.amountWithSymbol || ''}</strong>`
      }`
    },
    wonderfulComments () {
      return this.item?.facadeReviewInfo?.products || []
    },
    reviewsDesc () {
      let num = 0
      this.wonderfulComments?.forEach(item => {
        num += item.count
      })
      if (!this.configs?.comment_num || !num || (num < this.configs?.comment_num)) return ''
      return template(
        this.countHandle(num),
        this.userCenterLanguage.SHEIN_KEY_PWA_26687
      )
    },
    countDownTips () {
      const { H, M, S } = this.countTimer?.timeObj || {}
      return `${H} : ${M} : ${S}`
    },
    hideSpecialPriceCountDownTips () {
      const { H, M, S } = this.countTimer?.timeObj || {}
      return `
        <span>${H}</span> : <span>${M}</span> : <span>${S}</span>
      `
    }
  },
  mounted () {
    /* 未支付订单倒计时 */
    let { expire_countdown } = this.item
    const endTime = Number(expire_countdown)
    const nowTime = Date.parse(new Date()) / 1000
    this.countTimer.start({
      seconds: endTime - nowTime,
      endFunc: () => {
        // 倒计时结束
        this.$emit('countdownEnd')
      }
    })
  },
  methods: {
    payNowHandle () {
      daEventCenter.triggerNotice({
        daId: '1-25-2-1003',
        extraData: {
          order_id: this.item.billno,
          scene: 'order_floor'
        }
      })
      this.$router.push(`${langPath}/user/orders/detail/${this.item.billno}?goBack=1${this.item.payment_method == 'cod' ? '' : '&show_error_guide_payment=1'}&from_type=2`)
    },
    countHandle (length) {
      let commentDesc = ''
      if (length >= 0 && length <= 100) {
        commentDesc = length
      } else if (length > 100 && length <= 500) {
        commentDesc = '100+'
      } else if (length > 500 && length <= 1000) {
        commentDesc = '500+'
      } else {
        commentDesc = '1000+'
      }
      return commentDesc
    },
  },
  render () {
    const reviewsDescEl = () => {
      if(this.hideSpecialPrice) {
        return (
          <p
            class="item-left__cutdown-wrap"
          >
            <span
              class="item-left__cutdown-pre"
            >{this.userCenterLanguage.SHEIN_KEY_PWA_31357}</span>
            <span
              class="item-left__cutdown-content"
              domPropsInnerHTML={this.hideSpecialPriceCountDownTips}></span>
          </p> 
        )
      }
      if (this.reviewsDesc) {
        return (
          <p
            class="item-left__info-ellipsis item-left__info-review"
            domPropsInnerHTML={this.reviewsDesc}
          >
          </p>
        )
      }
    }

    return (
      <div class="unpaid-order__item">
        <div class="unpaid-order__item-left">
          <img-wraper orderGoodsList={this.item.orderGoodsList} />
          <div class="item-left__info">
            <p
              class={`item-left__info-total
                ${this.hideSpecialPrice ? 'item-left__info-ellipsis-normal' : ' item-left__info-ellipsis'} 
              `}
              domPropsInnerHTML={this.totalDesc}
            >
            </p>
            {reviewsDescEl()}
          </div>
        </div>

        <div class="unpaid-order__item-right">
          <s-button
            type={['primary', 'H48PX']}
            max-width={`${this.hideSpecialPrice ? '1.97' : ''}`}
            vOn:click_stop={this.payNowHandle}
          >
            {this.userCenterLanguage.SHEIN_KEY_PWA_26689}
          </s-button>
          {!this.hideSpecialPrice && <div class="unpaid-order__count-down">
            <div class="unpaid-order__count-down-info">
              <i class="suiiconfont sui_icon_hourglass_12px_2"></i>
              <span>{this.countDownTips}</span>
            </div>
          </div>}
        </div>
      </div>
    )
  }
}
</script>

<style lang="less">
.unpaid-order {
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item-left {
    flex: 1;
    display: flex;
    align-items: center;
    .item-left__info {
      flex: 1;
    }
    .item-left__info-ellipsis {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .item-left__info-ellipsis-normal {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .item-left__cutdown-wrap {
      display: flex;
    }
    .item-left__cutdown-pre {
      max-width: 3.6rem;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(---sui_color_gray_dark3, #767676);
      font-size: 12px;
    }
    .item-left__cutdown-content {
      display: flex;
      align-items: center;
      color: #000;
      font-weight: 700;
      font-size: 12px;
      line-height: .3733rem;
      span {
        display: flex;
        width: .427rem;
        height: .427rem;
        justify-content: center;
        align-items: center;
        background-color: #000;
        color: #FFFFFF;
        margin: .0533rem;
        &:first-child {
          margin-left: .1067rem;
        }
      }
    }
    .item-left__info-total {
      font-size: 14px;
      font-weight: 600;
      strong {
        font-weight: 700;
      }
    }
    .item-left__info-review {
      font-size: 12px;
      font-weight: 400;
    }
    span {
      color: @sui_color_discount;
    }
  }
  &__item-right {
    margin-left: .32rem;
    position: relative;
  }
  &__count-down {
    padding: 8/75rem;
    text-wrap: nowrap;
    text-align: center;
    position: absolute;
    top: 0.82rem;
    background: #fa6338;
    .font-dpr(20px);
    border-radius: 2px;
    .right(50%);
    .margin-r(-0.9rem);
    color: #fff;
    z-index: 3;
    &::before {
      position: absolute;
      content: '';
      width: 16/75rem;
      height: 16/75rem;
      background: #fa6338;
      transform: rotate(45deg);
      z-index: 2;
      top: -8/75rem;
      .left(50%);
      .margin-l(-8/75rem);
    }
  }
  &__count-down-info{
    i{
      .font-dpr(20px);
    }
    line-height: normal;
    .font-dpr(20px);
    color: #FFFFFF;
    background: @sui_color_promo;
    border-radius: 2px;
  }
}
</style>
