var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-25-11-25',
    data: {
      my_assets_list: _setup.reportAssetsList,
    }
  }),expression:"{\n    id: '1-25-11-25',\n    data: {\n      my_assets_list: reportAssetsList,\n    }\n  }"}],staticClass:"me-list"},_vm._l((_setup.assets),function(item){return _c('div',{directives:[{name:"enterkey",rawName:"v-enterkey"}],key:item.key,staticClass:"me-li waves-btn waves-btn-auto waves-effect waves-classic",attrs:{"data-sa-param":item.id == 'points' ? JSON.stringify({ points: _setup.props.personalInfo?.point }) : '',"tabindex":"0","role":"button"},on:{"click":() => _setup.emits('router-push', { target: item.router, orderStatusType: '', scence: item.scence })}},[(item.isShowValue)?_c('i',{class:[
        'me-li-icon',
        _setup.GB_cssRight ? 'assets-wallet-ar' : '',
        item.id == 'wallet' && item.val ? 'assets-wallet' : 'assets-val',
      ],domProps:{"innerHTML":_vm._s(item?.val)}}):_c(_setup.Icon,{attrs:{"name":item.icon,"size":"24px"}}),_vm._v(" "),_c('p',{staticClass:"me-li__title"},[_vm._v("\n      "+_vm._s(_setup.htmlDecode({ text: item.title }))+"\n    ")])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }