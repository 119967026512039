var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"new-user-benefits__coupon-list"},_vm._l((_vm.coupons),function(coupon,index){return _c(_setup.CouponItem,{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-25-2-26',
      data: {
        module_nm: 'new_user_coupon',
        coupon_code: coupon.couponData?.couponCode || '',
        item_loc: index + 1,
        content_list: _vm.cccxConfig?.jumpUrl || '',
      },
    }),expression:"{\n      id: '1-25-2-26',\n      data: {\n        module_nm: 'new_user_coupon',\n        coupon_code: coupon.couponData?.couponCode || '',\n        item_loc: index + 1,\n        content_list: cccxConfig?.jumpUrl || '',\n      },\n    }"}],key:index,attrs:{"index":index,"language":_vm.language,"coupon-data":coupon.couponData,"coupon-style":coupon.couponStyle,"cccx-config":_vm.cccxConfig}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }