<template>
  <AlertTips
    :tips-content="tipsContent"
    :arrow-position="2"
    @on-click="onTipsClick"
    @on-close="onTipsClose"
  />
</template>

<script setup name="ExpirePointTips">
import { computed, onMounted } from 'vue'
import {
  htmlDecode,
  setLocalStorage
} from '@shein/common-function'
import { useMapMutation } from 'public/src/pages/common/vuex-composition'
import AlertTips from './AlertTips.vue'
import { triggerLowPriorityTask } from 'public/src/pages/user/child_pages/dashboard/service/utils'

const props = defineProps({
  isLogin: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  couponOrPointsData: {
    type: Object,
    default: () => ({}),
  },
  routerPush: {
    type: Function,
    default: () => {},
  },
})

const emits = defineEmits([
  'hide-module',
])

const tipsContent = computed(() => htmlDecode({
  text: props.language?.SHEIN_KEY_PWA_18552,
}).replace(
  '{1}',
  props.couponOrPointsData?.pointsNum
))

onMounted(() => {
  reportModuleExpose()
})

const onTipsClick = () => {
  setNextShowWhenLogin()
  props.routerPush?.('/user/points')
}

const onTipsClose = () => {
  reportTipsClose()
  setNextShowWhenLogin()
}

const {
  changeCouponOrPointsData,
} = useMapMutation('root', ['changeCouponOrPointsData'])

const setNextShowWhenLogin = () => {
  if (!props.isLogin) return
  const { member_id } = gbCommonInfo.user
  setLocalStorage({
    key: `${member_id}_pointsHit`,
    value: 1,
    expire: 3600 * 24 * 7 * 1000,
  })
  changeCouponOrPointsData({ isClickPoints: true })
  emits('hide-module')
}

const reportModuleExpose = () => triggerLowPriorityTask(() => {
  daEventCenter.triggerNotice({
    daId: '1-25-2-11',
    target: {
      reminder_type: 1,
    },
  })
})

const reportTipsClose = () => daEventCenter.triggerNotice({
  daId: '1-25-2-9',
  target: {
    reminder_type: 1,
    operation: 1
  }
})
</script>
