var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fusion-super-save__panel",on:{"click":function($event){$event.stopPropagation();return _setup.onSuperSavePanelClick.apply(null, arguments)}}},[_c('header',{staticClass:"fusion-super-save__header"},[_c('i',{staticClass:"fusion-super-save__logo"}),_vm._v(" "),_c('div',{staticClass:"fusion-super-save__btn-wrapper"},[(_setup.marketingInfo?.labelText)?_c('div',{staticClass:"fusion-super-save__tooltip"},[_vm._v("\n        "+_vm._s(_setup.marketingInfo.labelText)+"\n      ")]):_vm._e(),_vm._v(" "),(_setup.marketingInfo?.btnText)?_c('a',{staticClass:"fusion-super-save__btn",attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(_setup.marketingInfo.btnText))]):_vm._e()])]),_vm._v(" "),_c('main',{staticClass:"fusion-super-save__main"},[(_setup.showCoupons?.length)?_c('div',{staticClass:"fusion-super-save__coupon-list"},_vm._l((_setup.showCoupons),function(coupon){return _c('div',{key:coupon.couponCode,staticClass:"fusion-super-save__coupon",class:[
          `fusion-super-save__coupon-${_vm.computedStyles.couponWidthRem}`,
          coupon.hasMoreCoupons ? 'fusion-super-save__more-coupons' : '',
        ]},[_c('div',{staticClass:"fusion-super-save__coupon-content"},[(coupon.count > 1)?_c('span',{staticClass:"fusion-super-save__coupon-badge"},[_vm._v(_vm._s(_setup.GB_cssRight ? `${coupon.count} x` : `x ${coupon.count}`))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"fusion-super-save__coupon-discount-wrapper"},[_c(_setup.SAdapterText,{staticClass:"fusion-super-save__coupon-discount",class:{
                'free-shipping-coupon': coupon.isFreeShippingCoupon,
                'no-countdown-style': !_setup.showCouponCountdown(coupon),
              },attrs:{"min-size":12,"lines":_setup.showCouponCountdown(coupon) ? 1 : 2,"text":coupon.discountText}})],1),_vm._v(" "),(_setup.showCouponCountdown(coupon))?_c(_setup.CouponCountdown,{staticClass:"fusion-super-save__coupon-countdown",attrs:{"seconds":_setup.getCountDownSeconds(coupon)}}):_vm._e()],1)])}),0):(_setup.savedTextHtml)?_c('div',{staticClass:"fusion-super-save__coupon-list"},[_c('div',{staticClass:"fusion-super-save__coupon-content fusion-super-save__saved-text"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.savedTextHtml)}})])]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"fusion-super-save__desc",domProps:{"innerHTML":_vm._s(_setup.titleHtml)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }