<template>
  <CommonWrapper
    :cccx-config="cccxConfig"
  >
    <div
      class="new-user-benefits__deliver-goods"
      @click.stop="onModuleClick"
    >
      <div
        v-for="(product, index) in products"
        :key="product.goods_id"
        class="new-user-benefits__deliver-goods-item"
        :style="{
          backgroundImage: `url(${product.image_url})`
        }"
        v-expose="{
          id: '1-25-2-28',
          code: 'new-user-benefits-expose-goods',
          data: {
            product,
            cccxConfig,
          },
        }"
        @click.stop="onGoodsItemClick(product, index)"
      >
        <div
          v-if="product.unit_discount > 0"
          class="new-user-benefits__deliver-goods-discount"
        >
          -{{ product.unit_discount }}%
        </div>
        <div
          class="new-user-benefits__deliver-goods-price"
        >
          <CamelCasePrice
            class="new-user-benefits__goods-price-camelcase"
            v-bind="getCamelCasePrice(product)"
            :show-camel-price="true"
          />
        </div>
      </div>
    </div>
  </CommonWrapper>
</template>

<script setup name="DeliverGoods">
import CommonWrapper from './CommonWrapper.vue'
import CamelCasePrice from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/CamelCasePrice.vue'
import { handlePriceHtml } from 'public/src/pages/components/product/item_v3/js/utils'
import { useCccxConfig } from 'public/src/pages/user/child_pages/dashboard/hooks/new-user-benefits'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  cccxConfig: {
    type: Object,
    default: {},
  },
  products: {
    type: Array,
    default: () => [],
  },
})

const getCamelCasePrice = product => {
  const { amountWithSymbol, amount } = product.sale_price
  return handlePriceHtml(amountWithSymbol, amount)
}

const onModuleClick = () => {
  const { cccxConfig } = props
  const { jumpUrl } = useCccxConfig(cccxConfig)
  setTimeout(() => {
    location.href = jumpUrl
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-2-27',
    bindData: {
      module_nm: 'new_user_fd',
      goods_id_list: '',
      content_list: cccxConfig?.jumpUrl || '',
    },
  })
}

const onGoodsItemClick = (product, index) => {
  const { cccxConfig } = props
  const { jumpUrl } = useCccxConfig(cccxConfig)
  setTimeout(() => {
    const goodsIds = props.products.map(product => product.goods_id)
    goodsIds.splice(index, 1)
    goodsIds.unshift(product.goods_id)
    location.href = `${jumpUrl}${jumpUrl.indexOf('?') > -1 ? '&' : '?'}top_goods_id=${goodsIds.join(',')}`
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-2-27',
    bindData: {
      module_nm: 'new_user_fd',
      goods_id_list: product.goods_id,
      content_list: cccxConfig?.jumpUrl || '',
    },
  })
}
</script>

<style lang="less" scoped>
.new-user-benefits {
  &__deliver-goods {
    display: flex;
    align-items: center;
    height: 114/37.5rem;
    padding: 33/37.5rem 8/37.5rem 0;
  }
  &__deliver-goods-item {
    position: relative;
    width: 64/37.5rem;
    height: 77/37.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 2/37.5rem;
    overflow: hidden;
    &:not(:first-child) {
      margin-left: 4/37.5rem;
    }
  }
  &__deliver-goods-discount {
    position: absolute;
    top: 0;
    left: 0 /* rtl: unset */;
    right: unset /* rtl: 0 */;
    background-color: #FA6338;
    color: #fff;
    font-size: 9px;
    line-height: 1.2222;
    padding: 1/37.5rem 2/37.5rem 0;
    font-family: 'SF-UI-Text-Regular';
    direction: ltr /* rtl:ltr */;
  }
  &__deliver-goods-price {
    position: absolute;
    bottom: 4/37.5rem;
    left: 50%;
    transform: translateX(-50%);
    height: 13/37.5rem;
    padding: 1/37.5rem 4/37.5rem;
    font-family: 'SF-UI-Text-Regular';
    border-radius: 6.5/37.5rem;
    background-color: rgba(255, 255, 255, 0.80);
  }
  &__goods-price-camelcase {
    line-height: 11/37.5rem;
    font-family: 'SF-UI-Text-Bold';
    font-weight: 700;
    color: #FA6338;
    font-size: 8px;
    direction: ltr /* rtl:ltr */;
    /deep/ .product-card__camel-case-price__number-before-dot {
      font-size: 11px;
    }
  }
}
</style>
