import { SIMetric, SILogger } from 'public/src/pages/common/monitor/index'

const reportMetric = ({ metricName, page, msg, ...arg }) => {
  
  if (typeof location === 'undefined') return 

  const params = {
    metric_name: metricName,
    tags: { 
      page: page || location?.pathname,
      ...arg
    },
  }

  // console.log("🚀 ~ reportMetric ~ params:", params)

  try {
    // 错误信息收集
    if (msg) {
      const message = {
        msg: `page=${location?.pathname}, metric_name=${metricName} Failed`,
        info: msg
      }
      params.message = JSON.stringify(message)
    }

    // 监控上报
    SIMetric.metricCount(params)
  } catch (error) {
    // 上报错误日志
    SILogger.logInfo({
      tag: 'STORE_MONITOR_ERROR_TAG',
      message: error,
      params: {
        page,
        metricName,
        message: error
      }
    })
  }
}

// 页面核心接口是否正常响应
const metricPageSuccess = (params) => {
  reportMetric({ metricName: 'page_success_total', ...params })
}

// 加车弹窗弹起监控
const metricAddCart = (params) => {
  reportMetric({ metricName: 'click_to_addcart_total', ...params })
}

// 跳转商详情
const metricClickToDetail = (params) => {
  reportMetric({ metricName: 'click_to_detail_total', ...params })
}

// CCC组件点击
const metricCccClick = (params) => {
  reportMetric({ metricName: 'ccc_block_main_click_total', ...params })
}

// 图片加载成功率
const metricImageRequest = (params) => {
  reportMetric({ metricName: 'image_request_total', ...params })
}

// 悬浮购物车
const metricSuspensionAddCart = (params) => {
  reportMetric({ metricName: 'suspension_cart_click_total', ...params })
}

// 趋势频道趋势词是否满足十个
const metricTrendWordsTotal = (params) => {
  reportMetric({ metricName: 'trend_words_total', ...params })
}

export default {
  metricPageSuccess,
  metricAddCart,
  metricClickToDetail,
  metricCccClick,
  metricImageRequest,
  metricSuspensionAddCart,
  metricTrendWordsTotal,
}
