import { timeTransformer } from '@shein/common-function'
// 各站点时间格式：https://doc.weixin.qq.com/sheet/e3_AD8AJwZwACswwyvh3opSpu9RSQabh?scode=APMA2QcuAAk1TY3LbsAG4AdQZpACs&tab=deivy6

export const handlerDate = (date, code) => {
  return timeTransformer({
    time: date * 1000,
    defaultCode: code // 'E-1': 'MMM DD YYYY HH:mm:ss'
  })
}

const noop = () => {}
export function _useCountDown(
  options = {
    startTime: '',
    endTime: '',
    interval: 1000,
    onCompleted: null,
    countDown: 0
  },
  cb = noop
) {
  let {
    startTime,
    endTime,
    interval = 1000,
    countDown,
    onCompleted = noop
  } = options
  if (!startTime || !endTime || endTime - startTime < 1) {
    cb({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      seconds_transform: '00',
      days_transform: '00',
      hours_transform: '00',
      minutes_transform: '00'
    })
    if (typeof onCompleted === 'function') onCompleted()
    return
  }
  startTime = Number(startTime) || 0
  endTime = Number(endTime) || 0
  let timer = null
  let countDownTime = countDown * 1000 || (endTime - startTime) * 1000

  const parseMs = milliseconds => {
    const timeObje = {
      days: Math.floor(milliseconds / 86400000),
      hours: Math.floor(milliseconds / 3600000) % 24,
      minutes: Math.floor(milliseconds / 60000) % 60,
      seconds: Math.floor(milliseconds / 1000) % 60
    }
    if (+timeObje.days > 0) {
      timeObje.hours += timeObje.days * 24
    }
    const results = {}
    Object.entries(timeObje).forEach(item => {
      if (item[1] < 10) {
        results[`${item[0]}_transform`] = `0${item[1]}`
      } else {
        results[`${item[0]}_transform`] = item[1]
      }
    })

    return {
      ...timeObje,
      ...results
    }
  }

  const clear = () => {
    countDownTime = 0
    clearInterval(timer)
  }

  if (countDownTime > 0) {
    cb({
      ...parseMs(countDownTime),
      clear
    })

    !timer &&
      (timer = setInterval(() => {
        countDownTime -= interval
        if (countDownTime <= 0) {
          clear()
          if (typeof onCompleted === 'function') onCompleted()
        }
        cb({
          ...parseMs(countDownTime),
          clear
        })
      }, interval))
  }
}

export const timestampToDatetime = timestamp => {
  const date = new Date(timestamp * 1000) // 注意：JavaScript中的时间戳是以毫秒为单位的，所以需要乘以1000转换为秒
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // 月份从0开始，所以要加1
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')

  const formattedDatetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return {
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    formattedDatetime
  }
}
