<template>
  <div>
    <SSticky
      class="sticky-index"
      type="sticky"
      :offset="0"
      :fixed-work="onTabFixChanged"
    >
      <STab
        ref="footerSectionTabRef"
        class="my-wishlist-tab"
        type="equally"
        :value="goodsTabActive"
        @input="switchGoodsTab"
      >
        <STabItem
          id="save"
          v-enterkey
        >
          {{ language.SHEIN_KEY_PWA_15733 }}
        </STabItem>
        <STabItem
          id="recent"
          v-enterkey
        >
          {{ language.SHEIN_KEY_PWA_15010 }}
        </STabItem>
      </STab>
    </SSticky>
    <div
      ref="listEleRef"
      :class="listClasses"
    >
      <WishProducts
        v-show="goodsTabActive === 'save'"
        :show-water-fall-style="showWaterFallStyle"
        class="j-da-event-box"
        code="wishlistUser"
        data-title="wishlist"
        data-active-from="wishlist"
        da-expose-code="userWishlistProductContainer"
        da-sa-name="saved"
        :show="goodsTabActive === 'save'"
        :is-login="isLogin"
        :footer-rcmd-list-resolve-status="footerRcmdListResolveStatus"
      />

      <RecentView
        v-show="goodsTabActive === 'recent'"
        :show-water-fall-style="showWaterFallStyle"
        class="j-da-event-box"
        code="recentlyViewed"
        data-title="Recently Viewed"
        data-active-from="recently_viewed"
        da-expose-code="userRecentlyViewedProductContainer"
        da-sa-name="recently_viewed"
        :show="goodsTabActive === 'recent'"
        :is-login="isLogin"
        :footer-rcmd-list-resolve-status="footerRcmdListResolveStatus"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { Tab as STab, TabItem as STabItem, Sticky as SSticky } from '@shein/sui-mobile'
import { FooterRcmdListResolveStatus } from 'public/src/pages/user_index/service/consts'
import WishProducts from 'public/src/pages/user_index/components/wishlist_v2/index.vue'
import RecentView from 'public/src/pages/user_index/components/recent_view/index.vue'

const props = defineProps({
  isLogin: {
    type: Boolean,
    default: false,
  },
  abtInfo: {
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  footerRcmdListResolveStatus: {
    type: Number,
    default: -1,
  },
})

onBeforeMount(() => {
  initWishlist()
})

const tabFixed = ref(false)
const listEleRef = ref(null)
const goodsTabActive = ref('save')
const showWaterFallStyle = ref(false)
const listClasses = computed(() => {
  const classes = ['index-me-list']
  if (props.footerRcmdListResolveStatus === FooterRcmdListResolveStatus.HAS_RCMD_LIST) {
    classes.push('followed-by-rcmdlist')
  }
  return classes
})

const onTabFixChanged = (fixed) => tabFixed.value = fixed
const initWishlist = () => {
  showWaterFallStyle.value = props.abtInfo?.client_abt?.WishLoadmore?.['abt-params-json']?.WishLoadmore === 'type=waterfall'
}
const switchGoodsTab = (tabVal) => {
  goodsTabActive.value = tabVal
  changeTitleFixed()
  Vue.nextTick(() => {
    $(window).scroll()
  })

  const isRecent = tabVal == 'recent'
  sa('send', {
    activity_name: isRecent ? 'click_recent_view_tab' : 'click_wishlist_tab',
    activity_param: ''
  })
}
const changeTitleFixed = () => {
  if (!tabFixed.value) return
  const listEl = listEleRef.value
  if (!listEl) return
  const [paddingTop] = getStyles(listEl, ['paddingTop'])
  $(window).scrollTop(listEl.offsetTop - paddingTop * 3)
}
const getStyles = (el, options) => {
  const styles = window.getComputedStyle(el, null)
  return options.reduce((arr, styleKey) => (arr.push(styles[styleKey] && Number(styles[styleKey].replace('px', ''))), arr), [])
}
</script>

<style lang="less">
.my-wishlist-tab {
  border-bottom: none;
}
.sticky-index {
  z-index: @zindex-header;
}

.index-me-list {
  background: #fff;
  padding: 0.32rem 0 0.64rem;
  min-height: 370/37.5rem;
  &.followed-by-rcmdlist {
    display: flex;
    flex-direction: column;
    padding-top: 0.32rem;
    padding-bottom: 0;
    border-bottom: .26666667rem solid #f6f6f6;
    .wishlist-center-page {
      min-height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      .wishlist-center-empty {
        padding: 40/37.5rem 0 80/37.5rem;
      }
      .wish-list-comp {
        flex: 1;
      }
      .wishlist-center-page__view {
        height: 40/37.5rem;
        font-weight: normal;
      }
      .wish-list-comp__list {
        background-color: #fff !important;
      }
    }
    .recently-page {
      min-height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      .wish-list-comp {
        flex: 1;
      }
      &__empty {
        padding: 40/37.5rem 0 80/37.5rem;
      }
      .wish-list-comp__list {
        background-color: #fff !important;
      }
    }
  }

  .goodsl-wrap {
    background: #fff;
    min-height: 12rem;
    padding: 0 0.32rem;
    .flexbox();
    flex-flow: row wrap;
    font-size: 0;

    .c-goodsli .left-discount > div {
      width: auto;
    }

    .goodsl-item-wrap {
      .margin-r(0.32rem);
      display: inline-block;

      &:nth-child(2n) {
        .margin-r(0);
      }

      .sold-out {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .flexbox();
        .align-center();
        .pack-center();
        text-align: center;
        font-weight: bold;
        color: #fff;
        .font-dpr(28px);
        background: rgba(0, 0, 0, 0.4);

        span {
          display: inline-block;
          line-height: 2;
          background: fade(#fff, 90%);
          color: #222;
          padding: 0 16px;
          min-width: 1.92rem;
        }
      }
    }

    .goodsli-price-discount {
      color: @sui_color_discount;
      .font-dpr(28px);

      &.single {
        color: #222;
      }
    }

    .c-goodsli .goodsli-price-original > del {
      .font-dpr(20px);
      color: #767676;
    }

    .c-goodsli {
      .font-dpr(20px);

      .save-del {
        position: absolute;
        .right(0);
        top: 0.89rem;
        transform: translateY(-100%);
        width: 0.85rem;
        height: 0.9rem;
        .padding-l(0.27rem);
        padding-bottom: 0.27rem;
        text-align: center;

        [class*='iconfont'] {
          .font-dpr(36px);
        }

        .unstock {
          color: #fff;
        }
      }

      .goodsl-promotion-wrap {
        display: table;

        > div {
          display: table-cell;
        }

        .goodsl-promotion {
          width: 100%;

          .goodsl-promotion-item {
            text-align: center;
            /*(border: 1px solid @color_red_dark;*/
            .font-dpr(24px);
            line-height: 0.5333rem;
            min-height: 0.5333rem;
            padding: 0 0.1067rem;
            display: inline-block;
            max-width: 4.52rem;
            word-break: keep-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background: rgba(245, 110, 110, 0.04);
            border: 1px solid rgba(213, 51, 51, 0.5);
            color: #d53333;

            & when(@IS_SH) {
              background: rgba(245, 110, 110, 0.04);
              border: 1px solid rgba(213, 51, 51, 0.5);
              color: @color_red_dark;
            }

            & when(@IS_RW) {
              background: #ffefed;
              color: @color_brand;
            }
          }
        }
      }

      .goodsli-bottom-price {
        width: 85%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .j-goodsli-bottom {
        margin-bottom: 0;

        .goodsli-name {
          width: 85%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }
      }
    }

    .goodsli-price-discountTips {
      color: @sui_color_highlight;
      .font-dpr(20px);
      margin-bottom: 0.05rem;
      line-height: 2;
      max-width: 4.52rem;

      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .goodsli-size-stockTips {
      color: @sui_color_highlight;
      .font-dpr(20px);
      margin-bottom: 0.05rem;
      line-height: 2;
      max-width: 85%;

      &__cut {
        span {
          margin-top: 0;
        }
      }

      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .sold-out-text {
      opacity: 0.4;
      text-transform: none;

      .promo-icon {
        display: none;
      }
    }

    .goodsl-promotion-wrap.sold-out-promo {
      .goodsl-promotion .goodsl-promotion-item {
        display: none;
      }

      .icon-add-bag {
        color: #ccc;
      }
    }

    .goodsl-item-wrap {
      display: inline-block;
      margin-bottom: 0.64rem;
      overflow: hidden;
    }
  }

  .height-auto {
    min-height: auto;
  }

  .resent-view {
    &.mgds-goodsl {
      .margin-r(0);
    }
  }
}
</style>
