<template>
  <div
    :class="`index-me-header mshe-z-header ${getBgImage?.src
      ? 'index-me-header_sheinbg'
      : ''
    }`"
  >
    <div class="header-right">
      <div
        v-enterkey
        role="button"
        tabindex="0"
        aria-label="settings"
        class="waves-btn waves-btn-auto waves-effect waves-classic"
        DA-type="syncClick"
        @click="emits('routerPush', { target: JUMP_URL.USER_SETTING })"
      >
        <i class="me-header-li suiiconfont sui_icon_nav_setting_24px"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, defineEmits, computed } from 'vue'
import { JUMP_URL } from '../consts.js'
export default defineComponent({
  name: 'UserIndexHeader',
})
</script>

<script setup>
const props = defineProps({
  activityAtmosphere: { type: Object, default: () => {} }
})

/* computed */
const getBgImage = computed(() => props.activityAtmosphere?.bgImage || {})

const emits = defineEmits(['routerPush'])
</script>

<style lang="less" scoped>
.index-me-header {
  height: 1.17rem;
  padding: 0 0.1rem;
  background-color: #fff;
  .flexbox();
  .align-center();
  .space-between();
  &_sheinbg {
    background: transparent;
    position: relative;
    z-index: @zindex-hack;
  }
 

  &.scrolling {
    border-bottom: 1px solid rgba(229, 229, 229, 1);
  }

  .header-left,
  .header-right {
    .flexbox();
    .align-center();
    flex: 1;
    height: 1.17rem;
  }

  .header-right {
    .txt-r();
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .me-header-li {
    color: var(--config-color, #222);
    display: inline-block;
    .font-dpr(48px);
    height: 1.17rem;
    line-height: 1.17rem;
    width: 1.17rem;
    text-align: center;

    // & when (@IS_RW) {
    //   color: #fff;
    //   color: var(--config-color, #fff);
    // }
  }
}
</style>
