import { SuperSaveCouponStatus } from 'public/src/pages/user_index/service/consts'

export const getCouponStyles = (fusionData, fusionItemCount) => {
  if (!fusionData) return {}
  const { isPaid: isSuperSaveMember, couponCount, couponInfoList: couponList } = fusionData

  if (!couponList?.length) return {}
  if (isSuperSaveMember) {
    // 只取一张已投放和一张待投放
    const toUseCoupons = couponList.filter(coupon => coupon.couponStatus === SuperSaveCouponStatus.TO_USE)
    const toUseCoupon = toUseCoupons[0]
    const toCollectCoupons = couponList.filter(coupon => coupon.couponStatus === SuperSaveCouponStatus.TO_COLLECT)
    const toCollectCoupon = toCollectCoupons[0]
    let showCoupons
    if (toUseCoupon && toCollectCoupon) {
      const hasMoreCoupons = couponCount > 2
      showCoupons = [
        {
          ...toUseCoupon,
          hasMoreCoupons,
        },
        {
          ...toCollectCoupon,
          hasMoreCoupons,
        },
      ]
    } else {
      // 只展示前两张
      showCoupons = couponList.slice(0, 2)
      const hasMoreCoupons = showCoupons.length < couponCount
      showCoupons = showCoupons.map(coupon => ({
        ...coupon,
        hasMoreCoupons,
      }))
    }
    return {
      showCoupons,
      couponWidthRem: showCoupons.length > 1 ? 88 : 140,
    }
  } else {
    let showCoupons = []
    let couponWidthRem
    if (fusionItemCount > 1) {
      showCoupons = couponList.slice(0, 2)
      couponWidthRem = showCoupons.length === 1 ? 140 : 88
    } else {
      showCoupons = couponList.slice(0, 5)
      couponWidthRem = showCoupons.length === 1
        ? 160
        : showCoupons.length === 2
          ? 130
          : showCoupons.length === 3 ? 85 : 80
    }
    const canTouchScroll = showCoupons.length > 3
    const hasMoreCoupons = !canTouchScroll && showCoupons.length < couponCount
    return {
      showCoupons: showCoupons.map(coupon => ({
        ...coupon,
        hasMoreCoupons,
      })),
      couponWidthRem,
    }
  }
}
