<template>
  <s-dialog
    :visible="dialogShow"
    :class="`unpaid-dialog ${isHide ? 'hide' : ''}`"
    :style="{
      '--transform-height': transformHeight + 'px'
    }"
    append-to-body
    :close-on-click-modal="false"
  >
    <div 
      ref="dialogBodyRef"
      class="unpaid-dialog__bd"
    >
      <div class="unpaid-layout">
        <i 
          class="unpaid-layout__close"
          :style="{ backgroundImage: `url(${USER_INDEX_LOCAL_IMG.closeImage})` }"
          @click="handleCloseDialog"
        >
        </i>
        <!-- coupons -->
        <div
          class="unpaid-layout__header"
          :style="{ backgroundImage: `url(${getOrderIncentivePoint?.type === '6' ? USER_INDEX_LOCAL_IMG.priceBg : USER_INDEX_LOCAL_IMG.defaultBg})` }"
        >
          <!-- 券相关 -->
          <template v-if="getCouponLists?.length">
            <div class="unpaid-layout__coupons">
              <div
                v-for="(coupon, index) in getCouponLists"
                :key="index"
                class="unpaid-layout__coupon"
                :style="{ 
                  backgroundImage: `url(
                    ${['1', '2'].includes(coupon.type) ? USER_INDEX_LOCAL_IMG.couponNewOrange: USER_INDEX_LOCAL_IMG.couponNewGreen}
                  )`,
                  marginLeft: index == 1 ? '8px' : '0'
                }"
              >
                <div
                  class="unpaid-layout__coupon-type"
                  :style="{ backgroundColor: ['1', '2'].includes(coupon.type) ? '#FF804D' : '#2DAE78' }"
                >
                  {{ coupon.couponTitle }}
                </div>
                <div
                  v-if="coupon.couponFaceValue"
                  class="unpaid-layout__coupon-price"
                >
                  <div
                    v-if="coupon.couponFaceValue"
                    v-html="coupon.couponFaceValue"
                  ></div>
                  <div
                    v-if="coupon.couponThresholdTip"
                    :class="{
                      'unpaid-layout__coupon-text': true, 
                      'unpaid-layout__coupon-small-text': coupon.couponThresholdTip.length > 15
                    }"
                  >
                    {{ coupon.couponThresholdTip }}
                  </div>
                </div>
              </div>
              <div 
                v-if="getCouponLists.length > 2"
                class="unpaid-layout__coupon-more"
                :style="{ backgroundImage: `url(${USER_INDEX_LOCAL_IMG.moreIcon})` }"
              ></div>
            </div>
            <div 
              class="unpaid-layout__coupon-tip"
              v-html="getOrderIncentivePoint.text"
            >
            </div>
          </template>

          <!-- 积分 -->
          <div v-if="getOrderIncentivePoint?.type === '5'">
            <div
              class="unpaid-layout__points-main"
            >
              <img 
                class="unpaid-layout__points-icon"
                :src="getOrderIcon(getOrderIncentivePoint?.type)" 
              />
              <div
                class="unpaid-layout__points-num"
              >
                {{ getOrderIncentivePoint?.point }}
              </div>
            </div>
            <div 
              class="unpaid-layout__points-tip" 
              v-html="getOrderIncentivePoint?.text"
            >
            </div>
          </div>

          <!-- 活动结束 -->
          <div
            v-if="['1','3','6'].includes(getOrderIncentivePoint?.type)"
            class="unpaid-layout__timeout"
          >
            <img 
              class="unpaid-layout__timeout-icon"
              :src="getOrderIcon(getOrderIncentivePoint?.type)"
            />
            <div
              class="unpaid-layout__timeout-text"
              v-html="getOrderIncentivePoint?.text"
            >
            </div>
          </div>
        </div>
        <img
          class="unpaid-layout__content-top"
          :src="USER_INDEX_LOCAL_IMG.mainBgTop"
        />
        <!-- DialogContent -->
        <UnpaidDialogNewContent
          :order-item="props.orderItem"
          @closeDialog="closeDialog"
        />
      </div>
    </div>
  </s-dialog>
</template>
<script setup>
// 未支付弹窗(旧)
import { ref, computed, defineEmits, watch } from 'vue'
import UnpaidDialogNewContent from './UnpaidDialogNewContent.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getBuriedData } from 'public/src/pages/user_index/service/utils.js'
import { useMapMutations } from '../../../hooks/useVuex.js'
import { USER_INDEX_LOCAL_IMG } from '../../../consts'

// 利诱点类型，1.活动已结束 2.券过期 3.活动即将结束 4.返券 5.返积分 6.价格保护
// const dialogShow = ref(true)
const isHide = ref(false)
const transformHeight = ref(0)
const dialogShow = ref(false)
const dialogBodyRef = ref(null)

const { setStoreState } = useMapMutations(['setStoreState'])

const props = defineProps({
  orderItem: { type: Object, default: () => {} },
  unpaidOrderRects: { type: [Object, DOMRect], default: () => {} }
})

const emits = defineEmits(['clickCloseDialog'])


/* computed */
// 订单利诱点
const getOrderIncentivePoint = computed(() => props.orderItem?.orderIncentivePoint || {})
const getCouponLists = computed(() => getOrderIncentivePoint.value?.couponInfos || [])

/* methods */

const closeDialog = () => {
  dialogShow.value = false
  setStoreState({ dialogName: 'next-renew' })
}
// 关闭动效
const handleCloseDialog = () => {
  const dialogRect = dialogBodyRef.value.getBoundingClientRect()
  const dialogHeight = dialogRect?.top + dialogRect?.height / 2
  const contentHeight = props.unpaidOrderRects?.top  + props.unpaidOrderRects?.height / 2
  transformHeight.value = (contentHeight - dialogHeight) * 2
  isHide.value = true
  setTimeout(() => {
    dialogShow.value = false
    emits('clickCloseDialog')
    daEventCenter.triggerNotice({
      daId: '1-25-11-1005',
      extraData: getBuriedData(props.orderItem?.buriedObject || [], 1)
    })
  }, 500)
}

const getOrderIcon = (type) => {
  switch(type) {
    case '1':
      return USER_INDEX_LOCAL_IMG.orderLabel
    case '3':
      return USER_INDEX_LOCAL_IMG.orderTime
    case '5':
      return USER_INDEX_LOCAL_IMG.orderCoin
    case '6':
      return USER_INDEX_LOCAL_IMG.orderGuaranteePrice
    default:
      return ''
  }
}

/* watch */
watch(() => props.orderItem, 
  (newValue) => {
    if(!newValue) return
    setStoreState({ dialogName: 'unpaid' })
    dialogShow.value = true
    const { order_id, scene, goods_number, benefit_type } = getBuriedData(newValue?.buriedObject, 1)
    daEventCenter.triggerNotice({
      daId: '1-25-11-1012',
      extraData: {
        scene
      }
    })
    daEventCenter.triggerNotice({
      daId: '1-25-11-1004',
      extraData: {
        order_id,
        scene,
        goods_number,
        benefit_type
      }
    })
  }, 
  {
    immediate: true
  })
</script>
<style lang="less" scoped>
// 设计稿用的 375px
@unit: 375/100vw;
.unpaid-dialog {
  // 弹窗样式重置
  .S-dialog__top {
    display: none;
  }
  .S-dialog__body {
    padding: 0;
  }
  &__bd {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__close{
    position: absolute;
    bottom: -42 / @unit;
    left: 50%;
    right: 50%;
    margin-left: -10 / @unit;
    margin-right: -10 / @unit;
    width: 20 / @unit;
    height: 20 / @unit;
    background-size: cover;
  }
}
.unpaid-layout {
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &__close {
    position: absolute;
    top: -34 / @unit;
    right: -10 / @unit;
    width: 20 / @unit;
    height: 20 / @unit;
    background-size: cover;
  }
  &__header {
    width: 311 / @unit;
    background-size: 100% auto;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 8 / @unit 10 / @unit 24 / @unit;
  }
  &__coupons {
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__coupon {
    position: relative;
    width: 136 / @unit;
    height: 68 / @unit;
    box-sizing: border-box;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__coupon-type {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    max-width: 136 / @unit;
    padding: 0 2 / @unit;
    border-radius: 4px 0 4px 0;
    background: #2DAE78;
    color: rgba(255, 255, 255, 0.6);
    font-size: 9 / @unit;
  }
  &__coupon-price {
    color: #FFF;
    font-size: 14 / @unit;
    font-weight: 700;
    text-align: center;
  }
  &__coupon-small-text {
    font-size: 10 / @unit;
  }
  &__coupon-text {
    font-size: 10 / @unit;
    font-weight: 500;
    color: rgba(#FFF, .7);
  }
  &__coupon-tip {
    color: #6E2D18;
    text-align: center;
    font-size: 14 / @unit;
    font-weight: 500;
    margin-top: 8 / @unit;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
  &__coupon-more {
    position: absolute;
    width: 14 / @unit;
    height: 14 / @unit;
    background-size: cover;
    top: 50%;
    right: -7 / @unit;
    transform: translateY(-50%);
  }

  &__points-main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__points-icon {
    width: 48 / @unit;
    height: 48 / @unit;
    background-size: cover;
    margin-right: 8 / @unit;
  }
  &__points-num {
    color: #FA6338;
    font-size: 20 / @unit;
    font-style: italic;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }
  &__points-tip {
    color: #6E2D18;
    text-align: center;
    font-size: 14 / @unit;
    font-weight: 500;
    margin-top: 8 / @unit;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  &__timeout {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__timeout-icon {
    width: 32 / @unit;
    height: 32 / @unit;
    background-size: cover;
  }
  &__timeout-text {
    color: #6E2D18;
    text-align: left;
    font-size: 14 / @unit;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  &__content-top {
    margin-top: -10 / @unit;
    width: 328 / @unit;
    height: 10 / @unit;
  }
}

.hide {
  animation: scaleOut 0.5s forwards;
}


@keyframes scaleOut {
  from {transform: translate3d(0px, 0px, 100002px) scale(1);}
  to {
    transform: translate3d(0px, 0px, 100002px) scale(0);
    top: var(--transform-height);
  }
}
</style>
