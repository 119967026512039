var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.showFusionFloor)?_c('div',{staticClass:"rights-fusion-floor__wrapper"},_vm._l((_setup.showUserRights),function(rightsType){return _c('div',{key:rightsType,staticClass:"rights-fusion-floor__item"},[(rightsType === _setup.UserRightsFusionType.SHEIN_CLUB_MEMBER && _setup.showSheinClubMemberPanel)?_c(_setup.PrimePannel,_vm._b({staticClass:"rights-fusion-floor__prime",style:({
        width: `${_setup.fusionItemStyles.containerWidth}rem`,
      }),attrs:{"context":"fusion-floor","fusion-floor-position":_setup.primePanelPosition}},'PrimePannel',_vm.primePanelBindData,false)):_vm._e(),_vm._v(" "),(rightsType === _setup.UserRightsFusionType.SUPER_SAVE && _setup.showSuperSavePanel)?_c(_setup.NewSuperSavePanel,{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '1-25-2-29',
        data: _setup.superSaveReportParams,
      }),expression:"{\n        id: '1-25-2-29',\n        data: superSaveReportParams,\n      }"}],style:({
        width: `${_setup.fusionItemStyles.containerWidth}rem`,
      }),attrs:{"super-save-data":_setup.superSavePanelData,"computed-styles":_setup.fusionItemStyles,"report-params":_setup.superSaveReportParams}}):_vm._e()],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }