<template>
  <div
    v-if="iconLists.length"
    class="module-content my-entry-block more-service__wrap"
  >
    <!-- title -->
    <div class="module-content-title">
      <h3 tabindex="0">
        {{ myServiceInfo?.titleGroup?.title }}
      </h3>
    </div>
    <swiper-container
      ref="swiperRef"
      class="more-service__list"
      init="false"
      pagination="true"
      :slides-per-view="myServiceInfo.iconsGroup?.rowCount"
    >
      <!-- list item -->
      <swiper-slide
        v-for="(item, index) in iconLists"
        :key="item.type"
        v-expose="{
          id: '1-25-11-21',
          data: baseAnalysisData(item, index)
        }"
        v-tap="{
          id: '1-25-11-22',
          data: baseAnalysisData(item, index)
        }"
        class="more-service__item"
        :data-param="isLogin() && item?.iconAttributes?.isShow ? 1 : 0"
        @click="() => handleSectionItemClick(item)"
      >
        <!-- icon -->
        <img
          v-if="item.iconObject?.iconType === 2"
          class="more-service__item-img"
          :src="transformImg({ img: item.iconObject?.iconUrl || '' })"
        />
        <i
          v-else-if="item.iconObject?.iconType === 3"
          :class="`more-service__item-img ${item.iconObject?.iconUrl}`"
        ></i>
        <Icon
          v-else
          :name="item.iconObject?.iconUrl"
          size="24px"
        />
        <!-- red point  type 1: 点 , 2: 数字 , 3: 气泡, 2,3 情况才有数字-->
        <span
          v-if="showItemReminder(item)"
          class="red-point"
          :style="[2,3].includes(Number(item.iconAttributes?.type)) && styleConfig[GB_cssRight? 'cssRightStyle': 'cssLeftStyle']"
        >
          <template v-if="[2,3].includes(Number(item.iconAttributes?.type))">
            {{ item.iconAttributes?.value || '' }}
          </template>
        </span>
        <p class="more-service__item-title">
          {{ htmlDecode({ text: item.title }) }}
        </p>
      </swiper-slide>
    </swiper-container>
    <div
      class="more-service-pagination"
    ></div>
  </div>
</template>

<script setup>
import { computed, defineEmits, watch, ref, onMounted, nextTick, onActivated } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { isLogin } from 'public/src/pages/common/utils/index.js'

import { setRedDotStatus } from '../hooks/more-services/redDot'
import { htmlDecode, transformImg } from '@shein/common-function'
import { Pagination } from 'swiper/modules'
import { Icon } from '@shein-aidc/icon-vue2'
import { register } from 'swiper/element'
import { useMapState } from '../hooks/useVuex.js'

const { host, langPath, GB_cssRight } = gbCommonInfo
const storeState = useMapState([
  'isRisk',
])

// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()

/* state */
const swiperRef = ref(null)
const swiperInstance = ref(null)
const realIndex = ref(0)

const hasExposedMap = {}

const analysisMap = {
  'FREE_TRAIL_LIST': '1-25-12-15',
  REFERENCE: '1-25-12-14',
  SHARE_EARN: '1-25-12-13',
  FOLLOW_LIST: '1-25-12-30',
}
const exposeServiceItems = (iconList) => {

  iconList.forEach(item => {
    if (hasExposedMap[item.type] || !analysisMap[item.type]) {
      return
    }
    daEventCenter.triggerNotice({ daId: analysisMap[item.type] })
    hasExposedMap[item.type] = 1
  })
}

onActivated(() => {
  if (swiperInstance.value) {
    swiperInstance.value.destroy()
    swiperInstance.value = null
    initPageEvt()
  }
})

onMounted(() => {
  initPageEvt()
  // 登录成功触发
  appEventCenter.$on('initUserInfo', () => {
    initPageEvt()
  })
})

const props = defineProps({
  dynamicService: { type: Object, default: () => { } },
  showItemReminder: { type: Function, default: () => () => false },
})

const emits = defineEmits(['routerPush', 'openRiskDrawer'])

/* computed */
const myServiceInfo = computed(() => props.dynamicService?.serviceList?.find((item) => item?.type === 'myService') || {})
const iconLists = computed(() => {
  const result = myServiceInfo.value?.iconsGroup?.icons || []
  nextTick(() => {
    typeof requestIdleCallback !== 'undefined' && requestIdleCallback(() => {
      exposeServiceItems(result)
    })
  })
  return result
})
const baseAnalysisData = (item, index) => ({
  title: item.type,
  position: index + 1,
})
const showItemReminder = (item) => props.showItemReminder(item)

const styleConfig = {
  cssRightStyle: {
    backgroundColor: '#fa6338',
    borderRadius: '.2667rem .16rem 0 .2667rem',
    fontSize: '10px',
    fontWeight: 600,
    left: 0,
    padding: '.0533rem .1067rem .08rem',
    top: '-.32rem',
  },
  cssLeftStyle: {
    backgroundColor: '#fa6338',
    borderRadius: '.16rem .2667rem .2667rem 0',
    fontSize: '10px',
    fontWeight: 600,
    padding: '.0533rem .1067rem .08rem',
    right: 0,
    top: '-.32rem',
  }
}

/* methods */
const initPageEvt = () => {
  if (iconLists.value.length) {
    // setTimeout(() => {
    initSwiper(iconLists.value) // 延迟初始化swiper
    // }, 1500)
  }
}
const initSwiper = (val) => {
  if (!swiperInstance.value) {
    const swiperEl = swiperRef.value
    if (!swiperEl) return // 没有swiper数据直接返回
    const rowCount = myServiceInfo.value?.iconsGroup?.rowCount
    const swiperConfig = {
      modules: val.length > rowCount ? [Pagination] : [],
      pagination: val.length > rowCount ? {
        el: '.more-service-pagination',
        bulletClass: 'more-service-bullet',
        bulletActiveClass: 'more-service-bullet-active',
      } : {},
      spaceBetween: 8,
      initialSlide: realIndex.value
    }
    Object.assign(swiperEl, swiperConfig)
    swiperEl.initialize()
    swiperInstance.value = swiperEl.swiper
  } else {
    nextTick(() => {
      swiperInstance.value.update()
    })
  }
}

const handleSectionItemClick = (item) => {
  const { type = '', jumpUrl = '', eventCache } = item
  if (type.toUpperCase() === 'SURVEY') {
    daEventCenter.triggerNotice({ daId: '1-25-12-7' })
  } else if (type.toUpperCase() === 'FREE_TRAIL_LIST') {
    handleGoTrialCenter()
    return
  }
  if (jumpUrl) {
    setRedDotStatus({ type, expire: eventCache?.find(cache => cache.type == 1)?.cacheTime })
    emits('routerPush', { target: jumpUrl || '' })
  }
}

// 试用中心特殊跳转逻辑
const handleGoTrialCenter = () => {
  if (storeState.isRisk.value) {
    return emits('openRiskDrawer')
  }
  const target = iconLists.value.find(item => item.type === 'FREE_TRAIL_LIST')
  if (target?.jumpUrl) {
    return (location.href = `${host}${langPath}${target.jumpUrl}`)
  }
  return (location.href = host + langPath + '/free-trial-center/index')

  // if (this.isLogin) {
  //   location.href = host + langPath + '/free-trial-center/my-center'
  // } else {
  //   window.SHEIN_LOGIN.show({
  //     show: true,
  //     reload: false,
  //     from: 'freeTrial',
  //     ga: 'freetrial',
  //     cb: () => {
  //       location.href = host + langPath + '/free-trial-center/my-center'
  //     }
  //   })
  // }
}

/* watch */
watch(iconLists, (value) => {
  if (iconLists.value?.length) {
    initSwiper(value)
  }
})


</script>
<style lang="less" scoped>
swiper-container::part(container) {
  overflow: visible;
}
.module-content {
  border-bottom: 20/75rem solid #f6f6f6;
  background: #fff;
  padding: 0.32rem 0 0;
  position: relative;
  z-index: 1;

  .module-content-title {
    .flexbox();
    .space-between();
    padding: 0 0.32rem;
  }
}

.my-entry-block {
  min-height: 2.606667rem;
}

.more-service {
  &__wrap {
    width: 10rem;
    overflow: hidden;

    .more-service-pagination {
      display: block;
      text-align: center;
      padding-bottom: 4px;
    }

    /deep/ .more-service-bullet {
      width: .32rem;
      height: .08rem;
      display: inline-block;
      background: #e5e5e5;
    }

    /deep/ .more-service-bullet-active {
      // stylelint-disable-line
      background: #666;
    }
  }

  &__list {
    padding: .2133rem .2133rem 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .red-point {
      position: absolute;
      top: -0.16rem;
      right: 30%;
      padding: .05rem;
      display: inline-block;
      border: 1px solid @sui_color_guide;
      border-radius: 0.08rem;
      background: @sui_color_guide;
      color: #fff;
    }
  }

  &__item-img {
    width: .64rem;
    height: .64rem;
  }

  &__item-title {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
