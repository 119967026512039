var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.pageData)?_c('section',{staticClass:"c-index-me c-index-me_bg bff-container",style:({
    '--config-background-img': `url(${_setup.getActivityAtmosphere?.bgImage?.src || ''})`,
    '--config-color': _setup.getActivityAtmosphere?.fontColor
  }),attrs:{"is-use-bff":"1"}},[_c('div',[_c(_setup.Header,{attrs:{"activity-atmosphere":_setup.getActivityAtmosphere},on:{"routerPush":_setup.routerPush}}),_vm._v(" "),_c('div',{staticClass:"me-scroll-wrap"},[_c('div',{staticClass:"common-notice-wrap"},[(_setup.isCommonNoticeShow)?_c(_setup.CommonNotice,{attrs:{"message":_setup.commonNotifyContent},on:{"closeAlert":_setup.onCloseCccNotify,"showDetailEvt":() => {
            _setup.daEventCenter.triggerNotice({
              daId: '1-25-11-12'
            })
          }}}):_vm._e()],1),_vm._v(" "),_c(_setup.MainSection,{attrs:{"is-page-data-ready":_setup.isPageDataReady,"activity-atmosphere":_setup.getActivityAtmosphere,"bottom-tips-info":_setup.userAssetsBottomTipsInfo,"user-rights-info":_setup.getPersonalCenterEntranceInfo,"personal-info":_setup.getPersonalInfo,"user-level-info":_setup.getUserLevelInfo,"news-info":_setup.newsBarInfo,"route-info":_vm.routeInfo},on:{"routerPush":_setup.routerPush,"loginSucRedirection":_setup.loginSucRedirection,"openRiskDrawer":_setup.openRiskDrawer}}),_vm._v(" "),_c(_setup.MyOrdersSection,{attrs:{"personal-info":_setup.getPersonalInfo,"base-other-info":_setup.baseOtherInfo,"dynamic-service":_setup.getDynamicService},on:{"routerPush":_setup.routerPush}}),_vm._v(" "),_c(_setup.ShoppingGuide),_vm._v(" "),_c(_setup.MoreServicesSection,{attrs:{"dynamic-service":_setup.getDynamicService,"show-item-reminder":_setup.showMoreServiceItemReminder},on:{"routerPush":_setup.routerPush,"openRiskDrawer":_setup.openRiskDrawer}}),_vm._v(" "),_c(_setup.WishAndRecentTabs,{attrs:{"is-login":_setup.isLogin,"abt-info":_setup.abtInfo,"language":_setup.language,"footer-rcmd-list-resolve-status":_setup.footerRcmdListResolveStatus}}),_vm._v(" "),(_setup.footerRcmdCccConfig && !_setup.firstTrendFlowPending)?_c('FooterRcmdList',{attrs:{"ccc-config":_setup.footerRcmdCccConfig,"handle-insert-cart-slot":_setup.fetchTrendFlowData,"cart-slot-info-list":_setup.trendFlowData,"language":_setup.language,"shein-club-info":_setup.sheinClubInfo,"abt-info":_setup.abtSource}}):_vm._e()],1)],1),_vm._v(" "),_c(_setup.RiskDialog,{ref:"riskDialog",on:{"changeUserRisk":_setup.changeUserRisk}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }