import { render, staticRenderFns } from "./ModuleIndex.vue?vue&type=template&id=69e5d593&"
import script from "./ModuleIndex.vue?vue&type=script&setup=true&name=UserRightsPanel&lang=js&"
export * from "./ModuleIndex.vue?vue&type=script&setup=true&name=UserRightsPanel&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.8.1_ejs@2.7.2_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.86.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports