<script lang="jsx">
import ImgWraper from './ImgWraper.vue'
import ScrollingText from 'public/src/pages/user/child_pages/dashboard/components/ScrollingText.vue'
import { template, CountDown, transformImg } from '@shein/common-function'
const { langPath, } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { UNPAID_DIALOG_IMG } from 'public/src/pages/user/child_pages/dashboard/components/OrderPannel/UnpaidDialog/utils.js'

export default {
  name: 'UnpaidOrder',
  components: { ImgWraper, ScrollingText },
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },
    userCenterLanguage: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    unpaidOrderActiveClass: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      countTimer: new CountDown(),
      timeDomClass: ''
    }
  },
  computed: {
    totalDesc () {
      const { orderGoodsList = [], currency_pay_amount = {} } = this.item
      let length = 0
      orderGoodsList.forEach(val => {
        if (!(val.is_prime_goods || val.is_xtra_goods)) {
          length += Number(val.product.quantity)
        }
      })
      return `${template(
        length,
        length > 1 ? this.userCenterLanguage.SHEIN_KEY_PWA_31186 : this.userCenterLanguage.SHEIN_KEY_PWA_31626
      )}: ${`<strong>${currency_pay_amount?.amountWithSymbol}</strong>`}`
    },
    countDownTips () {
      const { H, M, S } = this.countTimer?.timeObj || {}
      return `${H} : ${M} : ${S}`
    },
  },
  watch: {
    unpaidOrderActiveClass: {
      async handler (newVal) {
        if(newVal == 'unpaid-order-box__show') {
          setTimeout(() => {
            this.timeDomClass = 'unpaid-order__transform-time'
          }, 500)
        } else if(newVal == 'unpaid-order-box__hide') {
          this.timeDomClass = 'unpaid-order__hide-time'
        } else if(newVal == 'unpaid-order-box__default') {
          this.timeDomClass = 'unpaid-order__show-time'
        }
      },
      immediate: true
    },
  },
  mounted () {
    /* 未支付订单倒计时 */
    let { expire_countdown } = this.item
    const endTime = Number(expire_countdown)
    const nowTime = Date.parse(new Date()) / 1000
    this.countTimer.start({
      seconds: endTime - nowTime,
      endFunc: () => {
        // 倒计时结束
        this.$emit('countdownEnd')
      }
    })
  },
  methods: {
    payNowHandle () {
      daEventCenter.triggerNotice({
        daId: '1-25-2-1003',
        extraData: {
          order_id: this.item.billno,
          scene: 'order_floor'
        }
      })
      this.$router.push(`${langPath}/user/orders/detail/${this.item.billno}?goBack=1${this.item.payment_method == 'cod' ? '' : '&show_error_guide_payment=1'}&from_type=2`)
    },
  },
  render () {
    const infoSavedEl = () => {
      if(Number(this.item?.total_discount_new?.amount) > 0) {
        return (
          <div
            class="item-left__saved-box"
          >
            <img 
              class="item-left__saved-icon"
              src={UNPAID_DIALOG_IMG.downwardTrendIcon} 
            />
            <ScrollingText 
              text={template(this.item.total_discount_new.amountWithSymbol, this.userCenterLanguage.SHEIN_KEY_PWA_31199)}
            />
          </div>
        )
      }
    }

    return (
      <div 
        class="unpaid-order__item"
      >
        <div class="unpaid-order__item-left">
          <img 
            class="item-left__img"
            src={transformImg({ img: this.item.orderGoodsList?.[0]?.product?.goods_img })} />
          <div class="item-left__info">
            <div class="item-left__info-content">
              <div
                class="item-left__info-total" 
                domPropsInnerHTML={this.totalDesc}
              ></div>
              {infoSavedEl()}
            </div>
            <div 
              class="item-left__tip-box"
            >
              <img 
                src={transformImg({ img: this.item.freeShippingTempt?.icon })}
                class="item-left__tip-icon"
              />
              <div
                class="item-left__tip-text"
              >{this.item.freeShippingTempt?.text}</div>
            </div>
          </div>
        </div>

        <div class="unpaid-order__item-right">
          <s-button
            type={['primary', 'H48PX']}
            vOn:click_stop={this.payNowHandle}
          >
            {this.userCenterLanguage.SHEIN_KEY_PWA_26689}
          </s-button>
          <div 
            class={`unpaid-order__count-down ${this.timeDomClass}` }
          >
            <div class="unpaid-order__count-down-info">
              <i class="suiiconfont sui_icon_hourglass_12px_2"></i>
              <span>{this.countDownTips}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
// 设计稿用的 375px
@unit: 375/100vw;
.unpaid-order {
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item-left {
    display: flex;
    align-items: center;
    max-width: 70vw;
    .item-left__img {
      width: 36 / @unit;
      height: 36 / @unit;
      border-radius: 2 / @unit;
      border: 1 / @unit solid #FFF;
    }
    .item-left__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-left: 8 / @unit;
      max-width: 220 / @unit;
    }
    .item-left__info-content {
      display: flex;
      overflow: hidden;
    }
    .item-left__info-total {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #000;
      font-size: 12 / @unit;
      font-weight: 400;
      strong {
        font-weight: 700;
      }
    }
    .item-left__saved-box {
      min-width: 83 / @unit;
      max-width: 114 / @unit;
      display: flex;
      color: #FA6338;
      font-size: 10 / @unit;
      font-weight: 700;
      border-radius: 2 / @unit;
      border: 0.5 / @unit solid rgba(250, 99, 56, 0.60);
      background: #FFF8EB;
      padding: 1 / @unit 2 / @unit;
      align-items: center;
      gap: 2 / @unit;
      margin-left: 8 / @unit;
      display: flex;
      overflow: hidden;
    }
    .item-left__saved-icon {
      width: 12 / @unit;
      height: 12 / @unit;
      background-size: contain;
    }
    .item-left__saved-text {
      flex: 1;
      line-height: normal;
      overflow: hidden; /* 隐藏溢出的内容 */
      text-overflow: ellipsis; /* 第一行文本溢出时显示省略号 */
      white-space: nowrap;
    }

    .item-left__tip-box { 
      display: flex;
      align-items: center;
      margin-top: 4 / @unit;
    }
    .item-left__tip-icon {
      width: 12 / @unit;
      height: 12 / @unit;
      background-size: contain;
    }
    .item-left__tip-text {
      margin-left: 4 / @unit;
      color: #198055;
      font-size: 12 / @unit;
      font-weight: 500;
      line-height: normal;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
  &__item-right {
    margin-left: .32rem;
    width: 1.76rem;
    position: relative;
  }
  &__count-down {
    opacity: 0;
    padding: 8/75rem;
    text-wrap: nowrap;
    text-align: center;
    position: absolute;
    top: 0.82rem;
    border-radius: 4/75rem;
    background: #fa6338;
    .font-dpr(20px);
    .right(50%);
    .margin-r(-0.9rem);
    color: #fff;
    z-index: 3;
    &::before {
      position: absolute;
      content: '';
      width: 16/75rem;
      height: 16/75rem;
      background: #fa6338;
      transform: rotate(45deg);
      z-index: 2;
      top: -8/75rem;
      .left(50%);
      .margin-l(-8/75rem);
    }
  }
  &__hide-time {
    opacity: 0;
  }
  &__show-time {
    opacity: 1;
  }
  &__transform-time {
    top: 98/75rem;
    animation: fadeIn .2s forwards;
  }
  &__count-down-info{
    i{
      .font-dpr(20px);
    }
    line-height: normal;
    .font-dpr(20px);
    color: #FFFFFF;
    background: @sui_color_promo;
    border-radius: 2px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: 90/75rem;
  }
  to {
    top: 0.82rem;
    opacity: 1;
  }
}
</style>
