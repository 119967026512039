<template>
  <div class="wishlist-center-empty">
    <i 
      v-if="IS_RW"
      class="suiiconfont sui_img_nodata_save_75px"
    ></i>
    <Icon 
      v-else
      name="sui_icon_empty_75px_1" 
      size="75px" 
    />
    <p>
      {{ htmlDecode({text: language.SHEIN_KEY_PWA_16562}) }}
    </p>
    <s-button 
      :type="['H72PX']"
      class="user-index-emtpy"
      @click="goShopping"
    > 
      {{ language.SHEIN_KEY_PWA_15617.toUpperCase() }}
    </s-button>
  </div>
</template>

<script>
import { htmlDecode } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
const { IS_RW, langPath } = gbCommonInfo
export default {
  name: 'PageEmpty',
  components: {
    Icon
  },
  props: {
    language: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      IS_RW
    }
  },
  methods: {
    htmlDecode,
    goShopping() {
      this.$router.push(langPath + '/')
    }
  }
}
</script>

<style lang="less">
.wishlist-center-empty {
  padding-top: 1.8133333rem;
  text-align: center;
  i {
    color: #fc4070;
  }
  p {
    margin: .533333rem 0;
    font-size: 0.3733333rem;
    color: #222;
  }
  .user-index-emtpy {
    width: 4.266666666rem;
    height: 0.96rem;
  }
}
</style>

