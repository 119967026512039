import { FooterRcmdListResolveStatus } from 'public/src/pages/user/child_pages/dashboard/service/constants'

const createFooterRcmdMixin = () => ({
  props: {
    footerRcmdListResolveStatus: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    showSkeleton() {
      return !this.listDataReady || this.footerRcmdListResolveStatus === FooterRcmdListResolveStatus.PENDING
    },
    showProducts() {
      const resolveStatus = this.footerRcmdListResolveStatus
      return resolveStatus === FooterRcmdListResolveStatus.HAS_RCMD_LIST
        ? this.products.slice(0, 2)
        : this.products
    },
  },
})

export default createFooterRcmdMixin
