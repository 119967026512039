var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tips-list not-fsp-element"},[_c('div',{staticClass:"tips-arrow"},[_c('div',{staticClass:"arrow"},[_c('i',{class:[
          _vm.arrowPosition === 1 ? 'arrow-icon' : '',
        ]})]),_vm._v(" "),_c('div',{staticClass:"arrow"},[_c('i',{class:[
          _vm.arrowPosition === 2 ? 'arrow-icon' : '',
        ]})]),_vm._v(" "),_c('div',{staticClass:"arrow"},[_c('i',{class:[
          _vm.arrowPosition === 3 ? 'arrow-icon' : '',
        ]})]),_vm._v(" "),_c('div',{staticClass:"arrow"},[_c('i',{class:[
          _vm.arrowPosition === 4 ? 'arrow-icon' : '',
        ]})])]),_vm._v(" "),_c(_setup.SAlert,{key:"coupon",staticClass:"preference-remind remind-tips",attrs:{"show-icon":!_setup.IS_RW,"type":_setup.IS_RW ? 'info' : 'warning',"rtl-icon-roate":_setup.lang == 'ar',"closable":false},nativeOn:{"click":function($event){return _setup.onClick.apply(null, arguments)}}},[_c('div',{staticClass:"preference-text",attrs:{"tabindex":"0"}},[_vm._v("\n      "+_vm._s(_vm.tipsContent)+"\n    ")]),_vm._v(" "),_c('template',{slot:"operation"},[_c('i',{staticClass:"iconfont icon-close icon-jumpable",on:{"click":function($event){$event.stopPropagation();return _setup.onClose.apply(null, arguments)}}})])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }