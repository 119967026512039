<template>
  <div class="tips-list not-fsp-element">
    <div class="tips-arrow">
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 1 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 2 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 3 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
      <div class="arrow">
        <i
          :class="[
            arrowPosition === 4 ? 'arrow-icon' : '',
          ]"
        ></i>
      </div>
    </div>
    <SAlert
      key="coupon"
      :show-icon="!IS_RW"
      :type="IS_RW ? 'info' : 'warning'"
      :rtl-icon-roate="lang == 'ar'"
      :closable="false"
      class="preference-remind remind-tips"
      @click.native="onClick"
    >
      <div
        tabindex="0"
        class="preference-text"
      >
        {{ tipsContent }}
      </div>
      <template slot="operation">
        <i
          class="iconfont icon-close icon-jumpable"
          @click.stop="onClose"
        ></i>
      </template>
    </SAlert>
  </div>
</template>

<script setup name="AlertTips">
import { ref } from 'vue'
import { Alert as SAlert } from '@shein/sui-mobile'

defineProps({
  arrowPosition: {
    type: Number,
    default: 0,
  },
  tipsContent: {
    type: String,
    default: '',
  },
})

const IS_RW = ref(gbCommonInfo?.IS_RW)
const lang = ref(gbCommonInfo?.lang)
const emits = defineEmits([
  'on-close',
  'on-click',
])
const onClose = () => emits('on-close')
const onClick = () => emits('on-click')
</script>

<style lang="less" scoped>
.tips-list {
  padding: 0 8/75rem 24/75rem;
  margin-top: -24/75rem;
  background: #FFF;
}

.tips-arrow {
  display: flex;

  .arrow {
    flex: 1;
    justify-content: center;
    display: flex;
    align-items: center;

    .arrow-icon {
      border: 12/75rem solid transparent;
      border-bottom-color: #fff1d5;
      width: 12/75rem;
      height: 12/75rem;
      display: inline-block;
    }
  }
}

.preference-remind {
  .preference-text {
    .padding-r(24/75rem);
  }
  [class*='iconfont'] {
    color: @sui_color_gray_light1;
  }
}

.remind-tips {
  margin: 0 16/75rem;

  & when (@IS_RW) {
    border-radius: 4px;
  }
}
</style>

