<template>
  <div
    v-cloak
    v-if="!!productConfig.desc"
    class="super-extra-wrap fsp-element"
    @click="goToRightDetail"
  >
    <div class="super-extra">
      <div :class="['super-extra__layer', { 'super-extra__layerAr': GB_cssRight }]">
        <div class="super-extra__header">
          <div class="super-extra__header-left">
            <img
              :class="['logo', superExtraStatus === 'IS_SUPER_EXTRA_EXPIRED' ? '' : 'add-pr']"
              :src="`${PUBLIC_CDN}/pwa_dist/images/user/extra/shein-saver-ea9b36164c.png`"
            />
            <div
              v-if="superExtraStatus === 'IS_SUPER_EXTRA_EXPIRED'"
              class="status"
            >
              {{ language.SHEIN_KEY_PWA_26010 }}
            </div>
            <div
              v-if="isExpiredRecently"
              class="ending-soon"
            >
              <div class="ending-soon__text">
                {{ language.SHEIN_KEY_PWA_27569 }}
              </div>
            </div>
          </div>

          <div class="super-extra__header-right">
            <div
              v-if="!(!isExpiredRecently && superExtraStatus === 'IS_SUPER_EXTRA') && isProductHasDiscount"
              class="special-offer"
            >
              <div class="special-offer__text">
                {{ language.SHEIN_KEY_PWA_26046 }}
              </div>
              <img
                class="special-offer__arrow"
                :src="`${PUBLIC_CDN}/pwa_dist/images/user/prime/renew_discount_bg_tips-e70005d314.png`"
              />
            </div>
            <div class="operation-box">
              <div
                v-if="
                  !isExpiredRecently && superExtraStatus && superExtraStatus === 'IS_SUPER_EXTRA'
                "
                class="operation-text"
              >
                <span>{{ productConfig.btnText }}</span>
                <i class="suiiconfont sui_icon_more_right_16px_2"></i>
              </div>
              <div
                v-else
                class="operation-btn"
              >
                {{ productConfig.btnText }}
              </div>
            </div>
          </div>
        </div>
        <div :class="['super-extra__content', status === 4 ? 'remove-pd' : '']">
          <div class="super-extra__content-left">
            <span
              class="coupon-desc"
              v-html="productConfig.desc"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
const { langPath, PUBLIC_CDN, GB_cssRight, SiteUID } = gbCommonInfo

export default {
  name: 'SuperExtraDetail',
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      langPath,
      PUBLIC_CDN,
      GB_cssRight,
      SiteUID,
    }
  },
  computed: {
    detail() {
      return this.source.detail
    },
    language() {
      return this.source.language ?? {}
    },
    /*
      status的值会有如下几种情况：
        1: 会员无省额有可用卷  2: 会员无省额无可用卷   3: 会员有省额有可用卷，  4: 会员有省额无可用卷
        5: 非会员首次购买或者退订购买有优惠  6: 非会员首次购买或者退订购买无优惠   7: 过期会员续费购买有省额有优惠   
        8: 过期会员续费购买有省额无优惠 9: 过期会员续费购买无省额有优惠   10: 过期会员续费购买无省额无优惠
        11: 会员无省额有优惠 12:会员无省额无优惠 13:会员有省额有优惠 14:会员有省额无优惠
    */
    status() {
      const { abtControl } = this.source
      const { buy_status, reason } = this.detail || {}
      const hasProductHasDiscount = this.isProductHasDiscount
      const isHasHistoryEnjoyed = this.isHasHistoryEnjoyed
      // 1.是超省卡会员
      if (this.superExtraStatus === 'IS_SUPER_EXTRA') {
        // 临期
        if (this.isExpiredRecently) {
          if (!this.isHasCurrentEnjoyed) {
            // 无省额
            return this.isProductHasDiscount ? 11 : 12
          } else {
            return this.isProductHasDiscount ? 13 : 14
          }
        }
        if (!this.isHasCurrentEnjoyed) {
          // 无省额
          return this.isCouponAvailable ? 1 : 2
        } else {
          return this.isCouponAvailable ? 3 : 4
        }
      }

      // 2.非超省卡会员
      if (abtControl) {
        if (buy_status === 3 && reason !== 3) {
          // 不可购买且不可购买原因不是其它
          return hasProductHasDiscount ? 5 : 6
        }

        if (buy_status === 1) {
          // 可购买
          return hasProductHasDiscount ? 5 : 6
        }

        if (buy_status === 2) {
          // 可续费
          if (isHasHistoryEnjoyed) {
            return hasProductHasDiscount ? 7 : 8
          }
          return hasProductHasDiscount ? 9 : 10
        }
      }

      return ''
    },

    // 按钮文案和权益内容的配置对象
    productConfig() {
      const getSpanText = text => `<span class='highlight'>${text}</span>`
      const configMap = new Map([
        [
          1,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26063),
            desc: template(
              getSpanText(this.couponAvailableAmount),
              this.language.SHEIN_KEY_PWA_26071
            )
          }
        ], // 会员无省额有可用卷
        [
          2,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26063),
            desc: this.language.SHEIN_KEY_PWA_26072,
          }
        ], // 会员无省额无可用卷
        [
          3,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26063),
            desc:
              template(getSpanText(this.currentEnjoyedAmount), this.language.SHEIN_KEY_PWA_26069) +
              template(getSpanText(this.couponAvailableAmount), this.language.SHEIN_KEY_PWA_26071)
          }
        ], // 会员有省额有可用卷，
        [
          4,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26063),
            desc: template(
              `<span class='special-height'>${this.currentEnjoyedAmount}</span>`,
              this.language.SHEIN_KEY_PWA_26069
            )
          }
        ], // 会员有省额无可用卷,
        [
          5,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26050),
            desc: template(getSpanText(this.totalDiscountPrice), this.language.SHEIN_KEY_PWA_26065)
          }
        ], // 非会员首次购买或者退订购买有优惠
        [
          6,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26050),
            desc: template(
              getSpanText(this.couponAvailableAmountTotal),
              getSpanText(this.productPriceTotal),
              this.language.SHEIN_KEY_PWA_26066
            )
          }
        ], // 非会员首次购买或者退订购买无优惠
        [
          7,
          {
            btnText: this.language.SHEIN_KEY_PWA_26057,
            desc:
              template(getSpanText(this.historyEnjoyedAmount), this.language.SHEIN_KEY_PWA_26069) +
              template(getSpanText(this.totalDiscountPrice), this.language.SHEIN_KEY_PWA_26070)
          }
        ], // 过期会员续费购买有省额有优惠
        [
          8,
          {
            btnText: this.language.SHEIN_KEY_PWA_26057,
            desc:
              template(getSpanText(this.historyEnjoyedAmount), this.language.SHEIN_KEY_PWA_26069) +
              template(
                getSpanText(this.couponAvailableAmountTotal),
                this.language.SHEIN_KEY_PWA_26068
              )
          }
        ], // 过期会员续费购买有省额无优惠
        [
          9,
          {
            btnText: this.language.SHEIN_KEY_PWA_26057,
            desc: template(getSpanText(this.totalDiscountPrice), this.language.SHEIN_KEY_PWA_26067)
          }
        ], // 过期会员续费购买无省额有优惠
        [
          10,
          {
            btnText: this.language.SHEIN_KEY_PWA_26057,
            desc: template(
              getSpanText(this.couponAvailableAmountTotal),
              this.language.SHEIN_KEY_PWA_26068
            )
          }
        ], // 过期会员续费购买无省额无优惠
        [
          11,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26057),
            desc: template(getSpanText(this.totalDiscountPrice), this.language.SHEIN_KEY_PWA_27431)
          }
        ], // 会员无省额有优惠
        [
          12,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26057),
            desc: template(
              getSpanText(this.pureCouponAvailableAmountTotal),
              getSpanText(this.productPriceTotal),
              this.language.SHEIN_KEY_PWA_27430
            )
          }
        ], // 会员无省额无优惠
        [
          13,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26057),
            desc: template(
              getSpanText(this.currentEnjoyedAmount),
              getSpanText(this.totalDiscountPrice),
              this.language.SHEIN_KEY_PWA_27428
            )
          }
        ], // 会员有省额有优惠，
        [
          14,
          {
            btnText: this.getBtnText(this.language.SHEIN_KEY_PWA_26057),
            desc: template(
              getSpanText(this.currentEnjoyedAmount),
              getSpanText(this.pureCouponAvailableAmountTotal),
              this.language.SHEIN_KEY_PWA_27429
            )
          }
        ],
      ])
      return configMap.get(this.status) ?? {}
    },
    // 可购产品包信息
    canBuyProductInfo() {
      // 如果有推荐产品包，优先取推荐产品包，否则取列表第一个
      const product_info_list = this.detail?.product_info_list ?? []
      const recommendProduct = product_info_list?.filter(item => item.is_recommend === 1)
      return recommendProduct?.length > 0 ? recommendProduct[0] : product_info_list[0] ?? {}
    },

    // 获取生效会员当期可用优惠券数量
    couponAvailableAmount() {
      const right_config_list = this.detail?.paid_detail?.product_info?.right_config_list || []
      const item = right_config_list.find(
        item => item.right_cycle_status === 2 && +item.coupon_available_amount > 0
      )
      return item?.coupon_available_amount ? item.coupon_available_amount + 'X' : 0
    },

    // 获取可购产品包所有期的可用优惠券总量
    couponAvailableAmountTotal() {
      const { right_config_list = [] } = this.canBuyProductInfo || {}
      return (
        right_config_list.reduce((total, item) => {
          total += +item.coupon_num
          return total
        }, 0) + 'X'
      )
    },
    // 获取可购产品包所有期的可用优惠券总量没有X
    pureCouponAvailableAmountTotal() {
      const { right_config_list = [] } = this.canBuyProductInfo || {}
      return right_config_list.reduce((total, item) => {
        total += +item.coupon_num
        return total
      }, 0)
    },

    // 获取产品包的等效价值
    productPriceTotal() {
      return this.canBuyProductInfo?.equivalent_value_with_symbol ?? ''
    },

    // 获取生效会员当前省额
    currentEnjoyedAmount() {
      const { req_currency_price_with_symbol = '', discount_price_with_symbol_usd = '' } =
        this.detail?.paid_detail?.billno_enjoy_privilege_info ?? {}
      return req_currency_price_with_symbol || discount_price_with_symbol_usd
    },

    // 生效会员是否有当前省额
    isHasCurrentEnjoyed() {
      const price_usd = this.detail?.paid_detail?.billno_enjoy_privilege_info?.price_usd ?? 0
      return +price_usd > 0
    },

    // 获取过期会员历史省额
    historyEnjoyedAmount() {
      const { discount_price_with_symbol = '', discount_price_with_symbol_usd = '' } =
        this.detail?.paid_detail?.discount_summary_info ?? {}
      return discount_price_with_symbol || discount_price_with_symbol_usd
    },

    // 过期用户是否有历史省额
    isHasHistoryEnjoyed() {
      const discount_price_usd =
        this.detail?.paid_detail?.discount_summary_info.discount_price_usd ?? 0
      return +discount_price_usd > 0
    },

    // 生效会员可用优惠券数量是否大于0
    isCouponAvailable() {
      const { right_config_list = [] } = this.detail?.paid_detail?.product_info || {}
      return right_config_list.some(
        item => item.right_cycle_status === 2 && +item.coupon_available_amount > 0
      )
    },

    // 可购产品包是否有购买优惠金额
    isProductHasDiscount() {
      const price_discount_rate =
        this.canBuyProductInfo?.product_price_info?.price_discount_rate ?? 0
      return +price_discount_rate > 0
    },

    // 可购产品包购买优惠金额
    productDiscountRate() {
      const price_discount_rate =
        this.canBuyProductInfo?.product_price_info?.price_discount_rate ?? 0
      return price_discount_rate * 100 + '%'
    },

    // 总折扣金额
    totalDiscountPrice() {
      return this.canBuyProductInfo?.product_price_info?.total_discount_price_with_symbol || 0
    },
    // 是临期会员
    isExpiredRecently() {
      return (
        this.detail?.paid_detail?.product_info?.front_status === 1 && this.detail.buy_status === 2
      )
    },
    // 超省卡会员状态
    superExtraStatus() {
      return this.sharedData?.superSaveMemberStatus
    },
  },

  mounted() {
    this.reportOnSuperSavePanelShow()
    this.handleDaEvent()
  },

  methods: {
    reportOnSuperSavePanelShow() {
      const map = new Map([
        [1, 'valid'],
        [2, 'not_effective']
      ])
      const { superExtraStatus } = this.handleDaParams()
      this.$emit('on-extra-show', {
        saver_status: map.get(superExtraStatus) ?? null
      })
      daEventCenter.triggerNotice({
        daId: '1-16-1-32',
        extraData: {
          save_status: map.get(superExtraStatus) ?? null,
          is_club_show: '',
          has_unpaid_order: [4, 5].includes(this.detail?.reason) ? 1 : 0
        }
      })
    },
    // 去超省卡权益详情页
    goToRightDetail() {
      let save_status =
        this.superExtraStatus === 'IS_SUPER_EXTRA'
          ? 'valid'
          : this.superExtraStatus === 'IS_SUPER_EXTRA_EXPIRED'
            ? 'not_effective'
            : null

      const { reason } = this.detail || {}
      daEventCenter.triggerNotice({
        daId: '1-16-1-33',
        extraData: {
          save_status,
          is_club_show: '',
          has_unpaid_order: [4, 5].includes(reason) ? 1 : 0,
        }
      })

      const isLogin = isLoginFn()
      if (isLogin) {
        this.$router.push(`${langPath}/user/sheinsaver?productsource=me`)
      } else {
        SHEIN_LOGIN.show({
          show: true,
          bi: 'saver',
          cb: () => {
            this.$router.push(`${langPath}/user/sheinsaver?productsource=me`)
          }
        })
      }
    },

    handleDaParams() {
      const { buy_status, reason } = this.detail || {}
      let superExtraStatus = ''

      // 1.非会员
      if (buy_status === 1 || (buy_status === 3 && [2, 3].includes(reason))) {
        superExtraStatus = 0
      }

      // 2.会员
      if (buy_status === 3 && reason === 1) {
        superExtraStatus = 1
      }

      // 3.过期会员
      if (buy_status === 2) {
        superExtraStatus = 2
      }

      return {
        superExtraStatus,
      }
    },

    // 处理埋点
    handleDaEvent() {
      const { buy_status } = this.detail || {}
      const { superExtraStatus } = this.handleDaParams()

      // 非超省卡用户满足可购资格时上报
      if (superExtraStatus !== 1 && buy_status !== 3) {
        daEventCenter.triggerNotice({
          daId: '1-16-1-31',
          extraData: {
            is_buyable_status: buy_status
          }
        })
      }
    },
    getBtnText(defalutText) {
      // 未支付引导
      const { buy_status, reason } = this.detail || {}
      if(buy_status == 3 && [4, 5].includes(reason) ) {
        return this.language.SHEIN_KEY_PWA_28693
      } else {
        return defalutText
      }
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-nested-pattern, declaration-no-important, selector-max-specificity, selector-max-type, declaration-property-value-blacklist  */
.super-extra-wrap {
  background: #fff;
  margin: 0.2133rem 0.32rem 0 0.32rem;
}
.super-extra {
  border: 0.0267rem solid rgba(255, 202, 214, 0.8);
  border-radius: 0.16rem;

  background: linear-gradient(270deg, #ffedf1 0%, #fcf2ff 100%);

  &__layer {
    position: relative;
    background-image: url('/pwa_dist/images/user/extra/left-layer-63d85c3d3a.png'),
      url('/pwa_dist/images/user/extra/right-layer-0adf0765ef.png');
    background-repeat: no-repeat;
    background-position: left top, right bottom;
    background-size: contain, contain;
  }

  &__layerAr {
    position: relative;
    background-image: url('/pwa_dist/images/user/extra/ar-left-layer-3a525e7679.png'),
      url('/pwa_dist/images/user/extra/ar-right-layer-85c1100e0a.png');
    background-repeat: no-repeat;
    background-position: left top, right bottom;
    background-size: contain, contain;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-right: 0.1067rem;

    &-left {
      position: relative;
      display: inline-flex;
      height: 0.6667rem;
      padding: 0 0 0 0.2133rem;
      align-items: center;
      flex-shrink: 0;
      border-radius: 0.16rem 0rem 0.32rem 0rem;
      background: linear-gradient(
        89deg,
        rgba(255, 43, 119, 0.08) 25.9%,
        rgba(204, 49, 198, 0.08) 68.09%,
        rgba(252, 86, 255, 0.08) 100%
      );

      .logo {
        width: 2.4rem;
        height: 0.5067rem;
      }

      .add-pr {
        margin-right: 0.2133rem;
      }

      .status {
        display: flex;
        padding: 0.0533rem 0.16rem;
        align-items: center;
        border-radius: 0.56rem;
        line-height: normal;
        border: 0.0267rem solid rgba(128, 135, 193, 0.5);
        background: #ecf1fd;
        color: #5d6492;
        font-weight: 400;
        font-size: 0.2667rem;
        transform: scale(0.8);
      }
      .ending-soon {
        position: absolute;
        border-radius: 0.312rem;
        border: 1px solid rgba(51, 51, 51, 0.3);
        background-color: rgba(19, 18, 71, 0.05);
        right: -0.2rem;
        transform: translateX(100%);
        top: 0.182rem;
        display: block;
        align-items: center;
        padding: 0 0.156rem;
        font-size: 0.26rem;
        font-weight: 400;
      }
      .ending-soon__text {
        background-image: linear-gradient(
          90deg,
          rgba(248, 40, 84, 0.8) 3.8%,
          rgba(248, 11, 203, 0.8) 49.39%,
          rgba(223, 29, 255, 0.8) 96.2%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &-right {
      margin-right: 0.1067rem;
      position: relative;
      margin-top: 0.1067rem;
      color: #240b44;
      line-height: normal;
      font-style: normal;

      .operation-btn {
        display: inline-flex;
        padding: 0.0533rem 0.2133rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.4267rem;
        border: 0.0267rem solid #240b44;
        font-size: 0.32rem;
        font-family: 'SF UI Display';
        font-weight: 600;
      }
      .operation-text {
        display: flex;
        font-weight: 400;
        align-items: center;
      }
      .special-offer {
        position: absolute;
        top: -0.3733rem;
        right: 0;
        z-index: 99;
        border-radius: 0.0533rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, #f93776 0%, #d52bff 100%);
        .special-offer__text {
          white-space: nowrap;
          color: #fff;
          font-family: 'SF UI Display';
          font-size: 9px;
          font-style: normal;
          font-weight: 700;
          padding: 0.0267rem 0.1067rem;
        }
        .special-offer__arrow {
          width: 0.16rem !important;
          height: 0.1067rem !important;
          position: absolute;
          bottom: -0.1067rem;
          left: 75%;
        }
      }
    }
  }

  &__content {
    font-size: 0.32rem;
    font-weight: 400;
    color: #240b44;
    padding: 0.25rem 0.2133rem 0.25rem 0.2667rem;
    display: flex;
    position: relative;
    &-left {
      .highlight {
        color: #f82854;
        font-weight: 600;
      }
      .special-height {
        color: #240b44;
        text-align: center;
        font-family: SF UI Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  & .remove-pd {
    padding-top: 0.13rem;
    padding-bottom: 0.13rem;
  }
}
</style>
