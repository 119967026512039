import { ref, computed } from 'vue'
import { getNewsListCccConfigApi } from 'public/src/pages/user_index/api'

export const initNewsListModule = () => {
  const { SiteUID, lang } = gbCommonInfo
  const newsListRawConfig = ref(null)
  const newsBarInfo = computed(() => (newsListRawConfig.value?.content || []).find(item => item?.styleType === 'NEWS_SECTION'))
  const newsListConfig = computed(() => {
    const { content, pageType } = newsListRawConfig.value || {}
    if (!content?.length) return
    return {
      cateLinks: {},
      content,
      context: {
        WEB_CLIENT,
        SiteUID,
        lang
      },
      sceneData: {
        pageFrom: 'pageMe',
        pageType,
        sceneName: pageType,
      }
    }
  })
  const getNewsListCccConfig = async () => {
    const cccConfig = await getNewsListCccConfigApi()
    if (!cccConfig) return
    newsListRawConfig.value = cccConfig
  }

  return {
    newsBarInfo,
    newsListConfig,
    getNewsListCccConfig,
  }
}
