<template>
  <div
    v-if="showFusionFloor"
    class="rights-fusion-floor__wrapper"
  >
    <div
      v-for="rightsType in showUserRights"
      :key="rightsType"
      class="rights-fusion-floor__item"
    >
      <PrimePannel
        v-if="rightsType === UserRightsFusionType.SHEIN_CLUB_MEMBER && showSheinClubMemberPanel"
        class="rights-fusion-floor__prime"
        :style="{
          width: `${fusionItemStyles.containerWidth}rem`,
        }"
        context="fusion-floor"
        :fusion-floor-position="primePanelPosition"
        v-bind="primePanelBindData"
      />
      <NewSuperSavePanel
        v-if="rightsType === UserRightsFusionType.SUPER_SAVE && showSuperSavePanel"
        v-expose="{
          id: '1-25-2-29',
          data: superSaveReportParams,
        }"
        :style="{
          width: `${fusionItemStyles.containerWidth}rem`,
        }"
        :super-save-data="superSavePanelData"
        :computed-styles="fusionItemStyles"
        :report-params="superSaveReportParams"
      />
    </div>
  </div>
</template>

<script setup name="UserRightsFusionFloor">
import { computed } from 'vue'
import NewSuperSavePanel from './NewSuperSavePanel.vue'
import PrimePannel from 'public/src/pages/user/child_pages/dashboard/components/UserRightsPanel/PrimePanel.vue'
import { UserRightsFusionType } from 'public/src/pages/user/child_pages/dashboard/service/constants'
import { getCouponStyles } from 'public/src/pages/user/child_pages/dashboard/service/user-rights-fusion'

const props = defineProps({
  userRightsFusion: {
    type: Object,
    default: () => ({}),
  },
  primePanelBindData: {
    type: Object,
    default: () => ({}),
  },
})

const fusionPriority = computed(() => {
  const { priority } = props.userRightsFusion || {}
  return priority?.length ? priority : [
    UserRightsFusionType.SHEIN_CLUB_MEMBER,
    UserRightsFusionType.SUPER_SAVE,
  ]
})
const showUserRights = computed(() => {
  const priorities = fusionPriority.value
  return priorities.filter(type => {
    if (showSheinClubMemberPanel.value && (type === UserRightsFusionType.SHEIN_CLUB_MEMBER)) {
      return true
    }
    if (showSuperSavePanel.value && (type === UserRightsFusionType.SUPER_SAVE)) {
      return true
    }
    return false
  })
})
const primePanelPosition = computed(() => {
  const index = fusionPriority.value.findIndex?.(type => type === UserRightsFusionType.SHEIN_CLUB_MEMBER)
  if (index < 0) return
  return index + 1
})

const showFusionFloor = computed(() => showUserRights.value.length > 0)
const showSheinClubMemberPanel = computed(() => props.primePanelBindData?.showSheinClubMemberPanel)
const superSavePanelData = computed(() => props.userRightsFusion?.fusedSuperSaveData)
const showSuperSavePanel = computed(() => !!superSavePanelData.value)
const fusionItemStyles = computed(() => {
  const fusionItemCount = showUserRights.value.length
  const containerWidth = (fusionItemCount > 1 ? 270 : 351) / 37.5
  const couponStyles = getCouponStyles(superSavePanelData.value, fusionItemCount)
  return {
    containerWidth,
    ...couponStyles,
  }
})

const superSaveReportParams = computed(() => {
  const { isSuperSaveMember } = superSavePanelData.value || {}
  const position = showUserRights.value.findIndex(type => type === UserRightsFusionType.SUPER_SAVE)
  return {
    is_club_show: showSheinClubMemberPanel.value ? 1 : 0,
    saver_status: isSuperSaveMember ? 'valid' : 'null',
    position: position > -1 ? position + 1 : '',
  }
})
</script>

<style lang="less" scoped>
.rights-fusion-floor {
  &__wrapper {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 7/37.5rem 12/37.5rem 0;
    margin-top: -7/37.5rem;
    &::-webkit-scrollbar {
      height: 0;
    }
  }
  &__item:not(:first-child) {
    margin-left: 6/37.5rem;
  }
  &__prime {
    height: 100%;
    /deep/ .atmosphere-bg {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .paid-vip-content {
        flex: 1;
      }
    }
    /deep/ .paid-vip-ctn {
      height: 80/37.5rem;
      margin: 0;
    }
  }
}
</style>

