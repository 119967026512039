import { ref } from 'vue'
import { fetchFooterRcmdListCccConfig } from '../api'

export const FooterRcmdListResolveStatus = {
  PENDING: 0,
  HAS_RCMD_LIST: 1,
  NO_RCMD_LIST: 2,
}

export const initFooterRcmdListModule = () => {
  const footerRcmdListResolveStatus = ref(FooterRcmdListResolveStatus.PENDING)
  const footerRcmdCccConfig = ref(null)
  const getFooterRcmdListCccConfig = async ({
    resetResolveStatus = true,
  } = {}) => {
    if (resetResolveStatus) {
      footerRcmdListResolveStatus.value = FooterRcmdListResolveStatus.PENDING
    }
    const cccConfig = await fetchFooterRcmdListCccConfig()
    if (footerRcmdCccConfig.value) {
      footerRcmdCccConfig.value = null
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        footerRcmdCccConfig.value = cccConfig
        footerRcmdListResolveStatus.value = !!cccConfig
          ? FooterRcmdListResolveStatus.HAS_RCMD_LIST
          : FooterRcmdListResolveStatus.NO_RCMD_LIST
        resolve()
      }, 20)
    })
  }
  return {
    footerRcmdListResolveStatus,
    footerRcmdCccConfig,
    getFooterRcmdListCccConfig,
  }
}
