<template>
  <CommonWrapper
    :cccx-config="cccxConfig"
    v-expose="{
      id: '1-25-2-26',
      data: {
        module_nm: 'new_user_promo',
        opt_nm: ugActivityType || 0,
        content_list: cccxConfig?.jumpUrl || '',
      },
    }"
  >
    <div
      class="new-user-benefits__banner"
      @click.stop="onBannerClick"
    ></div>
  </CommonWrapper>
</template>

<script setup name="SingleBanner">
import CommonWrapper from './CommonWrapper.vue'
import { useCccxConfig } from 'public/src/pages/user/child_pages/dashboard/hooks/new-user-benefits'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  cccxConfig: {
    type: Object,
    default: {},
  },
  ugActivityType: {
    type: Number,
    default: 0,
  },
})

const onBannerClick = () => {
  const { cccxConfig } = props
  const { jumpUrl } = useCccxConfig(cccxConfig)
  setTimeout(() => {
    location.href = jumpUrl
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-2-27',
    bindData: {
      module_nm: 'new_user_promo',
      opt_nm: props.ugActivityType || 0,
      content_list: cccxConfig?.jumpUrl || '',
    },
  })
}
</script>

<style lang="less" scoped>
.new-user-benefits {
  &__banner {
    height: 111/37.5rem;
  }
}
</style>
