var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rights-fusion-floor__wrapper"},_vm._l((_setup.cardList),function(card,i){return _c('div',{key:i,staticClass:"rights-fusion-floor__item"},[(card.cardType === _setup.UserRightsCardType.MEMBER_CARD)?_c(_setup.MemberCard,{staticClass:"rights-fusion-floor__prime",style:({
        width: `${_setup.fusionItemStyles.containerWidth}rem`,
      }),attrs:{"context":"fusion-floor","card-info":card,"payback-info":_vm.rightsInfo?.payback_info,"renew-popup-info":_vm.rightsInfo?.renew_pop_up_info}}):_vm._e(),_vm._v(" "),(card.cardType === _setup.UserRightsCardType.SUPER_SAVE)?_c(_setup.NewSuperSavePanel,{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '1-25-11-29',
        data: _setup.superSaveReportParams,
      }),expression:"{\n        id: '1-25-11-29',\n        data: superSaveReportParams,\n      }"}],style:({
        width: `${_setup.fusionItemStyles.containerWidth}rem`,
      }),attrs:{"card-info":card,"computed-styles":_setup.fusionItemStyles,"report-params":_setup.superSaveReportParams}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }