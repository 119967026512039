<script lang="jsx">
import {
  sui_icon_me_support_24px,
  sui_icon_me_exchange_24px,
  sui_icon_me_survey_24px,
  sui_icon_me_shareearn_24px,
  sui_icon_me_freetrial_24px,
  sui_icon_me_myreference_24px,
  sui_icon_me_brandambassador_24px,
  sui_icon_me_friednshipcoupon_24px,
  sui_icon_me_csr_24px,
  sui_icon_me_following_24px,
  sui_icon_me_follow_24px,
  sui_icon_me_trial_24px,
  sui_icon_me_affiliate_24px,
} from '@shein-aidc/icon-vue2'

import { mapState } from 'vuex'
import { htmlDecode, transformImg } from '@shein/common-function'
import { register } from 'swiper/element'
import { Pagination } from 'swiper/modules'
const { host, langPath, GB_cssRight } = gbCommonInfo
typeof window !== 'undefined' && register()
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  components: {
    sui_icon_me_support_24px,
    sui_icon_me_exchange_24px,
    sui_icon_me_survey_24px,
    sui_icon_me_shareearn_24px,
    sui_icon_me_freetrial_24px,
    sui_icon_me_myreference_24px,
    sui_icon_me_brandambassador_24px,
    sui_icon_me_friednshipcoupon_24px,
    sui_icon_me_csr_24px,
    sui_icon_me_following_24px,
    sui_icon_me_follow_24px,
    sui_icon_me_trial_24px,
    sui_icon_me_affiliate_24px,
  },
  props: {
    moreServices: {
      type: Object,
      default: null
    },
    isRisk: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean,
      default: false
    },
    isOpenFreeTrialEntry: {
      type: Boolean,
      default: false
    },
    isShowFollowUnReadDot: {
      type: Boolean,
      default: false
    },
    openRiskDrawer: {
      type: Function,
      default: () => {}
    },
    handleRouterPush: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      swiperInstance: null,
      realIndex: 0,
    }
  },
  computed: {
    ...mapState('dashboard', ['isRouterLeaveFlag']),
  },
  watch: {
    moreServices: {
      handler (val) {
        this.initSwiper(val.iconsGroup.icons)
      },
      deep: true
    },
    isRouterLeaveFlag (val) {
      if (!val) {
        this.swiperInstance.destroy()
        this.swiperInstance = null
        this.initPageEvt()
      }
    }
  },
  mounted () {
    this.initPageEvt()
    // 登录成功触发
    appEventCenter.$on('initUserInfo', () => {
      this.initPageEvt()
    })
  },
  methods: {
    initPageEvt () {
      if (this.moreServices.iconsGroup.icons.length > 1) {
        this.initSwiper(this.moreServices.iconsGroup.icons)
      }
    },
    initSwiper (val) {
      if (!this.swiperInstance) {
        const swiperEl = this.$refs.moreServiceSwiperRef
        if (!swiperEl) return // 没有swiper数据直接返回
        const swiperConfig = {
          modules: val.length > this.moreServices.iconsGroup.rowCount ? [Pagination] : [],
          pagination: val.length > this.moreServices.iconsGroup.rowCount ? {
            el: '.more-service-pagination',
            bulletClass: 'more-service-bullet',
            bulletActiveClass: 'more-service-bullet-active',
          } : {},
          spaceBetween: 8,
          initialSlide: this.realIndex
        }
        Object.assign(swiperEl, swiperConfig)
        swiperEl.initialize()
        this.swiperInstance = swiperEl.swiper
      } else {
        this.$nextTick(() => {
          this.swiperInstance.update()
        })
      }
    },
  },
  render() {
    // 跳转页面
    const handleSectionItemClick = item => {
      if (item.type == 'survey') {
        daEventCenter.triggerNotice({ daId: '1-16-1-7' })
      }
      if (item.type == 'free_trail_list') {
        handleGoTrialCenter(item)
        return
      }
      item.jumpUrl && this.handleRouterPush(item.jumpUrl, '', '', item?.jumpIgnoreLogin || 0)
    }

    // 试用中心特殊跳转逻辑
    const handleGoTrialCenter = () => {
      if (this.isRisk) return this.openRiskDrawer()
      if (this.isOpenFreeTrialEntry)
        return (location.href = host + langPath + '/free-trial-center/index')

      if (this.isLogin) {
        location.href = host + langPath + '/free-trial-center/my-center'
      } else {
        window.SHEIN_LOGIN.show({
          show: true,
          reload: false,
          from: 'freeTrial',
          ga: 'freetrial',
          cb: () => {
            location.href = host + langPath + '/free-trial-center/my-center'
          }
        })
      }
    }

    const iconSection = ({ fontSize, iconType, iconUrl }) => {
      if (iconType == 2) {
        return (
          <img class="more-service__item-img" src={transformImg({ img: iconUrl })} />
        )
      }
      if (iconType == 3) {
        return (
          <i
            class={`more-service__item-img ${iconUrl}`}
            style={`font-size: ${fontSize}px`}
          ></i>
        )
      }
      return (
        <iconUrl size="24px" />
      )
    }

    // 小红点
    const redPoint = ({ type, badgetConfigs = null }) => {
      let styleObj = ''
      if (badgetConfigs?.style && badgetConfigs?.cssRightStyle) {
        const styleBaseConfig = badgetConfigs[GB_cssRight ? 'cssRightStyle' : 'style']
        styleObj = Object.keys(styleBaseConfig).map(key => `${key}: ${styleBaseConfig[key]}`).join(';') + ';'
      }
      // 特殊处理
      const specialHandler = () => {
        // Followling 小红点配置
        if (this.isShowFollowUnReadDot && type == 'follow_list') return true
        return false
      }
      if (badgetConfigs?.isShow || specialHandler()) {
        return (
          <span
            class="red-point"
            style={styleObj}
          >
            {badgetConfigs?.txt || ''}
          </span>
        )
      }
    }

    const renderSectionListItem = (item, index) => {
      const baseAnalysisData = { title: item.type, position: index + 1 }
      return (
        <swiper-slide
          v-expose={{
            id: '1-25-2-21',
            data: baseAnalysisData
          }}
          v-tap={{
            id: '1-25-2-22',
            data: baseAnalysisData
          }}
          key={item.type}
          class="more-service__item"
          data-param={item?.badgetConfigs?.isShow && this.isLogin ? 1 : 0}
          vOn:click={() => handleSectionItemClick(item)}
        >
          {iconSection(item.iconObject)}
          {redPoint(item)}
          <p class="more-service__item-title">{htmlDecode({ text: item.titleTxt })}</p>
        </swiper-slide>
      )
    }
    const renderSectionTitle = () => {
      return (
        <div class="module-content-title">
          <h3 tabindex="0">
            {this.moreServices.moduleNameText}
          </h3>
        </div>
      )
    }
    return (
      <li class="module-content my-entry-block more-service__wrap fsp-element">
        {renderSectionTitle()}
        <swiper-container
          class="more-service__list"
          ref="moreServiceSwiperRef"
          init="false"
          slides-per-view={this.moreServices.iconsGroup.rowCount}
          pagination="true"
        >
          {this.moreServices.iconsGroup.icons.map((item, index) => renderSectionListItem(item, index))}
        </swiper-container>
        <div class="more-service-pagination"></div>
      </li>
    )
  }
}
</script>

<style lang="less">
swiper-container::part(container) {
  overflow: visible;
}
.more-service {
  &__wrap {
    width: 10rem;
    overflow: hidden;
    .more-service-pagination {
      text-align: center;
      padding-bottom: 4px;
    }

    .more-service-bullet {
      width: .32rem;
      height: .08rem;
      display: inline-block;
      background: #e5e5e5;
    }

    .more-service-bullet-active { // stylelint-disable-line
      background: #666;
    }
  }

  &__list {
    padding: .2133rem .2133rem 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .red-point {
      position: absolute;
      top: -0.16rem;
      right: 30%;
      padding: .05rem;
      display: inline-block;
      border: 1px solid @sui_color_guide;
      border-radius: 0.08rem;
      background: @sui_color_guide;
      color: #fff;
    }
  }
  &__item-img {
    width: .64rem;
    height: .64rem;
  }
  &__item-title {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
