<script lang="jsx">
import { isLogin } from 'public/src/pages/common/utils/index.js'
import UnpaidOrder from './UnpaidOrder.vue'
import UnpaidOrderV2 from './UnpaidOrderV2.vue'
import UnReviewOrder from './UnReviewOrder.vue'
import schttp from 'public/src/services/schttp'
let { langPath, language, SiteUID, UNREVIEW_NOT_FIRST_SHOW, USER_CENTER_UNPAID_ORDER_CONFIG, shein_suggested_sale_price } = gbCommonInfo
import { clearPoskey } from '@shein-aidc/basis-abt-router'
import { abtservice } from 'public/src/services/abt'
import { getLocalStorage, setLocalStorage, discountPercentNum, throttle } from '@shein/common-function'
import { mapState, mapMutations } from 'vuex'
import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()

const { PUBLIC_CDN } = gbCommonInfo

// USER_CENTER_UNPAID_ORDER_CONFIG = {
//   rank: 1, // 门槛
//   comment_num: 1, // 精彩评论门槛数量
//   comment_label_num: 1, // 精彩评论个数
//   times: 3, // 弹窗次数
//   intervel: .2 // 弹窗间隔（30分钟）
// }

export default {
  name: 'OrderPannel',
  components: {
    UnpaidOrder,
    UnpaidOrderV2,
    UnReviewOrder,
    UnpaidDialogWrap: () => import(/* webpackChunkName: "unpaid-dialog" */ /* @vite-ignore */ './UnpaidDialog/UnpaidDialogWrap.vue')
  },
  props: {
    unpaidOrder: {
      type: Array,
      default: () => []
    },
    unpaidOrderAbt: {
      type: Object,
      default () {
        return {}
      }
    },
    unReviewOrder: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      language,
      cutDownObj: {},
      newSwiper: null,
      exposeBillnos: [],
      dialogOrderItem: null,
      unpaidOrderType: '',
      countdownEnd: [], // 倒计时订单
      unReviewList: [], // 待评论列表
      first_comment_flag: '', // 后端返回的abt值
      swiperInstance: null,
      realIndex: 0,
      unpaidDialogHasClicked: false,
      unpaidOrderRects: {},
    }
  },
  computed: {
    ...mapState('dashboard', ['isRouterLeaveFlag']),
    ...mapState('root', ['userInfo', 'userCenterLanguage']),
    newUnpaidOrders () {
      // 未支付订单过滤
      const newUnpaidOrders = []
      if (this.unpaidOrderType && this.unpaidOrderType != 'None') {
        this.unpaidOrder.forEach(item => {
          const { expire_countdown } = item
          const endTime = Number(expire_countdown)
          const nowTime = Date.parse(new Date()) / 1000
          if (nowTime < endTime && !this.countdownEnd.includes(item.billno)) {
            newUnpaidOrders.push(item)
          }
        })
      }
      return newUnpaidOrders
    },
    // 待支付与待评论列表总和
    totalList () {
      let list = []
      if (this.newUnpaidOrders.length > 0) {
        list = list.concat(this.newUnpaidOrders)
      }
      if (this.unReviewList.length > 0) {
        list = list.concat(this.unReviewList)
      }
      return list
    },
    unpaidOrderVersion () {
      const abtBranch = this.unpaidOrderAbt?.PopType ?? 'Default'
      if(abtBranch == 'Default') {
        return 'v1'
      } else {
        return 'v2'
      }
    },
    isNewFloor() {
      return this.unpaidOrderAbt?.FloorType == 'On' && !shein_suggested_sale_price[SiteUID]
    },
    isShowUnpaidDialog() {
      return !!this.dialogOrderItem
    },
    isNewUnpaidDialog() {
      const abtBranch = this.unpaidOrderAbt?.PopType ?? 'Default'
      return abtBranch != 'Default'
    },
    unpaidOrderActiveClass() {
      if(this.isShowUnpaidDialog && this.isNewUnpaidDialog && this.isNewFloor) {
        return this.unpaidDialogHasClicked ? 'unpaid-order-box__show' : 'unpaid-order-box__hide'
      }
      return 'unpaid-order-box__default'
    },
  },
  watch: {
    totalList: {
      handler (newVal, oldVal) {
        if (newVal.length) {
          this.$nextTick(() => {
            this.initSwiper(newVal, oldVal)
            this.unpaidOrderRects = this.$refs.unpaidOrderRef?.getBoundingClientRect()
          })
        }
      },
      deep: true
    },
    unReviewOrder(){
      this.handleUnReviewInfo()
    },
    unpaidOrderActiveClass(newVal) {
      if(newVal == 'unpaid-order-box__show') {
        const indexActive = this.totalList.findIndex(el => el.billno == this.dialogOrderItem.billno)
        this.swiperInstance?.slideTo(indexActive)
        this.swiperInstance?.loopCreate()
        this.swiperInstance?.autoplay?.start()
      }
    },
  },
  mounted() {
    // 登录成功触发
    appEventCenter.$on('initUserInfo', () => {
      this.initPageEvt()
    })
    this.initPageEvt()
  },
  activated() {
    this.swiperInstance?.loopCreate()
    this.swiperInstance?.autoplay?.start()
  },
  destroyed() {
    Object.keys(this.cutDownObj).forEach(key => {
      clearInterval(this.cutDownObj[key])
    })
  },
  methods: {
    ...mapMutations('dashboard', ['updateDialogName']),
    // 弹窗展示逻辑
    showDialog () {
      let flag = false
      if (this.unpaidOrderType != 'TypeB') flag = true
      if (this.newUnpaidOrders.length == 0) flag = true
      if (this.isRouterLeaveFlag) flag = true
      if(!this.userInfo?.member_id) flag = true
      let [
        dialogOrderItem,
        cacheKey
      ] = [null, `unpaid_dialog_cache_${SiteUID}_${this.userInfo?.member_id}`]
      let cache = getLocalStorage(cacheKey) || {}
      let billnos = cache?.billnos || []
      // 第二天重置次数
      // 当天结束时间
      const dayEndTime = new Date(new Date(USER_CENTER_UNPAID_ORDER_CONFIG.time).toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
      if (Date.now() > dayEndTime) {
        cache.count = 0
      }
      // 次数限制
      if (!USER_CENTER_UNPAID_ORDER_CONFIG.times || (cache?.count >= USER_CENTER_UNPAID_ORDER_CONFIG.times)) flag = true
      // 频率限制
      if (!USER_CENTER_UNPAID_ORDER_CONFIG.intervel || (cache?.time > Date.now() - USER_CENTER_UNPAID_ORDER_CONFIG.intervel * 60 * 1000)) flag = true
      // 获取订单数据
      this.newUnpaidOrders.forEach(item => {
        if (
          !billnos.includes(item.billno) // 是否弹出过
          && !dialogOrderItem // 取最近的第一个
        ) {
          billnos.push(item.billno)
          dialogOrderItem = item
        }
      })
      // 过滤历史数据
      billnos = billnos.filter(_ => this.newUnpaidOrders.some(val => val.billno == _ ))
      if (!dialogOrderItem || flag) {
        this.updateDialogName('next-renew')
        return
      }
      this.dialogOrderItem = dialogOrderItem
      // 设置缓存
      setLocalStorage({
        key: cacheKey,
        value: {
          count: cache?.count ? cache.count + 1 : 1,
          time: Date.now(),
          billnos
        }
      })
    },
    initSwiper(newVal, oldVal) {
      if(!this.swiperInstance) {
        const swiperConfig = {
          modules: [Pagination, Autoplay],
          injectStylesUrls: [
            PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
          ],
          injectStyles: [
          `.unpaid-swiper-order {
            overflow: visible;
          }
          `,
          `.swiper-pagination {
            position: static !important;
          }
          `,
          `.swiper-pagination-bullet {
            width: 0.585rem !important;
            height: 0.08rem !important;
            display: inline-block !important;
            background: #302b2b !important;
            border-radius: 0 !important;
            margin: 0 !important
          }
          `,
          `.swiper-pagination-bullet-active {
            background: #666 !important;
          }
          `,
          `.swiper-pagination-lock {
            background: transparent;
            display: ${this.newUnpaidOrders.length == 1 ? 'block' : 'none'};
            height: 18px;
          }
          `,
          ],
          spaceBetween: 20,
          initialSlide: this.realIndex
        }
        const swiperEl = this.$refs.swiper
        Object.assign(swiperEl, swiperConfig)
        swiperEl.initialize()
        this.swiperInstance = swiperEl.swiper
        const isStopAutoplay = this.unpaidOrderActiveClass == 'unpaid-order-box__hide'
        if(isStopAutoplay) {
          this.swiperInstance.autoplay.stop()
        }
      } else {
        // 在 Swiper 中使用循环模式（loop）时，会遇到一个已知问题：由于 Swiper 为了实现循环效果，在轮播的开始和结束处各复制了若干张幻灯片，当手动快速滑动到轮播的开始或结尾时，可能会导致内容的不一致。这通常发生在从最后一张滑动到第一张或者从第一张滑动到最后一张时
        if(oldVal.length == 1 && newVal.length > 1) {
          this.swiperInstance.loopDestroy()
          this.swiperInstance.loopCreate()
          this.swiperInstance.autoplay.start()
        }
        this.swiperInstance.update()
      }
    },
    initPageEvt: throttle({
      func: async function() {
        if (!isLogin()) { return false }
        clearPoskey({ posKeys: ['unpaidOrderMe'] })
        // eslint-disable-next-line
        const abtInfo = await abtservice.getUserAbtResult({ newPosKeys: 'unpaidOrderMe' })
        this.unpaidOrderType = abtInfo.unpaidOrderMe?.param?.unpaidOrderMe || ''
        await this.getData()
        this.showDialog()
        if (this.newUnpaidOrders.length) {
          daEventCenter.triggerNotice({
            daId: '1-25-2-1006',
            extraData: {
              scenes: 'unpaidOrderMe',
              order_id_list: this.newUnpaidOrders.map(item => item.billno).join('`'),
            }
          })
          this.analysisData(0)
          this.exposeData()
        }
      },
      wait: 100,
      options: {
        leading: false,
      }
    }),
    // swiper切换回调
    silderChangeCallback () {
      this.realIndex = this.swiperInstance?.realIndex
      this.analysisData(this.realIndex)
    },
    exposeData() {
      daEventCenter.triggerNotice({
        daId: '1-25-2-1012',
        extraData: {
          scene: this.isNewFloor ? 'new_floor' : 'unpaid'
        }
      })
    },
    analysisData(realIndex) {
      if (this.exposeBillnos.includes(this.totalList[realIndex]?.billno)) return
      const isUnReview = (realIndex + 1) > this.newUnpaidOrders.length
      daEventCenter.triggerNotice({
        daId: '1-25-2-1000',
        extraData: {
          order_id: this.totalList[realIndex]?.billno,
          floor_type: isUnReview ? 'waitingReview' : this.isNewFloor ? 'new_floor' : 'unpaid',
          is_first_review: isUnReview ? (this.totalList[realIndex]?.isFirst ? 1 : 0)  : '-',
          discount_status: (isUnReview || !this.isNewFloor) ? '-' : (this.totalList[realIndex]?.total_discount_new ? 1 : 0),
        }
      })
      this.exposeBillnos.push(this.totalList[realIndex]?.billno)
    },
    getData () {
      this.handleUnReviewInfo()
      // 未支付订单场景埋点
      daEventCenter.triggerNotice({
        daId: '1-22-2-1009',
        extraData: {
          scenes: 'unpaidOrderMe',
          order_id_list: this.newUnpaidOrders.map(item => item.billno).join('`'),
        }
      })
    },
    // 处理待评论列表
    async handleUnReviewInfo() {
      const { unReviewInfoResult, unReviewPointsResult, imgs, billnoList } = this.unReviewOrder
      // UNREVIEW_TIME
      if (unReviewInfoResult?.code == 0 && unReviewInfoResult?.info?.data?.length && unReviewPointsResult?.code == 0) {
        const contentListObj = this.handleUnReviewRewards(unReviewPointsResult)
        const { contentList, isFirstList } = contentListObj
        if(!contentList?.length) return
        contentList.map((_, index) => {
          this.unReviewList.push(
            {
              img: imgs[index],
              content: contentList[index],
              billno: billnoList[index],
              isFirst: isFirstList[index]
            }
          )
        })

        // 曝光埋点上报
        daEventCenter.triggerNotice({
          daId: '1-25-2-1002',
          extraData: {
            scenes: this.first_comment_flag ? 'firstReview' : 'review',
            order_id_list: billnoList.join('`'),
          }
        })
      }
    },
    // 处理待评论奖励
    handleUnReviewRewards(result) {
      if(result?.code == 0) {
        const contentList = []
        const isFirstList = []
        const info = result.info
        info?.point_rewards?.forEach((el) => {
          let contentItem = this.template(this.getStrongOrange(el.point), language.SHEIN_KEY_PWA_26431) 
          contentList.push(contentItem)
          isFirstList.push(false)
        })
        //后端返回abt值
        this.first_comment_flag = info.first_comment_flag || 0
        // 存在待评论优惠，则第一个积分改为券展示
        if(contentList.length > 0 && info.coupon_reward) {
          const { rules = [], discount_type } = info.coupon_reward
          if(!rules.length) return
          let contentText = ''
          const ruleItem = rules[rules.length - 1]
          if([1, 2, 5].includes(discount_type)) { // 优惠券类型
            contentText = discountPercentNum({ val: ruleItem.value, percent: true })
          } else {
            contentText = ruleItem.value_amount ?? ''
          }
          const contentItem = this.template(this.getStrongOrange(contentText), this.language.SHEIN_KEY_PWA_26430)
          contentList.splice(0, 1, contentItem)
          isFirstList.splice(0, 1, true)
        }
        // 非首单奖励全部过滤掉
        if(!UNREVIEW_NOT_FIRST_SHOW) {
          if(isFirstList[0]) {
            return { contentList: [contentList[0]], isFirstList: [isFirstList[0]] }
          } else {
            return { contentList: [], isFirstList: [] }
          }
        }

        return { contentList, isFirstList }
      }
    },
    getStrongOrange(text) {
      return `<strong class="strong-orange">${text}</strong>`
    },
    // 获取待支付列表
    async fetchUnpaidOrderInfo() {
      try {
        const data = await schttp({
          url: '/api/orders/base/getUnpaidOrderByStatus/query',
        })
        return data
      } catch (error) {
        return []
      }
    },
    // 跳转订单详情
    goToDetail (item = {}) {
      const { billno, isFirst, comType, total_discount_new } = item 
      if (comType == 1) {
        this.$router.push(`${langPath}/user/orders/detail/${billno}?goBack=1`)
      } else {
        this.$router.push(`${langPath}/user/orders/order_review?billno=${billno}&review_page_location=individual`)
      }
      const isUnReview = (this.realIndex + 1) > this.newUnpaidOrders.length
      daEventCenter.triggerNotice({
        daId: '1-25-2-1001',
        extraData: {
          order_id: billno,
          floor_type: comType == 1 ? (this.isNewFloor ? 'new_floor' : 'unpaid') : 'waitingReview',
          is_first_review: comType == 1 ? '-' : isFirst ? 1 : 0,
          iscount_status: comType == 1 ? (total_discount_new ? 1 : 0) : '-',
          discount_status: (isUnReview || !this.isNewFloor) ? '-' : (this.totalList[this.realIndex]?.total_discount_new ? 1 : 0),
        }
      })
    },
    clickCloseDialog() {
      this.unpaidDialogHasClicked = true
      this.swiperInstance?.slideTo(0)
    },
  },
  render () {
    const itemEl = (item) => {
      if (item.comType == 1) {
        if(this.isNewFloor) {
          return (
            <UnpaidOrderV2
              item={item}
              userCenterLanguage={this.userCenterLanguage}
              configs={USER_CENTER_UNPAID_ORDER_CONFIG}
              unpaidDialogHasClicked={this.unpaidDialogHasClicked}
              unpaidOrderActiveClass={this.unpaidOrderActiveClass}
              vOn:countdownEnd={() => this.countdownEnd.push(item.billno)}
            />
          )
        } else {
          return (
            <UnpaidOrder
              item={item}
              userCenterLanguage={this.userCenterLanguage}
              configs={USER_CENTER_UNPAID_ORDER_CONFIG}
              vOn:countdownEnd={() => this.countdownEnd.push(item.billno)}
            />
          )
        }
      } else {
        return (
          <UnReviewOrder
            item={item}
          />
        )
      }
    }

    const renderOrderItem = (item, index) => {
      return (
        <swiper-slide
          da-event-expose="1-25-2-18"
          class={[
            'unpaid-slide-order'
          ]}
          key={item.billno}
          da-eid={item.billno}
        >
          <div
            class="unpaid-slide-order__container"
            vOn:click_stop={() => this.goToDetail(item, index)}
          >
            {itemEl(item)}
          </div>
        </swiper-slide>
      )
    }

    if (this.totalList.length == 0) return null

    return (
      <div
        ref="unpaidOrderRef"
        class={
          ['unpaid-order-box', 
            (this.totalList.length == 1) && (this.newUnpaidOrders.length > 1) ? 'unpaid-order-box__single' : '',
            this.unpaidOrderActiveClass,
          ]
        }
        da-expose-code="unpaidOrder"
      >
        <swiper-container
          class="unpaid-swiper-order"
          ref="swiper" 
          init="false"
          centeredSlides="true"
          observer="true"
          noSwiping="true"
          autoplay-delay="3000"
          observeSlideChildren="true"
          autoplay-disableOnInteraction="false"
          loop={ this.totalList.length > 1 ? true : false}
          pagination={ this.totalList.length > 1 ? true : false}
          destroy-on-disconnected="false"
          onSwiperslidechangetransitionend={ () => this.silderChangeCallback() }
        >
          {this.totalList.map(renderOrderItem)}
        </swiper-container>
        <unpaid-dialog-wrap
          orderItem={this.dialogOrderItem}
          userCenterLanguage={this.userCenterLanguage}
          configs={USER_CENTER_UNPAID_ORDER_CONFIG}
          unpaidOrderAbt={this.unpaidOrderAbt}
          unpaidOrderRects={this.unpaidOrderRects}
          clickCloseDialog={this.clickCloseDialog}
        />
      </div>
    )
  }
}
</script>
<style lang="less">
.strong-orange {
  color: #FA6338;
}
.unpaid-order-box {
  width: 9.36rem;
  margin: 0 auto;
  border-bottom: .29rem solid #fff;
  background-color: #F6F6F6;
  &__single {
    margin-bottom: 10px;
  }
  &__hide {
    opacity: 1;
  }
  &__show {
    animation: backgroundFade .8s forwards;
  }
  .unpaid-swiper-order {
    display: flex;
    flex-wrap: nowrap;
  }
  .unpaid-slide-order {
    flex-shrink: 0;
    padding: 24/75rem 16/75rem;
  }

  .unpaid-slide-order__container {
    display: flex;
    align-items: center;
  }

  .order-info {
    flex: 1;
    display: flex;
  }

  .order-img {
    position: relative;
    width: 72/75rem;
    height: 72/75rem;
    overflow: hidden;

    .first-img {
      width: 100%;
      height: 100%;
      display: block;
      border: none;
    }

    .order-unpaid-num {
      position: absolute;
      text-align: center;
      color: #fff;
      .font-dpr(32px);
      background: rgba(0, 0, 0, 0.3);
      bottom: 0;
      width: 200%;
      transform: scale(.5);
      transform-origin: 0 bottom;
      .left(0);
    }
  }

  .order-content{
    .margin-l(16/75rem);
    position: relative;
    width: 100%;
    color: #222;
    .font-dpr(28px);
    font-style: normal;
    font-weight: 600;

    
  }

  .order-other {
    .margin-l(16/75rem);
    position: relative;
    width: 100%;
  }

  .order-total {
    color: #222;
    .font-dpr(24px);

    .current-price {
      color: @sui_color_discount;
      .margin-l(8/75rem);
      font-weight: bold;
    }

    .origin-price {
      color: #666666;
      .font-dpr(20px);
    }
  }

  .order-cutdown {
    color: @sui_color_highlight;
    position: absolute;
    bottom: -.05rem;
    .left(0);
    .font-dpr(24px);
    width: 100%;

    .cut-down {
      font-weight: 400;
    }
  }
}

@keyframes backgroundFade {
  0%,66% { background-color: #FFF6F3; }
  33%,100% { background-color: #F6F6F6; }
}
</style>
