<script lang="jsx">
import { htmlDecode } from '@shein/common-function'
const { language } = gbCommonInfo
export default {
  name: 'UnReviewOrder',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      language
    }
  },
  render () {
    return (
      <div class="unreview__container">
        <div class="order-info">
          <div class="order-img">
            <img
              class="first-img"
              src={this.item.img}
              alt=""
            />
          </div>
          <div class="order-content" >
            <div class="order-content__text" domPropsInnerHTML={this.item.content}>
            </div>
          </div>
        </div>
        <div class="order-btn">
          <SButton
            type={['primary', 'H48PX']}
            block
          >
            { htmlDecode({ text: this.language.SHEIN_KEY_PWA_26429 }) }
          </SButton>
        </div>
      </div>
    )
  }
}
</script>

<style lang="less">
.strong-orange {
  color: #FA6338;
}
.unpaid-order-box {
  .unreview__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .order-info {
    flex: 1;
    display: flex;
  }

  .order-img {
    position: relative;
    width: 72/75rem;
    height: 72/75rem;
    overflow: hidden;

    .first-img {
      width: 100%;
      height: 100%;
      display: block;
      border: none;
    }
  }

  .order-content{
    .margin-l(16/75rem);
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: #222;
    .font-dpr(28px);
    font-style: normal;
    font-weight: 600;
  }

  .unpaid-swiper-pagination {
    text-align: center;
  }

  .unpaid-bullet {
    width: 44/75rem;
    height: 6/75rem;
    display: inline-block;
    background: #e5e5e5;
  }

  .unpaid-bullet-active {
    background: #666;
  }
}
</style>
