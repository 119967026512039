<template>
  <div class="unpaid-order__item">
    <!-- left -->
    <div class="unpaid-order__item-left">
      <div class="item-left item-left__img-wraper">
        <div class="bg-mask bg-mask1"></div>
        <div class="bg-mask bg-mask2"></div>
        <img
          class="item-left__img"
          :src="transformImg({img: props.item?.goodImage || ''})"
        />
        <span
          v-if="props.item?.goodNum"
          class="item-left__num"
        >{{ props.item?.goodNum }}</span>
        <!-- qtyEl -->
        <div
          v-if="props.item?.goodNum"
          class="num-mask"
        >
          <span class="num-mask__txt">{{ props.item?.goodNum }}</span>
        </div>
      </div>
      <div class="item-left__info">
        <div
          :class="`item-left__info-total ${isCompliance ? 'item-left__info-ellipsis-normal' : 'item-left__info-ellipsis'}`"
          v-html="props.item?.upTips"
        ></div>
        <!-- reviewsDescEl -->
        <p
          v-if="isCompliance"
          class="item-left__cutdown-wrap"
        >
          <span
            class="item-left__cutdown-pre"
          >{{ props.item?.countdownText }}</span>
          <span
            class="item-left__cutdown-content"
            v-html="countDownTips"
          ></span>
        </p> 
        <!-- <p
          v-if="props.item?.countdownText"
          class="item-left__info-ellipsis item-left__info-review"
          v-html="props.item?.commonTips"
        >
        </p> -->
      </div>
    </div>

    <!-- right -->
    <div class="unpaid-order__item-right">
      <s-button
        :type="['primary', 'H48PX']"
        :max-width="`${isCompliance ? '1.97' : ''}`"
        @click.stop="payNowHandle"
      >
        {{ clickButton?.content || '' }}
      </s-button>
      <template v-if="!isCompliance">
        <div class="unpaid-order__count-down">
          <div class="unpaid-order__count-down-info">
            <Icon name="sui_icon_hourglass_12px_2" />
            <span v-html="countDownTips"></span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
// 未支付订单(旧)
import { ref, computed, getCurrentInstance, onMounted, defineEmits } from 'vue'
import { CountDown, transformImg } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { formatUrlQuery } from '../../../service/utils.js'
const { langPath } = gbCommonInfo


const instance = getCurrentInstance()
const routerInstance = instance?.proxy?.$router

const countTimer = ref(new CountDown())

const props = defineProps({
  item: { type: Object, default: () => {} }
})

const emits = defineEmits(['countdownEnd'])

onMounted(() => {
  /* 未支付订单倒计时 */
  const { expireCountdown } = props.item
  if (expireCountdown) {
    countTimer.value.start({
      seconds: expireCountdown,
      endFunc: () => {
        // 倒计时结束
        emits('countdownEnd')
      }
    })
  }
})

/* computed */
// 是否合规
const isCompliance = computed(() => !!props.item.countdownText)
const clickButton = computed(() => props.item?.clickButton)

const countDownTips = computed(() => {
  const { H = 0, M = 0, S = 0 } = countTimer.value?.timeObj || {}
  return `<span>${H}</span> : <span>${M}</span> : <span>${S}</span>`
})

/* methods */
const payNowHandle = () => {
  const { jumpUrl = '', jumpParams = {} } = clickButton.value.urlObject
  const { billno, page_from, from_type, show_selected_pay_method } = jumpParams
  const urlQuery = formatUrlQuery({
    goBack: 1,
    show_error_guide_payment: show_selected_pay_method,
    from_type,
    page_from
  })
  routerInstance.push(`${langPath}${jumpUrl}/${billno}?${urlQuery}`)
  const reportInfo = (props.item?.clickButton?.buriedObject || []).find(item => item.buriedType === 2 && item.buriedKey === 'order_floor_pay')
  daEventCenter.triggerNotice({
    daId: '1-25-11-1003',
    extraData: reportInfo?.defaultParams,
  })
}

</script>
<style lang="less" scoped>
.unpaid-order {
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item-left {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    .item-left {
      margin-right: .2133rem;
      &__img-wraper {
        position: relative;
        width: .96rem;
        height: 1.1467rem;
        .bg-mask {
          position: absolute;
          top: 0;
          left: 50%;
          border-radius: 2px;
          transform: translateX(-50%);
          background-color: rgba(0,0,0,.3);
        }
        .bg-mask1 {
          width: .7467rem;
          height: .3467rem;
          z-index: 1;
        }
        .bg-mask2 {
          width: .8533rem;
          height: 1.0133rem;
          top: .08rem;
          z-index: 2;
        }
        .item-left__img {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 3;
          object-fit: cover;
        }
        .num-mask {
          width: .96rem;
          height: .2667rem;
          position: absolute;
          bottom: -.0267rem;
          left: 0;
          z-index: 4;
          background-color: rgba(0,0,0,.6);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0 0 2px 2px;
        }
        .num-mask__txt {
          font-size: 12px;
          font-weight: 700;
          color: #fff;
          transform: scale(.6667);
        }
      }
      &__img {
        width: .96rem;
        height: .96rem;
        border-radius: 2px;
        border: 1px solid #FFF;
      }
      &__num {
        display: inline-block;
        position: absolute;
        bottom: 0;
        width: 9.6vw;
        background: rgba(34, 34, 34, .5);
        color: white;
        text-align: center;
        height: 5vw;
      }
    }


    .item-left__info {
      flex: 1;
    }
    .item-left__info-ellipsis {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .item-left__info-ellipsis-normal {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .item-left__cutdown-wrap {
      display: flex;
    }
    .item-left__cutdown-pre {
      max-width: 3.6rem;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(---sui_color_gray_dark3, #767676);
      font-size: 12px;
    }
    .item-left__cutdown-content {
      display: flex;
      align-items: center;
      color: #000;
      font-weight: 700;
      font-size: 12px;
      line-height: .3733rem;
      /deep/ span {
        display: flex;
        width: .427rem;
        height: .427rem;
        justify-content: center;
        align-items: center;
        background-color: #000;
        color: #FFFFFF;
        margin: .0533rem;
        &:first-child {
          margin-left: .1067rem;
        }
      }
    }
    .item-left__info-total {
      font-size: 14px;
      font-weight: 600;
      strong {
        font-weight: 700;
      }
    }
    .item-left__info-review {
      font-size: 12px;
      font-weight: 400;
    }
    /deep/ span {
      color: @sui_color_discount;
    }
  }

  &__item-right {
    margin-left: .32rem;
    position: relative;
  }
  &__count-down {
    padding: 8/75rem;
    text-wrap: nowrap;
    text-align: center;
    position: absolute;
    top: 0.82rem;
    background: #fa6338;
    .font-dpr(20px);
    border-radius: 2px;
    .right(50%);
    .margin-r(-0.9rem);
    color: #fff;
    z-index: 3;
    &::before {
      position: absolute;
      content: '';
      width: 16/75rem;
      height: 16/75rem;
      background: #fa6338;
      transform: rotate(45deg);
      z-index: 2;
      top: -8/75rem;
      .left(50%);
      .margin-l(-8/75rem);
    }
  }
  &__count-down-info{
    i {
      .font-dpr(20px);
    }
    line-height: normal;
    .font-dpr(20px);
    color: #FFFFFF;
    background: @sui_color_promo;
    border-radius: 2px;
  }
}
</style>
