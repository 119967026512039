<template>
  <ListSkeletonV2
    v-if="showSkeleton"
    page="mePageForWishlist"
    :style="{
      'z-index': 1,
      'transform': 'translateZ(12px)'
    }"
  />
  <div
    v-else
    class="wishlist-center-page"
  >
    <Empty
      v-if="!products.length"
      :language="language"
    />

    <template v-else>
      <Products
        :show-water-fall-style="showWaterFallStyle"
        :products="showProducts"
        :column="2"
        :language="language"
        :config="config"
        :show-filter-empty="false"
        @afterDelete="handleDelete"
      />

      <!-- 按钮 -->
      <div
        v-if="showViewMoreBtn"
        class="wishlist-center-page__view"
        @click="toWishList"
      >
        {{ htmlDecode({text: language.SHEIN_KEY_PWA_15040}) }} 
        <i class="suiiconfont sui_icon_more_right_12px"></i>
      </div>
    </template>
  </div>
</template>

<script>
import Empty from './Empty.vue'
import Products from 'public/src/pages/user/child_pages/wishlist_v2/components/Products/index.vue'

import { htmlDecode } from '@shein/common-function'
import { getWishListsApi } from 'public/src/services/api/user/wishlist_v2/index.js'
import { getUserWishCfg } from 'public/src/pages/user/child_pages/wishlist_v2/configs'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import ListSkeletonV2 from 'public/src/pages/product_list_v2/components/ListSkeletonV2/index.vue'
import createFooterRcmdMixin from 'public/src/pages/user_index/mixins/footer-rcmd-mixin'
import { FooterRcmdListResolveStatus } from 'public/src/pages/user_index/service/consts'
const { language: gbLanguage, } = gbCommonInfo

export default {
  name: 'WishCenterPage',
  components: {
    Empty,
    Products,
    ListSkeletonV2,
  },
  mixins: [
    createFooterRcmdMixin(),
  ],
  props: {
    show: Boolean,
    isLogin: Boolean,
  },
  data() {
    return {
      language: {},
      config: {
        sa: {
          name: 'saved',
          from: 'wishlist',
          activity_from: 'wishlist',
        },
        itemDAEventClickId: '2-3-7',
        itemDAEventExposeId: '2-3-15',
        autoCropImage: true,  // 开启方图
        showItemV3: true, // 使用新商卡
      },
      listDataReady: false,
      products: [],
      total: 0,
      showWaterFallStyle: true, // 修复在个人中心收藏足迹列表刷新（严格意义上是用户中心的BUG,见public/src/pages/user/child_pages/dashboard/container_new.vue中clearAbtInfo方法）， 考虑到瀑布流实验推全了，所以直接hack code在这写死值，有问题联系Johnny Lv
    }
  },
  computed: {
    showViewMoreBtn() {
      const resolveStatus = this.footerRcmdListResolveStatus
      return this.total > (resolveStatus === FooterRcmdListResolveStatus.HAS_RCMD_LIST ? 2 : 10)
    }
  },
  watch: {
    isLogin(v) { // 登录后请求一次
      if (v && !this.products.length && this.show) {
        this.listDataReady = false
        this.fetchData()
      }
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const response  = await this.getWishList()
      const { wishlist = [], total = 0, language = {}, abtData = {}, sheinClubState, promotionInfoFromServer, standardPrice } = response
      this.language = { ...language, ...gbLanguage }
      const { sheinClubType, isPaid } = sheinClubState || {}
      const showOldSheinClubTag = `${sheinClubType}` === '1'
      const showNewSheinClubTag = `${sheinClubType}` === '2'

      this.config = getUserWishCfg({
        ...this.config,
        ...{
          showBadge: false,
          showSellingPoint: false,
          showQuickShip: false,
        },
        showSheinClubDiscountValue: showOldSheinClubTag,
        showSheinClubDiscountValueAfterPrice: showNewSheinClubTag,
        showSheinClubPriceOnSale: !!(showNewSheinClubTag && isPaid),
        standardPrice,
      }, abtData || {}, { isPaid, sheinClubType, standardPrice })

      if (wishlist.length) {
        dealProductsPretreatInfo({
          products: wishlist,
          itemConfig: this.config,
          language: this.language,
          promotionInfoFromServer,
        })
      }
      this.products = wishlist
      this.total = total
      this.listDataReady = true
    },
    async handleDelete(delteItem) {
      const { wishlist = [], total } = await this.getWishList()
      this.products = wishlist.filter(item => item.goods_id != delteItem.goods_id)
      this.total = total
    },
    async getWishList() {
      try {
        const res = await getWishListsApi({
          page: 1,
          size: 10,
          notFilter: 1,
          firstPage: 1
        })
        return res || {}
      }catch(e) {
        console.log(e)
        return {}
      }
    },
    toWishList() {
      this.$routerGoWish({ pf: 'me' })
    },
    htmlDecode,
  }
}
</script>

<style lang="less">
.wishlist-center-page {
  min-height: 8rem;

  &__view {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    color: #999;
  }
}
</style>
