class Sa {
  viewTips () {
    sa('send', {
      activity_name: 'expose_filling_personal_profile',
      activity_param: ''
    })
  }

  clickMyAddress () {
    sa('send', {
      activity_name: 'click_my_address',
      activity_param: ''
    })
  }

  clickMyCoupons (activity_param = '') {
    sa('send', {
      activity_name: 'click_my_coupons',
      activity_param
    })
  }

  clickMyWallet (activity_param = '') {
    sa('send', {
      activity_name: 'click_my_wallet',
      activity_param,
    })
  }

  // clickRecentViewTab () {
  //   sa('send', {
  //     activity_name: 'click_recent_view_tab',
  //     activity_param: ''
  //   })
  // }

  clickSettings () {
    sa('send', {
      activity_name: 'click_settings',
      activity_param: ''
    })
  }

  // clickWishlistTab () {
  //   sa('send', {
  //     activity_name: 'click_wishlist_tab',
  //     activity_param: ''
  //   })
  // }

  clickTips () {
    sa('send', {
      activity_name: 'click_filling_profile_banner',
      activity_param: ''
    })
  }

  // exposeTips () {
  //   sa('send', {
  //     activity_name: 'expose_filling_profile_banner',
  //     activity_param: ''
  //   })
  // }

  // clickAvatarAndNickname () {
  //   sa('send',
  //     {
  //       activity_name: 'click_personal_avatar_nickname',
  //       activity_param: ''
  //     },
  //     { beacon: 1 })
  // }

  ClickSheinVip () {
    sa('send',
      {
        activity_name: 'click_sheinvip',
        activity_param: ''
      })
  }
	
  clickOrders () {
    sa('send',
      {
        activity_name: 'click_orders',
        activity_param: ''
      })
  }
	
  clickCoupon () {
    sa('send',
      {
        activity_name: 'click_coupon',
        activity_param: ''
      })
  }

  clickSupport () {
    sa('send',
      {
        activity_name: 'click_support',
        activity_param: ''
      })
  }

  clickGiftcard () {
    sa('send',
      {
        activity_name: 'click_gift_card',
        activity_param: ''
      })
  }

  clickPoints(activity_param = '') {
    sa('send', {
      activity_name: 'click_shein_points',
      activity_param,
    })
  }
}
export default Sa
