<template>
  <CommonWrapper
    :cccx-config="cccxConfig"
  >
    <div
      class="new-user-benefits__coupons"
      @click.stop="onModuleClick"
    >
      <CouponList
        :language="language"
        :coupons="coupons"
        :cccx-config="cccxConfig"
      />
    </div>
  </CommonWrapper>
</template>

<script setup name="NewUserCoupons">
import CommonWrapper from './CommonWrapper.vue'
import CouponList from './new-user-coupon/CouponList.vue'
import { useCccxConfig } from 'public/src/pages/user/child_pages/dashboard/hooks/new-user-benefits'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  coupons: {
    type: Array,
    default: () => [],
  },
  cccxConfig: {
    type: Object,
    default: {},
  },
})

const onModuleClick = () => {
  const { cccxConfig } = props
  const { jumpUrl } = useCccxConfig(cccxConfig)
  setTimeout(() => {
    location.href = jumpUrl
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-2-27',
    bindData: {
      module_nm: 'new_user_coupon',
      coupon_code: '',
      item_loc: '',
      content_list: cccxConfig?.jumpUrl || '',
    },
  })
}
</script>

<style lang="less" scoped>
.new-user-benefits {
  &__coupons {
    height: 85/37.5rem;
    padding-top: 33/37.5rem;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0;
    }
  }
}
</style>
