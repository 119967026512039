<script lang="jsx">
import { transformImg } from '@shein/common-function'
export default {
  name: 'ImgWraper',
  props: {
    orderGoodsList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    img () {
      if (this.orderGoodsList.length == 1) {
        return this.orderGoodsList?.[0]?.product?.goods_img || ''
      }
      return this.orderGoodsList?.find(_ => {
        return !(_.is_prime_goods || _.is_xtra_goods)
      })?.product?.goods_img || ''
    },
    goodsQty () {
      return this.orderGoodsList?.find(_ => {
        return !(_.is_prime_goods || _.is_xtra_goods)
      })?.quantity || 0
    },
    imgLength () {
      return this.orderGoodsList.length
    }
  },
  render () {
    const qtyEl = () => {
      if (this.goodsQty > 1) {
        return (
          <div class="num-mask">
            <span class="num-mask__txt">x {this.goodsQty}</span>
          </div>
        )
      } else {
        return null
      }
    }
    if (this.imgLength == 1) {
      return (
        <img class="item-left item-left__img" src={transformImg({ img: this.img })} />
      )
    }
    return (
      <div class="item-left item-left__img-wraper">
        <div class="bg-mask bg-mask1"></div>
        <div class="bg-mask bg-mask2"></div>
        <img class="item-left__img" src={transformImg({ img: this.img })} />
        {qtyEl()}
      </div>
    )
  }
}
</script>

<style lang="less">
.item-left {
  margin-right: .2133rem;
  &__img-wraper {
    position: relative;
    width: .96rem;
    height: 1.1467rem;
    .bg-mask {
      position: absolute;
      top: 0;
      left: 50%;
      border-radius: 2px;
      transform: translateX(-50%);
      background-color: rgba(0,0,0,.3);
    }
    .bg-mask1 {
      width: .7467rem;
      height: .3467rem;
      z-index: 1;
    }
    .bg-mask2 {
      width: .8533rem;
      height: 1.0133rem;
      top: .08rem;
      z-index: 2;
    }
    .item-left__img {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;
      object-fit: cover;
    }
    .num-mask {
      width: .96rem;
      height: .2667rem;
      position: absolute;
      bottom: -.0267rem;
      left: 0;
      z-index: 4;
      background-color: rgba(0,0,0,.6);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 2px 2px;
    }
    .num-mask__txt {
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      transform: scale(.6667);
    }
  }
  &__img {
    width: .96rem;
    height: .96rem;
    border-radius: 2px;
    border: 1px solid #FFF;
  }
}
</style>
