import { ref } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getLocalStorage, setLocalStorage } from '@shein/common-function'

export const initCommonNotifyModule = () => {
  const isCommonNoticeShow = ref(false)
  const commonNotifyContent = ref('')
  const commonNotifyCache = ref({})

  const initCommonNotify = (notifyService) => {
    const hideCccNotice = getLocalStorage('hideCccNotice')
    const cccMsg = notifyService?.notifyList?.find(item => item.type === 'cccMsg')
    const { content, cacheRule } = cccMsg || {}
    commonNotifyCache.value = cacheRule
    commonNotifyContent.value = content
    isCommonNoticeShow.value = !hideCccNotice && !!content
    if (!!content) {
      daEventCenter.triggerNotice({
        daId: '1-25-11-4',
      })
      // 新增埋点
      daEventCenter.triggerNotice({
        daId: '1-25-11-13',
      })
    }
  }

  const onCloseCccNotify = () => {
    isCommonNoticeShow.value = false
    const cacheTime = commonNotifyCache.value?.cacheTime
    setLocalStorage({
      key: 'hideCccNotice',
      value: true,
      expire: cacheTime?.length > 0 ? cacheTime[0] : 24 * 60 * 60 * 1000
    })
    daEventCenter.triggerNotice({
      daId: '1-25-11-5',
    })
    // 新增埋点
    daEventCenter.triggerNotice({
      daId: '1-25-11-14',
    })
  }
  return {
    isCommonNoticeShow,
    commonNotifyContent,
    initCommonNotify,
    onCloseCccNotify,
  }
}
