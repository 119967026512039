<template>
  <div
    v-if="isShow"
    v-expose="{
      id: '1-25-11-23'
    }"
    class="news-pannel__wrap"
  >
    <svg
      class="news-pannel__news-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 2H2V14H14V2ZM4 10.75H12V9.25H4V10.75ZM12 6.75L7.5 6.75V5.25L12 5.25V6.75ZM4 6.75H6V5.25H4V6.75Z"
        fill="#337EFF"
      />
    </svg>
    <div 
      class="news-pannel__list"
    >
      <div 
        class="news-pannel__list-wrap" 
        :style="{ animation: newsModuleCssAnimation }"
      >
        <div
          v-for="(item, index) in newsList"
          :key="index"
          class="news-pannel__list-item"
          @click="linkToNews(item)"
          v-html="item?.title"
        >
        </div>
      </div>
    </div>
    <div
      class="news-pannel__more text-ellipsis"
      @click="linkToUserNews"
    >
      {{ gbLanguage.SHEIN_KEY_PWA_30868 || 'More' }}
    </div>
  </div>
</template>

<script setup name="NewsPanel">
/**
 * @file 个人中心news入口
 */

import { computed, onMounted, ref, getCurrentInstance, inject } from 'vue'
import { CccLink, isInSpa } from 'public/src/pages/components/ccc/common/ccc-link'
import { useNewsFatigue } from '../hooks'

let cccLink = null
const instance = getCurrentInstance()
const routerInstance = instance?.proxy?.$router

const {
  language: gbLanguage,
} = gbCommonInfo
const props = defineProps({
  newsInfo: { type: Object, default: () => {} },
  routeInfo: {
    type: Object,
    default: () => ({})
  },
})

const { getClientAbt } = inject('USER_CENTER_CONTEXT')

const routeInfo = ref(props.routeInfo)
const newsModuleCssAnimation = ref('')
const newsList = ref([])
const newsAnimationName = 'newsAnimation'
let newsModuleCssAnimationCacheValue = ''

onMounted(() => {
  if (!props.newsInfo || Object.keys(props.newsInfo).length === 0) return
  initNewsCarouselAnimation()
  cccLink = new CccLink({
    sceneData: {}, 
    propData: {}
  })
})


/* computed */
const getArticleTitleLists = computed(() => {
  const list = props.newsInfo?.props?.metaData?.articleTitles || []
  const propsItems = props.newsInfo?.props?.items || []
  return list.map(article => {
    const { hrefTarget } = article
    let startTime = ''
    propsItems.some(data => {
      return (data.items || []).some(item => {
        if (item.hrefTarget === hrefTarget) {
          startTime = item.startTime
          return true
        }
        return false
      })
    })
    return {
      ...article,
      startTime,
    }
  })
})

const { isShow, setRecord, handleDisplay } = useNewsFatigue({ getArticleTitleLists, routeInfo, getClientAbt })

const initNewsCarouselAnimation = () => {
  if(newsModuleCssAnimation.value) return
  const lists = getArticleTitleLists.value
  if (lists.length > 1) { // 两条以上的数据才需要滚动
    newsList.value = [...lists, ...lists.slice(0, 1)]
    const { animDuration, cssStr } = buildKeyFramesAndReturnCSSInfo(false, lists.length, 0.2, 2.8)
    appendStyle(cssStr)
    newsModuleCssAnimationCacheValue = `${newsAnimationName} ${animDuration}s linear infinite`
    newsModuleCssAnimation.value = newsModuleCssAnimationCacheValue
  }
}

const appendStyle = (cssStr) => {
  if(document.querySelector('style[title="newsAnimationStyle"]')) return

  const style = document.createElement('style')
  style.title = 'newsAnimationStyle'
  style.innerHTML = cssStr
  document.getElementsByTagName('head')[0].appendChild(style)
}
const buildKeyFramesAndReturnCSSInfo = (isHorizontal, count, scrollTime, displayTime) => {
  if(count < 2) return {}

  const translate = isHorizontal ? 'translateX' : 'translateY'
  const sumTime = (displayTime + scrollTime) * count // 总动画时长
  let sumPercentage = 0
  let translateValue = 0
  let cssStr = `@keyframes ${newsAnimationName} { 0%{transform:${translate}(0%);}`
  for (let index = 0; index < count; index++) {
    // 静止展示动画区段
    sumPercentage += displayTime / sumTime
    cssStr += `${(sumPercentage * 100).toFixed(2)}%{transform:${translate}(-${(translateValue * 100).toFixed(2)}%);}`
    // 滚动动画区段
    sumPercentage += scrollTime / sumTime
    translateValue = (index + 1) / (count + 1)
    cssStr += `${(sumPercentage * 100).toFixed(2)}%{transform:${translate}(-${(translateValue * 100).toFixed(2)}%);}`
  }
  cssStr += '}'

  return {
    animDuration: sumTime,
    cssStr,
  }
}

const linkToUserNews = () => {
  setRecord({ type: 'addCli' })
  routerInstance.push({
    name: 'user_news',
    query: {
      page_from: 'me'
    }

  })
}

const linkToNews = (item) => {
  setRecord({ type: 'addCli' })
  const { hrefTarget = '', hrefType = '', relativeUrl = '' } = item || {}
  const hrefInfo = { hrefTarget, hrefType, relativeUrl }
  let content_list = '-'
  if (hrefInfo?.hrefType && hrefInfo?.hrefTarget) {
    content_list = `${hrefInfo?.hrefType}_${hrefInfo?.hrefTarget}`
  }
  daEventCenter.triggerNotice({ 
    daId: '1-25-11-24',
    extraData: {
      button_type: 'content',
      content_list,
    }
  })
  const url = cccLink.getFullLink({
    item: hrefInfo || {},
    cateLinks: {},
    compIndex: 1,
    index: 1,
  })
  if (url.indexOf('javascript:;') > -1) return
  if (!isInSpa(hrefInfo || {})) {
    window.open(url, '_blank')
    return
  }
  routerInstance.push(url)
}

routerInstance.beforeEach((to, from, next) => {
  routeInfo.value = { to, from }
  handleDisplay()
  next()
})

</script>
<style lang="less" scoped>
@ratio: 37.5rem;
.news-pannel {
  &__wrap {
    margin: 8/@ratio 12/@ratio 0;
    border-radius: 3/@ratio;
    background: rgba(217, 234, 255, 0.50);
    padding: 7/@ratio 8/@ratio;
    display: flex;
    align-items: center;
    font-size: 12/@ratio;
    line-height: normal;
    font-family: 'SF-UI-Text-Regular';
  }
  &__news-icon {
    flex-shrink: 0;
    margin: 0 4/@ratio 0 0;
  }
  &__list {
    flex: 1;
    overflow: hidden;
    position: relative;
    height: 14/@ratio;
  }
  &__list-wrap {
    width: 100%;
    height: auto;
    display: inline-block;
  }
  &__list-item {
    height: 14/@ratio;
    .text-overflow();
    .high-light {
      color: #FA6338;
    }
  }
  &__more {
    padding: 0 0 0 16/@ratio;
    max-width: 72/@ratio;
    color: #337EFF;
    margin-left: auto;
    flex-shrink: 0;
    position: relative;
    font-family: 'SF-UI-Text-Bold';
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 12px;
      background: #ccc;
      top: 50%;
      left: 8/@ratio;
      transform: translate(0, -50%);
    }
  }
}
</style>
