<script lang="jsx">
const {
  IS_RW,
  PUBLIC_CDN,
  GB_cssRight,
} = gbCommonInfo

/* VIP CONFIG */
const vipEsSite = ['es', 'mes', 'pwes', 'andshes', 'iosshes']
const vipImgs = {
  2: GB_cssRight
    ? `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_s0_ar-8ed5e7ba2c.png`
    : `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_s0-b8caf01d39.png`,
  3: GB_cssRight
    ? `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_s1_ar-1714c1f024.png`
    : `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_s11-b70305408e.png`,
  4: GB_cssRight
    ? `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_s2_ar-41a90364dd.png`
    : `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_s21-3295ccb5ea.png`,
  5: GB_cssRight
    ? `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_s3_ar-2f0e50d3f1.png`
    : `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_s31-ce85dc5861.png`
}
const vipEsImgs = {
  2: GB_cssRight
    ? `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_es0_ar-13acd0f483.png`
    : `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_es_s0-638cfa0441.png`,
  4: GB_cssRight
    ? `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_es_ar-f1ec27b01e.png`
    : `${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_es_s1-9b363e5f4a.png`
}

export default {
  name: 'UserVipTag',
  functional: true,
  props: {
    isOldEsVip: {
      type: Boolean,
      default: false
    },
    siteFrom: {
      type: String,
      default: ''
    },
    level: {
      type: [Number, String],
      default: ''
    }
  },
  render(h, { props, listeners }) {
    const { isOldEsVip, siteFrom, level } = props

    const handleOpenVip = () => listeners.openVip()

    return (
      <div
        role="button"
        tabindex="0"
        aria-label={IS_RW ? 'ROMWE VIP' : 'SHEIN VIP'}
        class="user-vip"
        vOn:click_stop={() => handleOpenVip()}
      >
        {!IS_RW ? (
          <img
            class="vip-img"
            src={vipEsSite.includes(siteFrom) && isOldEsVip ? vipEsImgs[level] : vipImgs[level]}
            alt=""
          />
        ) : (
          <span class={['rwvip-img', `rwvip-img-${level}`]}></span>
        )}
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
.user-vip {
  display: inline-block;
  height: 40/75rem;
  line-height: 40/75rem;
  position: relative;
  bottom: 0.038rem;
  .left(8/75rem);

  > img.vip-img {
    height: 100%;
    width: auto;
  }

  .rwvip-img {
    height: 28/75rem;
    vertical-align: middle;
    background: url('/pwa_dist/rw_images/user/romwevip/VIP_level-6101707eb8.png') no-repeat;
    background-size: 398/75rem 188/75rem;
    display: inline-block;

    &.rwvip-img-2 {
      background-position: 0px 0px;
      width: 80/75rem;
    }

    &.rwvip-img-3 {
      background-position: -100/75rem -38/75rem;
      width: 80/75rem;
    }

    &.rwvip-img-4 {
      background-position: -200/75rem -77/75rem;
      width: 80/75rem;
    }

    &.rwvip-img-5 {
      background-position: -300/75rem -118/75rem;
      width: 100/75rem;
    }
  }
}
</style>
