<template>
  <s-dialog
    :visible="currentDialogShow"
    :show-close="false"
    :close-on-click-modal="false"
    class="payback-dialog"
    append-to-body
  >
    <div
      v-expose="
        getAnalysisData({
          id: '1-25-7-36',
          data: {
            bottomtype: getPayBackBuries.defaultParams?.bottomtype
          }
        })
      "
      class="payback-dialog__wrapper"
      :class="{
        'payback-dialog__wrapper-animate': visibility === 'visible'
      }"
    >
      <p class="payback-dialog__title">
        {{ getPopupsInfo.popups_title }}
      </p>
      <!-- 头部切图 -->
      <div
        class="payback-dialog__content-img"
        :style="{ backgroundImage: `url(${getPopupsInfo.badges_img_url})` }"
      >
        <span class="payback-roi__wrapper">
          <span class="payback-roi__top">
            {{ template(getPopupsInfo.roi, getPopupsInfo.roi_tips) }}
          </span>
          <span class="payback-roi__bottom">
            {{ template(getPopupsInfo.roi, getPopupsInfo.roi_tips) }}
          </span>
        </span>
      </div>
      <div class="payback-dialog__content">
        <div
          class="payback-dialog__bg"
          :style="{ backgroundImage: `url(${getPopupsInfo.underline_img_url})` }"
        >
          <div class="payback-dialog__main">
            <!-- 标题 -->
            <p
              class="payback-dialog__content-title"
              v-html="getPaybackDialogTitle"
            ></p>
            <!-- 超越用户数量 -->
            <div class="beyond-line">
              <div class="beyond-line__dot">
              </div>
            </div>
            <div class="payback-dialog__content-beyond">
              <!-- 扫光动画 -->
              <div class="beyond-animation"></div>
              <p v-html="getPaybackDialogBeyond"></p>
            </div>
            <div class="beyond-line">
              <div class="beyond-line__dot"></div>
            </div>

            <!-- 按钮 -->
            <div
              v-tap="
                getAnalysisData({
                  id: '1-25-7-37',
                  data: {
                    bottomtype: getPayBackButtonBuries.defaultParams?.bottomtype
                  }
                })
              "
              class="payback-dialog__content-btn"
              :style="{ backgroundImage: `url(${getPopupsInfo.button_img_url})` }"
            >
              <div
                v-if="getPopupsInfo.button_bubble"
                class="payback-dialog__btn-tip"
              >
                <img :src="getPopupsInfo.button_img_url" />
                {{ getPopupsInfo.button_bubble }}
              </div>
              <span>{{ getPopupsInfo.button_tips }}</span>
            </div>
          </div>
        </div>
      </div>
      <i
        class="payback-dialog__close suiiconfont sui_icon_close_24px"
        @click="closeHandle('close')"
      ></i>
    </div>
  </s-dialog>
</template>

<script setup>
// 回本弹窗
import { ref, computed, watch } from 'vue'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { template, setLocalStorage, getLocalStorage, removeLocalStorage } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager'
import { useMapState } from 'public/src/pages/user_index/hooks/useVuex'

const { SiteUID } = gbCommonInfo
const storeState = useMapState(['dialogName'])


/* state */
const visibility = ref('visible')
const isShowPayBackDialog = ref(false)
const isBanCountry = ref((UserInfoManager.getSync({ key: 'primeDisableCountry', actionType: 'prime/payBackDialog' }) == 'JO') || UserInfoManager.getSync({ key: 'Country', actionType: 'prime/payBackDialog' })?.countryAbbr == 'JO' || false)

const props = defineProps({
  paybackInfo: { type: Object, default: () => null },
  isShow: { type: Boolean, default: false },
})

/* computed */
const getPopupsInfo = computed(() => props.paybackInfo?.popups_info || {})
const getPayBackBuries = computed(() => props.paybackInfo?.buries.find((item) => item.buriedKey === 'pay_back') || {})
const getPayBackButtonBuries = computed(() => props.paybackInfo?.buries.find((item) => item.buriedKey === 'pay_back_button') || {})

const getPaybackDialogTitle = computed(() => getPopupsInfo.value.payback_content?.replace(/%s/, getPopupsInfo.value.req_currency_price_with_symbol))
const getPaybackDialogBeyond = computed(() => getPopupsInfo.value.payback_detail?.replace(/%s/, getPopupsInfo.value.roi))
const currentDialogShow = computed(() => {
  return storeState.dialogName.value === 'next-pay-back' && isShowPayBackDialog.value
})


/* method */
const init = () => {
  let memberId = UserInfoManager.get({ key: 'memberId', actionType: 'init' })
  const cacheKey = `pay_back_cache_${memberId || ''}_${SiteUID}`
  setLocalStorage({ key: cacheKey, value: getPopupsInfo.value.roi })
  animationBegin()
}

// 动画开始
const animationBegin = () => {
  setTimeout(() => {
    visibility.value = 'visible'
    setTimeout(() => {
      $('.beyond-animation').css('left', '120%')
    }, 1000)
  }, 166)
}

// 缓存判断
const cacheVerify = () => {
  let memberId = UserInfoManager.get({ key: 'memberId', actionType: 'cacheVerify' })
  const cacheKey = `pay_back_cache_${memberId || ''}_${SiteUID}`
  const newRoi = getPopupsInfo.value.roi
  const cacheRoi = getLocalStorage(cacheKey) || UserInfoManager.get({ key: cacheKey, actionType: 'cacheVerify' }) // 兼容线上用户以后缓存逻辑

  // payback_info为null 清除当前(站点+id)缓存
  if (!props.paybackInfo) {
    removeLocalStorage(cacheKey)
    return
  }
  // payback_info不为null 获取缓存。没有缓存则弹窗并更新缓存。有缓存对比roi，如果新的roi更大则弹窗并更新缓存
  if (cacheRoi < newRoi || cacheRoi) {
    setLocalStorage({ key: cacheKey, value: getPopupsInfo.value.roi })
    return
  }
  isShowPayBackDialog.value = true
}

const getAnalysisData = ({ id, data }) =>{
  return {
    id,
    data
  }
}

// 关闭弹窗
const closeHandle = (type) => {
  isShowPayBackDialog.value = false
  if (type == 'close') return
}


watch(() => props.isShow, (newVal) => {
  if (newVal && isLogin() && isBanCountry.value) {
    cacheVerify()
  }
}, { immediate: true })

watch(currentDialogShow, (newVal) => {
  if (newVal) {
    init()
  }
}, { immediate: true })

</script>

<style lang="less" scoped>
@keyframes pay-back-scale {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.payback-dialog {
  background: rgba(0, 0, 0, 0.6);
  
  /deep/.S-dialog__wrapper {
    width: 10rem;
    max-width: 10rem;
    background: rgba(0, 0, 0, 0);
  }
  
  /deep/.S-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  // &__vap {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 8rem;
    opacity: 0;
    position: relative;
    z-index: 2;
  }
  &__wrapper-animate {
    opacity: 1;
    animation: pay-back-scale 0.5s ease-in-out;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #ffae97;
    text-shadow: 0px 0px 15px #ff9159;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.2133rem;
  }
  // &__color {
  //   width: 8rem;
  //   height: 1.9733rem;
  //   background-repeat: no-repeat;
  //   background-size: 100%;
  //   margin-bottom: -1.5467rem;
  // }
  &__content {
    background: linear-gradient(180deg, #ffba97 0%, #ffe2d3 37.53%, #ffe2d3 100%);
    border-radius: 0.2133rem;
    margin-bottom: 0.88rem;
  }
  &__bg {
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 0.2133rem;
  }
  &__content-img {
    position: relative;
    z-index: 2;
    margin-bottom: -1.92rem;
    width: 8rem;
    height: 3.4667rem;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .payback-roi__wrapper {
      width: 1.3333rem;
      height: 0.7733rem;
      position: absolute;
      bottom: 0.5333rem;
      left: 50%;
      top: 75%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: 24px;
      line-height: 0.7733rem;
      .payback-roi__top {
        position: absolute;
        z-index: 2;
        color: #ffe9de;
        left: 50%;
        transform: translate3d(-50%, 0, 2px);
        // background: linear-gradient(110.64deg, #FFECE3 19.28%, #FFD2BB 84.4%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
      }
      .payback-roi__bottom {
        position: absolute;
        z-index: 1;
        color: #cc5d25;
        left: calc(50% + 1px);
        top: 0.0533rem;
        transform: translate3d(-50%, 0, 1px);
      }
    }
  }
  &__main {
    border-radius: 5px;
    border: 1px solid #fd8f58;
    padding: 0.4267rem 0.4267rem 0.2667rem;
  }
  &__content-title {
    margin-top: 1.5267rem;
    font-size: 17px;
    font-weight: 700;
    color: #873c00;
    line-height: 20px;
    margin-bottom: 0.4267rem;
    text-align: center;
    span {
      font-size: 20px;
      line-height: 24px;
      color: @sui_color_discount;
    }
  }
  .beyond-line {
    position: relative;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      270deg,
      rgba(135, 60, 0, 0) 0%,
      rgba(234, 128, 74, 0.2) 19.65%,
      rgba(234, 130, 77, 0.2) 80.59%,
      rgba(135, 60, 0, 0) 100%
    );
    .beyond-line__dot {
      position: absolute;
      left: 50%;
      top: -0.0533rem;
      z-index: 2;
      margin-left: -0.0533rem;
      width: 0.1067rem;
      height: 0.1067rem;
      background: linear-gradient(135deg, #ffcfb6 0%, #ffb995 91.67%);
      transform: rotate(45deg);
    }
  }
  &__content-beyond {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 44px;
    color: rgb(135, 60, 0, 0.8);
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      90deg,
      rgba(255, 238, 230, 0) 0%,
      rgba(255, 238, 230, 0.9) 52.12%,
      rgba(255, 238, 230, 0) 100%
    );
    .beyond-animation {
      position: absolute;
      left: -1rem;
      top: -0.2133rem;
      width: 0.8rem;
      height: 1.7133rem;
      opacity: 0.9;
      transform: rotateZ(20deg);
      background: linear-gradient(
        95deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.1)
      );
      transition: left 0.75s ease-in-out;
    }
    span {
      font-size: 16px;
      line-height: 17px;
      font-weight: 700;
      margin: 0 0.1067rem;
      color: @sui_color_discount;
      text-transform: lowercase;
    }
  }
  &__content-btn {
    width: 5.4667rem;
    height: 1.5733rem;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0.4267rem auto 0;
    position: relative;
  }
  &__btn-tip {
    border-radius: 0.3733rem;
    background: linear-gradient(96deg, #ff405e 0%, #c73bff 54.26%);
    position: absolute;
    right: 0.4266rem;
    top: -0.3866rem;
    height: 0.53333rem;
    color: #fff;
    text-align: center;
    font-size: 0.2667rem;
    font-weight: 590;
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
    img {
      width: 0.16rem;
      height: 0.08rem;
      position: absolute;
      left: 50%;
      bottom: -0.07rem;
      transform: translateX(-50%);
    }
  }
  &__close {
    cursor: pointer;
    color: #666;
  }
}
</style>
