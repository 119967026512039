import { 
  EXPIRE_GAME_MONEY_CACHE_KEY,
  ExpireTipsClickStatus,
} from 'public/src/pages/user/child_pages/dashboard/service/constants'
import { getLocalStorage } from 'public/src/pages/user/child_pages/dashboard/service/utils'
import { isLogin } from 'public/src/pages/common/utils'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager'

export const getUserAssetsBottomContent = ({
  isLogin,
  readyToInit,
  newUserBenefits,
  expireGameMoney,
  couponOrPointsData,
  personalReward,
  isHideModuleAction = false
}) => {
  const controls = {
    showNewUserBenefits: false,
    showExpireGameMoney: false,
    showExpireCouponTips: false,
    showExpirePointTips: false,
    showPersonalReward: false,
  }
  if (!readyToInit) {
    return controls
  }
  initNewUserBenefits({ controls, newUserBenefits })
  if (isHideModuleAction) {
    // 如果是显式隐藏模块，则隐藏后续模块
    return controls
  }
  initExpireGameMoneyTipsStatus({ controls, expireGameMoney })
  if (controls.showExpireGameMoney) {
    return controls
  }
  initExpireCouponTipsStatus({ controls, isLogin, couponOrPointsData })
  if (controls.showExpireCouponTips) {
    return controls
  }
  initExpirePointTipsStatus({ controls, isLogin, couponOrPointsData })
  if (controls.showExpirePointTips) {
    return controls
  }

  initPersonalReward({ controls, isLogin, personalReward })
  if (controls.showPersonalReward) {
    return controls
  }
  
  return controls
}

const initNewUserBenefits = ({
  controls,
  newUserBenefits,
}) => {
  controls.showNewUserBenefits = !!newUserBenefits
}

export const getExpireGameMoneyCacheKey = () => {
  const userId = isLogin() ? UserInfoManager.get({
    key: 'memberId',
    actionType: 'user-dashboard/user-assets-bottom',
  }) || '' : 'anonymous'
  return `${EXPIRE_GAME_MONEY_CACHE_KEY}_${userId}`
}

export const getExpireGameMoneyCache = () => {
  const cacheKey = getExpireGameMoneyCacheKey()
  return getLocalStorage(cacheKey, {
    status: ExpireTipsClickStatus.CAN_BE_CLOSED,
    closeTimes: 0,
  })
}

const initExpireGameMoneyTipsStatus = ({
  controls,
  expireGameMoney,
}) => {
  if (!expireGameMoney) return
  if (!expireGameMoney?.expireAmountWithSymbol || !expireGameMoney?.maxCloseTimes) return
  const expireTipCache = getExpireGameMoneyCache()
  if (expireTipCache.status === ExpireTipsClickStatus.NO_LONGER_SHOW) return
  const { maxCloseTimes } = expireGameMoney
  const reachMaxCloseTimes = expireTipCache.closeTimes >= maxCloseTimes
  if (reachMaxCloseTimes) return
  const now = Date.now()
  controls.showExpireGameMoney = expireTipCache.closeTimes === 0 || now > expireTipCache.displayAfterTime
}

const initExpireCouponTipsStatus = ({
  controls,
  isLogin,
  couponOrPointsData,
} = {}) => {
  const { couponNum, isOpenCoupon, isClickCoupon } = couponOrPointsData || {}
  const show = isLogin && isOpenCoupon && !isClickCoupon && couponNum >= 1
  controls.showExpireCouponTips = show
}

const initExpirePointTipsStatus = ({
  controls,
  isLogin,
  couponOrPointsData,
} = {}) => {
  const { pointsNum, isOpenPoints, isClickPoints } = couponOrPointsData || {}
  const show = isLogin && isOpenPoints && !isClickPoints && pointsNum >= 50
  controls.showExpirePointTips = show
}

const initPersonalReward = ({
  controls,
  isLogin,
  personalReward,
}) => {
  // 新人权益展示时不展示新人券
  if (controls.showNewUserBenefits) return

  const { couponListData = {}, rulesData = {}, isMeCouponShow } = personalReward || {}
  const isHitPeople = rulesData?.data?.length > 0 // 命中人群
  if(gbCommonInfo?.IS_RW || !isMeCouponShow) {
    return
  }
  if (!isLogin) {
    if (isHitPeople) {
      controls.showPersonalReward = true
    }
    return
  }
  const { code, info } = couponListData || {}
  if (code == 0 && info?.coupon?.length) {
    controls.showPersonalReward = true
  }
}


