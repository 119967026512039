export const UNPAID_DIALOG_IMG = {
  closeImage: 'https://shein.ltwebstatic.com/svgicons/2024/05/21/1716261449791297913.svg',
  defaultBg: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/171626169214cbd27645c88a79f0f04bdbbd204487.png',
  priceBg: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/1716261692abb2503734bf8ef53c9e78b640f769bb.png',
  mainBgTop: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/17162616921abf81c4f528ec2f69d3f7a6a6d1b620.png',
  moreIcon: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/1716261692e6dc32a53b8e0a03f172f15b90d8204f.png',
  rectangleIcon: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/0c/17162727199d8334642f5e9846f1a728e454db5c82.png',
  downwardTrendIcon: 'https://img.ltwebstatic.com/images3_ccc/2024/05/21/af/17162616924797aa14a90fefe17685ab45d39332b7.png',
}

