var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.getOrdersList.length > 0)?_c('div',{ref:"unpaidOrderRef",class:[
    'unpaid-order-box',
    `${_setup.getOrdersList.length === 1 && _setup.getUnpaidOrdersFloor.length > 1 ? 'unpaid-order-box__single':''}`,
    `${_setup.unpaidOrderActiveClass}`
  ],attrs:{"da-expose-code":"unpaidOrder"}},[_c('swiper-container',{ref:"swiperRef",staticClass:"unpaid-swiper-order",attrs:{"init":"false","no-swiping":"true","centered-slides":"true","observer":"true","autoplay-delay":"3000","observe-slide-children":true,"autoplay-disableOnInteraction":"false","loop":_setup.getOrdersList.length > 1,"pagination":_setup.getOrdersList.length > 1,"destroy-on-disconnected":"false"},on:{"swiperslidechangetransitionend":_setup.sliderChangeCallback}},_vm._l((_setup.getOrdersList),function(item){return _c('swiper-slide',{directives:[{name:"expose",rawName:"v-expose",value:({ id: '1-25-11-18' }),expression:"{ id: '1-25-11-18' }"}],key:item.billNo,staticClass:"unpaid-slide-order"},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
          id: '1-25-11-1000',
          data: {
            item
          }
        }),expression:"{\n          id: '1-25-11-1000',\n          data: {\n            item\n          }\n        }"}],staticClass:"unpaid-slide-order__container",on:{"click":function($event){$event.stopPropagation();return (() => _setup.gotoDetail(item)).apply(null, arguments)}}},[(item.orderType === 1)?[(_setup.isNewFloor)?[_c(_setup.UnpaidOrderNew,{attrs:{"item":item,"unpaidOrderActiveClass":_setup.unpaidOrderActiveClass},on:{"countdownEnd":() => _setup.countdownEnd?.push(item.billNo)}})]:[_c(_setup.UnpaidOrder,{attrs:{"item":item},on:{"countdownEnd":() => _setup.countdownEnd?.push(item.billNo)}})]]:_vm._e(),_vm._v(" "),(item.orderType === 2)?[_c('div',{staticClass:"unreview__container"},[_c('div',{staticClass:"order-info"},[_c('div',{staticClass:"order-img"},[_c('img',{staticClass:"first-img",attrs:{"src":item.goodImage,"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"order-content"},[_c('div',{domProps:{"innerHTML":_vm._s(item.upTips)}})])]),_vm._v(" "),_c('div',{staticClass:"order-btn"},[_c('SButton',{attrs:{"type":['primary','H48PX'],"block":""}},[_vm._v("\n                "+_vm._s(_setup.htmlDecode({ text: item.clickButton?.content || '' }))+"\n              ")])],1)])]:_vm._e()],2)])}),1),_vm._v(" "),(_setup.isNewUnpaidDialog)?[(_setup.dialogOrderItem)?_c(_setup.UnpaidDialogNew,{attrs:{"order-item":_setup.dialogOrderItem,"unpaid-order-rects":_setup.unpaidOrderRects},on:{"clickCloseDialog":_setup.clickCloseDialog}}):_vm._e()]:[(_setup.dialogOrderItem)?_c(_setup.UnpaidDialog,{attrs:{"order-item":_setup.dialogOrderItem}}):_vm._e()]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }