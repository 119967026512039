<template>
  <div
    v-expose="{
      id: '1-25-11-25',
      data: {
        my_assets_list: reportAssetsList,
      }
    }"
    class="me-list"
  >
    <div
      v-for="(item) in assets"
      :key="item.key"
      v-enterkey
      class="me-li waves-btn waves-btn-auto waves-effect waves-classic"
      :data-sa-param="item.id == 'points' ? JSON.stringify({ points: props.personalInfo?.point }) : ''"
      tabindex="0"
      role="button"
      @click="() => emits('router-push', { target: item.router, orderStatusType: '', scence: item.scence })"
    >
      <i
        v-if="item.isShowValue"
        :class="[
          'me-li-icon',
          GB_cssRight ? 'assets-wallet-ar' : '',
          item.id == 'wallet' && item.val ? 'assets-wallet' : 'assets-val',
        ]"
        v-html="item?.val"
      ></i>
      <Icon
        v-else
        :name="item.icon"
        size="24px"
      />
      <p class="me-li__title">
        {{ htmlDecode({ text: item.title }) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits } from 'vue'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { htmlDecode } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { useMapState } from '../hooks/useVuex.js'

const storeState = useMapState(['isRisk'])

const {
  language: gbLanguage,
  GB_cssRight,
} = gbCommonInfo

const props = defineProps({
  personalInfo: { type: Object, default: ()=> ({}) }
})

const emits = defineEmits(['router-push'])

const reportAssetsList = computed(() => {
  const { coupons, point, wallet_amount, wallet_currency } = props.personalInfo || {}
  return [{
    'item_type': 'my_coupons',
    'coupon_num': coupons || 0,
  }, {
    'item_type': 'shein_points',
    'points_num': point || 0,
  }, {
    'item_type': 'my_wallet',
    'wallet_num': !!wallet_amount ? `${wallet_currency}_${wallet_amount}` : null
  }, {
    'item_type': 'gift_card'
  }]
})
const assets = computed(() => {
  const _isLogin = isLogin()
  const { wallet_amount, wallet_currency } = props.personalInfo
  const walletText = !(Number(wallet_amount) == 0)
    ? `<span>${wallet_amount || 0}</span><sub>${wallet_currency}</sub>`
    : 0
  return [
    {
      id: 'coupon',
      title: gbLanguage.SHEIN_KEY_PWA_16129,
      icon: 'sui_icon_me_coupon_24px',
      router: '/user/coupon',
      isShowValue: _isLogin,
      val: props.personalInfo?.coupons || (storeState.isRisk.value ? '***' : '0'),
      scence: 'coupons'
    },
    {
      id: 'points',
      title: gbLanguage.SHEIN_KEY_PWA_16455,
      icon: 'sui_icon_me_points_24px',
      router: '/user/points',
      isShowValue: _isLogin,
      val: props.personalInfo?.point || (storeState.isRisk.value ? '***' : '0'),
      scence: 'points'
    },
    {
      id: 'wallet',
      title: gbLanguage.SHEIN_KEY_PWA_15109,
      icon: 'sui_icon_me_wallet_24px',
      router: '/user/wallet',
      isShowValue: _isLogin,
      val: walletText,
      scence: 'wallet'
    },
    {
      id: 'gift_card',
      title: gbLanguage.SHEIN_KEY_PWA_15846,
      icon: 'sui_icon_me_gift_24px',
      router: '/user/gift_card',
      isShowValue: false,
      scence: 'giftcard'
    }
  ] 
})
</script>
<style lang="less" scoped>
.me-list {
  .flexbox();
  flex-flow: row nowrap;
  padding: 0 8/75rem 24/75rem;
  background-color: #fff /* rw:ignore */;

  .me-li {
    position: relative;
    display: inline-block;
    width: 25%;
    text-align: center;
    .font-dpr(26px);
    word-break: break-word;
    padding: 24/75rem 8/75rem 0;

    /* stylelint-disable */
    .me-li-icon {
      display: block;
      .font-dpr(48px);
      margin-bottom: 0.1rem;
      height: 38/75rem;
      line-height: 38/75rem;
      font-weight: bold;
      &.assets-val {
        font-weight: bold;
        .font-dpr(28px);
        color: #222222;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        /deep/ sub {
          bottom: 0;
          .font-dpr(20px);
        }
      }

      &.assets-wallet {
        .flexbox();
        .pack-center();
        color: #222;
        font-size: 14px;

        span {
          font-weight: 700;
          .font-dpr(28px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          direction: ltr;
        }

        /deep/ sub {
          .font-dpr(20px);
          line-height: normal;
          font-weight: bold;
          position: relative;
          width: 0.7rem;
          white-space: nowrap;
        }
      }
      &.assets-wallet-ar {
        /deep/ sub {
          .left(0);
        }
      }
    }
    /* stylelint-enable */

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      .font-dpr(24px);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .order-num {
    position: absolute;
    top: 0.1rem;
    min-width: 0.32rem;
    height: 0.4rem;
    padding: 0 0.1rem;
    box-sizing: border-box;
    .font-dpr(20px);
    display: inline-block;
    border: 1px solid #222;
    border-radius: 0.4rem;
    text-align: center;
    line-height: 0.4rem;
    background: #fff;
    color: #222;
  }

  .red-num {
    background: #ff411c;
    color: #fff;
    border: none;
  }

  .red-point {
    position: absolute;
    top: 0.1rem;
    .right(32%);
    height: 0.16rem;
    width: 0.16rem;
    display: inline-block;
    border: 1px solid @sui_color_guide;
    border-radius: 0.08rem;
    background: @sui_color_guide;
  }
}
.font-bold {
  font-family: Adieu-Bold, Adieu;
  font-weight: bold;
}
</style>
