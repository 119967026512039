import { ref, onMounted } from 'vue'
import { getNewUserBenefitData } from '../api'

export const CccComponentStyleType = {
  NEW_USER_COUPON: 'newComerCoupon',
  BANNER: ['orderReturnCoupon', 'couponFusion'],
  DELIVER_GOODS: 'luckyBag',
}

export const initNewUserBenefitsModule = () => {
  const newUserBenefits = ref(null)
  const isNewUserBenefitsDataReady = ref(false)

  const initNewUserBenefits = () => {
    isNewUserBenefitsDataReady.value = false
    getNewUserBenefitData().then(data => {
      if (data?.code !== '0') return
      const { hitNewCustomerRightType, rightInfo } = data.info || {}
      const flag = Object.values(CccComponentStyleType).some(item => {
        if (Array.isArray(item)) {
          return item.some(subItem => subItem === hitNewCustomerRightType)
        }
        return item === hitNewCustomerRightType
      })
      if (!flag || !rightInfo) {
        newUserBenefits.value = null
        return
      }
      newUserBenefits.value = data.info
    }).finally(() => {
      isNewUserBenefitsDataReady.value = true
    })
  }

  onMounted(() => {
    initNewUserBenefits()
  })

  return {
    newUserBenefits,
    initNewUserBenefits,
    isNewUserBenefitsDataReady,
  }
}

export const setCouponsStyles = (coupons) => {
  if (coupons.length === 1) {
    return coupons.map(coupon => ({
      couponData: coupon,
      couponStyle: {
        couponWidthRem: 335 / 37.5,
        discountFontSizeClass: 'new-user-benefits__coupon-discount-fz14',
        discountLineHeightRem: (isSingleGearCoupon(coupon) ? 14 : 16) / 37.5,
        ruleTipsLineHeightRem: 10 / 37.5,
      },
    }))
  }
  const hasMultiGearCoupon = coupons.some(coupon => isMultiGearCoupon(coupon))
  if (hasMultiGearCoupon) {
    return coupons.map(coupon => ({
      couponData: coupon,
      couponStyle: {
        couponWidthRem: (isMultiGearCoupon(coupon) ? 277 : 155) / 37.5,
        discountFontSizeClass: isMultiGearCoupon(coupon)
          ? 'new-user-benefits__coupon-discount-fz12'
          : 'new-user-benefits__coupon-discount-fz14',
        discountLineHeightRem: 16 / 37.5,
        ruleTipsLineHeightRem: 8 / 37.5,
      }
    }))
  }
  return coupons.map(coupon => ({
    couponData: coupon,
    couponStyle: {
      couponWidthRem: (coupons.length === 2 ? 165 : 155) / 37.5,
      discountFontSizeClass: 'new-user-benefits__coupon-discount-fz14',
      discountLineHeightRem: 14 / 37.5,
      ruleTipsLineHeightRem: 10 / 37.5,
    }
  }))
}

const isSingleGearCoupon = coupon => coupon.rule.length === 1
const isMultiGearCoupon = coupon => coupon.rule.length > 1
