<template>
  <ListSkeletonV2
    v-if="showSkeleton"
    page="mePageForWishlist"
    :style="{
      'z-index': 1,
      'transform': 'translateZ(12px)'
    }"
  />
  <div
    v-else
    class="recently-page"
  >
    <div
      v-if="!isLogin || !products.length"
      class="recently-page__empty"
    >
      <i 
        v-if="IS_RW"
        class="suiiconfont sui_img_nodata_default_75px"
      ></i>
      <Icon 
        v-else
        name="sui_icon_empty_75px_1" 
        size="75px" 
      />
      <p> {{ htmlDecode({text: language.SHEIN_KEY_PWA_16991}) }}</p>
    </div>

    <Products 
      v-else
      :show-water-fall-style="showWaterFallStyle"
      :products="showProducts"
      :column="2"
      :language="language"
      :config="config"
      :show-filter-empty="false"
    />
    <ClientOnly>
      <UserConfirmModal
        v-if="confirmModalInfo"
        :modal-info="confirmModalInfo"
        @close="onConfirmDialogClose"
      />
    </ClientOnly>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
const { IS_RW, language, } = gbCommonInfo
import { htmlDecode } from '@shein/common-function'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
const LOCAL_KEY = 'shein_pwa_recent_views'
import schttp from 'public/src/services/schttp'
import { abtservice } from 'public/src/services/abt'
import Products from 'public/src/pages/user/child_pages/wishlist_v2/components/Products/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getWishGroupCfg } from 'public/src/pages/user/child_pages/wishlist_v2/configs'
import ListSkeletonV2 from 'public/src/pages/product_list_v2/components/ListSkeletonV2/index.vue'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import createFooterRcmdMixin from 'public/src/pages/user_index/mixins/footer-rcmd-mixin'
import { Icon } from '@shein-aidc/icon-vue2'
import UserConfirmModal from './UserConfirmModal.vue'
daEventCenter.addSubscriber({ modulecode: '2-2' })

export default {
  name: 'RecentView',
  components: {
    Products,
    ListSkeletonV2,
    Icon,
    ClientOnly,
    UserConfirmModal,
  },
  mixins: [
    createFooterRcmdMixin(),
  ],
  props: {
    isLogin: Boolean,
    show: Boolean,
  },
  data() {
    return {
      IS_RW,
      language,
      products: [],
      config: {
        sa: {
          code: 'recentlyViewed',
          name: 'recently_viewed',
          activity_from: 'recently_viewed',
        },
        itemDAEventClickId: '2-3-7',
        itemDAEventExposeId: '2-3-15',
        callback: {
          delete: (item) => {
            this.confirmModalInfo = {
              info: language.SHEIN_KEY_PWA_17429,
              okText: language.SHEIN_KEY_PWA_15502,
              ok: () => {
                this.handleDelete(item)
              },
            }
          }
        },
        autoCropImage: true, // 开启方图
        showItemV3: true, // 使用新商卡
      },
      listDataReady: false,
      realTimeTspLabels: { // tsp标签
        label_cloud: [],
        format_machine_label: [
          '3526', // 最近被购买的时间（实时购买）
          '3527', // 最近被5星好评的时间（实时好评）
          '1661', // 商品近90天销量
          '207',  // 商品已复购用户数
          '3460', // 商品近30天加购人数
          '3457', // 商品近30天收藏人数
          '3726', // 商品2年内5星好评数
          '3454'  // 近30天累计浏览
        ]
      },
      croppingABT: null, // 保存图片裁剪abt的结果：AllListCropping
      showWaterFallStyle: true, // 修复在个人中心收藏足迹列表刷新（严格意义上是用户中心的BUG,见public/src/pages/user/child_pages/dashboard/container_new.vue中clearAbtInfo方法）， 考虑到瀑布流实验推全了，所以直接hack code在这写死值，有问题联系Johnny Lv
      apolloConfig: null,
      confirmModalInfo: null,
    }
  },
  watch: {
    show: {
      async handler(v) {
        if (v && !this.products.length) {
          this.init()
        } 
      },
      immediate: true
    },
    isLogin(v) {
      if (v && this.show) { // 登录后进行重置状态
        this.listDataReady = false
        this.init()
      }
    }
  },
  methods: {
    async init() {
      await this.handleConfig()
      const list = this.getLocalData()
      if (list.length) {
        await Promise.all([this.updataProductInfo(list), this.getGradeState(list)])
      }
      this.products = list
      this.listDataReady = true
    },
    async getPrimieInfoByAbt() {
      return await schttp({
        url: '/api/prime/sheinClubInfoByAbt/get'
      })
    },
    async handleConfig() {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const [abtData = {}, { sheinClubType, isPaid } = {}, apollo = {}] = await Promise.all([abtservice.getUserAbtResult({ posKeys: 'AllListQuickShip,sheinclubprice', newPosKeys: 'NewSheinClub,discountLabel,greysellingPoint,AllListCropping,imageLabel,EstimatedPrice' }), this.getPrimieInfoByAbt(), this.getApolloConfig()]).catch(() => [])
      // abt-图片剪裁
      this.croppingABT = abtData?.AllListCropping?.param || {}
      const { CropA, CropB } = this.croppingABT
      if (CropA) this.realTimeTspLabels.label_cloud.push(CropA)
      if (CropB) this.realTimeTspLabels.label_cloud.push(CropB)

      const showOldSheinClubTag = `${sheinClubType}` === '1'
      const showNewSheinClubTag = `${sheinClubType}` === '2'
      const { isSuggestedSalePrice = false } = apollo

      this.config = getWishGroupCfg({
        ...this.config,
        showSheinClubDiscountValue: showOldSheinClubTag,
        showSheinClubDiscountValueAfterPrice: showNewSheinClubTag,
        showSheinClubPriceOnSale: !!(showNewSheinClubTag && isPaid),
        showPromotion: true,
        standardPrice: isSuggestedSalePrice
      }, abtData, { sheinClubType, isPaid })
    },
    getLocalData() {
      try {
        return JSON.parse(localStorage.getItem(LOCAL_KEY)) || []
      } catch (e) {
        return []
      }
    },
    onConfirmDialogClose() {
      this.confirmModalInfo = null
    },
    async updataProductInfo (data) {
      try {
        const needLocateLabels = this.config.showLocateLabels
        const fields = {
          mallInfo: 1,
          baseInfo: 1,
          promotion: 1,
          stock: 1,
          seriesAndBrand: 1,
          plusSize: 1,
          sellingPoints: 1,
          estimatedPrice: 1,  
          sheinClubPromotionInfo: true, // 付费会员抵扣
          quickShip: this.config.showQuickShip,
          cccTspBadges: true,
          promoLabel: !needLocateLabels && this.config.showPromoLabel,
          locateLabels: needLocateLabels,
          prices: true,
        }
        // abt-图片剪裁
        if (this.realTimeTspLabels.label_cloud.length) {
          fields.realTimeTspLabels = this.realTimeTspLabels
        }

        await itemSer.getAtomicInfoNew({
          goods: data,
          fields,
          pageKey: 'page_collection',
          sceneKey: 'TWO_IN_A_ROW',
          needAssign: true,
          cb: (goodsItem) => {
            let itGoodsIndex = data.findIndex(item => item.goods_id == goodsItem.goods_id)
            data[itGoodsIndex] = {
              ...data[itGoodsIndex],
              ...goodsItem
            }
          }
        })

        dealProductsPretreatInfo({
          products: data,
          itemConfig: this.config,
          language: this.language,
          promotionInfoFromServer: {},
        })

        this.assignCropRate({ list: data, AbtResult: this.croppingABT })
      } catch(e) {
        console.error(e)
      }
    },
    async getGradeState (list) {
      const goodIds = list.map(item => item.goods_id)
      if (!goodIds.length) return []
      try {
        const json = await schttp({
          method: 'post',
          url: '/api/productInfo/gradeState/query',
          data: {
            goods_id_arr: goodIds.join()
          }
        })
        const gradeStates = json?.info?.data || []
        if (!gradeStates.length) return
        list.forEach(item => {
          item.gradeState = gradeStates.find(i => i.skc == item.goods_sn)
          item.new_product_unsale = item.new_product_unsale || item.gradeState?.[0]?.new_product_unsale
        })
      } catch(e) {
        console.error(e)
      }
    },
    /**
     * 请求apollo获取站点是否支持销量标签配置
     */
    async getApolloConfig() {
      if (this.apolloConfig) {
        return this.apolloConfig
      }
      const res = await schttp({
        method: 'POST',
        url: '/api/config/apolloConfig/query',
        data: { apolloKeys: 'shein_suggested_sale_price' }
      })
      
      const { shein_suggested_sale_price = {} } = res?.info || {}
      const { SiteUID } = gbCommonInfo
      this.apolloConfig = {
        isSuggestedSalePrice: !!shein_suggested_sale_price[SiteUID]
      }
      return this.apolloConfig
    },
    handleDelete(deleteItem) {
      this.products = this.products.reduce((arr, item) => {
        if (item.goods_id != deleteItem.goods_id) {
          arr.push(item)
        }
        return arr
      }, [])

      daEventCenter.triggerNotice({
        daId: '2-3-4',
        extraData: {
          goods_id: deleteItem.goods_id,
          activity_from: this.config.sa.activity_from,
          item_sold_out: deleteItem.isSoldOut
        }
      })
      localStorage.setItem(LOCAL_KEY, JSON.stringify(this.products))
    },
    /** 给商品列表的每一项和其色块添加上裁剪属性cropRate
      * @param {*} list 列表
      * @param {*} AbtResult abt对应该poskey的结果对象
      * @param {*} cb 还需执行的其他操作
      * @returns 
    */
    assignCropRate({ list = [], AbtResult = {}, propA = 'CropA', propB = 'CropB', cb }) {
      const fn = ({ item, relatedColor }) => {
        if (item && !relatedColor) {
          let cropRate = null
          // CropA 1:1剪裁，CropB 5:6裁剪
          const CropA = AbtResult[propA] || ''
          const CropB = AbtResult[propB] || ''
          if (item?.realTimeTspLabels?.[CropA]) {
            cropRate = 1
          } else if (item?.realTimeTspLabels?.[CropB]) {
            cropRate = 1.2
          }
          
          cropRate && (item.cropRate = cropRate)
          cb && cb({ item })
        }
        if (item && relatedColor) {
          item.cropRate && (relatedColor.cropRate = item.cropRate) // 色块剪裁比例和主商品保持一致
          cb && cb({ item: relatedColor, type: 'relatedColor' } )
        }
      }
      
      list.forEach(item => {
        fn({ item })
        if (item.relatedColor?.length) {
          item.relatedColor.forEach(subItem => fn({ item, relatedColor: subItem }))
        }
      })
    },
    htmlDecode
  }
}
</script>

<style lang="less">
.recently-page {
  min-height: 8rem;

  &__empty {
    padding-top: 1.81333333rem;
    text-align: center;
    i {
      font-size: 75px;
      color: #222;

      & when (@IS_RW) {
        color: #fc4070;
      }
      
    }
    p {
      font-size: 0.37333333rem;
      color: #222;
      margin-top: 0.5333333rem;
    }
  }
}
</style>
