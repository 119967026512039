
import { ref, onMounted, onActivated } from 'vue'
import { setPageName, showTopTrendCard } from 'public/src/pages/product_app/store/modules/trend_landing/topTrend.utils.js'
import { abtservice } from 'public/src/services/abt'
import { fetchFooterRcmdData } from '../api/index.js'

const RECORD_KEY = 'usercenter_newspannel_display_logic'
const FIRST_LOAD_TAG_KEY = 'usercenter_newspannel_first_load'

// news疲劳值
export const useNewsFatigue = ({ getArticleTitleLists, routeInfo, getClientAbt }) => {
  const isShow = ref(false)

  const needExp = () => {
    const { to, from } = routeInfo.value
    // 首次链接访问（非刷新页面）
    if (!sessionStorage.getItem(FIRST_LOAD_TAG_KEY) ) return true
    // 其他一级tab跳过来
    if (to?.name === 'UserIndex' && ['config_index', 'page_category', 'campaigns'].includes(from?.name)) return true
    return false
  }
  const getRecord = () => {
    return JSON.parse(localStorage.getItem(RECORD_KEY) || '{}')
  }
  const setRecord = ({ type, time } = {}) => {
    let obj = getRecord()
    switch (type) {
      case 'addExp':
        if (!needExp()) return
        obj = {
          ...obj,
          expNum: (obj.expNum || 0) + 1
        }
        break
      case 'addCli':
        obj = {
          ...obj,
          cliNum: (obj.cliNum || 0) + 1
        }
        break
      case 'reset':
        obj = {
          time,
          expNum: 1,
          cliNum: 0,
        }
        break
    }
    localStorage.setItem(RECORD_KEY, JSON.stringify(obj))
  }

  const getStrategy = () => {
    const { PageMeNews } = getClientAbt()

    // 旧策略返回 'old' ，新策略返回 number（0,1,2 ...）代表疲劳值曝光次数
    let strategy = PageMeNews?.['abt-params-json']?.pageme_news_exposure
    if (!strategy) {
      strategy = strategy === 0 || strategy === '0' ? 0 : 'old' // 兜底值
    }
    const isNewStrategy = strategy !== 'old'
    return {
      strategy,
      isNewStrategy,
    }
  }

  const handleDisplay = () => {
    const { strategy, isNewStrategy } = getStrategy()
    if (isNewStrategy && +strategy) { // 新策略
      const { time, expNum, cliNum } = getRecord()
      const curTIme = getArticleTitleLists.value[0].startTime
      const hasClicked = (cliNum || 0) === 0
      if (time === curTIme && expNum >= +strategy && hasClicked) { // 曝光n次，且该设备未点击过news栏
        isShow.value = false
      } else {
        isShow.value = true
        time !== curTIme ? setRecord({ type: 'reset', time: curTIme }) : setRecord({ type: 'addExp' })
      }
      return
    }
  
    isShow.value = true
  }

  onMounted(() => {
    handleDisplay()
    sessionStorage.setItem(FIRST_LOAD_TAG_KEY, '1') // 用于判断是首次加载还是刷新页面
  })

  return {
    isShow,
    setRecord,
    handleDisplay,
  }
}

export const useTrendFlow = () => {

  const trendFlowData = ref([])
  const trendFlowPageNum = ref(1)
  const sheinClubInfo = ref({})
  const firstTrendFlowPending = ref(true)
  const abtSource = ref({})

  // 请求趋势内容体数据
  const fetchTrendFlowData = async ({ unuseCartSlotInfoNums } = {}) => {
    try {
      setPageName('user_index')
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtSource = await abtservice.getUserAbtResult({ newPosKeys: 'pagemefeedtrend,listwithCoupon' })
      const trendFlowAbt = {
        pagemefeedtrendword: +abtSource?.pagemefeedtrend?.p?.pagemefeedtrendword || 0, 
        pagemefeedtrendcard: +abtSource?.pagemefeedtrend?.p?.pagemefeedtrendcard || 0, 
      }
      const { pagemefeedtrendword, pagemefeedtrendcard } = trendFlowAbt
      // 还剩2个内容体时，请求剩余数据
      if (pagemefeedtrendword === 0 || unuseCartSlotInfoNums > 2 || unuseCartSlotInfoNums === 0) return
      const startIndex = trendFlowData.value.length ? trendFlowData.value[trendFlowData.value.length - 1].index : 0
      const getTrendCardFlow = showTopTrendCard(true) && pagemefeedtrendcard > 0
      const res = await fetchFooterRcmdData({
        getTrendCardFlow,
        trendFlowPageNum: trendFlowPageNum.value,
      })
      const { trendWordFlow = {}, trendCardFlow = {} } = res || {}
      sheinClubInfo.value = res?.sheinClubInfo || {}
      const validTrendCardData = trendCardFlow.data?.products?.length
      const _trendFlowData = trendWordFlow.data?.map((n, i) => {
        const slotIndex = (startIndex + i) * 20 + (pagemefeedtrendword - 1)
        // 趋势频道内容体与趋势词内容体共用同一套插坑逻辑，在趋势词内容体插坑中仅展示一次
        const isTrendCardSlot = validTrendCardData && getTrendCardFlow && (pagemefeedtrendcard - 1) === i
        const data = isTrendCardSlot ? trendCardFlow.data : n
        const type = isTrendCardSlot ? 'TopTrendCard' : 'TrendWordCard'
        return ({ slotIndex, data, type, index: startIndex + i + 1 })
      })
      const newTrendFlowData = trendFlowData.value.concat(_trendFlowData)
      // console.log('newTrendFlowData: ', unuseCartSlotInfoNums, newTrendFlowData)
      trendFlowData.value = newTrendFlowData
      trendFlowPageNum.value += 1
    } finally {
      firstTrendFlowPending.value = false
    }
  }

  onActivated(() => {
    trendFlowPageNum.value = 1
    trendFlowData.value = []
    firstTrendFlowPending.value = true
    fetchTrendFlowData()
  })

  return {
    firstTrendFlowPending,
    trendFlowData,
    fetchTrendFlowData,
    sheinClubInfo,
    abtSource,
  }
}
