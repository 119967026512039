var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"new-user-benefits__coupon-item",style:(_setup.itemStyles),on:{"click":function($event){$event.stopPropagation();return _setup.onCouponItemClick.apply(null, arguments)}}},[_c('div',{staticClass:"new-user-benefits__coupon-left",style:(_setup.itemLeftStyles)},[(_setup.couponRules.length)?_vm._l((_setup.couponRules),function(rule,i){return _c('div',{key:i,staticClass:"new-user-benefits__coupon-rule",style:({
          maxWidth: `${90 / _setup.couponRules.length}%`
        })},[_c(_setup.SAdapterText,{staticClass:"new-user-benefits__coupon-discount",class:[_vm.couponStyle.discountFontSizeClass],style:({
            lineHeight: `${_vm.couponStyle.discountLineHeightRem}rem`,
          }),attrs:{"min-size":12,"text":rule.discountAmountText}}),_vm._v(" "),(rule.useConditionText)?_c(_setup.SAdapterText,{staticClass:"new-user-benefits__coupon-rule-tips",style:({
            lineHeight: `${_vm.couponStyle.ruleTipsLineHeightRem}rem`,
          }),attrs:{"min-size":9,"text":rule.useConditionText}}):_vm._e()],1)}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"new-user-benefits__coupon-right"},[_c('div',{staticClass:"new-user-benefits__coupon-use-btn"},[_c(_setup.SAdapterText,{staticClass:"new-user-benefits__coupon-use-text",attrs:{"min-size":9,"text":_vm.couponData.useText}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }