var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.CommonWrapper,{attrs:{"cccx-config":_vm.cccxConfig}},[_c('div',{staticClass:"new-user-benefits__deliver-goods",on:{"click":function($event){$event.stopPropagation();return _setup.onModuleClick.apply(null, arguments)}}},_vm._l((_vm.products),function(product,index){return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '1-25-2-28',
        code: 'new-user-benefits-expose-goods',
        data: {
          product,
          cccxConfig: _vm.cccxConfig,
        },
      }),expression:"{\n        id: '1-25-2-28',\n        code: 'new-user-benefits-expose-goods',\n        data: {\n          product,\n          cccxConfig,\n        },\n      }"}],key:product.goods_id,staticClass:"new-user-benefits__deliver-goods-item",style:({
        backgroundImage: `url(${product.image_url})`
      }),on:{"click":function($event){$event.stopPropagation();return _setup.onGoodsItemClick(product, index)}}},[(product.unit_discount > 0)?_c('div',{staticClass:"new-user-benefits__deliver-goods-discount"},[_vm._v("\n        -"+_vm._s(product.unit_discount)+"%\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"new-user-benefits__deliver-goods-price"},[_c(_setup.CamelCasePrice,_vm._b({staticClass:"new-user-benefits__goods-price-camelcase",attrs:{"show-camel-price":true}},'CamelCasePrice',_setup.getCamelCasePrice(product),false))],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }