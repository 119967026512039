<template>
  <div
    v-expose="{
      id: '1-25-2-31'
    }"
    class="expire-game-money__tips-wrapper"
    @click.stop="onTipsClick"
  >
    <i class="expire-game-money__arrow"></i>
    <div
      class="expire-game-money__tips"
      v-html="tipsContent"
    ></div>
    <div
      class="expire-game-money__close-icon-wrapper"
      @click.stop="onTipsClose"
    >
      <i class="expire-game-money__close-icon"></i>
    </div>
  </div>
</template>

<script setup name="ExpireGameMoneyTips">
import { computed } from 'vue'
import { template } from '@shein/common-function'
import { ExpireTipsClickStatus } from 'public/src/pages/user/child_pages/dashboard/service/constants'
import { setLocalStorage } from 'public/src/pages/user/child_pages/dashboard/service/utils'
import {
  getExpireGameMoneyCache,
  getExpireGameMoneyCacheKey,
} from 'public/src/pages/user/child_pages/dashboard/hooks/user-assets-bottom'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  expireGameMoney: {
    type: Object,
    default: null,
  },
  routerPush: {
    type: Function,
    default: () => {},
  },
})

const emits = defineEmits([
  'hide-module',
])

const tipsContent = computed(() => template(
  `<span style="display: inline-block;color: #FA6338;font-weight: 700;font-family: 'SF-UI-Text-Bold';direction: ltr;">${
    props.expireGameMoney?.expireAmountWithSymbol
  }</span>`,
  props.language?.SHEIN_KEY_PWA_32056))

const onTipsClick = () => {
  setTimeout(() => {
    props.routerPush?.('/user/wallet', '', '', false, true)
  }, 300)
  emits('hide-module')
  const cacheKey = getExpireGameMoneyCacheKey()
  setLocalStorage(cacheKey, {
    status: ExpireTipsClickStatus.NO_LONGER_SHOW,
  })
  daEventCenter.triggerNotice({
    daId: '1-25-2-33',
  })
}

const onTipsClose = () => {
  emits('hide-module')
  updateTipsCloseTimes()
  daEventCenter.triggerNotice({
    daId: '1-25-2-32',
  })
}

const updateTipsCloseTimes = () => {
  const expireTipCache = getExpireGameMoneyCache()
  const after24Hours = new Date().getTime() + 24 * 3600 * 1000
  const cacheKey = getExpireGameMoneyCacheKey()
  setLocalStorage(cacheKey, {
    ...expireTipCache,
    closeTimes: expireTipCache.closeTimes + 1,
    displayAfterTime: after24Hours,
  })
}
</script>

<style lang="less" scoped>
.expire-game-money {
  &__tips-wrapper {
    position: relative;
    margin: 0 12/37.5rem 12/37.5rem;
    padding: 10/37.5rem 32/37.5rem 10/37.5rem 8/37.5rem;
    background-color: #FFF1D5;
  }
  &__arrow {
    position: absolute;
    border-width: 5px 6px;
    border-style: solid;
    border-color: transparent transparent #FFF1D5 transparent;
    top: -10px;
    left: 216/37.5rem /* rtl:unset */;
    right: unset /* rtl:5.76rem */;
  }
  &__tips {
    font-size: 12px;
    line-height: 14/37.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 28/37.5rem;
    font-family: 'SF-UI-Text-Regular';
  }
  &__close-icon-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8/37.5rem /* rtl:unset */;
    left: unset /* rtl:0.2133rem */;
    padding: 4/37.5rem;
  }
  &__close-icon {
    display: block;
    width: 16/37.5rem;
    height: 16/37.5rem;
    background: url(https://img.ltwebstatic.com/images3_ccc/2024/07/10/78/17206157549e524e376ac378f369298061b4dd62b4.png) 50% 50% / contain no-repeat;
  }
}
</style>

