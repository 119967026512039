<template>
  <div class="product-pannel">
    <template
      v-if="getNewGoodInfo.length <= 2"
    >
      <div
        v-for="(item, index) in getNewGoodInfo"
        :key="index"
        class="product-pannel__good"
      >
        <div class="product-pannel__img-pannel">
          <img
            :src="transformImg({ img: item.goodImage })"
            class="product-pannel__img-good"
          />
          <div
            v-if="item.stockTip"
            class="product-pannel__img-tip"
          >
            {{ item.stockTip }}
          </div>
        </div>
        
        <div
          class="product-pannel__good-info"
        >
          <p class="product-pannel__good-title">
            {{ item.goodTitle }}
          </p>
          <p
            :class="`product-pannel__good-price ${props.isDoubleView && item.label ? 'product-pannel__double-price': ''}`"
          >
            {{ item.goodPrice }}
          </p>
          
          <div
            v-if="(props.isDoubleView && item.label)"
            :key="item.label"
            class="product-pannel__good-tag"
          >
            <div 
              :class="{
                'product-pannel__tag-pre': true,
                'lowest-tag': item.type == '1',
                'best-tag': item.type == '2',
                'sales-tag': item.type == '3'
              }"
            >
              {{ item.label?.toUpperCase() || 'best' }}
            </div>
            <div 
              :class="{
                'product-pannel__tag-text': true,
                'lowest-text': item.type == '1',
                'best-text': item.type == '2',
                'sales-text': item.type == '3'
              }"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <ProductSwiperList
        v-if="props.isDoubleView"
        :order-item="props.orderItem"
      />
      <ProductList
        v-else
        :order-item="props.orderItem"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { transformImg } from '@shein/common-function'
import ProductList from './ProductList.vue'
import ProductSwiperList from './ProductSwiperList.vue'

const props = defineProps({
  orderItem: { type: Object, default: null },
  isDoubleView: { type: Boolean, default: false } // A：展示商品视角弹窗，B：展示商品+订单视角弹窗
})

const getNewGoodInfo = computed(() => props.orderItem?.newGoodInfo || [])

// const get

</script>

<style lang="less" scoped>
// 设计稿用的 375px
@unit: 375/100vw;
.product-pannel {
  margin-top: 12 / @unit;
  &__good {
    display: flex;
    width: 295 / @unit;
    height: 83 / @unit;
    box-sizing: border-box;
    padding: 4 / @unit 12 / @unit 4 / @unit 4 / @unit;
    border-radius: 2 / @unit;
    background: #F6F6F6;
    margin-bottom: 4 / @unit;
  }
  &__img-pannel {
    position: relative;
    width: 56 / @unit;
    height: 75 / @unit;
    margin-right: 8 / @unit;
  }
  &__img-good {
    width: 56 / @unit;
    height: 75 / @unit;
    margin-right: 8 / @unit;
    object-fit: cover;
  }
  &__img-tip {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 15 / @unit;
    line-height: 15 / @unit;
    text-align: center;
    width: 56 / @unit;
    box-sizing: border-box;
    padding: 0 2 / @unit;
    background: rgba(34, 34, 34, 0.60);
    color: #FFF;
    font-size: 9 / @unit;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__good-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__good-title {
    color: #000;
    font-size: 12 / @unit;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
  &__good-price {
    margin-top: 8 / @unit;
    font-size: 14 / @unit;
    font-weight: 700;
  }
  &__double-price {
    margin-top: 4 / @unit;
  }
  &__good-tag {
    width: 215 / @unit;
    box-sizing: border-box;
    height: 20 / @unit;
    display: flex;
    margin-top: 4 / @unit;
    display: inline-flex;
    align-items: center;
    border-radius: 2px;
    .best-tag {
      color: #fff;
      border-color: rgba(168, 97, 4, 0.2);
      background: linear-gradient(#785237, #97714F);
    }

    .sales-tag {
      color: #fff;
      border-color: rgba(250, 99, 56, 0.2);
      background: linear-gradient(#FA6338, #FAAC38);
    }

    .lowest-tag {
      color: #fff;
      border-color: rgba(248, 51, 42, 0.2);
      background: linear-gradient(#FF4540, #FFAE92);
    }
    .best-text {
      color: #A86104;
      background-color: #FFF7E8;
      border-color: #A8610433;
    }

    .sales-text {
      color: #fa6338;
      background-color: #FFF1D5;
      border-color: #FFF1D5;
    }

    .lowest-text {
      color: #f8332a;
      background-color: #FEEBEA;
      border-color: #F8332A33;
    }
  }
  &__tag-pre {
    display: flex;
    padding: 6 / @unit;
    justify-content: center;
    align-items: center;
    gap: 6 / @unit;
    align-self: stretch;
    border-radius: 2/ @unit 0 / @unit 0/ @unit 2/ @unit;
    color: #FFF;
    font-size: 10 / @unit;
    font-style: italic;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    
  }
  &__tag-text {
    height: 20 / @unit;
    font-size: 10 / @unit;
    box-sizing: border-box;
    padding: 0 6 / @unit;
    line-height: 18 / @unit;
    font-weight: 700;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* 防止内容换行 */
    border-width: 0.0067rem;
    border-style: solid;
    border-left: 0;
    border-radius: 0 2px 2px 0;
  }
}
.goods-online {
  border-radius: 2 / @unit;
  background: #F6F6F6;
  width: 295 / @unit;
  padding: 4 / @unit 4 / @unit 9 / @unit;
  &__img-pannel {
    display: flex;
    gap: 4 / @unit;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__img-wrap {
    position: relative;
  }
  &__img-good {
    width: 64 / @unit;
    height: 85 / @unit;
    object-fit: cover;
  }
  &__img-tip {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 15 / @unit;
    line-height: 15 / @unit;
    text-align: center;
    width: 64 / @unit;
    box-sizing: border-box;
    padding: 0 2 / @unit;
    background: rgba(34, 34, 34, 0.60);
    color: #FFF;
    font-size: 9 / @unit;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__counts {
    color: var(---sui_color_gray_dark3, #767676);
    text-align: right;
    font-size: 12 / @unit;
    margin-top: 8 / @unit;
    margin-right: 12 / @unit;
  }
  &__price {
    color: var(---sui_color_gray_dark3, #767676);
    text-align: right;
    font-size: 12px;
    margin-top: 4 / @unit;
    margin-right: 12 / @unit;
  }
}
</style>
