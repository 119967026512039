<template>
  <div class="new-user-benefits__coupon-list">
    <CouponItem
      v-for="(coupon, index) in coupons"
      v-expose="{
        id: '1-25-2-26',
        data: {
          module_nm: 'new_user_coupon',
          coupon_code: coupon.couponData?.couponCode || '',
          item_loc: index + 1,
          content_list: cccxConfig?.jumpUrl || '',
        },
      }"
      :key="index"
      :index="index"
      :language="language"
      :coupon-data="coupon.couponData"
      :coupon-style="coupon.couponStyle"
      :cccx-config="cccxConfig"
    />
  </div>
</template>

<script setup name="NewUserCouponList">
import CouponItem from './CouponItem.vue'

defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  coupons: {
    type: Array,
    default: () => [],
  },
  cccxConfig: {
    type: Object,
    default: {},
  },
})
</script>

<style lang="less" scoped>
.new-user-benefits {
  &__coupon-list {
    white-space: nowrap;
  }
}
</style>
