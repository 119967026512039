<script lang="jsx">
import { htmlDecode, template } from '@shein/common-function'
const { gbLanguage } = gbCommonInfo

export default {
  name: 'RewardMsgNotice',
  functional: true,
  props: {
    activityInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  render(h, { props, listeners }) {
    const { activityInfo } = props
    const showNotice = activityInfo.couponActOpen == 1 || activityInfo.pointActOpen == 1

    if (showNotice && activityInfo.abtResult) {
      const rewardMsg = template(activityInfo.rewardMsg, htmlDecode({ text: activityInfo.couponActOpen == 1 ? gbLanguage.SHEIN_KEY_PWA_16122 : gbLanguage.SHEIN_KEY_PWA_16121 }))
      return (
        <div
          class="reward-notice"
          vOn:click={listeners.clickBanner}
        >
          <i class="iconfont icon-BulletinBoard tongzhi"></i>
          <p class="content">
            {rewardMsg}
          </p>
          <i class="suiiconfont sui_icon_nav_back_24px_mir right"></i>
        </div>
      )
    }
    return null
  }
}
</script>

<style lang="less">
.reward-notice {
  background: #FFF1D5;
  min-height: 77/75rem;
  .flexbox();
  .align-center();
  padding: .32rem 0;

  .tongzhi {
    .font-dpr(30px);
    color: #222222;
    .padding-l(24/75rem);
    .padding-r(16/75rem);
    opacity: .6;
    .flip();
  }

  .content {
    flex: 1;
    word-break: break-word;
    .font-dpr(24px);
    color: #222222;
    line-height: 1.2;
  }

  .right {
    .font-dpr(24px);
    color: #999999;
    padding: 0 .32rem;
    cursor: pointer;
    .flip();
  }

  & when (@IS_RW) {
    background: #D9EAFF;
  }
}</style>
