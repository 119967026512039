var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.CommonWrapper,{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-25-2-26',
    data: {
      module_nm: 'new_user_promo',
      opt_nm: _vm.ugActivityType || 0,
      content_list: _vm.cccxConfig?.jumpUrl || '',
    },
  }),expression:"{\n    id: '1-25-2-26',\n    data: {\n      module_nm: 'new_user_promo',\n      opt_nm: ugActivityType || 0,\n      content_list: cccxConfig?.jumpUrl || '',\n    },\n  }"}],attrs:{"cccx-config":_vm.cccxConfig}},[_c('div',{staticClass:"new-user-benefits__banner",on:{"click":function($event){$event.stopPropagation();return _setup.onBannerClick.apply(null, arguments)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }