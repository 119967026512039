var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"product-pannel"},[(_setup.getNewGoodInfo.length <= 2)?_vm._l((_setup.getNewGoodInfo),function(item,index){return _c('div',{key:index,staticClass:"product-pannel__good"},[_c('div',{staticClass:"product-pannel__img-pannel"},[_c('img',{staticClass:"product-pannel__img-good",attrs:{"src":_setup.transformImg({ img: item.goodImage })}}),_vm._v(" "),(item.stockTip)?_c('div',{staticClass:"product-pannel__img-tip"},[_vm._v("\n          "+_vm._s(item.stockTip)+"\n        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"product-pannel__good-info"},[_c('p',{staticClass:"product-pannel__good-title"},[_vm._v("\n          "+_vm._s(item.goodTitle)+"\n        ")]),_vm._v(" "),_c('p',{class:`product-pannel__good-price ${_setup.props.isDoubleView && item.label ? 'product-pannel__double-price': ''}`},[_vm._v("\n          "+_vm._s(item.goodPrice)+"\n        ")]),_vm._v(" "),((_setup.props.isDoubleView && item.label))?_c('div',{key:item.label,staticClass:"product-pannel__good-tag"},[_c('div',{class:{
              'product-pannel__tag-pre': true,
              'lowest-tag': item.type == '1',
              'best-tag': item.type == '2',
              'sales-tag': item.type == '3'
            }},[_vm._v("\n            "+_vm._s(item.label?.toUpperCase() || 'best')+"\n          ")]),_vm._v(" "),_c('div',{class:{
              'product-pannel__tag-text': true,
              'lowest-text': item.type == '1',
              'best-text': item.type == '2',
              'sales-text': item.type == '3'
            }},[_vm._v("\n            "+_vm._s(item.text)+"\n          ")])]):_vm._e()])])}):[(_setup.props.isDoubleView)?_c(_setup.ProductSwiperList,{attrs:{"order-item":_setup.props.orderItem}}):_c(_setup.ProductList,{attrs:{"order-item":_setup.props.orderItem}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }